import RefuelsList from './RefuelsList'
import RefuelsHeader from "./RefuelsHeader"

import { makeStyles } from "@mui/styles";
import { Paper, TableContainer, Typography } from '@mui/material';
import OpenFilterPaneButton from '../Filter/OpenFilterPaneButton';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    // This component is placed into the pageContainerOverEclipse. The parent has 'fit-content' height and width values,
    // so we need to calculate the proper height and width for this component.
    root: {
        backgroundColor: "white",
        width: "100%",
        height: "100%",
        borderRadius: theme.shape.borderRadius,
        boxShadow: theme.shadows[5],
        display: "flex",
        flexDirection: "column",
        padding: theme.spacing(1),
    },
    scroll: {
        overflowY: "auto",
    },
    openButton: {
        display: "flex",
        justifyContent: "space-between",
        padding: theme.spacing(1),
    },
}));

const RefuelsResults = () => {

    const classes = useStyles()
    const { t } = useTranslation()
    
    return (
        <div className={classes.root}>
            <div className={classes.openButton}>
                <Typography variant="h5">
                    {t("refuels.listTitle")}
                </Typography>
                <OpenFilterPaneButton />
            </div>
            <div className={classes.scroll}>
                <TableContainer component={Paper}>
                    <RefuelsHeader />
                    <RefuelsList />
                </TableContainer>
            </div>
        </div>
    )
}

export default RefuelsResults