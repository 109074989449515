// React
import React from 'react'
import * as COM from '../../utilities/Common'


import NoSelectedTarget from '../Filter/NoSelectedTarget'
import TargetMenu from '../TargetMenu/TargetMenu'
import { t } from 'i18next'
import { AutorenewRounded, NotInterestedRounded } from '@mui/icons-material'
import { makeStyles } from '@mui/styles'
import { Chip } from '@mui/material'

const useStyles = makeStyles((theme) => ({
    odd: {
      backgroundColor: "lightgray" //TODO: from theme!
    },
    even: {
      backgroundColor: theme.palette.common.white
    },
    chipContainer: {
      //backgroundColor: "gold", // for debug
      display: "flex",
    },
    chipWrapper: {
      marginRight: "0.25rem",
    },
    emptyList: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "row",
    },
    '@keyframes rotate360': {
      from: {
        transform: 'rotate(0deg)',
      },
      to: {
        transform: 'rotate(360deg)',
      }
    },
    animateRotate360: {
      animation: '$rotate360 1s linear infinite'
    }
}))

const TrackingSelectedTargets = (props) => {

    const classes = useStyles()

    const { items, onDelete } = props;

    if (items.length === 0) {
      return (
        <div className={classes.emptyList}>
          <NoSelectedTarget />
        </div>
      )
    }

    const comparator = (a,b) => b.frontendTimeMicros - a.frontendTimeMicros
    
    const statusColors= {}
    statusColors[`${COM.TRACKING_STATUS_PENDING}`] = "default"
    statusColors[`${COM.TRACKING_STATUS_NO_DATA}`] = "default"
    statusColors[`${COM.TRACKING_STATUS_LIVE}`] = "primary"
    
    return (
      <>
        
        <div className={classes.chipContainer}>
          {props.items.sort(comparator).map((item, index) => {
            
            const statusIcons= {}
            statusIcons[`${COM.TRACKING_STATUS_PENDING}`] = <AutorenewRounded className={classes.animateRotate360} />
            statusIcons[`${COM.TRACKING_STATUS_NO_DATA}`] = <NotInterestedRounded />
            statusIcons[`${COM.TRACKING_STATUS_LIVE}`] = <TargetMenu item={item} />

            const statusIconTitles= {
              [`${COM.TRACKING_STATUS_PENDING}`]: t("tracking.loading"),
              [`${COM.TRACKING_STATUS_NO_DATA}`]: t("tracking.noData"),
              [`${COM.TRACKING_STATUS_LIVE}`]: t("tracking.live"),
            }
            
            return (
              <div className={classes.chipWrapper} key={`cwdiv-${index}`}>
                <Chip
                  title={statusIconTitles[item.status]}
                  icon={statusIcons[item.status]}
                  color={statusColors[item.status]}
                  label={item.target.name}
                  key={index}
                  onDelete={() => onDelete(item.target)} />
              </div>
            )
          })}
        </div>
      </>
    )
}

export default TrackingSelectedTargets