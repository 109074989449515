import { useEffect } from 'react'
import { createPlutoLogger } from '../../utilities/Common'

const KEY_DOWN_EVENT_NAME = 'keydown'

const D = createPlutoLogger("[useKeyboardWithBindings.js]")

const useKeyboardWithBindings = (keyBindings) => {
  D("Key bindings: ", keyBindings)
  useEffect(() => {
    const handleKeyDown = (event) => {
      D("[useEffect] [handleKeyDown] Event: ", event)
      const { key, shiftKey, ctrlKey, altKey, metaKey } = event
      keyBindings.forEach((binding) => {
        const { key: bindingKey, action, modifiers = {} } = binding
        const { shift = false, ctrl = false, alt = false, meta = false } = modifiers

        if (
          key === bindingKey &&
          shiftKey === shift &&
          ctrlKey === ctrl &&
          altKey === alt &&
          metaKey === meta
        ) {
          D(`[useEffect] [handleKeyDown] Key pressed: ${key}`)
          //D(`[useEffect] [handleKeyDown] Calling action: ${action}`)
          action(event)
        }
      });
    };

    window.addEventListener(KEY_DOWN_EVENT_NAME, handleKeyDown)
    return () => {
      window.removeEventListener(KEY_DOWN_EVENT_NAME, handleKeyDown)
    };
  }, [keyBindings]);
};

export default useKeyboardWithBindings