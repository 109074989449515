import { fork, putResolve, race, select, take } from "redux-saga/effects"
import { createPlutoLogger, isTokenValid } from "../../../utilities/Common"

import * as errorActions from "../../actions/error"
import * as trackingActions from "../../actions/tracking"
import * as actionTypes from '../../actions/actionTypes'

import { ApiError } from "../../../other/ApiError"

const D = createPlutoLogger("📍 [SAGA] [trackingAuth.js]")

export function* pollHandler() {
    D("[pollHandler] Forked.")
    while (true) {
        D("[pollHandler] ⏳ Waiting for signIn or signOut...")
        const { signIn, signOut} = yield race({
            signIn: take(actionTypes.AUTH_SUCCESS),
            signOut: take(actionTypes.AUTH_SIGN_OUT_SUCCESS),
        })
        D("[pollHandler] Received:", signIn, signOut)

        if (signIn) {
            D("[pollHandler] 🟢 SignIn received.")
            yield putResolve(trackingActions.pollStart())
        }

        if (signOut) {
            D("[pollHandler] 🔴 SignOut received.")
            yield putResolve(trackingActions.pollStop())
        }
    }
}

export function* targetForTokenHandler() {
    while (true) {
        D("[tokenHandler] Forked.")

        D("[tokenHandler] Waiting for sign in...")
        yield take(actionTypes.AUTH_SUCCESS)
        D("[tokenHandler] Auth success received.")
        
        D("Waiting for targets to be set...")
        yield take(actionTypes.TARGETS_SET)
        D("[tokenHandler] Set targets received.")

        try {
            const state = yield select()
            D("[tokenHandler] State:", state)

            const { targets, auth } = state
            D("[tokenHandler] Targets:", targets, "Auth:", auth)

            const { credentials } = auth
            const token = credentials.token
            const tokenPresented = isTokenValid(token)
            D("[tokenHandler] Token:", token, "tokenPresented:", tokenPresented)
            if (!tokenPresented) {
                D("Token is not valid. Skipping.")
                continue
            }

            D("[tokenHandler] Token is valid. Setting the first target.")
            const target = targets.items[0]
            D("[tokenHandler] First target:", target)

            yield putResolve(trackingActions.addTargets([target]))
        } catch (e) {
            console.warn("[tokenHandler] Error during setting the first target.", e)
            yield putResolve(errorActions.addError(new ApiError(e, ApiError.MODULE_TRACKING)));
        }

        D("[tokenHandler] Waiting for Sign out...")
        yield take(actionTypes.AUTH_SIGN_OUT_SUCCESS)
        D("[tokenHandler] Sign out received.")
    }
}

export function* saga() {
    D("Forked.")

    D("Waiting for rehydration...")
    yield take("persist/REHYDRATE") // We need to wait until the stored state is rehydrated because there could be a stored targets
    D("Rehydrated.")


    yield fork(pollHandler)
    yield fork(targetForTokenHandler)
}