// React imports
import React from 'react'

// i18n
import { useTranslation } from 'react-i18next'

import { Badge, Tooltip } from '@mui/material'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'

const ClearSelectedItems = (props) => {

    const { onClick, selectedCount } = props
    const { t } = useTranslation()

    if (selectedCount < 1) return null

    return (
        <Badge badgeContent={selectedCount} onClick={onClick}>
            <Tooltip title={t("general.deleteAll")}>
                <HighlightOffIcon />
            </Tooltip>
        </Badge>          
    )
}

export default ClearSelectedItems

