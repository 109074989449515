// Logging
// There is no logging in the production environment by default.
// To enable logging, we need to set the following environment variables.
const DEBUG_LOGGING_ENABLED = process.env.REACT_APP_DEBUG_LOGGING_ENABLED === 'true' || false
const WARN_AND_ERROR_LOGGING_ENABLED = process.env.REACT_APP_WARN_AND_ERROR_LOGGING_ENABLED === 'true' || false
const INFO_LOGGING_ENABLED = process.env.REACT_APP_INFO_LOGGING_ENABLED === 'true' || false

export const initLogging = async () => {
    
    const dummyFunction = () => {}

    // We do not want to see debug messages in the production environment,
    // so we use a block-by-default approach. The console.debug function only works if
    // DEBUG_LOGGING_ENABLED is true; otherwise, it is just an empty function.
    if (!DEBUG_LOGGING_ENABLED) {
        console.info("🐛 Debug logging is disabled now.")
        console.debug = dummyFunction
    } else {
        console.debug("🐛 Debug logging is enabled.")
    }

    if (!WARN_AND_ERROR_LOGGING_ENABLED) {
        console.info("⚠️ Warning logging is being disabled now.")
        console.warn = dummyFunction
    } else {
        console.debug("⚠️ Warning logging is enabled.")
    }

    if (!INFO_LOGGING_ENABLED) {
        console.info("ℹ️ Info logging is being disabled now.")
        console.info = dummyFunction
    } else {
        console.debug("ℹ️ Info logging is enabled.")
    }
}
