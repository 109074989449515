import DisplayableError from "./DisplayableError"

export class ApiError extends DisplayableError {
    
    static MESSAGE_KEY_ROOT = "error.api"
    
    static MODULE_UNKNOWN = "unknown"
    static MODULE_AUTH = "auth"
    static MODULE_REFUELS = "refuels"
    static MODULE_ROUTES = "routes"
    static MODULE_TARGETS = "targets"
    static MODULE_TRACKING = "tracking"
    static MODULE_CHAT = "chat"
    static MODULE_SHARE_TARGET = "shareTarget"

    static ERRORS = [
        {
          code: 'ERR_NETWORK',
          severity: ApiError.SEVERITY_WARNING,
        },
        {
          code: 'ECONNABORTED',
          severity: ApiError.SEVERITY_WARNING,
        },
        {
          code: 'ERR_BAD_REQUEST',
          severity: ApiError.SEVERITY_WARNING,
        },
        {
          code: 'ERR_UNAUTHORIZED',
          severity: ApiError.SEVERITY_FATAL
        },
        {
          code: 'ERR_FORBIDDEN',
          severity: ApiError.SEVERITY_FATAL,
        },
        {
          code: 'ERR_NOT_FOUND',
          severity: ApiError.SEVERITY_WARNING,
        },
        {
          code: 'ERR_METHOD_NOT_ALLOWED',
          severity: ApiError.SEVERITY_WARNING
        },
        {
          code: 'ERR_TIMEOUT',
          severity: ApiError.SEVERITY_WARNING
        },
        {
          code: 'ERR_CONFLICT',
          severity: ApiError.SEVERITY_WARNING
        },
        {
          code: 'ERR_TOO_MANY_REQUESTS',
          severity: ApiError.SEVERITY_WARNING
        },
        {
          code: 'ERR_BAD_RESPONSE',
          severity: ApiError.SEVERITY_WARNING
        },
        {
          code: 'ERR_FR_TOO_MANY_REDIRECTS',
          severity: ApiError.SEVERITY_WARNING
        },
        {
          code: 'ERR_DEPRECATED',
          severity: ApiError.SEVERITY_WARNING
        },
        {
          code: 'ERR_NETWORK_CHANGED',
          severity: ApiError.SEVERITY_WARNING
        }
      ];

    constructor(exception, module, forcedSeverity) { 

        const code = exception?.code
        
        let messageKey
        let severity
        const match = ApiError.ERRORS.find(error => {
          //console.debug("[ApiError.js] error: ", error.code, " code: ", code, "result ", error.code === code)
          return error.code === code
        })
        console.debug("[ApiError.js] match: ", match)
        if (match) {
          messageKey = match.code.toLowerCase()
          severity = match.severity
        } else {
          messageKey = "unknown"
          severity = ApiError.SEVERITY_UNKNOWN
        }

        /*
          There is a strange behavior in the axios library. If the server returns a 401 or 403 status code, the code is ERR_BAD_REQUEST.
          This is why we have to check the status code here. I have no time to investigate this issue now.
          code: "ERR_BAD_REQUEST"
          message: "Request failed with status code 401"
        */
        const statusCode = exception?.response?.status
        if (statusCode) {
          switch (statusCode) {
            case 401: {
              messageKey = "unauthorized"
              severity = ApiError.SEVERITY_FATAL
              break;
            }
            case 403: {
              messageKey = "forbidden"
              severity = ApiError.SEVERITY_FATAL
              break;
            }
          }
        }

        super(exception, `${ApiError.MESSAGE_KEY_ROOT}.${messageKey}`, `${ApiError.MESSAGE_KEY_ROOT}.title`)
        if (forcedSeverity) {
          this.severity = forcedSeverity
        } else {
          if (severity) this.severity = severity 
        }
        this.module = module || ApiError.MODULE_UNKNOWN

        console.debug("[ApiError.js] match: ", match, " exception: ", this.exception, " module:", this.module, "severity:", this.severity, "forced severity: ", forcedSeverity)
    }
}