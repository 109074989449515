import { makeStyles } from "@mui/styles"
import TimeBox from "./TimeBox"

const Position = (props) => {
    const { timeText, icon, geocodedAddress, color } = props
    const timeBoxAlign = props.timeBoxAlign || "flex-start"

    ////console.debug("[Position.js] params and properties: ", { timeText, icon, geocodedAddress, color, timeBoxAlign })

    const useStyles = makeStyles((theme) => ({
        root: {
            display: "flex",
            gap: ".25rem",
        },
        addressBox: {
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            gap: ".25rem",
            fontWeight: "bold",
            // !!! do not define color here, it has to be inherited from the parent
            // because this component is used in different contexts with different background colors !!!
        },
        iconAndAddress: { 
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            // !!! do not define color here, it has to be inherited from the parent
            // because this component is used in different contexts with different background colors !!!
            // backgroundColor: "red" // just for debugging
        },
        icon: {
            alignSelf: timeBoxAlign,
        },
        timeBox: {
            display: "flex",
            justifyContent: "flex-start",
            alignItems: timeBoxAlign,
            // backgroundColor: "red", //just for debugging
       }
    }))
    const classes = useStyles()

    return (
        <div className={classes.root}>
            <div className={classes.timeBox}>
                <TimeBox timeText={timeText} color={color} />
            </div>
            <div className={classes.addressBox}>
                <div className={classes.iconAndAddress}>
                    <span className={classes.icon}>{icon}</span>
                    <span>{geocodedAddress}</span>
                </div>
            </div>
        </div>
    )
}

export default Position