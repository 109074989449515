import { t } from "i18next"
import PlutoButton from "./PlutoButton"
import { connect } from "react-redux"
import { NAVIGATION_ROUTE_ROUTES, NAVIGATION_ROUTE_TRACKING } from "../BottomMenu/NavigationRoutes"
import { useLocation, useNavigate } from "react-router-dom"

const CloseButton = (props) => {

    const { state, onClick } = props
    const { tracking, routes } = state

    const location = useLocation()
    const isTracking = location.pathname === NAVIGATION_ROUTE_TRACKING
    const isRoutes = location.pathname === NAVIGATION_ROUTE_ROUTES
 
    let multipleSelectedInModule = false;
    if (isTracking) {
        multipleSelectedInModule = tracking?.targets?.length > 0
    } else if (isRoutes) {
        const selectedRouteItems = routes?.items?.filter((route) => route.checked)
        multipleSelectedInModule = selectedRouteItems.length > 0
    }

    const i18nKey = multipleSelectedInModule ? "drawer.showOnMap" : "drawer.close"

    return <PlutoButton i18key={t(i18nKey)} onClick={onClick} />
  }
  
const mapDispatchToProps = dispatch => {
    return {
        // no actions
    }
}

const mapStateToProps = (state) => {
    return {
        state: state,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CloseButton)