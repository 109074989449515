import { LinearProgress, Typography } from "@mui/material"
import { makeStyles } from "@mui/styles"

import { useTranslation } from "react-i18next"

const useStyles = makeStyles( theme => ({
    innerContainer: {
        minWidth: "15rem",
        maxHeight: "90%", 
    }
}))

const ProgressIndicator = (props) => {
    
    const classes = useStyles()
    const { t } = useTranslation()
    const { message, flag, color } = props
    const localizedMessage = message ? t(message) : t("general.waitingForServer")
    const style = {color: `${color ? color : "black"}`}

    if (!flag) return null

    return (
        <div className={classes.innerContainer} style={style}>
            <Typography varian="h5">{localizedMessage}</Typography>
            <LinearProgress />
        </div>
    )
}

export default ProgressIndicator