
import { Paper } from "@mui/material"
import ProgressIndicator from "./ProgressIndicator"
import { makeStyles } from "@mui/styles"

const useStyles = makeStyles( theme => ({
    root: {
        position: "absolute",
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 1,
        backgroundColor: "rgba(0, 0, 0, .5)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    paper: {
        borderRadius: theme.shape.borderRadius,
        padding: "2rem",
    },
}))

const PaperAdder = (props) => {

    const { paperClass, children } = props
    
    return (
        <Paper className={paperClass}>
            {children}
        </Paper>
    )
}

const ModalProgressIndicator = (props) => {
    
    const classes = useStyles()
    const { message, flag, showPaper } = props
    let progressIndicator = <ProgressIndicator message={message} flag={true} color={showPaper ? "black" : "white"} />

    if (!flag) return null
    if (showPaper) progressIndicator = <PaperAdder paperClass={classes.paper}>{progressIndicator}</PaperAdder>

    return (
        <div className={classes.root}>
            {progressIndicator}
        </div>
    )
}

export default ModalProgressIndicator