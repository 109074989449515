//!!! This implementation assumes the use of the Luxon date library as the date adapter. !!!
// But I leave the old JavaScript Date based implementation here as reference
// React & redux imports
import React, { useState } from 'react'
import { connect } from 'react-redux'
import * as shareTargetActions from '../../store/actions/shareTarget'
//import * as COM from '../../utilities/Common'

// i18n
import { useTranslation } from 'react-i18next'

import {
  MobileDatePicker,
  MobileDateTimePicker,
  renderTimeViewClock
} from '@mui/x-date-pickers'

// Own components
//import { endOfDay, isSameDay, setSeconds, startOfDay } from 'date-fns'
import Actions from './Actions'
import { Close } from '@mui/icons-material'
import { makeStyles } from '@mui/styles'
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material'
import { DateTime } from 'luxon'

const useStyles = makeStyles((theme) => ({
  dialog: {
    // no css at the moment
  },
  dialogTitle: {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column-reverse',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.spacing(3),
    marginRight: theme.spacing(3),
  },
  closeIcon: {
    cursor: 'pointer',
    alignSelf: 'flex-end',
  },
  container: {
    maxWidth: theme.breakpoints.values.md,
    flexDirection: 'column',
    gap: "1rem",
  },
  pickerLeft: {
    boxSizing: 'border-box',
  },
  pickerRight: {
    boxSizing: 'border-box',
  },
  [`@media only screen and (min-width: ${theme.breakpoints.values.sm}px)`]: {
    dialogTitle: {
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      marginTop: 0,
      marginRight: theme.spacing(3), // to get the close icon to the right align with the other components
    },
    closeIcon: {
      cursor: 'pointer',
      alignSelf: 'center',
    },
    container: {
      flexDirection: 'row',
      gap: 0,
    },
    pickerLeft: {
      paddingRight: ".5rem",
    },
    pickerRight: {
      paddingLeft: ".5rem",
    },
    textareaContainer: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
  infoBox: {
    marginTop: '1rem',
    marginBottom: '1rem'
  },
  textareaContainer: {
    // no 
  },
  textarea: {
    width: '100%',
    height: '4rem',
    resize: 'none',
    border: `solid 1px ${theme.palette.grey[300]}`,
    borderRadius: theme.shape.borderRadius,
  },
  button: {
    margin: theme.spacing(1),
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: ".75%",
  },
}));


const TOKEN_REPLACE_VARIABLE = "{token}"
const LNG_CODE_REPLACE_VARIABLE = "{lngCode}"

const ShareDialog = (props) => {

  const { loginToken, inProgress } = props
  const { t, i18n } = useTranslation()
  const classes = useStyles()
  const [isEmbedded, setIsEmbedded] = useState(false)

  //console.debug("[ShareDialog.js] loginToken: ", loginToken)
  
  // For coding & debugging purposes, here is the link that will be shared:
  // baseURL?t={token}&lng={lngCode}
  // The token and lngCode will be replaced with the actual values

  const pe = process.env
  const iFrameWidth = pe.REACT_APP_SHARE_TARGET_IFRAME_WIDTH || 800
  const iFrameHeight = pe.REACT_APP_SHARE_TARGET_IFRAME_HEIGHT || 600
  const baseLinkWithToken =  pe.REACT_APP_SHARE_TARGET_BASE_LINK.replace(TOKEN_REPLACE_VARIABLE, loginToken)
  const lngCode = i18n.language
  const baseLinkWithTokenAndLngCode = baseLinkWithToken.replace(LNG_CODE_REPLACE_VARIABLE, lngCode)
  const link = isEmbedded ? `<iframe src="${baseLinkWithTokenAndLngCode}" width="${iFrameWidth}" height="${iFrameHeight}"></iframe>` : `${baseLinkWithTokenAndLngCode}`

  /*console.debug(
      "[Actions.js] values: isEmbedded: ", isEmbedded,
      ", iFrameWidth: ", iFrameWidth,
      ", iFrameHeight: ", iFrameHeight,
      ", baseLinkWithToken: ", baseLinkWithToken,
      ", baseLinkWithTokenAndLngCode: ", baseLinkWithTokenAndLngCode,
      ", lngCode: ", lngCode,
      ", link: ", link);*/

  const today = DateTime.now()
  const startOfToday = today.startOf('day') //startOfDay(today)
  const [fromDate, setFromDate] = useState(startOfToday)
  const endOfToday = today.endOf('day') //endOfDay(today)
  const [toDate, setToDate] = useState(endOfToday)

  const handleEmbeddedChange = (event) => {
    setIsEmbedded(event.target.checked)
  }

  const handleGenerateClick = () => {
    const fromTimeMillis = fromDate.toMillis() //fromDate.getTime()
    const toTimeMillis = toDate.toMillis() // toDate.getTime()

    //console.debug(`[ShareDialog.js] [sharable_link_time] Start generating sharable link: fromTimeMillis: `, fromTimeMillis, ", toTimeMillis: ", toTimeMillis)
    props.generateStart(props.item.target.id, fromTimeMillis, toTimeMillis, "", "")
  }

  /*const checkAndSetDates = (from, to) => {
      // If the two days are matching and the time of the 'to' date is 23:59 and the time of the 'from' date is 00:00
      // assume the user intends to select the entire day, so we have to set the seconds of the 'to' date to 59
      // to include the last minute of the day.
      //console.debug("[ShareDialog.js] wholeDayHandler: from: ", from, ", to: ", to)
      
      // It appears that the seconds can only be set to 0.
      // But for sure we set the 'from' seconds to 0
      const zeroSecondFrom = setSeconds(from, 0)
      const daysAreSame =  isSameDay(zeroSecondFrom, to)
      const fromHours = zeroSecondFrom.getHours()
      const fromMinutes = zeroSecondFrom.getMinutes()
      const toHours = to.getHours()
      const toMinutes = to.getMinutes()

      //console.debug("[ShareDialog.js] wholeDayHandler: daysAreSame: ", daysAreSame, ", fromHours: ", fromHours, ", fromMinutes: ", fromMinutes, ", toHours: ", toHours, ", toMinutes: ", toMinutes)

      let handledTo = to
      if (daysAreSame && fromHours === 0 && fromMinutes === 0 && toHours === 23 && toMinutes === 59) {
        //console.debug("[ShareDialog.js] wholeDayHandler: Set the seconds of the 'to' date to 59")
        handledTo = endOfDay(to)
      }
      
      clearLoginToken()
      setFromDate(zeroSecondFrom)
      setToDate(handledTo)
      }*/

      const checkAndSetDates = (from, to) => {
        // If the two days are matching and the time of the 'to' date is 23:59 and the time of the 'from' date is 00:00
        // assume the user intends to select the entire day, so we have to set the seconds of the 'to' date to 59
        // to include the last minute of the day.
        // console.debug("[ShareDialog.js] wholeDayHandler: from: ", from, ", to: ", to)
        
        // It appears that the seconds can only be set to 0.
        // But for sure we set the 'from' seconds to 0
        const zeroSecondFrom = from.set({ second: 0 });
        const daysAreSame = zeroSecondFrom.hasSame(to, 'day');
        const fromHours = zeroSecondFrom.hour;
        const fromMinutes = zeroSecondFrom.minute;
        const toHours = to.hour;
        const toMinutes = to.minute;
    
        // console.debug("[ShareDialog.js] wholeDayHandler: daysAreSame: ", daysAreSame, ", fromHours: ", fromHours, ", fromMinutes: ", fromMinutes, ", toHours: ", toHours, ", toMinutes: ", toMinutes)
    
        let handledTo = to;
        if (daysAreSame && fromHours === 0 && fromMinutes === 0 && toHours === 23 && toMinutes === 59) {
            // console.debug("[ShareDialog.js] wholeDayHandler: Set the seconds of the 'to' date to 59")
            handledTo = to.set({ second: 59 });
        }
        
        clearLoginToken();
        setFromDate(zeroSecondFrom);
        setToDate(handledTo);
    };

  const handleFromDateChange = (date) => {
      //console.debug("[ShareDialog.js] handleFromDateChange: date: ", date)
      checkAndSetDates(date, toDate)
  }

  const handleToDateChange = (date) => {
      //console.debug("[ShareDialog.js] handleToDateChange: date: ", date)
      checkAndSetDates(fromDate, date)
  }

  const shouldDisableFromDate = (date) => {
    return toDate < date
    //return COM.isBefore(toDate, date)
  }

  const shouldDisableToDate = (date) => {

      //The 'toDate' cannot be before the fromDate or before today
      //We have to 'roll' the time of the dates to the begining of the day and to the end of the day
      const today = DateTime.now().startOf('day');
      const endOfTheDayDate = date.endOf('day')

      return endOfTheDayDate < today || endOfTheDayDate < fromDate

      //return COM.isBefore(endOfTheDayDate, today) || COM.isBefore(endOfTheDayDate, fromDate)
  }


  const content = loginToken
  ?
  <Grid item xs={12} className={classes.textareaContainer}>
      <textarea
        readOnly
        className={classes.textarea}
        value={link} />
  </Grid>
  :
  null

  const embeddedTitle = t("share.embedded.title")
  const normalTitle = t("share.normal.title")
  const title = t(isEmbedded ? embeddedTitle : normalTitle)

  const viewRenderers = {
    hours: renderTimeViewClock,
    minutes: renderTimeViewClock,
    seconds: renderTimeViewClock,
  }
  const views = ['year', 'day', 'hours', 'minutes']

  const clearLoginToken = () => {
    props.setLoginToken(null)
  }

  const handleClose = () => {
    // If the dialog is going to be closed, we should clear thep shareTargetState.loginToken
    // so that the next time the dialog is opened, the previous link is not displayed
    clearLoginToken()
    
    // Close the dialog
    props.onClose();
  }

  return (
    <Dialog
      onClose={props.onClose}
      aria-labelledby="share-dialog-title"
      open={props.open}
      className={classes.dialog}>

      <div className={classes.dialogTitle}>
        <DialogTitle id="share-dialog-title">
          {title}
        </DialogTitle>
        <Close onClick={handleClose} className={classes.closeIcon}/>
      </div>

      <DialogContent>
       
        <Grid container className={classes.container}>

          <Grid item xs={12} sm={6} className={classes.pickerLeft}>
            <MobileDateTimePicker
              slotProps={{ textField: { fullWidth: true } }}
              views={views}
              viewRenderers={viewRenderers}
              autoOk
              id="date-time-picker-from"
              label={t("general.dateTime.startDate")}
              todayLabel={t("general.dateTime.today")}
              cancelLabel={t("general.cancel")}
              okLabel={t("general.ok")}
              value={fromDate}
              onChange={handleFromDateChange}
              shouldDisableDate={shouldDisableFromDate} />
          </Grid>

          <Grid item xs={12} sm={6} className={classes.pickerRight}>
            <MobileDateTimePicker
              slotProps={{ textField: { fullWidth: true } }}
              views={views}
              viewRenderers={viewRenderers}
              autoOk={true}
              id="date-time-picker-to"
              label={t("general.dateTime.endDate")}
              todayLabel={t("general.dateTime.today")}
              cancelLabel={t("general.cancel")}
              okLabel={t("general.ok")}
              value={toDate}
              onChange={handleToDateChange}
              shouldDisableDate={shouldDisableToDate} />
          </Grid>

          <Grid item xs={12} mt={2}>
            {content}
          </Grid>

          <Grid item xs={12}>
            <Actions
              link={link}
              loginToken={loginToken}
              inProgress={inProgress}
              isEmbedded={isEmbedded}
              handleSwitchChange={handleEmbeddedChange}
              handleGenerate={handleGenerateClick} />
          </Grid>

      </Grid>

      </DialogContent>

      <DialogActions>
        {
        // There is no action  at the moment but 
        // we keep the DialogActions component to get same margin from the bottom
        }
      </DialogActions>
    </Dialog>
  )
}

function mapStateToProps(state) {
  return {
    loginToken: state.shareTarget.loginToken,
    inProgress: state.shareTarget.inProgress,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    generateStart: (targetId, fromTimeMillis, toTimeMillis, email, type) => dispatch(shareTargetActions.generateStart(targetId, fromTimeMillis, toTimeMillis, email, type)),
    setLoginToken: (loginToken) => dispatch(shareTargetActions.setLoginToken(loginToken)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ShareDialog)