import { useState } from "react";
import { useMapEvents, useMap, LayerGroup } from "react-leaflet"
import { makeStyles } from '@mui/styles'
import { Polyline } from "react-leaflet"
import RotableMarker from "./RotableMarker";
//import { createPlutoLogger } from "../../../utilities/Common";

const useStyles = makeStyles(theme => ({
    "@keyframes fadeEffect": {
        "0%": {
            opacity: 1,
        },
        "100%": {
            opacity: .5,
        }
    },
    animationTarget: {
        animationName: "$fadeEffect",
        animationDuration: "1000ms",
        animationIterationCount: "infinite",
        //animationDirection: "alternate",
    }
}));

//const D = createPlutoLogger("[PolylineWithArrows.js]")

const PolylineWithArrows = (props) => {

    const { sections, positions } = props //these are the latlng positions!
    const animationClasses = useStyles()
    const map = useMap()
    const [mapBounds, setMapBounds] = useState(map.getBounds())

    useMapEvents({
        zoomstart: () => {
        },
        zoomend: () => {
            //D("Zoom end")
            setMapBounds(map.getBounds())
        },
        movestart: () => {
        },
        moveend: () => {
            //D("Move end")
            setMapBounds(map.getBounds())
        }
    })
    
    if (positions.length === 0) return null;
    //D("Map bounds: ", mapBounds.getNorthEast(), mapBounds.getSouthWest())

    const sectionsWithVisibleMidPoint = sections.filter( s => mapBounds.contains(s.midPoint))
    //Be very careful! We are going to use nested, complex arrays here. To avoid problems
    //we have to set the arrow relevance null for each element in the 'sectionsWithVisibleMidPoint' array
    sectionsWithVisibleMidPoint.forEach( s => s.arrowRelevance = null)

    //const visibleCount = sectionsWithVisibleMidPoint.length;
    //D("Visible sections:", visibleCount)

    // How much arrows do we need?
    const ARROW_COUNT = 7;
    const proposedArrowCount = sectionsWithVisibleMidPoint.length >= ARROW_COUNT ? ARROW_COUNT : sectionsWithVisibleMidPoint.length
    //D("Proposed arrow count: ", proposedArrowCount)

    const visibleRouteLength = sectionsWithVisibleMidPoint.reduce((partialSum, s) => partialSum + s.length, 0);

    const optimalClusterLength = visibleRouteLength / (proposedArrowCount + 1)
    //D("Visible route length (m): ", visibleRouteLength)
    //D("Optimal length of a cluster (m): ", optimalClusterLength)
    
    let sectionStartDistance = 0;
    for (let i = 0; i < sectionsWithVisibleMidPoint.length; i++) {
        const s = sectionsWithVisibleMidPoint[i]
        if ((s.endPointDistanceFromStart - sectionStartDistance) >= optimalClusterLength) {
            sectionStartDistance = s.endPointDistanceFromStart
            s.arrowRelevance = 100
        }
    }

    const sectionsWithArrow = [...sectionsWithVisibleMidPoint.filter( s => s.arrowRelevance > 1)]
    //D("Sections with arrow: ", sectionsWithArrow.length)

    const LOWER_POLYLINE_COLOR = "#222222"
    const LOWER_POLYLINE_OPACITY = .75
    const LOWER_POLYLINE_WEIGHT = 6
    
    const UPPER_POLYLINE_COLOR = "#FF1414"
    const UPPER_POLYLINE_OPACITY = 1
    const UPPER_POLYLINE_WEIGHT = 4

    //For performance reasons we create the animation class here
    //and set as a parameter for the RotableMarker

    return (
        <>
            <Polyline positions={positions} stroke={true} weight={LOWER_POLYLINE_WEIGHT} color={LOWER_POLYLINE_COLOR} opacity={LOWER_POLYLINE_OPACITY} />
            <Polyline positions={positions} stroke={true} weight={UPPER_POLYLINE_WEIGHT} color={UPPER_POLYLINE_COLOR} opacity={UPPER_POLYLINE_OPACITY} />
            <LayerGroup>
                {sectionsWithArrow.map((s,i) => 
                    <RotableMarker
                        animationClasses={animationClasses}
                        position={s.midPoint}
                        angle={s.angle}
                        key={i}/>
                )}
            </LayerGroup>
        </>
    )
}

export default PolylineWithArrows