/*
  This component is a button that is used in the Drawer component but can be used elsewhere so it is a standalone component.
*/

import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
    root: {
        cursor: "pointer",
        textTransform: "uppercase",
        
        border: "solid 2px rgba(0, 0, 0, .35)",
        borderRadius: "1rem",
        backgroundColor: "rgba(255, 255, 255, 1)",
        color: theme.palette.primary.main,
  
        padding: ".5rem",
        boxShadow: "0 0 1rem rgba(0, 0, 0, .2)",

        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }
}));

const PlutoButton = ({i18key, i18Params, icon, onClick}) => {
    const classes = useStyles()
    const { t } = useTranslation()
    const text = t(i18key, i18Params)
    
    return <div onClick={onClick} className={classes.root}>{text}{icon}</div>
}

export default PlutoButton