import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        position: "absolute",
        top: "5%",
        left: "calc(50vw - (320px / 2))", // Same as 2.4.0
        width: "320px", //Same as 2.4.0
        zIndex: 30,
      },
      "@media (min-width: 480px)": {
        root: {
            left: "calc(50vw - (480px / 2))", // Same as 2.4.0
            width: "480px", //Same as 2.4.0
        },
      },
}));

const DialogContainer = (props) => {

    const classes = useStyles()

    return (
        <div className={classes.root}>
            {props.children}
        </div>
    )
}

export default DialogContainer