// Action Types import
import * as actionTypes from '../actions/actionTypes'

// Other imports
import { update } from '../../utilities/Common.js'

// Initial state
const initialState = {
    credentials: null,
    inProgress: false,
}

const reducer = (state = initialState, action) => {

    switch (action.type) {
        case actionTypes.AUTH_START: return update(state, {inProgress: true})
        case actionTypes.AUTH_SUCCESS: return update(state, {inProgress: false, credentials: action.credentials})
        case actionTypes.AUTH_FAIL: return update(state, {inProgress: false, error: action.error})
        case actionTypes.AUTH_SIGN_OUT_START: return state
        case actionTypes.AUTH_SIGN_OUT_SUCCESS: return update(state, initialState) //Return to initial state

        default: return state
    }
}

export default reducer