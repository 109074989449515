import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { NAVIGATION_ROUTE_CHAT, NAVIGATION_ROUTE_REFUELS, NAVIGATION_ROUTE_ROUTES, NAVIGATION_ROUTE_TRACKING } from "../BottomMenu/NavigationRoutes";
import { useAppControl } from "./AppControlProvider";
import { createPlutoLogger } from "../../utilities/Common";

const D = createPlutoLogger("[useKeyboard.js]")

export const useKeyboard = () => {
    const navigate = useNavigate()
    const { toggleDebugMode, getDebugMode } = useAppControl()

    D("Created. Get debug mode:", getDebugMode())

    useEffect(() => {
        const KEY_DOWN_EVENT = 'keydown'     

        const navigateTo = (to) => navigate(to)

        const onKeyDown = (e) => {
            D("[useEffect] [onKeyDown] Event: ", e)
            // Check if Shift, Alt, and specified key has pressed
            if (e.shiftKey && e.altKey) {
                switch (e.code) {
                    case "Digit0": toggleDebugMode(); break;
                    case "Digit1": navigateTo(NAVIGATION_ROUTE_TRACKING); break;
                    case "Digit2": navigateTo(NAVIGATION_ROUTE_ROUTES); break;
                    case "Digit3": navigateTo(NAVIGATION_ROUTE_CHAT); break;
                    case "Digit4": navigateTo(NAVIGATION_ROUTE_REFUELS); break;
                    // TODO: implement logout hotkey
                    default: //do nothing
                }
            }
        }
    
        window.addEventListener(KEY_DOWN_EVENT, onKeyDown)
        console.debug("[useKeyboard.js] Keyboard listener added")

        return () => {
            window.removeEventListener(KEY_DOWN_EVENT, onKeyDown)
            console.debug("[useKeyboard.js] Keyboard listener removed")
        }
    }, [navigate, toggleDebugMode]);
}