import { connect } from "react-redux"
import { HourglassFullTwoTone } from "@mui/icons-material"
import SteeringWheelIcon from "../../../IconsAndMarkers/SteeringWheelIcon"

import * as COM from "../../../../../utilities/Common"
import * as routesActions from "../../../../../store/actions/routes"
import Body from "./Body"
import Footer from "./Footer"
import DriverName from "../../../../Filter/Targets/DriverName"

import { ReactComponent as ShowOnMapIcon } from "./showOnMap.svg"
import { ReactComponent as ShowOnMapFilledIcon } from "./showOnMapFilled.svg"
import { makeStyles } from "@mui/styles"

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        borderTop: `1px solid ${theme.palette.grey[500]}`,
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "stretch",
        flexDirection: "column",
        gap: ".25rem",
        paddingBottom: ".5rem", //We should use padding instead of margin to ensure the gap color matches the selected or normal background color
    },
    unChecked: {
        '&:nth-child(odd)': {
            backgroundColor: theme.palette.grey[100],
        },
        '&:nth-child(even)': {
            // There is no color defined for even unchecked rows
        },
    },
    checked: {
        '&:nth-child(odd)': {
            backgroundColor: theme.palette.yellow.medium,
        },
        '&:nth-child(even)': {
            backgroundColor: theme.palette.yellow.light,
        },
    },
    header: {
        padding: ".25rem",
        flex: 1,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        "& > :first-child": {
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            gap: ".5rem",
            //backgroundColor: "brown", //just for debugging
        },
        //backgroundColor: "yellow", //just for debugging
    },
    selector: {
        //There is no style for selector at the moment
    },
    //spinning animation for selector ico
    '@keyframes spin': {
        '0%': {
            transform: 'rotate(0deg)',
        },
        '100%': {
            transform: 'rotate(360deg)',
        },
    },
    selectorInProgress: {
        animation: `$spin 1s linear infinite`,
    },
    iconContainer: {
        width: "1.75rem",
        height: "1.75rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        "& > svg": {
            width: "100%",
            height: "100%",
            preserveAspectRatio: "xMidYMid meet",
        },
    },
}))

const Selector = ({ checked, inProgress, onClick }) => {

    const classes = useStyles()

    const createIcon = ({name, onClick}) => {
        const icons = {
            "normal": <ShowOnMapIcon />,
            "filled": <ShowOnMapFilledIcon />,
        }
        return (
            <div className={classes.iconContainer} onClick={onClick}>
                {icons[name]}
            </div>
        )
    }

    const icon = inProgress === true
    ? <HourglassFullTwoTone className={classes.selectorInProgress} />
    : checked
    ? createIcon({name: "filled", onClick: onClick})
    : createIcon({name: "normal", onClick: onClick})

    return <span className={classes.selector}>{icon}</span>
}

const Row = (props) => {

    const classes = useStyles()

    const { route, itemCheckedByUser } = props
    const { checked, inProgress } = route
    const { startPosition, driver } = route

    const startDateText = COM.formattedDate(startPosition.timeMicros, true)
    
    const handleCheckboxChange = () => {
        itemCheckedByUser(startPosition.timeMicros)
    }

    const rootClasses = COM.clsx({
        [classes.root]: true,
        [classes.checked]: checked,
        [classes.unChecked]: !checked,
    })

    //const driverName = "Dummy driver name" // Just for debugging
    const driverName = driver && driver.name ? driver.name : null
    //console.debug("[Row.js] driver name:", driverName)

    return (
        <div className={rootClasses}>
            <div className={classes.header}>
                <div>
                    <span>{startDateText}</span>
                    <span><DriverName nameOnly name={driverName} startAdornment={<SteeringWheelIcon />} /></span>
                </div>
                <Selector checked={checked} inProgress={inProgress} onClick={handleCheckboxChange} />
            </div>
            <Body
                {...route} />
            <Footer
                {...route} />
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        state: state,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        itemCheckedByUser: (startPositionTimeMicros) => dispatch(routesActions.itemCheckedByUser(startPositionTimeMicros)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Row)