// React imports
import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import * as routesActions from '../../store/actions/routes'
import * as navigationActions from '../../store/actions/navigation'
import * as COM from '../../utilities/Common'

// i18n
import { useTranslation } from 'react-i18next'

import {
  LibraryAddCheckRounded,
  CalendarTodayRounded,
  LocalShippingRounded,
  ArrowForward,
} from "@mui/icons-material"


import Panel from "./Filter/Panel"
import TargetsFilter from "../Filter/Targets/TargetsFilter"
import SelectedLabel from '../Filter/SelectedLabel'
import FilterTabs from '../Filter/Tabs/FilterTabs'
import TargetInfo from '../Filter/Targets/TargetInfo'
import PlutoDrawer from '../Drawer/PlutoDrawer'
import PlutoButton from '../Drawer/PlutoButton'
import { makeStyles } from '@mui/styles'

//import DateRangeFilter from "../Filter/DateRangeFilter/DateRangeFilterWithDateFns"
//import DateRangeFilterWithMoment from '../Filter/DateRangeFilter/DateRangeFilterWithMoment'
import DateRangeFilterWithLuxon from '../Filter/DateRangeFilter/DateRangeFilterWithLuxon'

const TARGET_TAB_INDEX = 0
const DATE_TAB_INDEX = 1
const RESULT_TAB_INDEX = 2

const useStyles = makeStyles( theme => ({
  dateRangeFilterContainer: {
      //backgroundColor: "red", just for debugging
      padding: theme.spacing(1),
  },
  quickLinkContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginTop: theme.spacing(3),
  }
}))

const RoutesFilter = (props) => {

    const { state } = props
    const { routes, targets } = state
    const { t } = useTranslation()
    const classes = useStyles()

    //NOTE: To be human-friendy we add plus one to the real day difference, so in case of same days the difference is 1 intead of 0
    const dateDifferenceString = `${COM.dateDifference(routes.dateRangeFrom, routes.dateRangeTo).days + 1 }${t('d')}`
    const itemsCount = routes.items.length
    const targetsCount = targets.items.length
    const selectedCount = routes.target ? 1 : 0
    const { dateRangeFrom, dateRangeTo, selectedTab } = routes

    // XXX:
    // TODO: If we will have more time this logic should be refactored to avoid spaghetti code.
    const handleTargetClick = (item) => {
      // It's a UI logic, so we do not complicate the SAGA layer with this.
      // We call the addTarget first and after that we simoultanously set the selected tab the date range selector
      // The addTarget will not block, but we do not care about the order of the two actions.
      props.addTarget(item)
      props.setSelectedTab(DATE_TAB_INDEX)
    }
    
    const handleClearSelectedClick = () => {
      props.deleteTarget()
    }
    
    const handleDateRangeChange = (dateRange) => {
      //console.debug("[Routes.js] handleDateRangeChange() called with: ", dateRange)
      const from = dateRange[0]
      const to = dateRange[1]
      props.setDateRange(from,to)
    }

    const handleTabChange = (value) => {
      props.setSelectedTab(value)
    }

    //We have to create a common target format
    //We have to use this conditional approach to ensure that the 'selectedTargets' is an array
    //and it is an empty array if there is no selected target. The clear selected button needs this.
    const selectedTargets =[]
    const selectedTarget = state.routes?.target
    if (selectedTarget != null) selectedTargets.push(
      {
        id: state.routes?.target?.id,
        ...state.routes?.target
      }
    )

    const showGoToResults = itemsCount > 0
    //console.debug("[Routes.js] showGoToResults: ", showGoToResults)
    //console.debug("[Routes.js] itemsCount: ", itemsCount)

    const vesselName = state.routes.target ? state.routes.target.name : null
    const noResultSuffix = vesselName ? t("routes.noResultSuffix", {vesselName: vesselName}) : ""
    const noResult = t("routes.noResult", {suffix: noResultSuffix})
    const goToResults = 
    // The tab index contains the separator icon also, so we have to add 1 to the index here 
    <PlutoButton
      i18key={"routes.goToResults"}
      i18Params={{count: itemsCount}}
      onClick={() => props.setSelectedTab(RESULT_TAB_INDEX)}
      icon={<ArrowForward />} >
    </PlutoButton> 

    const tabContent = [
      
      <TargetsFilter
        showCheckbox={false}
        selectedCount={selectedCount}
        selectedTargets={selectedTargets}
        items={state.routes.target}
        onClearSelectedClick={handleClearSelectedClick}
        onTargetClick={handleTargetClick}>
      </TargetsFilter>,

      <div className={classes.dateRangeFilterContainer}>
        <DateRangeFilterWithLuxon
          dateRangeFrom={dateRangeFrom}
          dateRangeTo={dateRangeTo}
          handleDateRangeChange={handleDateRangeChange} />
        <div className={classes.quickLinkContainer}>
          {showGoToResults ? goToResults : noResult}
        </div>
      </div>,
        
      <Panel />,
    ]
       
    const tabs = [
      {
        icon: <LocalShippingRounded />,
        label: <SelectedLabel selectedCount={selectedCount} name={state.routes.target?.name} />,
        index: TARGET_TAB_INDEX,
      },
      {
        icon: <CalendarTodayRounded />,
        label: dateDifferenceString,
        index: DATE_TAB_INDEX
      },
      {
        icon: <LibraryAddCheckRounded />,
        label: itemsCount,
        index: RESULT_TAB_INDEX
      },
    ]

    return (

      <>

        <PlutoDrawer inProgress={routes.inProgress || targets.inProgress}>

          <FilterTabs
            tabs={tabs}
            selectedTab={selectedTab}
            onChange={handleTabChange} 
            dateRangeFrom={dateRangeFrom}
            dateRangeTo={dateRangeTo}
            targetCount={targetsCount}
            selectedTargetCount={selectedCount}
          />

          <TargetInfo 
              targetCount={targets.count}
              selectedTargets={selectedTargets}
              dateRangeFrom={routes.dateRangeFrom}
              dateRangeTo={routes.dateRangeTo}
              showSelected={false}
              showDate={true}/>

          {tabContent[selectedTab]}
          
        </PlutoDrawer>
             
      </>
    )
}

const mapDispatchToProps = dispatch => {
  return {
    // lifecycle
    setMounted: (mounted) => dispatch(routesActions.setMounted(mounted)),

    addTarget: (newItem) => dispatch(routesActions.addTarget(newItem)),
    deleteTarget: () => dispatch(routesActions.deleteTarget()),
    setDateRange: (from, to) => dispatch(routesActions.setDateRange(from, to)),
    setSelectedTab: (value) => dispatch(routesActions.setSelectedTab(value)),
    pageIsReady: () => dispatch(navigationActions.routesPageIsReady()),
  }
}

const mapStateToProps = (state) => {
  return {
    state: state
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RoutesFilter)