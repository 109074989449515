import React, {  useEffect, useRef } from "react"
import { connect } from 'react-redux'

import ModalProgressIndicator from "../Progress/ModalProgressIndicator"
import CloseButton from "./CloseButton"
import {BOTTOM_MENU_HEIGHT, createPlutoLogger} from "../../utilities/Common"
import { makeStyles } from "@mui/styles"
import { useAppControl } from "../App/AppControlProvider"

import ExoskeletonOrientation from "../App/ExoskeletonOrientation"

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    minWidth: "320px",
    maxWidth: "600px",
    width: "100vw", //The parent container uses 'fit-content' width so we have to use 100vw here
    height: `calc(100vh - (${BOTTOM_MENU_HEIGHT}))`,
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "column",
  },
  // If the browser supports 100dvh, we can use it. Dynamically calculate the height of the container
  [`@supports (height: 100dvh)`]: {
    mainContainer: {
      height: `calc(100dvh - (${BOTTOM_MENU_HEIGHT}))`,
    },
  },
  contentWrapperContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    gap: ".5rem",
  },
  contentContainer: {
    position: "relative",
    flex: 1,
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
    // Differenct child components will have different paddings
    // So we do not want to add left or right padding here
    // but the bottom padding is needed to avoid to hide the
    // rounded corners
    paddingBottom: "1rem",
  },
  closeContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "space-around",
    paddingBottom: ".5rem",
  },
  [`@media (min-width: 480px)`]: {
    contentContainer: {
      borderRadius: ".5rem", 
    },
  },
}))

const D = createPlutoLogger("🪟 [PlutoDrawer.js]")

const PlutoDrawer = (props) => {

  const { children, inProgress, progressMessage } = props
  const classes = useStyles()
  const { closeFilterPane } = useAppControl()

  return (
        <div className={classes.mainContainer}>
          <div className={classes.contentWrapperContainer}>
            <div className={classes.contentContainer}>
              <ModalProgressIndicator flag={inProgress} message={progressMessage} showPaper />
              {children}
            </div>
            <ExoskeletonOrientation showOnPortrait={true} showOnLandscape={false}>
              <div className={classes.closeContainer} onClick={closeFilterPane}>
                <CloseButton onClick={closeFilterPane} />
              </div>
            </ExoskeletonOrientation>
          </div>
        </div>
  )
}

const mapDispatchToProps = dispatch => {
  return {
    // no actions
  }
}

const mapStateToProps = (state) => {
  return {
    state: state,
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(PlutoDrawer)