
import { PlaceRounded } from "@mui/icons-material"

import getSign from "../GetSign"
import * as COM from "../../../../../utilities/Common"
import Position from "./Position"
import { useTranslation } from "react-i18next"
import { makeStyles } from "@mui/styles"
import { Grid } from "@mui/material"

const ARROW_BODY_WIDTH = 2
const ARROW_HEAD_WIDTH = 10
const ARROW_GREY_TONE = 600

const useStyles = makeStyles((theme) => ({
    root: {
        padding: ".25rem",
        flex: 1,
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "stretch",
        borderBottom: `1px dashed ${theme.palette.grey[500]}`,
        //backgroundColor: "red", //just for debugging
    },
    arrowContainer: {
        width: "1rem",
        //backgroundColor: "yellow", //just for debugging
    },
    arrow: {
        position: "relative",
        backgroundColor: theme.palette.grey[ARROW_GREY_TONE],
        top: `10%`,
        left: `calc(50% - ${ARROW_BODY_WIDTH}px)`,
        width: `${ARROW_BODY_WIDTH}px`,
        height: "80%",
        "&::before": {
            content: "''",
            position: "absolute",
            bottom: 0,
            left: `${((ARROW_HEAD_WIDTH - ARROW_BODY_WIDTH) / 2) * -1}px`, //(12px arrow head width - 2px arrow body with defined in arrow class) / 2
            width: 0,
            height: 0,
            borderLeft: `${ARROW_HEAD_WIDTH / 2}px solid transparent`,
            borderRight: `${ARROW_HEAD_WIDTH / 2}px solid transparent`,
            borderTop: `${ARROW_HEAD_WIDTH}px solid ${theme.palette.grey[ARROW_GREY_TONE]}`,
        },
    },
    routeDetails: {
        flex: 1,
        gap: ".25rem",
        //backgroundColor: "purple", //just for debugging
    },
    distanceDetails: {
        fontWeight: "bold",
        paddingTop: ".25rem",
        paddingBottom: ".25rem",
        fontSize: "1rem",
    },
    separator: {
        paddingLeft: ".25rem",
        paddingRight: ".25rem",
        color: theme.palette.grey[600],
    },
}))

const Body = ({ startPosition, stopPosition, distanceKm, durationTimeMicros }) => {

    ////console.debug("[Body.js]", startPosition, stopPosition, distanceKm, durationTimeMicros)

    const classes = useStyles()
    const { t } = useTranslation()

    const durationText = COM.formattedDuration(durationTimeMicros, true, getSign(t))
    const distanceUnit = process.env.REACT_APP_DISTANCE_UNIT || 'km'

    const positionInfo = {
        start: {
            timeText: COM.formattedTime(startPosition.timeMicros, true),
            geocodedAddress: startPosition.geocodedAddress,
            icon: <PlaceRounded fontSize="small" className={classes.icon} />
        },
        stop: {
            timeText: COM.formattedTime(stopPosition.timeMicros, true),
            geocodedAddress: stopPosition.geocodedAddress,
            icon: <PlaceRounded fontSize="small" className={classes.icon} />
        }
    }

    return (
        <div className={classes.root}>
            <div className={classes.arrowContainer}>
                <div className={classes.arrow}>&nbsp;</div>
            </div>
            <Grid container className={classes.routeDetails}>
                <Grid item xs={12}>
                    <Position {...positionInfo.start} color="primary" />
                </Grid>
                <Grid item xs={12} className={classes.distanceDetails}>
                    {durationText} <span className={classes.separator}>|</span> {distanceKm} {distanceUnit}
                </Grid>
                <Grid item xs={12}>
                    <Position {...positionInfo.stop} color="secondary" timeBoxAlign="flex-end" />
                </Grid>
            </Grid>
        </div>
    )
}

export default Body