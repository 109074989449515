// React imports
import React from 'react'

// i18n imports
import { useTranslation } from 'react-i18next';

import TextRow from './TextRow';
import NoResult from '../Filter/NoResult';
import { makeStyles } from '@mui/styles';
import { Button } from '@mui/material';


const useStyles = makeStyles((theme) => ({
  root: {
      marginTop: theme.spacing(2),
      maxWidth: "100%",
      textAlign: "center",
  },
}));

const PreviousMessageRow = (props) => {
  
  const classes = useStyles()
  const { t } = useTranslation()
 
  return (
    <div className={classes.root}>
        <Button onClick={props.handlePreviousClick}>
            {t("chat.loadPreviousMessages")}
        </Button>
    </div>
  )
}

const MessageList = (props) => {

    const { t } = useTranslation()
    const { messages, firstMessageSentTime } = props

    if (!messages) return <NoResult message={t("chat.noMessage")} />
    
    const rows = []
    messages.forEach( (m, idx) => {
      rows.unshift(<TextRow message={m} key={`${m.id}-${idx}`}/>)
    })
    
    if (firstMessageSentTime) { rows.unshift(<PreviousMessageRow {...props} key={`chat-previous-message-row-${firstMessageSentTime}`} />) }

    return rows
    //TODO: rows.map(r => r) WTF? Why was this here? I lave it here just in case I'm missing something
}

export default MessageList