import { makeStyles } from "@mui/styles"
import { createPlutoLogger, formattedDateTime } from "../../utilities/Common"
import { IgnitionStatusIcon } from "./IgnitionStatus"

const useStyles = makeStyles((theme) => ({
    root: {
        position: "absolute",
        top: 0,
        right: 0,
        zIndex: 1000,
        backgroundColor: 'rgba(0, 63, 0, 0.75)',
        border: 'solid 1px black',
        color: '#00ff00',
        display: "flex",
        flexDirection: "column",
        flex: 1,
        gap: theme.spacing(1),
        padding: theme.spacing(1),
    },
    ignition: {
        display: "flex",
        alignItems: "center",
        gap: theme.spacing(1),
        "& div": {
            display: "flex",
            alignItems: "center",
            gap: theme.spacing(1),
        }
    },
    additionalInfo: {
        display: "flex",
        justifyContent: "space-between",
        gap: theme.spacing(1),
        borderBottom: "solid 1px black",
        color: "yellow",
        //textShadow: "1px 1px 1px black",
    }
}))

const D = createPlutoLogger("🪲 [DebugTarget.js]")

const DebugTarget = ({ target }) => {

    D("Target: ", target)

    const classes = useStyles()

    if (!target) {
        return null
    }

    const details = target.target   // Be careful, target contains a target object.
    const curPos = target.currentPosition
    try {
        return (
            <div className={classes.root}>
                <div class={classes.additionalInfo}>
                    <span>{formattedDateTime(new Date())}</span>
                    <span>{target.id}, {details.name}</span>
                </div>
                <span>{formattedDateTime(curPos.timeMicros / 1000)}</span>
                <div className={classes.ignition}>
                    <div><IgnitionStatusIcon color={curPos.color} /><span>{details.id}, {details.description}</span></div>
                    <div>{curPos.speed} km/h</div>
                </div>
                <span>{curPos.geocodedAddress}</span>
                <span>{curPos.latitude.toFixed(4)}, {curPos.longitude.toFixed(4)} </span>
            </div>
        )
    } catch (e) {
        D("Error: ", e)
        return null
    }
}

export default DebugTarget