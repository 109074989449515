// React imports
import React from 'react'

import * as COM from '../../../utilities/Common.js'
import PlutoCheckBox from './PlutoCheckBox.js'
import DriverName from './DriverName.js'
import { makeStyles } from '@mui/styles'
import { Box } from '@mui/material'

const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
      fontWeight: "normal",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      gap: "0.5rem",
    },
    selected: {
      color: theme.palette.secondary.main,
      fontWeight: "bold",
      // I keep it here for future reference. It's a way to add
      // the target's state colored circle
      /*'&:before': {
        content: '"•"',
        paddingLeft: "0.15rem",
        paddingRight: "0.15rem",
        fontWeight: "bold",
        display: 'inline',
        color: theme.palette.primary.main,
      }*/
    }
}))

const Target = (props) => {

    //It results in a lot of console noise, but it's useful for debugging
    ////console.debug("[Target.js] props: ", props)

    const classes = useStyles()

    //It seems odd but we have to hard type check here due to the way Javascript works
    const showCheckbox = props.showCheckbox !== false ? true : props.showCheckbox

    const { selected, item, onClick } = props 
   
    const boxClasses = COM.clsx({
      [classes.root]: true,
      [classes.selected]: selected
    })

    const checkBox = showCheckbox ? <PlutoCheckBox selected={selected} /> : null
    const driverName = item.name
    const driverDescription = item.description
    return (
      <Box className={boxClasses} onClick={onClick}>
          {checkBox}
          <DriverName name={driverName} description={driverDescription} />
      </Box>
    );
}

export default Target