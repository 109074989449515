import { takeEvery, put, putResolve, call, select } from 'redux-saga/effects'

import * as actionTypes from '../../actions/actionTypes'
import * as chatActions from '../../actions/chat'
import * as errorActions from '../../actions/error'

import * as chatRepo from '../../repository/chat'

import { getItemFromAnArrayById as getById, isTokenValid } from '../../../utilities/Common'
import { ApiError } from '../../../other/ApiError'
import { createPlutoLogger } from "../../../utilities/Common"
import { mergeCurrentMessagesIntoNewThreads } from './chatUtils'

const D = createPlutoLogger("💬 [SAGA] [chatThreads.js]")

function* getThreads() {
    try {
        D("[getThreads] Getting threads...")

        const currentState = yield select()
        const { data } = yield call(chatRepo.getThreads, currentState.auth.credentials, currentState.settings.languageCode)

        D("[getThreads] Threads received.")

        yield putResolve(chatActions.getThreadsSuccess(data))

     } catch (e) {
        yield putResolve(errorActions.addError(new ApiError(e, ApiError.MODULE_CHAT)))
    }
}

function* getThreadsByUser(action) {
    D("[getThreadsByUser] Getting threads by user...")
    try {
        
        const currentState = yield select()
        const filter = action.payload
        
        const { data } = yield call(chatRepo.getThreadsByUser, {
            acceptLanguage: currentState.settings.languageCode,
            credentials: currentState.auth.credentials,
            name: filter
        })

        yield putResolve(chatActions.getThreadsSuccess(data))
     } catch (e) {
        yield putResolve(errorActions.addError(new ApiError(e, ApiError.MODULE_CHAT)))

    }
}

function* getThreadsSuccess(action) {
    
    const currentState = yield select()
    const currentThreads = currentState.chat.threads
    let newThreads = action.payload
    const selectedThreadId = currentState.chat.selectedThreadId

    if (newThreads.length > 0) {
        const currentMessages = []
        currentThreads.forEach( ct => {
            currentMessages.push(...ct.messages)
        })
        mergeCurrentMessagesIntoNewThreads(newThreads, currentMessages)
    }
    
    yield putResolve(chatActions.setThreads(newThreads))

    let newSelectedThreadId = selectedThreadId
    
    if (newThreads.length >= 1) {
        const index = getById(newThreads, selectedThreadId)
        if (!selectedThreadId || !index || index >= newThreads.length) {
            newSelectedThreadId = newThreads[0].id
        }
    } else {
        newSelectedThreadId = null
    }

    yield putResolve(chatActions.setSelectedThreadId(newSelectedThreadId))
    yield put(chatActions.countMessages())
}

function* handleAuthSuccess() {
    try {
        const state = yield select()
        const { credentials } = state.auth
        const { token } = credentials
        const stop = token && isTokenValid(token)
        D("[handleAuthSuccess] stop: ", stop)

        if (stop) {
            D("[handleAuthSuccess] Token is valid, we don't need to get threads.")
            return; // Continue the managerLoop
        }
        yield put(chatActions.getThreads())
    } catch (e) {
        D("[handleAuthSuccess] Error: ", e)
        yield putResolve(errorActions.addError(new ApiError(e, ApiError.MODULE_CHAT)))
    }
}


export function* saga() {
    D("Forked")
    yield takeEvery(actionTypes.AUTH_SUCCESS, handleAuthSuccess)
    yield takeEvery(actionTypes.CHAT_GET_THREADS, getThreads)
    yield takeEvery(actionTypes.CHAT_GET_THREADS_BY_USER, getThreadsByUser)
    yield takeEvery(actionTypes.CHAT_GET_THREADS_SUCCESS, getThreadsSuccess)
}