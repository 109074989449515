import { take, putResolve, all, takeLatest } from 'redux-saga/effects'

import * as actionTypes from '../../actions/actionTypes'
import * as chatActions from '../../actions/chat'

import { createPlutoLogger } from "../../../utilities/Common"

const D = createPlutoLogger("[SAGA] [chatFilter.js]")

function* changeFilter(action) {

    yield putResolve(chatActions.setFilteringInProgress(true))

    const filter = action.payload

    if (filter && filter.length > 3) {
        yield putResolve(chatActions.getThreadsByUser(filter))
        D(`Filter is long enough to filter: `, filter)
    } else {
        yield putResolve(chatActions.getThreads(filter))
        D(`Filter is too short to filter: `, filter)
    }

    yield all([
        take(actionTypes.CHAT_GET_THREADS_SUCCESS),
        take(actionTypes.CHAT_SET_THREADS),
        take(actionTypes.CHAT_SET_SELECTED_THREAD_ID),
    ]);
    D('All necessary sagas are done.', filter)

    yield putResolve(chatActions.setFilter(filter))

    yield putResolve(chatActions.setFilteringInProgress(false))
}

export function* saga() {
    D("Forked.")
    yield takeLatest(actionTypes.CHAT_CHANGE_FILTER, changeFilter)
}