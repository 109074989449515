// React imports
import React from 'react'
import { connect } from 'react-redux'
import * as targetsActions from '../../../store/actions/targets'

//i18n
import { useTranslation } from 'react-i18next'

// Own components
import ClearSelectedItems from '../ClearSelectedItems'
import TargetsContainer from './TargetsContainer'
import AddAll from './AddAll'
import TextSearch from '../TextSearch'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  
  root: {
    boxSizing: "border-box",
    width: "100%",
    flex: 1,
    display: "flex",
    alignItems: "stretch",
    flexDirection: "column",
    // The parent container do not define left or right padding, 
    // so we have to define it here
    paddingLeft: ".5rem",
    paddingRight: ".5rem",
    paddingTop: "1rem", // In this view there is no tabs section, so we have to add some padding at the top

    // We have to define max width to avoid overflow of the selected targets container
    maxWidth: "100vw",

    //backgroundColor: "lightgreen" //for debug
  },

  /* total height is 4.5rem */
  selectedTargetsContainer: {
    boxSizing: "border-box",
    paddingBottom: ".5rem",
    marginTop: "1rem",

    //overflowX: "scroll", 
    overflowY: "hidden", 

    display: "flex",
    alignItems: "center",
    justifyContent: 'flex-start',
    gap: "1rem",
  
    //backgroundColor: "tomato" //for debug
  },

}));


const TargetsFilter = (props) => {

  const classes = useStyles()
  const { t } = useTranslation()

  const {

    selectedTargets,

    children,
    state,
    
    onTargetClick,
    onClearSelectedClick,
    
    setInputFilter,
    clearInputFilter,
  
  } = props

  //To avoid erros in case of not presented we use this approach!
  const onAddAllClick = props.onAddAllClick

  const { targets } = state

  // TODO: check if this is needed
  // We are in hurry, so we don't want to refactor this now
  // For future debugging I have left this here:
  // const { filter, inputFilter } = targets
  
  const { inputFilter } = targets
  const selectedCount = selectedTargets ? selectedTargets.length : null
  
  // XXX TODO: I need more target's to be able to test this!
  const remoteItemCount = targets.count
  const localItemCount = targets.items.length
  const hasMore = remoteItemCount !== localItemCount
  
  const showCheckbox = props.showCheckbox
  const showTargetMenu = props.showTargetMenu

  //console.debug("[TargetsFilter.js], props: ", props)
  //console.debug("[TargetsFilter.js], state: ", state)
  
  const handleInputFilterChanged = (e) => {
    setInputFilter(e.target.value)
  }

  const handleClearClick = () => {
    clearInputFilter()
  }
 
  const next = () => props.next()

  //console.debug("[TargetsFilter.js], Show checkbox: ", showCheckbox)

  const isClearSelectedNeeded = selectedCount > 1
  
  return (
      <div className={classes.root}>

        <TextSearch
          filter={inputFilter}
          placeholder={t("filter.targetSearchPlaceholder")}
          endAdornment={null}
          onChange={handleInputFilterChanged}
          onClear={handleClearClick} />

        <div className={classes.selectedTargetsContainer}>
          <AddAll onClick={onAddAllClick} targetItems={targets.items} />
          { isClearSelectedNeeded 
            ? <ClearSelectedItems onClick={onClearSelectedClick} selectedCount={selectedCount} />
            : null
          }
          {children}
        </div>

        <TargetsContainer
          showCheckbox={showCheckbox}
          showTargetMenu={showTargetMenu}
          selectedItems={selectedTargets}
          items={state.targets.items}
          inProgress={state.targets.autocompleteInProgress}
          next={next}
          hasMore={hasMore}
          onTargetClick={onTargetClick} />
        
      </div>
  )
}

function mapStateToProps(state) {
  return {
    state: state
  }
}

const mapDispatchToProps = dispatch => {
  return {
    next: () => dispatch(targetsActions.next()),
    setInputFilter: (filter) => dispatch(targetsActions.setInputFilter(filter)),
    clearInputFilter: () => dispatch(targetsActions.clearInputFilter()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TargetsFilter)