import React from 'react'
import { connect } from 'react-redux'
import * as routesActions from '../../../store/actions/routes'

// i18n imports
import { useTranslation } from 'react-i18next'

import ClearSelectedItems from '../../Filter/ClearSelectedItems'
import NoResult from '../../Filter/NoResult'
import ScrollContainer from './ScrollContainer'
import { makeStyles } from '@mui/styles'
import { FormControlLabel, Grid, Switch } from '@mui/material'

const useStyles = makeStyles((theme) => ({
  root: {
    // TODO: Investigaste the paddings and margins. It could be better.
    paddingLeft: "1rem", //The FormControllLabel has a right padding of 1rem. This padding should be the same.
    //backgroundColor: "orange", //just for debugging
  },
}))

const Panel = (props) => {

  const { t } = useTranslation()
  const { state } = props
  const { routes } = state
  const { items } = routes
  const classes = useStyles()

  const itemsCount = items.length
  //console.debug("[Panel.js] itemsCount: ", itemsCount)

  const selectedItems = items.filter( itm => itm.checked === true)
  const checkedCount = selectedItems.length
  //console.debug("[Panel.js] Checked items: ", selectedItems)

  const handleSwitchChange = (ev, value) => {
    props.setMultiselect(value)
  }

  const handleClearSelectedItems = (ev) => {
    props.clearSelectedSections()
  }

  if (itemsCount < 1) return <NoResult />

  //console.debug("[Panel.js] items: ", items)

  return (
        <>
          
            <Grid container justifyContent="flex-start" alignItems="center" className={classes.root}>
              <Grid item xs={3}>
                <ClearSelectedItems
                  label="routes"
                  selectedCount={checkedCount}
                  onClick={handleClearSelectedItems}/>
              </Grid>
              <Grid item xs={9} container justifyContent="flex-end" alignItems="center">
                <FormControlLabel
                  control={<Switch checked={routes.multiselect}
                  onChange={handleSwitchChange}
                  name="multiselectChecker" />}
                  label={t("routes.mutiselect")}/>
              </Grid>
            </Grid>

            <ScrollContainer
              items={items}
              selectedItems={selectedItems}
              hasMore={false}
            />
        
        </>
    )
}

const mapStateToProps = (state) => {
  return {
    state: state,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setMultiselect: (value) => dispatch(routesActions.setMultiselect(value)),
    clearSelectedSections: () => dispatch(routesActions.clearSelectedSections()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Panel)