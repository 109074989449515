import axios from 'axios'
import { createPlutoLogger } from '../../utilities/Common'

const BASE_URL = process.env.REACT_APP_API_URL

const D = createPlutoLogger("[REPO] [tracking.js]")

export const getTargetsPosition = (credentials, payloadAsJSON, popupTargetId, acceptLanguage, useToken) => {
    
    D('getTargetsPosition', credentials, payloadAsJSON, popupTargetId, acceptLanguage, useToken)

    const suffix = popupTargetId ? `?popupTargetId=${popupTargetId}` : '';
    const query = `${BASE_URL}/targets/positions/tracking${suffix}`;

    //console.debug("[REPOSITORY] [tracking.js] [getTargersPosition] query:", query)
    //console.debug("[REPOSITORY] [tracking.js] [getTargersPosition] credentials:", credentials)
    //console.debug("[REPOSITORY] [tracking.js] [getTargersPosition] useToken:", useToken)
    //console.debug("[REPOSITORY] [tracking.js] [getTargersPosition] payloadAsJSON:", payloadAsJSON)
    
    const headers = {
        'Accept-Language': acceptLanguage,
        'Content-Type': 'application/json'
    }
    
    if (useToken === true) {
        const token = credentials.token
        headers['Authorization'] = `Token ${token}`;
    }
    //console.debug("[REPOSITORY] [tracking.js] Headers:", headers);

    const body = {
        headers: headers,
    }

    if (useToken !== true) {
        body['auth'] = credentials;
    }
    D("getTargetsPosition Body:", body);

    return axios.post(
        query,
        payloadAsJSON,
        body
    )
        

    /*return axios.post(
        query,
        payloadAsJSON,
        {
            headers: {
                'Accept-Language': acceptLanguage,
                'Content-Type': 'application/json'
            },
            auth: credentials
        }
    )*/
}