import packageJson from '../../../package.json';

import React, { useState } from 'react'

// i18n imports
import { useTranslation } from 'react-i18next'
import KeyValueTable from './KeyValueTable';
import { TableCell, TableRow, Typography } from '@mui/material';
import { createPlutoLogger } from '../../utilities/Common';
import { ArrowDropDownRounded, ArrowDropUpRounded } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';

const D = createPlutoLogger("📦 [Packages.js]")

const useStyle = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: theme.spacing(1),
    }
}))

const Packages = () => {

    const { t } = useTranslation()
    const classes = useStyle()
    const dependencies = packageJson.dependencies

    D("[Packages.js] dependencieKeys:", dependencies)

    const [show, setShow] = useState(false)
    const icon = show ? <ArrowDropUpRounded onClick={() => setShow(false)}/> : <ArrowDropDownRounded onClick={() => setShow(true)} />

    return (
        <>
            <TableRow>
                <TableCell component="th" scope="row" colSpan={2}>
                    <div className={classes.root}>
                        {icon}
                        <Typography variant="h6" component="h6">
                            {t("systemInfo.packages")}
                        </Typography>
                    </div>
                </TableCell>
            </TableRow>
            {show
            ?
                <KeyValueTable obj={dependencies} />
            :
                null
            }
        </>
    )
}

export default Packages