import { useEffect, useState } from 'react'
import { createPlutoLogger } from '../../utilities/Common';
import { useAppControl } from '../App/AppControlProvider';

const spiderfiedEventName = 'spiderfied'
const unspiderfiedEventName = 'unspiderfied'

const D = createPlutoLogger("[useSpiderfied.js]")

const useSpiderfied = (clusterGroupRef) => {
  const [isSpiderfied, setSpiderfied] = useState(false)
  const { spiderfiedOn, spiderfiedOff } = useAppControl()

  useEffect(() => {
    try {
      const currentRef = clusterGroupRef.current
      if (currentRef) {
        D("Adding event listeners for spiderfied and unspiderfied events")
        const handleSpiderfied = (layer) => {
          D("Spiderfied event: ", layer)
          setSpiderfied(true);
          spiderfiedOn();
        }

        const handleUnspiderfied = (layer) => {
          D("Unspiderfied event: ", layer)
          setSpiderfied(false);
          spiderfiedOff();
        }

        // Add event listeners
        currentRef.on(spiderfiedEventName, handleSpiderfied)
        currentRef.on(unspiderfiedEventName, handleUnspiderfied)

        // Clean up event listeners on component unmount or ref change
        return () => {
          currentRef?.off(spiderfiedEventName, handleSpiderfied)
          currentRef?.off(unspiderfiedEventName, handleUnspiderfied)
        };
      }
    } catch (e) {
      console.warn("Error during addind or removing spiderfied event listeners: ", e)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clusterGroupRef])


  return isSpiderfied
};

export default useSpiderfied