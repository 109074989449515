import {createPlutoLogger, getItemFromAnArrayById as getById} from '../../../utilities/Common'

// HELPERS
const MESSAGE_SORTER = (a,b) => b.id - a.id
const THREAD_FIRST_SENT_STATUS_TIME_MICROS = t => t.messages.length >= 1 ? t.messages[t.messages.length - 1].sentStatus.timeMicros : null
const THREAD_LAST_SENT_STATUS_TIME_MICROS = t => t.messages.length >= 1 ? t.messages[0].sentStatus.timeMicros : null

const D = createPlutoLogger("[SAGA] [chatUtils.js]")

export const mergeCurrentMessagesIntoNewThreads = (threads, messages) => {
    
    try {
        //D("New threads:", threads.length, "Current messages:", messages.length)

        // merge
        messages.forEach(m => {
            const thread = getById(threads, m.threadId)
            if (thread) {
                if (!getById(thread.messages, m.id)) {
                    thread.messages.push(m)
                }
                thread.messages = thread.messages.sort(MESSAGE_SORTER)
            } else {
                D(`No thread for threadId ${m.threadId}`)
            }
        })

        // We have to define the first and last time micros of the messages
        threads.forEach(t => {
            t.firstMessageSentTime = THREAD_FIRST_SENT_STATUS_TIME_MICROS(t)
            t.lastMessageSentTime = THREAD_LAST_SENT_STATUS_TIME_MICROS(t)
        })
    } catch (e) {
        D("Error during mergeing current messages into new threads:", e)
    }
}