import * as chatActions from '../../store/actions/chat'
import { connect } from 'react-redux'

// i18n imports
import { useTranslation } from 'react-i18next'

import ScrollToBottomManager from './ScrollToBottomManager'
import NoResult from '../Filter/NoResult'

import {getItemFromAnArrayById as getById} from '../../utilities/Common'
import ChatInput from './ChatInput'

import { BOTTOM_MENU_HEIGHT } from '../../utilities/Common'
import { makeStyles } from '@mui/styles'
import OpenFilterPaneButton from '../Filter/OpenFilterPaneButton'

const useStyles = makeStyles((theme) => ({
  // This component is placed into the pageContainerOverEclipse. The parent has 'fit-content' height and width values,
  // so we need to calculate the proper height and width for this component.
  paper: {   
    width: "100%",
    height: "100%",
    maxHeight: `100%`,
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[5],
  },
  header: {
    padding: theme.spacing(1),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "1.5rem",
    fontWeight: "bold",
    gap: theme.spacing(1),
  },
  content: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
    padding: theme.spacing(1),
    display: "flex",
    overflowY: "auto",
  },
  footer: {
    padding: theme.spacing(2),
    display: "flex",
  },
}));

const ChatResult = (props) => {

  const classes = useStyles()
  const { t } = useTranslation()

  const chat = props.state.chat
  const sendMessage = props.sendMessage
  const selectedThreadId = chat.selectedThreadId
  const selectedThread = getById(chat.threads, selectedThreadId)
  const topic = selectedThread ? selectedThread.threadUser.name : <NoResult message={t("chat.noThreadSelected")} />
  const messages = selectedThread ? selectedThread.messages : null
  const firstMessageSentTime = selectedThread ? selectedThread.firstMessageSentTime : null


  const handlePreviousClick = () => {
    //console.debug("Load previus messages before: ", firstMessageSentTime)
    props.getMessages({
      threadId: selectedThreadId,
      lastTimeMicros: firstMessageSentTime
    })
  }


  return (
    <div className={classes.paper}>
      <div className={classes.header}>
        {topic}
        <OpenFilterPaneButton />
      </div>

      <div className={classes.content}>
          <ScrollToBottomManager 
                messages={messages}
                selectedThreadId={selectedThreadId}
                firstMessageSentTime={firstMessageSentTime}
                handlePreviousClick={handlePreviousClick}>
          </ScrollToBottomManager>
      </div>

      <div className={classes.footer}>
        <ChatInput
          show={selectedThread}
          sendMessage={sendMessage} />
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    state: state
  }
}
const mapDispatchToProps = dispatch => {
  return {
    getMessages: (payload) => dispatch(chatActions.getMessages(payload)),
    sendMessage: (payload) => dispatch(chatActions.sendMessage(payload)),
  }
}

connect(mapStateToProps, mapDispatchToProps)(ChatInput)
export default connect(mapStateToProps, mapDispatchToProps)(ChatResult)