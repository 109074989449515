import { v4 as uuidv4 } from 'uuid';

export default class DisplayableError extends Error {
    
    static SEVERITY_UNKNOWN = 0
    static SEVERITY_WARNING = 1
    static SEVERITY_ERROR = 2
    static SEVERITY_FATAL = 3

    constructor(exception, messageKey, titleKey) {
        super(exception?.message)       
        this.messageKey = messageKey
        this.titleKey = titleKey
        this.exception = exception
        this.date = new Date()
        this.acknowledged = false
        this.uuid = uuidv4()
        this.severity = DisplayableError.SEVERITY_UNKNOWN

        console.error(`Displayable error: `, this.exception)
    }

}