
import React, { useEffect } from "react"

import { clsx, createPlutoLogger } from "../../../utilities/Common"
import { makeStyles } from "@mui/styles"
import useOrientationChange from "../../App/useOrientationChange"
import { useInView } from "react-intersection-observer"
import ExoskeletonOrientation from "../../App/ExoskeletonOrientation"

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: "8rem",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: theme.spacing(1),
        color: theme.palette.grey[700],
        fontWeight: theme.typography.fontWeightBold,
        gap: theme.spacing(1),
        textTransform: "uppercase",
    },
    selected: {
        borderRadius: theme.shape.borderRadius,
        border: `1px solid ${theme.palette.secondary.main}`,
        color: theme.palette.secondary.main,
        //backgroundColor: "red", //Just for debugging
    },
    // For longer labels we need to align the text to the center
    label: {
        textAlign: "center",
    },
}));

const D = createPlutoLogger("[PlutoTab.js]")

const PlutoTab = (props) => {
    const { icon, label } = props
    const isSelected = props.isSelected || false
    const classes = useStyles()

    D("[PlutoTab.js] props: ", props)

    const rootClasses = clsx([
        classes.root,
        isSelected && classes.selected,
    ])

    return (
        <div className={rootClasses}>
            <ExoskeletonOrientation showOnPortrait={true} showOnLandscape={false}>
                <span >{icon}</span>
            </ExoskeletonOrientation>
            <span className={classes.label}>{label}</span>
        </div>
    )
}

export default PlutoTab