import { useTranslation } from "react-i18next"

import Body from "./Item/Row/Body"
import Footer from "./Item/Row/Footer"
import * as COM from "../../../utilities/Common"
import Truncated from "./Truncated"
import { Grid, Typography } from "@mui/material"
import { makeStyles } from "@mui/styles"

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        padding: theme.spacing(1),
        marginBottom: theme.spacing(1),
        borderRadius: theme.spacing(1),
    },
    normal: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
    },
    multiple: {
        backgroundColor: theme.palette.yellow.dark,
        color: "black",
    },
}))

const Header = (props) => {

    const { 
        isMultipleSelected,
        items,
        itemsForSummary,
        itemsToDisplay,
    } = props
    
    const { t } = useTranslation()
    const classes = useStyles()
    const totalCount = items.length
    const summaryCount = itemsForSummary.length
    const displayedCount = itemsToDisplay.length
 
    const firstIndex = 0
    const lastIndex = itemsForSummary.length - 1
    const summary = {
        startPosition: itemsForSummary[firstIndex].startPosition,
        stopPosition: itemsForSummary[lastIndex].stopPosition,
        durationTimeMicros: 0,
        distanceKm: 0,
        standTimeMicros: 0,
    }
    
    itemsForSummary.forEach( s => {
        summary.standTimeMicros += s.standTimeMicros
        summary.durationTimeMicros += s.durationTimeMicros
        summary.distanceKm += s.distanceKm
    })

    // Formatting the summary
    summary.distanceKm = summary.distanceKm.toFixed(2)
    summary.durationTimeMicros = summary.durationTimeMicros.toFixed(0)
    summary.standTimeMicros = summary.standTimeMicros.toFixed(0)

    // We do not show all the rows because of performance reasons
    // so we have to inform the user about that
    const isTruncated = totalCount > displayedCount
    const showTruncatedInfo = isTruncated && !isMultipleSelected
    const title = `${t("routes.summary")} (${summaryCount} ${t("routes.row")})`

    /*
        // Keep it here for debugging
        //console.debug(`[Header.js] Summary items: ${itemsForSummary.length}`, itemsForSummary)
        //console.debug(`[Header.js] Displayed items: ${itemsToDisplay.length}`, itemsToDisplay)
        //console.debug(`[Header.js] Multiselect: ${isMultipleSelected}`)
    */

    const rootClasses = COM.clsx({
        [classes.root]: true,
        [classes.normal]: !isMultipleSelected,
        [classes.multiple]: isMultipleSelected,
    })

    const truncatedBox = showTruncatedInfo
    ?
    <Truncated totalCount={totalCount} displayedCount={displayedCount} />
    :
    null

    return (
        <>
            {truncatedBox}
            <Grid xs={12} className={rootClasses} container>
                <Typography variant='h5' className={classes.title}>
                    {title}
                </Typography>
                <Grid item xs={12}>
                    <Body {...summary} hideCheckbox/>
                    <Footer {...summary} />
                </Grid>
            </Grid>
        </>
    )
}

export default Header