import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import * as errorActions from '../../store/actions/error'

import { useTranslation } from 'react-i18next'

import { ModuleInfo } from './ModuleInfo'
import DisplayableError from '../../other/DisplayableError'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
    root: {
        position: "absolute",
        top: theme.spacing(2),
        left: theme.spacing(2),
        width: "fit-content",
        height: "fit-content",
        padding: theme.spacing(1),
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.primary.main,
        borderRadius: theme.shape.borderRadius,
        zIndex: 1200,
        boxShadow: theme.shadows[5],
    },
}));


const WarningMessage = (props) => {

    const { t } = useTranslation()
    const classes = useStyles()
    const allItems = props.errorState.items
    const items = allItems.filter( (i) => i.severity < DisplayableError.SEVERITY_ERROR)
    const notAcknowledgeds = items.filter( i => !i.acknowledged)
    const currentWarning = notAcknowledgeds.length > 0 ? notAcknowledgeds[0] : null

    console.debug("[WarningMessage.js] items: ", items)

    useEffect(() => {
        const timer = setTimeout(() => {
            if (currentWarning) {
                props.setAcknowledged(currentWarning.uuid)
            }
        }, 1500)
        return () => clearTimeout(timer)
    })

    if (!currentWarning) { return null }

    return (
        <div className={classes.root}>
            <ModuleInfo module={currentWarning.module} />{t(currentWarning.messageKey)}
        </div>
    )
}

function mapStateToProps(state) {
    return {
      errorState: state.error
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setAcknowledged: (uuid) => dispatch(errorActions.setAcknowledged(uuid)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WarningMessage)