import * as actionTypes from './actionTypes'

export const set = (payload) => {
    return {
        type: actionTypes.DEVICE_SET,
        payload: payload  
    }
}

export const infoReady = () => {
    return {
        type: actionTypes.DEVICE_INFO_READY
    }
}