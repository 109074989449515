import React from 'react'
import { useTranslation } from 'react-i18next'

import { makeStyles } from '@mui/styles'
import OpenFilterPaneButton from '../Filter/OpenFilterPaneButton'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "1rem",
        gap: theme.spacing(1),
    },
}))

const RefuelsNoSelectedTarget = (props) => {
   
    const classes = useStyles()
    const { t } = useTranslation()

    return (
        <div className={classes.root}>
            {/* No other items here at the moment. They have been removed but I leave this component at is is for further use. */}
            <span>{t("refuels.noSelectedTarget")}</span>
        </div>
    )
}

export default RefuelsNoSelectedTarget
