// React imports
import React from 'react'

import { makeStyles } from '@mui/styles'

//i18n
import { t } from 'i18next'
import { formattedPhoneNumber } from '../../utilities/Common'
import { Box, Link, Typography } from '@mui/material'

const useStyles = makeStyles( (theme) => ({
    root: {
        //fontSize: theme.typography.fontSize * 0.95,
        fontSize: '1rem', // 14px
        color: theme.palette.text.secondary,
    },
    [theme.breakpoints.up('sm')]: {
        root: {
            fontSize: theme.typography.fontSize
        }
    },
    tableContainer: {
        overflowY: 'scroll',
        height: '8rem', //TODO: Proper calculated height would be better
    },
    table: {
        width: '100%',
        fontSize: '1.1rem',
        fontWeight: 400,
        lineHeight: 1.43,
        letterSpacing: '0.01071em',
        // We use \n simboles in translation strings to break lines in table cells.
        // This CSS rule is necessary to make it work.
        "& *": {
            whiteSpace: 'pre-line',
        }
    },
    tableHeader: {
        textAlign: 'left',
    },
    odd: {
        backgroundColor: theme.palette.grey[100],
    }
    
}))

const Registration = ( props => {

    const classes = useStyles()

    return (
        <Box mt={4} mb={2}> 
            <Box>
                <Typography
                variant="body1"
                color="textSecondary"
                className={classes.root}>
                    {t("registration.title")}
                </Typography>
            </Box>
            <Box mt={1.5}>
                <Typography
                variant="body1"
                color="textSecondary"
                className={classes.root}>
                    {t("registration.body")}
                </Typography>
            </Box>
            <Box mt={3} mb={2} align="right">
                <Link
                variant="body1"
                href={`tel:${process.env.REACT_APP_CALL_CENTER_PHONE_NUMBER}`}
                className={classes.root}>
                    {formattedPhoneNumber(process.env.REACT_APP_CALL_CENTER_PHONE_NUMBER)}
                </Link>
            </Box>
            <div className={classes.tableContainer}>
                <div className={classes.root}>
                    <table className={classes.table}>
                        <tbody>
                            <tr>
                                <th className={classes.tableHeader}>
                                    {t("registration.table.header")}
                                </th>
                            </tr>
                            <tr>
                                <td className={classes.odd}>{t("registration.table.row1")}</td>
                            </tr>
                            <tr>
                                <td>{t("registration.table.row2")}</td>
                            </tr>
                            <tr>
                                <td className={classes.odd}>{t("registration.table.row3")}</td>
                            </tr>
                            <tr>
                                <td>{t("registration.table.row4")}</td>
                            </tr>
                            <tr>
                                <td className={classes.odd}>{t("registration.table.row5")}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </Box>
    )
})

export default Registration