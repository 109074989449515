// React imports
import { Box, Button, Checkbox, FormControlLabel, FormGroup, Grid, Link, TextField } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { useState } from 'react'

// import i18next
import { useTranslation } from 'react-i18next'
import { BROWSER, createPlutoLogger, IOS_EXOSKELETON } from '../../utilities/Common'

const D = createPlutoLogger("[Form.js]")

const useSyles = makeStyles( theme => ({
      form: {
        width: '100%',
        marginTop: theme.spacing(1),
      },
      storePasswordBox: {
        display: "flex",
        "& *": {
            color: theme.palette.grey['700'],
            fontSize: "0.85rem",
        }
      },
      paramsBox: {
        position: 'fixed',
        paddingTop: '0.25rem',
        left: '0',
        bottom: '0',
        width: '100%',
        height: '1.75rem',
        fontSize: '0.75rem',
        textAlign: 'center',
        zIndex: 5,
        backgroundColor: theme.palette.grey['600'],
        color: theme.palette.grey['800']
      },
}))


const Form = (props) => {
        
    const { t } = useTranslation()
    const classes = useSyles()
    const presenter = props.presenter
    const showSavePassword = props.presenter === BROWSER

    //NOTE: necessary to define an initial value to avoid controlled/non controller problem!
    const [state, setState] = useState({credentials: {
        username: "",
        password: "",
    }})
    
    const handleInputChange = (event) => {
        const newState = { ...state.credentials }
        newState[event.target.name] = event.target.value
        setState({ credentials: newState })
    }

    const handleSubmit = (event) => {
        event.preventDefault()
        props.handleSubmit(state.credentials)
    }

    D("Forced auto login: ", props.forcedAutoLogin)
    D("Show save password: ", showSavePassword)
    D("Presenter: ", presenter)

    return ( 
    <>
        <form className={classes.form}>
            <TextField
                type="text"
                autoCorrect="off"
                autoCapitalize="none"
                autoComplete="username"
                name="username"
                label={t("login.username")}
                id="username"
                autoFocus={true}
                margin="normal"
                required
                fullWidth
                value={state.credentials.username}
                onChange={handleInputChange}
                variant="filled" />
            <TextField
                margin="normal"
                required
                fullWidth
                value={state.credentials.password}
                name="password"
                label={t("login.password")}
                type="password"
                id="password"
                autoComplete="password"
                onChange={handleInputChange}
                variant="filled"/>

            <Box mt={4} mb={3}>
                <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center">
                    <Grid item xs={7}>
                        <Link
                            href="#0"
                            underline="none"
                            onClick={props.handleForgotPassword} >{t("login.forgotPassword")}</Link>
                    </Grid>
                    <Grid item xs={5}>
                        <Link href="#" variant="body2">
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                onClick={handleSubmit}
                                >
                                {t("login.signIn")}
                            </Button>
                        </Link>
                    </Grid>
                </Grid>
            </Box>
        </form>
        {
        showSavePassword
        ?
            <Box className={classes.storePasswordBox}>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={props.forcedAutoLogin}
                                onChange={(e) => props.setForcedAutoLogin(!props.forcedAutoLogin)}
                                />
                        }
                        label={t("login.savePassword")} />
                </FormGroup>
            </Box>
        : null
        }
    </>
    )
}

export default Form