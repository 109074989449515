import * as actionTypes from './actionTypes'

export const generateStart = (targetId, fromTimeMillis, toTimeMillis, email, variant) => {
    return {
        type: actionTypes.SHARE_TARGET_GENERATE_START,
        targetId: targetId,
        fromTimeMillis: fromTimeMillis,
        toTimeMillis: toTimeMillis,
        email: email,
        variant: variant,
    }
}

export const generateFail = () => {
    return {
        type: actionTypes.SHARE_TARGET_GENERATE_FAIL
    }
}

export const generateSuccess = () => {
    return {
        type: actionTypes.SHARE_TARGET_GENERATE_SUCCESS
    }
}

export const setLoginToken = (loginToken) => {
    return {
        type: actionTypes.SHARE_TARGET_SET_LOGIN_TOKEN,
        loginToken: loginToken
    }
}