import * as actionTypes from './actionTypes'

// Fetch (API call)
export const fetchStart = (offset, limit) => {
    return {
        type: actionTypes.REFUELS_FETCH_START,
        payload: {offset: offset, limit: limit}
    }
}

export const fetchSucces = (result) => {
    return {
        type: actionTypes.REFUELS_FETCH_SUCCESS,
        payload: result
    }
}


export const fetchFail = () => {
    return {
        type: actionTypes.REFUELS_FETCH_FAIL,
    }
}


// Target
export const addTarget = (target) => {
    return {
        type: actionTypes.REFUELS_ADD_TARGET,
        target: target 
    }
}

export const deleteTarget = () => {
    return {
        type: actionTypes.REFUELS_DELETE_TARGET,
    }
}

// Refuels
export const setRefuels = (refuels) => {
    return {
        type: actionTypes.REFUELS_SET_REFUELS,
        payload: refuels 
    }
}

// Date range
export const setDateRange = (from, to) => {
    return {
        type: actionTypes.REFUELS_SET_DATE_RANGE,
        from: from,
        to: to,  
    }
}


// SELECTED TAB
export const setSelectedTab = (value) => {
    return {
        type: actionTypes.REFUELS_SET_SELECTED_TAB,
        payload: value  
    }
}

// LIFECYCLE
export const setMounted = (value) => {
    return {
        type: actionTypes.REFUELS_SET_MOUNTED,
        payload: value
    }
}