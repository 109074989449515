import { call, putResolve, select, take } from "redux-saga/effects"
import { createPlutoLogger, isTokenValid } from "../../../utilities/Common"

import * as trackingActions from "../../actions/tracking"
import * as actionTypes from '../../actions/actionTypes'    

const D = createPlutoLogger("📍 [SAGA] [trackingReload.js]")

// We have to fork here because the reloadStoredTargets function use async call to update the targets
// to takeEvery action "interceptors" have to be ready to handle the actions
function* reloadStoredTargets(action) {

    const { settings, auth } = yield select()
    const { trackingTargets } = settings
    const storedUserId = settings.userId
    const credentials = auth.credentials
    const currentUserId = credentials.id
    D("Stored user id:", storedUserId, "Current user id:", currentUserId, "Tracking targets:", trackingTargets)

    // Using the same browser for both password login and token login can cause all stored targets to be imported again.
    // This should be avoided because if a valid token is presented, the API calls will return data only for the single target defined by the token.
    // This can lead to a situation where all previously stored targets are rehydrated but cannot be updated.
    const token = credentials.token
    const tokenValid = isTokenValid(token)
    D("Token valid:", tokenValid)
    if (tokenValid) {
        D("Token is valid. Skipping reload stored targets.")
        return
    }

    if (storedUserId &&
        storedUserId === currentUserId &&
        trackingTargets &&
        trackingTargets.length > 0) {

        D(`Reload last tracked targets. We have ${trackingTargets.length} targets stored.`, settings)
        yield putResolve(trackingActions.replaceAllTargets(trackingTargets))
    }
}

export function* saga() {
    D("Forked.")
    D("Waiting for rehydration...")
    yield take("persist/REHYDRATE")
    D("Rehydrated.")

    while (true) { 
        D("Waiting for auth success...")
        yield take(actionTypes.AUTH_SUCCESS)
        D("Auth success received.")

        yield reloadStoredTargets()

        D("Waiting for logout...")
        yield take(actionTypes.AUTH_SIGN_OUT_SUCCESS)
        D("Logged out.")
    }
}