// Basic imports
import React from 'react'
import { connect } from 'react-redux'
import * as COM from '../../utilities/Common'


// Icons
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation'
import LocationOnIcon from '@mui/icons-material/LocationOn'

// i18n imports
import { useTranslation } from 'react-i18next'
import { CalendarMonth } from '@mui/icons-material'
import { Box, Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.grey[200],        
    },
    aggregatedContainer: {
        flexDirection: "column",
        justifyContent: "flex-start",
        backgroundColor: theme.palette.primary.main,
        borderRadius: ".5rem",
        margin: ".5rem",
        color:  theme.palette.primary.contrastText,
    },
    aggregatedTableContainer: {
        padding: ".5rem",
    },
    aggregatedTable: {
        "& * ": {
            color: theme.palette.primary.contrastText,
        },
        "& tr td": {
            border: "none",
            padding: ".25rem",
        },
        "& tr th": {
            border: "none",
            padding: ".25rem",
            color: theme.palette.grey[300],
        },
    },
    header: {
        padding: "1rem .5rem 1rem .5rem", //TRBL
        margin: 0,
        backgroundColor: theme.palette.grey[300],
        color: theme.palette.common.black,
    },
    icon: {
      color: theme.palette.common.black,
      marginRight: "0.5rem",
    },
  }))

const RefuelsListHeader = (props) => {

    const classes = useStyles()
    const { t } = useTranslation()
  
    //XXX: !!! MISLEADING NAMES !!!
    //XXX: Nameing is misleading, be carefull!
    //XXX: The state contains a 'refuels' object.
    //XXX: The refuels object contains a 'refuels' list.
    const refuelsState = props.state.refuels
    const refuels = refuelsState.refuels

    const fromDateStr = COM.formattedDate(refuelsState.dateRangeFrom)
    const toDateStr = COM.formattedDate(refuelsState.dateRangeTo)
    const targetName = refuelsState.target ? refuelsState.target.name : null
  
    const title = targetName
    const dateRangeString = `${fromDateStr} - ${toDateStr}`

    //console.debug("[RefuelsListHeader.js] refules:", refuels)

    const totalFillAmountRaw = refuels.reduce((acc, r) => acc + r.fillAmount, 0)
    const totalFillAmount = parseFloat(totalFillAmountRaw).toFixed(1);
    //console.debug("[RefuelsListHeader.js] totalFillAmountRaw: ", totalFillAmountRaw, "totalFillAmount: ", totalFillAmount)

    return (
        <>
            <Grid container className={classes.root}>
                <Grid
                    item
                    xs={12}
                    container
                    className={classes.aggregatedContainer}>
                    <Box p={1} m={0}>
                        <Typography variant="h6">{t("refuels.aggregatedData")}</Typography>
                    </Box>
                    <div className={classes.aggregatedTableContainer}>
                        <Table className={classes.aggregatedTable}>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ color: 'primary.contrastText' }}>{t("refuels.vesselName")}:</TableCell>
                                    <TableCell sx={{ color: 'primary.contrastText', fontWeight: 'bold' }}>{title}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell sx={{ color: 'primary.contrastText'}}>{t("refuels.aggregatedDateRange")}:</TableCell>
                                    <TableCell sx={{ color: 'primary.contrastText', fontWeight: 'bold' }}>{dateRangeString}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ color: 'primary.contrastText'}}>{t("refuels.aggregatedFillAmount")}:</TableCell>
                                    <TableCell sx={{ color: 'primary.contrastText', fontWeight: 'bold' }}>{totalFillAmount}&nbsp;L</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </div>
                </Grid>
            </Grid>

            <Grid container className={classes.header}>
                <Grid item xs={12} sm={4} md={2} container alignItems="center">
                    <CalendarMonth fontSize="small" className={classes.icon} />
                    {t("refuels.dateTimeOfRefuel")}
                </Grid>
                <Grid item xs={12} sm={8} md={6} container alignItems="center">
                    <LocationOnIcon fontSize="small"  className={classes.icon} />
                    {t("refuels.placeOfRefuel")}
                </Grid>
                <Grid item xs={12} sm={12} md={4} container alignItems="center">
                    <LocalGasStationIcon fontSize="small" className={classes.icon} />
                    {t("refuels.fuelCalculation")}
                </Grid>
            </Grid>
        </>
    )
}

const mapDispatchToProps = dispatch => {
    return {}
}
  
const mapStateToProps = state => {
    return {
        state: state,
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(RefuelsListHeader)
