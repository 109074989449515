import axios from 'axios'
import { createPlutoLogger } from '../../utilities/Common'

const BASE_URL = process.env.REACT_APP_API_URL

const D = createPlutoLogger("[REPO] [auth.js]")

/**
 * Authenticate the user with email and password
 * @param {object} credentials - The username and password
 * @param {string} acceptLanguage - Mandatory parameter for the API, use the current language
 * 
 */
export const getCurrentUserWithPassword = (credentials, acceptLanguage) => {
    
    D("getCurrentUserWithPassword", credentials, acceptLanguage)

    return axios.get(
        `${BASE_URL}/users/current`,
        {
            headers: {
                'Accept-Language': acceptLanguage,
            },
            auth: credentials
        }
    )
}

/**
 * Authenticate the user with token
 * @param {string} token - The token to authenticate
 * @param {string} acceptLanguage - Mandatory parameter for the API, use the current language
 * 
 */
export const getCurrentUserWithToken = (token, acceptLanguage) => {
        return axios.get(
            `${BASE_URL}/users/current`,
            {
                headers: {
                    'Accept-Language': acceptLanguage,
                    'Content-Type': 'application/json', 
                    'Authorization': `Token ${token}`
                }
            }
        )
}