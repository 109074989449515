import * as actionTypes from './actionTypes'

export const setMounted = (value) => {
    return {
        type: actionTypes.TRACKING_SET_MOUNTED,
        payload: value
    }
}

export const updateTargetsStart = () => {
    return {
        type: actionTypes.TRACKING_UPDATE_TARGETS_START
    }
}

export const updateTargetsSuccess = (data) => {
    return {
        type: actionTypes.TRACKING_UPDATE_TARGETS_SUCCESS,
        payload: data
    }
}

export const updateTargetsFail = () => {
    return {
        type: actionTypes.TRACKING_UPDATE_TARGETS_FAIL,
    }
}

export const pollStart = () => {
    return {
        type: actionTypes.TRACKING_POLL_START
    }
}

export const pollStop = () => {
    return {
        type: actionTypes.TRACKING_POLL_STOP
    }
}

export const pollRestart = () => {
    return {
        type: actionTypes.TRACKING_POLL_RESTART
    }
}

// GENERAL SET
export const setTargets = (newItems) => {
    return {
        type: actionTypes.TRACKING_SET_TARGETS,
        payload: newItems
    }
}


//SELECTED
export const addTargetsUserRequest = (newItems) => {
    return {
        type: actionTypes.TRACKING_ADD_TARGETS_USER_REQUEST,
        payload: newItems
    }
}

export const addTargets = (newItems) => {
    return {
        type: actionTypes.TRACKING_ADD_TARGETS,
        payload: newItems
    }
}

export const removeTargetByIdUserRequest = (id) => {
    return {
        type: actionTypes.TRACKING_REMOVE_TARGET_BY_ID_USER_REQUEST,
        payload: id
    }
}


export const removeTargetById = (id) => {
    return {
        type: actionTypes.TRACKING_REMOVE_TARGET_BY_ID,
        payload: id
    }
}


export const replaceAllTargetsUserRequest = (targets) => {
    return {
        type: actionTypes.TRACKING_REPLACE_ALL_TARGETS_USER_REQUEST,
        payload: targets
    }
}

export const replaceAllTargets = (newArray) => {
    return {
        type: actionTypes.TRACKING_REPLACE_ALL_TARGETS,
        payload: newArray
    }
}


export const noPendingTarget = () => {
    return {
        type: actionTypes.TRACKING_NO_PENDING_TARGET,
    }
}

// REFRESH
export const updateTarget = (targetId) => {
    return {
        type: actionTypes.TRACKING_UPDATE_TARGET,
        payload: targetId,
    }
}
// POPUP
export const setPopupTargetId = (targetId) => {
    return {   
        type: actionTypes.TRACKING_SET_POPUP_TARGET_ID,
        payload: targetId,
    }   
}

export const setTargetStatus = (targetId, status) => {
    return {
        type: actionTypes.TRACKING_SET_TARGET_STATUS,
        targetId: targetId,
        status: status,
    }
}

// IN PROGRESS
export const setInProgress = (value) => {
    return {
        type: actionTypes.TRACKING_SET_IN_PROGRESS,
        payload: value
    }
}

// BOUNDS
export const fitToBounds = () => {
    return {
        type: actionTypes.TRACKING_FIT_TO_BOUNDS,
    }
}

export const fitToBoundsUserRequest = () => {
    return {
        type: actionTypes.TRACKING_FIT_TO_BOUNDS_USER_REQUEST,
    }
}

export const setBounds = (bounds) => {
    return {
        type: actionTypes.TRACKING_SET_BOUNDS,
        payload: bounds,
    }
}

export const createBounds = () => {
    return {
        type: actionTypes.TRACKING_CREATE_BOUNDS,
    }
}

export const boundsAreValid = () => {
    return {
        type: actionTypes.TRACKING_BOUNDS_ARE_VALID,
    }
}

export const setAutoFitToBounds = (value) => {
    return {
        type: actionTypes.TRACKING_SET_AUTO_FIT_TO_BOUNDS,
        payload: value,
    }
}