import React, { useState } from 'react'
import { connect } from 'react-redux'

import { useTranslation } from 'react-i18next'
import { Alert, Button, TableCell, TableRow, Typography } from '@mui/material'
import { createPlutoLogger, formattedDateTime } from '../../utilities/Common'
import { ModuleInfo } from '../ErrorDialog/ModuleInfo'
import { Check } from '@mui/icons-material'
import { makeStyles } from '@mui/styles'

const D = createPlutoLogger("[ErrorList.js]")

const useStyle = makeStyles((theme) => ({
    dateTime: {
        color: theme.palette.grey[500],
        fontSize: "0.8rem",
        marginRight: theme.spacing(1),
    },
}))

const ErrorList = (props) => {

    const classes = useStyle()
    const { t } = useTranslation()
    const items = props.errorState.items
    const [showAll, setShowAll] = useState(true)

    D("Items: ", items)

    const title = t("error.dialog.allErrors")
    const buttonText = showAll ? t("error.dialog.hideAll") : t("error.dialog.showAll")
    const itemCount = items.length
    const showButton = itemCount > 0

    return (
        <>
            <TableRow >
                <TableCell component="th" scope="row">
                    <Typography variant="h6" component="h6">
                        {title}
                    </Typography>
                </TableCell>
                <TableCell align="right">
                    {showButton && <Button variant="outlined" onClick={() => setShowAll(!showAll)}>{buttonText} ({itemCount})</Button>}
                </TableCell>
            </TableRow>
            {itemCount > 0
            ?
                showAll && items.map((i) =>
                    <TableRow> 
                        <TableCell colSpan={2}>
                            <span className={classes.dateTime}>{formattedDateTime(i.date)}</span>
                            <ModuleInfo module={i.module} />{t(i.messageKey)}
                        </TableCell>
                    </TableRow>
                )
            : 
            <TableRow>
                <TableCell colSpan={2}>
                    {t("systemInfo.noError")}
                </TableCell>
            </TableRow>}
        </>
    )
}

function mapStateToProps(state) {
    return {
      errorState: state.error
    }
}

const mapDispatchToProps = dispatch => {
    return {
        //setAcknowledged: (uuid) => dispatch(errorActions.setAcknowledged(uuid)),
    }
  }

export default connect(mapStateToProps, mapDispatchToProps)(ErrorList)