import StandTime from "../StandTime"
import { useState } from "react"
import Events from "./Events"
import { ArrowDropDownRounded, ArrowDropUpRounded } from "@mui/icons-material"
import { t } from "i18next"
import { makeStyles } from "@mui/styles"

const useStyles = makeStyles((theme) => ({
    root: {
        padding: ".25rem",
        flex: 1,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: ".25rem",
        //backgroundColor: "green", //just for debugging
    },
    switch: {
        padding: ".25rem",
        //borderRadius: ".25rem",
        //backgroundColor: theme.palette.grey[600],
        //color: "white",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: ".15rem",
        cursor: "pointer",
    }
}))

const Footer = ({events = null, standTimeMicros = 0}) => {

    const classes = useStyles()
    const [eventsVisible, setEventsVisible] = useState(false)

    const handleClick = (ev) => {
        setEventsVisible(!eventsVisible)
    }

    const icon = eventsVisible ? <ArrowDropUpRounded /> : <ArrowDropDownRounded />
    const eventCount = events ? events.length : 0
    const hasEvents = eventCount > 0
    const eventsI18Key = hasEvents && (eventCount > 1 ? "routes.events" : "routes.event")
    const text = t(eventsI18Key, {count: eventCount})

    return (
        <>
            <div className={classes.root}>
                <StandTime timeMicros={standTimeMicros} />
                {hasEvents && <div className={classes.switch} onClick={handleClick}>{text}{icon}</div>}
            </div>
            {eventsVisible && 
                <div>
                    <Events events={events} />
                </div>  
            }
        </>
    )
}

export default Footer