// Style
import './index.css'

// React
import React from 'react'
import { createRoot } from 'react-dom/client'

// Service worker
import * as serviceWorker from './serviceWorker'

// i18n
import { init as i18nInit } from './i18n/init' // initialization

// initializers
import { initRedux } from './redux'
import { initMetrics } from './metrics'
import { initLogging } from './logging'

// Components
import StateInjector from './components/App/StateInjector'
import { initLanguage } from './language'

console.info("🚀 Starting the app...")

// This is just for debugging purposes to delay the start of the app.
// Normally, this does not have any effect (the value is 0).
const delayBeforeStart = process.env.REACT_APP_DELAY_IN_MILIS_BEFORE_START_APP || 0
console.debug("🚀 Delay before start: ", delayBeforeStart)

const wrapper = async () => {

    console.debug("🚀 Environment: ",process.env.NODE_ENV)
    console.debug("🚀 API base URL: ", process.env.REACT_APP_API_URL)

    console.debug("🚀 Initializing metrics...")
    await initMetrics()
    console.info("🚀 Metrics initialized.")

    console.debug("🚀 Initializing Redux...")
    const redux = await initRedux()
    console.info("🚀 Redux initialized. ", redux)

    console.debug("🚀 Initializing i18n...")
    await i18nInit()
    console.info("🚀 i18n initialized.")

    console.debug("🚀 Initializing logging...")
    await initLogging()
    console.info("🚀 Logging initialized.")

    console.debug("🚀 Initializing language...")
    const languageCode = initLanguage()
    console.info("🚀 Language initialized. Language code is: ", languageCode)
      
    const stateInjector = <StateInjector redux={redux} languageCode={languageCode} />
       
    // React 18 way
    const container = document.getElementById('app')
    const root = createRoot(container)
    root.render(stateInjector)
    
    console.info("🚀 App started!")
}

setTimeout(wrapper, delayBeforeStart)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
