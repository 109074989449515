import { useEffect, useRef, useState } from 'react';
import { createPlutoLogger } from "../../utilities/Common";

import L from 'leaflet';

const D = createPlutoLogger("[useCoordinateChange.js]");

const DISTANCE_TRESHOLD_METERS = 25;

const useCoordinateChange = (coordinates) => {
    const prevCoordinatesRef = useRef();
    const [hasChanged, setHasChanged] = useState(false);

    useEffect(() => {
        try {
            const prevCoordinates = prevCoordinatesRef.current
            
            // Add timestamp to coordinates
            coordinates.map((c) => {
                return [c[0],c[1], new Date().getTime()]
            })
            
            const currentCoordinates = coordinates
            D("Checking coordinates change.");

            if (!prevCoordinates || !currentCoordinates || currentCoordinates.length === 0) {
                D("Coordinates cannot be checked. Coordinates are empty or undefined. Prev coordinates: ", prevCoordinates, "Current coordinates: ", currentCoordinates);
                setHasChanged(true);
                
            } else {

                D("Checking coordinates change.");
                D("Previous coordinates length: ", prevCoordinates.length);
                D("Current coordinates length: ", currentCoordinates.length);

                const coordinatesHaveChanged = checkCoordinatesChangeWithTreshold(prevCoordinates, currentCoordinates);

                setHasChanged(coordinatesHaveChanged);
            }

            // Update previous coordinates to current coordinates
            prevCoordinatesRef.current = coordinates;
        } catch (e) {
            console.warn("Error in useCoordinateChange", e);
        }
    }, [coordinates]);

    return hasChanged;
};


const checkCoordinatesChangeWithTreshold = (coordinatesCurrent, coordinatesNew) => {
    if (coordinatesCurrent.length !== coordinatesNew.length) {
        return true;
    }
    for (let i = 0; i < coordinatesCurrent.length; i++) {
        const currentTimeStamp = coordinatesCurrent[i][2];
        const newTimeStamp = coordinatesNew[i][2];
        const isTimestampOld = currentTimeStamp + 0 < newTimeStamp;
        const pointCurrent = L.latLng(coordinatesCurrent[i][0], coordinatesCurrent[i][1]);
        const pointNew = L.latLng(coordinatesNew[i][0], coordinatesNew[i][1]);
        const distance = pointCurrent.distanceTo(pointNew); // Distance in meters
        if (distance > DISTANCE_TRESHOLD_METERS || isTimestampOld) {
            return true;
        }
    }
    return false;
};

/*const checkCoordinatesChange = (coordinatesCurrent, coordinatesNew) => {
    if (coordinatesCurrent.length !== coordinatesNew.length) {
        return true;
    }
    for (let i = 0; i < coordinatesCurrent.length; i++) {
        if (coordinatesCurrent[i][0] !== coordinatesNew[i][0] || coordinatesCurrent[i][1] !== coordinatesNew[i][1]) {
            return true;
        }
    }
    return false; // No change
};*/

export default useCoordinateChange;