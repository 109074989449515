import { DEFAULT_LANGUAGE_CODE, supportedLanguageCodes } from './i18n/init'
import { createPlutoLogger } from './utilities/Common'

const D = createPlutoLogger("[language.js]")

const LOCAL_STORAGE_KEY = 'persist:iTrackPluto'

const readLocaleStorage = () => {
    try {
        const data = localStorage.getItem(LOCAL_STORAGE_KEY)
        const parsed = JSON.parse(data)
        D("[readLocaleStorage] Parsed data from locale storage:", parsed)
        const languageCode = parsed.setting.languageCode
        D("[readLocaleStorage] Language code from locale storage:", languageCode)

        return languageCode
    } catch (e) {
        // It is not an error if we cannot read the locale storage
        D("[readLocaleStorage] Error in reading locale storage. Error: ", e)
        return null
    }
}

const storeLanguage = (languageCode) => {
    try {
        const data = localStorage.getItem(LOCAL_STORAGE_KEY)
        const parsed = JSON.parse(data)
        D("[storeLanguage] Parsed data from locale storage:", parsed)
        parsed.setting.languageCode = languageCode
        D("[storeLanguage] New language code in the parsed data:", languageCode)

        localStorage.setItem('persist:iTrackPluto', JSON.stringify(parsed))
        D("[storeLanguage] Language code stored in locale storage:", languageCode)
    } catch (e) {
        console.warn("[storeLanguage] Error in storing language in locale storage. Error: ", e)
    }
}

const LANGUAGE_PARAM_VARIABLE_NAME = 'l'

export const initLanguage = () => {
    try {
        D("[initLanguage] Supported languages:", supportedLanguageCodes)

        const paramLanguage = new URLSearchParams(window.location.search).get(LANGUAGE_PARAM_VARIABLE_NAME)
        if (paramLanguage) {
            const params = new URLSearchParams(window.location.search);
            
             // Remove the LANGUAGE_PARAM_VARIABLE_NAME parameter
            params.delete(LANGUAGE_PARAM_VARIABLE_NAME);
            D("[initLanguage] URL parameter language removed:", paramLanguage)

            // Get the base URL (without the query string)
            const baseUrl = window.location.origin + window.location.pathname;
            D("[initLanguage] Base URL:", baseUrl)

            // Construct the new URL with the updated query string
            const newUrl = baseUrl + (params.toString() ? '?' + params.toString() : '');
            D("[initLanguage] New URL:", newUrl)

            // Update the browser's URL without reloading the page
            window.history.replaceState(null, '', newUrl);
            D("[initLanguage] Browser URL updated.")
        }
        D("[initLanguage] URL parameter language:", paramLanguage)
        const isParamLanguageSupported = supportedLanguageCodes.includes(paramLanguage)
        D("[initLanguage] Is URL parameter language supported:", isParamLanguageSupported)

        const storedLanguage = readLocaleStorage()
        D("[initLanguage] Stored language:", storedLanguage)
        const isStoredLanguageSupported = supportedLanguageCodes.includes(storedLanguage)
        D("[initLanguage] Is stored language supported:", isStoredLanguageSupported)

        const browserLanguage = navigator.language
        D("[initLanguage] Browser language:", browserLanguage)
        const isBrowserLanguageSupported = supportedLanguageCodes.includes(browserLanguage)
        D("[initLanguage] Is browser language supported:", isBrowserLanguageSupported)

        if (isParamLanguageSupported) {
            D("[initLanguage] Returning with URL parameter language:", paramLanguage)
            storeLanguage(paramLanguage)
            return paramLanguage
        }

        if (isStoredLanguageSupported) {
            D("[initLanguage] Returning with stored language:", storedLanguage)
            return storedLanguage
        }

        if (isBrowserLanguageSupported) {
            D("[initLanguage] Returning with browser language:", browserLanguage)
            return browserLanguage
        }

        D("[initLanguage] Returning with default language:", DEFAULT_LANGUAGE_CODE)
        return DEFAULT_LANGUAGE_CODE
        
    } catch (e) {
        console.warn("[initLanguage] Error in language initialization:", e)
    }
}