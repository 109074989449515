// Action Types import
import * as actionTypes from '../actions/actionTypes'
import { update } from '../../utilities/Common.js'

// Initial state
const initialState = {
    history: null,
}

const reducer = (state = initialState, action) => {

    switch (action.type) {

        case actionTypes.NAVIGATION_SET_HISTORY: return update(state, {history: action.payload})
               
        default: return state
    }
}

export default reducer