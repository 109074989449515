// React imports
import React from 'react'
import { connect } from 'react-redux'
import * as chatActions from '../../store/actions/chat'

// Own components
import ChatFilter from './ChatFilter'
import PlutoDrawer from '../Drawer/PlutoDrawer'
import ChatInput from './ChatInput'

const Chat = (props) => {  

  const chat = props.state.chat

  return (
    <>
      <PlutoDrawer inProgress={chat.inProgress}>
          <ChatFilter />
      </PlutoDrawer>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    state: state
  }
}
const mapDispatchToProps = dispatch => {
  return {
    setMounted: (payload) => dispatch(chatActions.setMounted(payload)),
  }
}

connect(mapStateToProps, mapDispatchToProps)(ChatInput)
export default connect(mapStateToProps, mapDispatchToProps)(Chat)