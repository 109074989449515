const getSign = (t) => {
    return {
        yearSign: t("general.dateTime.y"),
        monthSign: t("general.dateTime.mt"),
        daySign: t("general.dateTime.d"),
        hourSign: t("general.dateTime.h"),
        minuteSign: t("general.dateTime.m"),
        secondSign: t("general.dateTime.s"),
    }
}

export default getSign