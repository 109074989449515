import { makeStyles } from "@mui/styles";

const EMPTY_STRING = "";
const SPACE = " ";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: ".25rem",
        //backgroundColor: "yellow", // just for debugging
    },
    startAdornment: {
        transform: "scale(0.65)",
        color: theme.palette.grey[700],
        //backgroundColor: "blue", // just for debugging
    },
}))

const DriverName = (props) => {
    const classes = useStyles()

    const name = props.name
    if (!name) return null

    const nameOnly = props.nameOnly
    const description = props.description
    const startAdornment = props.startAdornment
    const validDescription = description !== null && description !== EMPTY_STRING && description !== SPACE
    
    let text = name
    if (!nameOnly) {
        text = validDescription ? `${name} (${description})` : name
    }
    const content = startAdornment
    ? 
        <div className={classes.root}>
            <div className={classes.startAdornment}>{startAdornment}</div>
            <div>{text}</div>
        </div>
    :
        text
    
    return content
}

export default DriverName