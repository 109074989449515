import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Marker, Tooltip } from "react-leaflet"

import L from 'leaflet';
import { useAppControl } from "../App/AppControlProvider";
import { createPlutoLogger } from "../../utilities/Common";

const iconUrl = `${process.env.PUBLIC_URL}leaflet/images/marker-icon.png`;
const iconRetinaUrl = `${process.env.PUBLIC_URL}leaflet/images/marker-icon-2x.png`;
const shadowUrl = `${process.env.PUBLIC_URL}leaflet/images/marker-shadow.png`;

// Fix Leaflet's default icon paths
L.Marker.prototype.options.icon = L.icon({
    iconRetinaUrl: iconRetinaUrl,
    iconUrl: iconUrl,
    shadowUrl: shadowUrl,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41]
});

const D = createPlutoLogger("[MyLocation.js]")

const makeStyles = (theme) => ({
    marker: {
        backgroundColor: "blue",
    },
    popup: {
        backgroundColor: "red",
    }
})

const MyLocation = (props) => {
    const { getShowMyLocation } = useAppControl()
    const showMyLocation = getShowMyLocation()
    const [position, setPosition] = useState(null)
    const { t } = useTranslation()
    const classes = makeStyles()

    D("showMyLocation: ", showMyLocation)
 
    useEffect(() => {

        D("[useEffect] showMyLocation: ", showMyLocation)

        if (!showMyLocation) return

        const params = {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 3000, //0 means no cache, but it consumes more resources, so we set it to 3 seconds. TODO: further optimization would be great
        }

        const handlePosition = (position) => {
            setPosition({
                lat: position.coords.latitude,
                lng: position.coords.longitude
            })
        }

        const handleError = (error) => {
            D(`[useEffect] error:`, error)
        }

        const watchId = navigator.geolocation.watchPosition(
            position => handlePosition(position),
            error => handleError(error),
            params,
        )
        D("[useEffect] watch started.")

        return () => {
            navigator.geolocation.clearWatch(watchId)
            D("[useEffect] watch cleared.")
        }
    }, [showMyLocation])
    
    if (!position || !showMyLocation) return null


    return (
        <Marker position={position} className={classes.marker}>
            <Tooltip direction={"auto"}>{t("geolocation.youAreHere")}</Tooltip>
        </Marker>
    )
}

export default MyLocation