import { createTheme } from '@mui/material'
import lightTheme from './themes/light.json'
import darkTheme from './themes/dark.json'
//import hicoTheme from './themes/hico.json' // For people who have visual impairments

export const AVAILABLE_THEMES = {
    light: {
        labelKey: 'theme.light',
        theme: createTheme(lightTheme),
    },
    dark:{
        labelKey: 'theme.dark',
        theme: createTheme(darkTheme),
    },
    /*hico: {
        labelKey: 'theme.hico',
        theme: createTheme(darkTheme),
    }*/
}