import { useState, useEffect } from 'react'

const useScreenRatio = () => {
    const [ratio, setRatio] = useState(window.innerHeight / window.innerWidth)

    const eventName = 'resize'

    useEffect(() => {
        const handleResize = () => {
            setRatio(window.innerHeight / window.innerWidth)
        }

        window.addEventListener(eventName, handleResize)
        
        return () => window.removeEventListener(eventName, handleResize)
    
    }, []);

    return ratio
};

export default useScreenRatio;