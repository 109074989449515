import React from "react";

import { ArrowForwardIos } from "@mui/icons-material";
import PlutoTab from "./PlutoTab";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    root: { 
        display: "flex",
        //backgroundColor: "cyan"   // just for debugging
    },
    wrapper: {
        width: "100%",
        padding: theme.spacing(1),
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        //backgroundColor: "pink",    // just for debugging
    },
    tabContainer: {
        flex: "1 1 auto",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        //backgroundColor: "yellow",    // just for debugging
    },
    separator: {
        color: theme.palette.grey[500],
    }
}));


const FilterTabs = (props) => {

    const classes = useStyles()
    const { tabs, selectedTab, onChange } = props

    console.debug("[FilterTabs.js] props: ", props)

    const icon = <ArrowForwardIos className={classes.separator} />   

    const handleClick = (index) => {
        console.debug("[FilterTabs.js] handleClick: ", index)
        onChange(index)
    }

    return (
        <div className={classes.root}>
            {tabs.map((tab, mapIndex) => {
                const isLast = mapIndex === tabs.length - 1
                return (
                    <div className={classes.wrapper} key={`${mapIndex}-${tab.index}`}>
                        <div onClick={() => handleClick(tab.index)} className={classes.tabContainer}>
                            <PlutoTab label={tab.label} icon={tab.icon} isSelected={tab.index === selectedTab} />
                        </div>
                        {isLast ? null : icon}
                    </div>
                )
            })}
        </div>
    )
}

export default FilterTabs;