// React imports
import React from 'react'

// Redux imports
import { connect } from 'react-redux'
import * as settingsActions from './../../store/actions/settings'

// i18n imports
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@mui/styles'

// Languages and map positions
import availableLanguages from './availableLanguages.json'
import { createPlutoLogger } from '../../utilities/Common'

availableLanguages.sort((a, b) => a.order - b.order)

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: "flex",
    //backgroundColor: 'red', // just for debugging
  },
  root: {
    padding: 0,
    fontSize: ".7rem",
    "& *": {
      listStyleType: "none",
      display: "inline-block",
    },
    cursor: "pointer",
  },
  selected: {
    color: theme.palette.primary.main,
    paddingRight: theme.spacing(1),
    fontWeight: "bold",
  },
  normal: {
    paddingRight: theme.spacing(1),
    color: theme.palette.grey[600]
  },
  [`@media (min-width: 669px)`]: {
    root: {
      fontSize: ".85rem",
    }
  },
  [`@media (min-width: 780px)`]: {
    root: {
      fontSize: "1rem",
    }
  }
}))

const D = createPlutoLogger("[LanguageSelector.js]")

const LanguageItem = ({language, onClick}) => {
    
    const { t, i18n } = useTranslation()
    const classes = useStyles()

    return (
      <li onClick={ ev => onClick(language.code)} className={language.code === i18n.language ? classes.selected : classes.normal}>
          {t(language.name)}
      </li>
    )
}

const LanguageSelector = (props) => {

    const classes = useStyles()
    const { i18n } = useTranslation()

    // old Redux actions
    const { languageChange } = props

    const handleClick = code => {
        // Pass language code into Redux store for storing by persistor
        languageChange(code)
    }

    return(
        <div className={classes.wrapper}>
          <ul className={classes.root}>
            {availableLanguages.map( l => <LanguageItem language={l} onClick={handleClick} key={l.code} /> )}
          </ul>
        </div>
     )
}

const mapStateToProps = (state) => {
  return {
    // no state needed
  };
}

const mapDispatchToProps = dispatch => {
  return {
    languageChange: (code) => dispatch( settingsActions.languageChange(code) ),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LanguageSelector)