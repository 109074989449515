import { connect } from "react-redux"
import { useTranslation } from "react-i18next"
import { TRACKING_STATUS_PENDING } from "../../utilities/Common"
import { makeStyles } from "@mui/styles"
import { CircularProgress } from "@mui/material"

const useStyles = makeStyles(theme => ({
    root: {
      position: 'absolute',
      width: 'fit-content',
      top: theme.spacing(1),
      left: theme.spacing(1),
      padding: theme.spacing(1),
      backgroundColor: 'rgba(255,255,255, .8)',
      color: theme.palette.primary.main,
      boxShadow: theme.shadows[3],
      border: `1px solid ${theme.palette.primary.main}`,
      display: "flex",
      flexDirection: "row",
      gap: theme.spacing(1),
      borderRadius: theme.shape.borderRadius,
      alignItems: "center",
      whiteSpace: 'nowrap',
    },
 }))

const TargetLoadingDialog = ({state}) => {

    const { tracking } = state
    const { targets } = tracking
    const { t } = useTranslation()
    const classes = useStyles()

    if (!targets || targets.length < 1) return null

    const loadingTargets = targets.filter(e => e.status === TRACKING_STATUS_PENDING);
    const loadingTargetsCount = loadingTargets.length;  
    const showLoadingTargets = (loadingTargetsCount > 0)

    if (!showLoadingTargets) return null; 

    return (
        <div className={classes.root}>
            <CircularProgress size="1rem" />
            <div>{t("tracking.mapOverlayLoadingIndicatorText", {count: loadingTargetsCount})}</div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
      state: state,
    }
}

const mapDispatchToProps = dispatch => {
    return {
      //No need for dispatching anything here
    }
  }

export default connect(mapStateToProps, mapDispatchToProps)(TargetLoadingDialog)
