import { SvgIcon } from "@mui/material"


const ArrowSvgIcon = () => {

    return (
        <SvgIcon viewBox='0 0 24 24'>
            <g fill={"white"} strokeWidth={1} strokeOpacity={.5} stroke={"black"}>
                <path d="M 21 12 L 12 18 L 12 15 L 3 15 L 3 9 L 12 9 L 12 6 L 21 12" />
            </g>
        </SvgIcon>
    )
}

export default ArrowSvgIcon