import { useEffect, useRef, useState } from 'react';

function useChangeHandler(selectedTargetsWithPosition) {
  const storedSelectedTargetsWithPosition = useRef();
  const [changeObj, setChangeObj] = useState({});

  useEffect(() => {
    const stored = storedSelectedTargetsWithPosition.current
    const current = selectedTargetsWithPosition
    const newChangeObj = {}
    let hasChanges = false

    current.forEach((item, index) => {
        if (stored && stored[index] && stored[index].id === item.id) {
            const storedItem = stored[index]

            const storedComp = {
                currentPosition: storedItem.currentPosition,
                lastPositions: storedItem.lastPositions,
                target: storedItem.target,
            };

            const currentComp = {
                currentPosition: item.currentPosition,
                lastPositions: item.lastPositions,
                target: item.target,
            };

            if (JSON.stringify(storedComp) !== JSON.stringify(currentComp)) {
                newChangeObj[item.id] = { changed: Math.random() }
                hasChanges = true
            } else {
                newChangeObj[item.id] = { changed: false }
            }
        } else {
            newChangeObj[item.id] = { changed: Math.random() }
            hasChanges = true
        }
    });

    storedSelectedTargetsWithPosition.current = current
    if (hasChanges) {
      setChangeObj(newChangeObj);
    }
  }, [selectedTargetsWithPosition])

  return changeObj;
}

export default useChangeHandler