import React from "react";

// Icons
import ClearIcon from '@mui/icons-material/Clear'
import { Search } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { Input, InputAdornment } from "@mui/material";

const useStyles = makeStyles((theme) => ({
      /* total height is 2rem */
    root: {
        padding: "1rem 1rem .5rem 1rem",
        height: "3rem",
        display: "flex",
        alignItems: "flex-end",
        justifyContent: 'center',
        gap: "1rem",
        backgroundColor: theme.palette.grey[100],
        borderRadius: "1.5rem",
    },
    endAdornmentContainer: {
        marginLeft: "1rem",
        height: "2rem", 
        fontSize: ".8rem",
        color: theme.palette.grey[800],
        display: "flex",
        alignItems: "center",
        justifyContent: 'center',
    },
    searchIcon: {
        color: theme.palette.grey[600],
    },
    clearIcon: {
        cursor: "pointer",
    }
}))


const TextSearch = (props) => {
    const {filter, onChange, onClear, placeholder} = props
    const endAdornment = props.endAdornment
    const classes = useStyles()

    const icon = filter !== "" ? <ClearIcon onClick={onClear} className={classes.clearIcon} /> : ""

    return (
        <div className={classes.root}>
            <Input
                fullWidth
                type="text"
                value={filter}
                onChange={onChange}
                disableUnderline={true}
                placeholder={placeholder}
                startAdornment={<InputAdornment position="start"><Search className={classes.searchIcon} /></InputAdornment>}
                endAdornment={<InputAdornment position="end" >{icon}</InputAdornment>} />
            {endAdornment
                ?
                    <div className={classes.endAdornmentContainer}>
                        {endAdornment}
                    </div>
                : 
                    null
            }
      </div>
    )
}

export default TextSearch