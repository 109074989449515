import { useTranslation } from "react-i18next"
import { ApiError } from "../../other/ApiError"
import { makeStyles } from "@mui/styles"

export const moduleEmojis = {
    [ApiError.MODULE_UNKNOWN]: "🤔",
    [ApiError.MODULE_CHAT]: "💬",
    [ApiError.MODULE_ROUTES]: "🛣️",
    [ApiError.MODULE_TRACKING]: "📍",
    [ApiError.MODULE_TARGETS]: "🎯",
    [ApiError.MODULE_SHARE_TARGET]: "🔗",
    [ApiError.MODULE_AUTH]: "🔒",
    [ApiError.MODULE_REFUELS]: "⛽",
}

const BASE_KEY = "error.moduleOfOrigin"

const useStyles = makeStyles( (theme) => ({
    root: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        gap: theme.spacing(1),
    },
}))

export const ModuleInfo = ({ module }) => {
    const classes = useStyles()
    const { t } = useTranslation()
    const tootlipKey = BASE_KEY + "." + module
    const emoji = moduleEmojis[module]
    const translatedTooltip = t(tootlipKey)
    return (
        <div className={classes.root}>
            <span>{emoji}</span>
            <span>{translatedTooltip}</span>
        </div>
    )
}