import axios from 'axios'
import { createPlutoLogger } from '../../utilities/Common'

const BASE_URL = process.env.REACT_APP_API_URL

const D = createPlutoLogger("[REPO] [routes.js]")

// https://m.itrack.hu/ws-rest/api/v1.1/targets/49841/routes?fromTime=1593640800000000&toTime=1603666799000000&_=1601913343119
export const getRoutes = (params) => {

    D('getRoutes', params)

    const result = axios.get(
        `${BASE_URL}/targets/${params.targetId}/routes`, {
            auth: params.credentials,
            params: {
                fromTime: params.fromTime,
                toTime: params.toTime,
            }
        }
    )

    console.debug("[repository/routes.js] [getRoutes] result:", result)
    return result
}

// https://m.itrack.hu/ws-rest/api/v1.1/targets/37665/positions?fromTime=1598422337000000&toTime=1598423472000000&_=1601957927249
export const getPositions = (params) => {

    //gconsole.debug("[repository/routes.js] [getPositions] params:", params)

    const result = axios.get(
        `${BASE_URL}/targets/${params.targetId}/positions`, {
            auth: params.credentials,
            params: {
                fromTime: params.fromTime,
                toTime: params.toTime,
            }
        }
    )
    console.debug("[repository/routes.js] [getPositions] result:", result)
    return result
}
