import React from "react"

import LanguageSelector from '../LanguageSelector/LanguageSelector'

//i18n
import { useTranslation } from 'react-i18next'
import { DIALOG_PROFILE, useAppControl } from "../App/AppControlProvider"
import { connect } from "react-redux"

import * as COM from '../../utilities/Common'
import { Button } from "@mui/material"
import { makeStyles } from "@mui/styles"
//import ShowMyLocationSwitch from "../Tracking/ShowMyLocationSwitch" // This feature is not tested and is not part of the requirements for the 2.4.1 release.
//import ThemeSelector from "../Theme/ThemeSelector" // This feature is not tested and is not part of the requirements for the 2.4.1 release.

const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: "320px",
        display: "flex",
        flexDirection: "column",
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
    },
    container: {
        flex: 1,
        margin: theme.spacing(2),
    },
    label: {
        fontSize: "0.85rem",
    },
    buttonContainer: {
        flex: 1,
        display: "flex",
        alignItems: "flex-end",
        justifyContent: "flex-end"
    },
    username: {
        color: theme.palette.grey[900],
        fontSize: "1.05rem"
    }
}));

const ProfileDialog = (props) => {
  
    const { t } = useTranslation()
    const classes = useStyles()
    const { getDialogOpen, closeDialog } = useAppControl()
    const isOpen = getDialogOpen(DIALOG_PROFILE)
    const close = () => closeDialog(DIALOG_PROFILE)
    
    const { auth } = props.state
    const { credentials } = auth
    const { token, username } = credentials
    const isTokenValid = COM.isTokenValid(token)

    if (!isOpen ) return null

    const displayUsername = () => {
        if (isTokenValid) return t("profile.authorizedByToken")
        return username
    }

    return (

        <div className={classes.root}>
            <div className={classes.container}>
                <div>
                    <h3>{t("profile.user")}</h3>
                    <span className={classes.username}>{displayUsername()}</span>
                </div>
                <div>
                    <h3>{t("profile.language")}</h3>
                    <LanguageSelector />
                </div>
                
                
                {/*
                // This feature is not tested and is not part of the requirements for the 2.4.1 release.
                // Comment out the following code block, and the main functionality of the feature will work immediately.
                // Proper testing and creation of a proper dark theme is needed for the next release.
                // Additionally, check the styles of all components. All related values should be referenced from the theme object!*/}
                {/*<div>
                    <h3>{t("profile.theme")}</h3>
                    <ThemeSelector />
                </div>*/}

                {/*
                // This feature is not tested and is not part of the requirements for the 2.4.1 release.
                // Comment out the following code block, and the main functionality of the feature will work immediately.*/}
                {/*<div>
                    <h3>{t("profile.currentPosition")}</h3>
                    <ShowMyLocationSwitch />
                </div>*/}

            </div>
            <div className={classes.buttonContainer}>
                <Button onClick={close} color="primary">{t("general.close")}</Button>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        state: state
    };
  }
  
  const mapDispatchToProps = dispatch => {
    return {
      // no actions needed
    }
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(ProfileDialog)
