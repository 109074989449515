import axios from 'axios'
import { createPlutoLogger } from '../../utilities/Common'

const BASE_URL = process.env.REACT_APP_API_URL

const D = createPlutoLogger("[REPO] [targets.js]")

/**
 * Get the targets by using the filter
 * @param {object} params - Complex object, mandatory to contain the following properties:
 * @param {string} params.acceptLanguage  - Mandatory parameter for the API, use the current language
 * @param {object} params.credentials - The username and password
 * @param {number} params.offset - The offset to start from
 * @param {number} params.limit - The limit of the results
 * @param {string} params.filter - The filter to apply
 * @param {boolean} params.useToken - Use the token to authenticate
 */

const createBody = (credentials, queryParams, acceptLanguage, useToken ) => {

    D('createBody', credentials, queryParams, acceptLanguage, useToken)

    const headers = {
        'Accept-Language': acceptLanguage,
        'Content-Type': 'application/json'
    }

    if (useToken === true) {
        const token = credentials.token
        headers['Authorization'] = `Token ${token}`;
    }
    //console.debug("[REPOSITORY targets.js createBody()] Headers:", headers);

    const body = {
        headers: headers,
        params: queryParams,
    }

    if (useToken !== true) {
        body['auth'] = credentials;
    }
    //console.debug("[REPOSITORY targets.js createBody()] Body:", body);

    return body
}

 export const getTargets = (params) => {
    const { acceptLanguage, credentials, offset, limit, filter, useToken } = params

    /*const headers = {
        'Accept-Language': acceptLanguage,
        'Content-Type': 'application/json'
    }
    
    if (useToken === true) {
        const token = credentials.token
        headers['Authorization'] = `Token ${token}`;
    }
    //console.debug("[REPOSITORY targets.js] Headers:", headers);

    const body = {
        headers: headers,
        params: {
            offset: offset,
            limit: limit,
            filter: filter
        }
    }

    if (useToken !== true) {
        body['auth'] = credentials;
    }
    //console.debug("[REPOSITORY targets.js] Body:", body);*/
    
    const queryParams = {
        offset: offset,
        limit: limit,
        filter: filter
    }
    const body = createBody(credentials, queryParams, acceptLanguage, useToken)


    return axios.get(
        `${BASE_URL}/targets`,
        body,
    )

    /*return axios.get(
        `${BASE_URL}/targets`, {
            headers: {
                'Accept-Language': acceptLanguage
            },
            params: {
                offset: offset,
                limit: limit,
                filter: filter
            },
            auth: credentials,
        }
    )*/
}

/**
 * Get the targets count by using the filter
 * @param {object} params - Complex object, mandatory to contain the following properties:
 * @param {string} params.acceptLanguage  - Mandatory parameter for the API, use the current language
 * @param {object} params.credentials - The username and password
 * @param {string} params.filter - The filter to apply
 */
export const getTargetCount = (params) => {

    const { acceptLanguage, credentials, filter, useToken } = params
    
    const queryParams = {
        filter: filter
    }
    const body = createBody(credentials, queryParams, acceptLanguage, useToken)

    return axios.get(
        `${BASE_URL}/targets/count`,
        body,
    )

    /*return axios.get(
        `${BASE_URL}/targets/count`, {
            headers: {
                'Accept-Language': params.acceptLanguage
            },
            params: {
                filter: params.filter
            },
            auth: params.credentials,
        }
    )*/
}