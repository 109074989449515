import { createContext, useContext, useEffect, useState } from 'react';
import { createPlutoLogger } from '../../utilities/Common';

// dialog ids available externally
export const DIALOG_PROFILE = 'profile'
export const DIALOG_CONTACTS = 'contacts'

// location
const SWITCH_MY_LOCATION = 'myLocation'

// map
const MAP = 'map'

// spiderfied
const SPIDERFIED = 'spiderfied'

// debug mode
const DEBUG_MODE = 'debugMode'

// bottom menu
const FILTER_PANE_VISIBLE = 'filterPaneVisible'

// theme
const THEME_NAME = 'themeName'
const DEFAULT_THEME_NAME =  "light" //Check ui/themes.js

const AppControlContext = createContext();

export const useAppControl = () => {
  return useContext(AppControlContext);
};

const D = createPlutoLogger("🎮 [AppControlProvider]")

const initialState = {
  //stateCnt: 0,
  [FILTER_PANE_VISIBLE]: false,
  [DIALOG_PROFILE]: false,
  [DIALOG_CONTACTS]: false,
  [SWITCH_MY_LOCATION]: true, // We want to show the user's location by default
  [THEME_NAME]: DEFAULT_THEME_NAME,
  [DEBUG_MODE]: false,
  [SPIDERFIED]: false,
}

export const AppControlProvider = ({ children }) => {

  const rndGen = () => Math.random().toString(36).substring(7)

  const [state, setState] = useState(() => {
    D(`Initialized with state: `, initialState)

    const initStateWithInstanceId = {
      instanceId: rndGen(), // Generate a random instance ID to distinguish between different instances of this component (if needed in the future)
      ...initialState
    }

    return initStateWithInstanceId
  }) // We have to initialize the state with a function to avoid reinitialization!


  // Main state update function
  //
  // The current solution ensures that all state updates are preserved and applied correctly.
  // Each time the update function is called, it creates a new state object that merges the current state with the new updates.
  // This approach ensures that no updates are lost, and all changes are applied in sequence.
  //
  // To keep it simple, we do not use throttling or debouncing here, but it can be added if needed.
  // As with any optimization method, it has its own drawbacks and should be used with caution.
  // For more information on throttling and debouncing, see:
  // - https://developer.mozilla.org/en-US/docs/Web/API/WindowOrWorkerGlobalScope/setTimeout#debouncing
  // - https://developer.mozilla.org/en-US/docs/Web/API/WindowOrWorkerGlobalScope/setTimeout#throttling
  const update = (update) => {
    setState(prevState => {
      const newState = {
        ...prevState,
        ...update,
        //stateCnt: (prevState.stateCnt || 0) + 1 //Keep track of the state changes 
      }
      return newState
    })
  }

  const updateKey = (key, value) => {
    D(`Update key: ${key}. New value is `, value)
    update({
      [key]: value
    })
  }

  useEffect(() => {
    // If stateCnt is enabled, you can use the following line to include the stateCnt in the debug messages:
    // console.debug(`${DEBUG_PREFIX} [${state.instanceId} #${state.stateCnt}] ${msg}`, payload)
    D(`[$${state.instanceId}] State has been changed: `, state)
  }, [state])  

  const get = (key) => {
    const value = state[key]
    D(`Get key: ${key}. Current value is `, value)
    return value
  }
  const set = (key, value) => updateKey(key, value)
  const neg = (key) => updateKey(key, !get(key))

  // map
  const setMap = (map) => set(MAP, map)
  const getMap = () => get(MAP)

  // spiderfied
  const spiderfiedOn = () => set(SPIDERFIED, true)
  const spiderfiedOff = () => set(SPIDERFIED, false)
  const getSpiderfied = () => get(SPIDERFIED)

  // theme
  const getThemeName = () => get(THEME_NAME)
  const setThemeName = (themeName) => set(THEME_NAME, themeName)

  // filter pane
  const getFilterPaneOpen = () => get(FILTER_PANE_VISIBLE)
  const closeFilterPane = () => set(FILTER_PANE_VISIBLE, false)
  const openFilterPane = () =>set(FILTER_PANE_VISIBLE, true)
  const toggleFilterPane = () => neg(FILTER_PANE_VISIBLE)

  // dialogs
  const getDialogOpen = (dialogId) => get(dialogId)
  const openDialog = (dialogId) => set(dialogId, true)
  const closeDialog = (dialogId) => set(dialogId, false)
  const toggleDialog = (dialogId) => neg(dialogId)

  // my location
  const getShowMyLocation = () => get(SWITCH_MY_LOCATION)
  const showMyLocationOn = () => set(SWITCH_MY_LOCATION, true)
  const showMyLocationOff = () => set(SWITCH_MY_LOCATION, false)

  // debug mode
  const getDebugMode = () => get(DEBUG_MODE)
  const debugModeOn = () => set(DEBUG_MODE, true)
  const debugModeOff = () => set(DEBUG_MODE, false)
  const toggleDebugMode = () => neg(DEBUG_MODE)

  return (
    <AppControlContext.Provider value={{

        // map
        setMap,
        getMap,

        // spiderfied
        spiderfiedOn,
        spiderfiedOff,
        getSpiderfied,

        // theme
        getThemeName,
        setThemeName,

        // filter pane
        getFilterPaneOpen,
        openFilterPane,
        closeFilterPane,
        toggleFilterPane,

        // dialogs
        getDialogOpen,
        openDialog,
        closeDialog,
        toggleDialog,

        // my location
        getShowMyLocation,
        showMyLocationOn,
        showMyLocationOff,

        // debug mode
        getDebugMode,
        debugModeOn,
        debugModeOff,
        toggleDebugMode,

      }}>
      {children}
    </AppControlContext.Provider>
  )
}