import { take, putResolve, call, select, race, delay, fork, put } from 'redux-saga/effects'
import * as trackingRepo from '../../repository/tracking'
import * as actionTypes from '../../actions/actionTypes'

import * as errorActions from '../../actions/error'
import * as trackingActions from '../../actions/tracking'

import { createPlutoLogger } from '../../../utilities/Common'
import { ApiError } from '../../../other/ApiError'

import * as COM from '../../../utilities/Common'

const D = createPlutoLogger("📍 [SAGA] [trackingPolling.js]")

const WAIT_BETWEEN_POLL_ERRORS_MS = 5000 // Set the 
const WAIT_BETWEEN_POLLS_MS = 1000 // Set the 
function* updateTargets() {       
    D("API call. Updating targets...")
    try {
        const state = yield select();
        const { tracking, settings } = state
        const { auth } = state
        const { credentials } = auth
        const token = credentials.token
        const useToken = token ? true : false

        // filtering by status
        const filteredByStatus = []
        filteredByStatus[`${COM.TRACKING_STATUS_PENDING}`] = []
        filteredByStatus[`${COM.TRACKING_STATUS_NO_DATA}`] = []
        filteredByStatus[`${COM.TRACKING_STATUS_LIVE}`] = []

        state.tracking.targets.forEach((i) => filteredByStatus[`${i.status}`].push(i))

        //we have to ignore items with NO_DATA status if we have any items with PENDING status
        const merged = []
        merged.push(...filteredByStatus[`${COM.TRACKING_STATUS_PENDING}`])
        merged.push(...filteredByStatus[`${COM.TRACKING_STATUS_LIVE}`])
        if (filteredByStatus[`${COM.TRACKING_STATUS_PENDING}`].length === 0) merged.push(...filteredByStatus[`${COM.TRACKING_STATUS_NO_DATA}`])

        // create datastructure for the API call
        const targetsArray = merged.map((t) => {             

            const ret = {
                targetId: t.target.id,
                
                fromTimeMicros: t.status === COM.TRACKING_STATUS_PENDING 
                ? COM.DEFAULT_FROM_TIME_MILIS : t.currentPosition
                ? t.currentPosition.timeMicros : COM.DEFAULT_FROM_TIME_MILIS,
                
                asynchronous: t.status !== COM.TRACKING_STATUS_PENDING
            }

            return ret
        })

        //We have to pass the popupTargetId as a query param to the API call
        const { popupTargetId } = tracking
        const languageCode = settings.languageCode
        const JSONTargetsArray = JSON.stringify(targetsArray)
        //console.debug("[SAGA tracking.js] Calling getTargetsPosition with the following parameters: ", credentials, JSONTargetsArray, popupTargetId, languageCode, useToken)

        const { data } = yield call(trackingRepo.getTargetsPosition, credentials, JSONTargetsArray, popupTargetId, languageCode, useToken)
        yield put(trackingActions.updateTargetsSuccess(data))  
        D("API call finished.")
        yield delay(WAIT_BETWEEN_POLLS_MS)
    } catch (e) {
        yield put(errorActions.addError(new ApiError(e, ApiError.MODULE_TRACKING)))
        yield delay(WAIT_BETWEEN_POLL_ERRORS_MS)
    }
    return true
}

function* manager() {
    D("Manager forked.")
    while (true) {

        D("[managerLoop] Waiting for start actions...")
        yield take(actionTypes.TRACKING_POLL_START)
        D("[managerLoop] Start actions received.")
               
        // polling loop
        while (true) {
            D("[pollingLoop] Polling loop is waiting for race to be finished...")
            const { stop, restart } = yield race({
                result: call(updateTargets),
                stop: take(actionTypes.TRACKING_POLL_STOP),
                restart: take(actionTypes.TRACKING_POLL_RESTART), // if the targets are changed, we have to restart the polling
            });
            D("[pollingLoop] Race finished.")

            if (stop) {
                D("[pollinLoop] Stopped. ", stop);
                break; // Exit the inner loop to restart the manager
            }

            if (restart) {
                D("[pollingLoop] Restart.", restart);
                continue; // Continue the polling loop
            }
        }
    }
}


export function* saga() {
    D("Saga started.")
    yield fork(manager)
}