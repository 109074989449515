import { takeEvery, put, select, putResolve } from 'redux-saga/effects'
import * as errorActions from './../actions/error'
import * as authActions from './../actions/auth'
import * as actionTypes from '../actions/actionTypes'
import { ApiError } from '../../other/ApiError'

function* addError(action) {
    const state = yield select()
    const error = action.error
    const errorArray = state.error
    const newItems = [...errorArray.items]

    if (newItems.length >= process.env.REACT_APP_MAX_ERROR_ARRAY_LENGTH) {
        newItems.pop() //drop the last item
    }
    
    newItems.push(action.error)
    newItems.sort((a,b) => b.date.getTime() - a.date.getTime() )

    const exception = error.exception
    const severity = error.severity
    console.debug(`[SAGA] [error.js] [addError] Handling error with severity: ${severity}`, error)

    if (severity && severity > ApiError.SEVERITY_ERROR) {
        console.error("[SAGA] [error.js] There was a fatal error, we are going to sign out the user!", exception)
        yield putResolve(authActions.authSignOutStart())
    }

    // We have to put the new items
    yield putResolve(errorActions.setItems(newItems))
}

function* setAcknowledged(action) {
    const currentState = yield select()
    const error = currentState.error
    const newItems = [...error.items]
    const uuid = action.payload

    const ackAllEnvFlag = process.env.REACT_APP_ACKNOWLEDGE_ALL_ERRORS
    const ackAll = ackAllEnvFlag === 'false' || true

    console.debug("Acknowledge all errors: ", ackAll)

    if (ackAll) {
        newItems.forEach(i => i.acknowledged = true)
    } else {
        const item = newItems.find(i => i.uuid === uuid)
        if (item) {
            item.acknowledged = true
        } else {
            console.error(`ERROR! There is no matching error item for uuid ${uuid}`)
        }
    }

    yield put(errorActions.setItems(newItems))
}

export function* saga() {
    
    //NOTE: BE CAREFUL, we are watching for any action passed!
    yield takeEvery(actionTypes.ERROR_ADD_ERROR, addError)

    yield takeEvery(actionTypes.ERROR_SET_ACKNOWLEDGED, setAcknowledged) 
}