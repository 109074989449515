import storage from 'redux-persist/lib/storage'
import { createTransform } from 'redux-persist'
import { createPlutoLogger, descryptString, encryptString } from '../utilities/Common'
//import { initialState } from '../store/reducers/settings' // Used by a commented-out code block below

const prefix = process.env.REACT_APP_LOCAL_STORAGE_KEY_PREFIX
const s = process.env.REACT_APP_API_URL
const settingsReducerName = "settings"

const D = createPlutoLogger("🗃️ [persistConfig.js] ")

const inboundStateHandler = (inboundState, key) => {
    
    D(`Write to local storage. Persist transform '${settingsReducerName}' state is being saved: `, inboundState, key)
    
    //We have to clone the state, because the mofification of the state causes
    //the object being persistated again!
    const newState = JSON.parse(JSON.stringify(inboundState));

    const p = newState?.autoLoginData?.password
    if (p) {
        D(`Encrypting auto login password: `, p)
        newState.autoLoginData.password = encryptString(p, s)
        D(`Encrypted auto login password: `, newState.autoLoginData.password)
    }

    D(`Persist transform '${settingsReducerName}' state has been saved: `, newState, key)

    return newState
}

const outboundStateHandler = (outboundState, key) => {
    D(`READ from local storage. Persist transform '${settingsReducerName}' state has been persisted: `, outboundState, key)
    
    //We have to clone the state, because the mofification of the state causes
    //the object being persistated again!
    const newState = JSON.parse(JSON.stringify(outboundState));

    const p = newState?.autoLoginData?.password
    if (p) {
        D(`Decrypting auto login password...`)
        newState.autoLoginData.password = descryptString(p, s)
        D(`Decrypted auto login password: `, newState.autoLoginData.password) // Be careful, is is a huge security risk to log the password!
    }

    // Handling the stored buildId
    //
    // The build ID check has been deactivated. I'm leaving the code here for future reference in case it's needed again.
    // It has been deactivated because, during the testing phase, it might not be user-friendly. We frequently release new builds
    // and we want to avoid inconveniencing users by wiping out their stored data with each new build.
    //
    // TODO: This feature was active long ago, so in case of reuse it, it should be checked and tested again.
    /*const isBuildIdStringMatch = buildIdString === outboundState.buildIdString
    if (!isBuildIdStringMatch) {
        //console.debug(`🗃️ Peprsisted buildId doesn't match to id in build info! ${newState.buildIdString} != ${buildIdString}`)
        //console.debug("🗃️ Using the initial state instead of persisted!", initialState)
        return initialState
    }*/

    return newState
}

const transform = createTransform( inboundStateHandler, outboundStateHandler, { whitelist: [settingsReducerName] })

const persistConfig = {
    key: `${prefix}`,
    storage,
    whitelist: [settingsReducerName],
    transforms: [transform]
}

export default persistConfig