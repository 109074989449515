import { useEffect, useState } from "react";

const useOrientationChange = () => {

    const matchMedia = window.matchMedia("(orientation: portrait)")
    const [isPortrait, setIsPortrait] = useState(matchMedia.matches);
  
    useEffect(() => {
      const changeEventName = "change"; 
      const eventHandler = (e) => setIsPortrait(e.matches);
  
      matchMedia.addEventListener(changeEventName, eventHandler);
      return () => {
        matchMedia.removeEventListener(changeEventName, eventHandler);
      };
    }, []);
  
    return isPortrait;
  };
  
  export default useOrientationChange;