// import for i18next
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import { LANGUAGE_FILES_URL as JSON_URL } from "../utilities/Common";

// Our simple fetch function to avoid using an external package for this.
const loadJson = async (lngCode) => {
    const filename = `${lngCode}.json`
    const fileURL = `${JSON_URL}/${filename}`
    const fallbackFileURL = `./i18n/${lngCode}.json`

    console.info(`📚 Loading language file ${filename} from ${fileURL}`)
    
    let response = await fetch(`${fileURL}`);
    if (!response.ok) {
        console.warn(`Cannot load language file from ${fileURL} Trying fallback ${fallbackFileURL}...`)
        await fetch(`${fallbackFileURL}`);
    }
    if (!response.ok) {
        throw new Error(`📚 Failed to fetch ${fileURL}`);
    }
    
    console.info("📚 Language file loaded: ", fileURL)
    return await response.json();
};

export const DEFAULT_LANGUAGE_CODE = "hu"
export const supportedLanguageCodes = ["en", 'hu', 'hr', 'sr', 'cz', 'sk', 'ro']

// We use this mechanism to load the language files because we need to access the language files from the public folder.
// This approach allows us to use the language files in our small translation helper tool. Additionally, it reduces the size of the bundle.
export const init = async () => {

    try {
        const languageCodes = supportedLanguageCodes
        let resources = {}
        try {
            const chunkCreator = async (lngCode) => ({ [lngCode]: {translation: await loadJson(lngCode)}})
            const chunks = await Promise.all(languageCodes.map(chunkCreator))

            // Add all items from the array to the resources object
            resources = chunks.reduce((acc, item) => { return { ...acc, ...item } }, {})
            console.debug("📚 i18n resources: ", resources)
        } catch (e) {
            console.error(`📚 Cannot load language resources! `, e)
        }
        i18n
        .use(initReactI18next)
        .init({
            resources: resources,
            fallbackLng: DEFAULT_LANGUAGE_CODE,
            interpolation: {
                escapeValue: false
            },
            debug: true,
        });

    } catch (error) {
        console.error("📚 Error while loading language files: ", error)
    }
}