// Action Types import
import * as actionTypes from '../actions/actionTypes'
import { BROWSER, buildIdString, update } from '../../utilities/Common.js'

// Initial state
// NOTE: To be exported is important here, because the persist transform use this export as a default return value in case of mismatching build ID-s!
export const initialState = {
    
    buildIdString: buildIdString(),
    uiThemeName: "light",           // String value contains the name of the selected theme
   
    autoLoginData: null,
    trackingTargets: null,
    languageCode: null,
    userId: null,
    forcedAutoLogin: false,

    userSettings: {},
}

const reducer = (state = initialState, action) => {

    switch (action.type) {
        
        case actionTypes.SETTINGS_SET: return update(state, action.payload)
        
        case actionTypes.SETTINGS_UI_THEME_CHANGE: return update(state, {uiThemeName: action.uiThemeName})
        
        case actionTypes.SETTINGS_AUTO_LOGIN_DATA_CHANGE: return update(state, {autoLoginData: action.autoLoginData})

        case actionTypes.SETTINGS_SET_FILTER_PANE_VISIBLE: return update(state, { filterPaneVisible: action.payload})

        case actionTypes.SETTINGS_SET_TRACKING_TARGETS: return update(state, {trackingTargets: action.payload})

        case actionTypes.SETTINGS_SET_SELECTED_BOTTOM_MENU_ITEM: return update(state, {selectedBottomMenuItem: action.payload})

        case actionTypes.SETTINGS_LANGUAGE_CHANGE: return update(state, {languageCode: action.payload})

        case actionTypes.SETTINGS_SET_USER_ID: return update(state, {userId: action.payload})

        case actionTypes.SETTINGS_SET_FORCED_AUTO_LOGIN: return update(state, {forcedAutoLogin: action.payload})

        case actionTypes.SETTINGS_SET_USER_SETTINGS: return update(state, {userSettings: action.payload})

        case actionTypes.SETTINGS_INIT_STATE: return update(state, initialState)

        //We don't want to erease the local storage
        //case actionTypes.AUTH_SIGN_OUT_SUCCESS: return update(state, initialState)

        default: return state
    }
}

export default reducer