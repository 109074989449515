
import { makeStyles } from "@mui/styles";
import Header from "./Header";
import Row from "./Item/Row/Row";
import { createPlutoLogger } from "../../../utilities/Common";

const D = createPlutoLogger("🛣️ [RouteList.js]")

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        //backgroundColor: "red", //just for debugging

        // The following padding makes space for the scrollbar at the right
        paddingRight: ".5rem",
    },
}))

const RouteList = (props) => {

    const { items, selectedItems } = props
    const classes = useStyles()
    
    const isMultipleSelected = (selectedItems && selectedItems.length > 1)
    const itemsForSummary = isMultipleSelected ? selectedItems : items;
    const itemsCount = items.length
   
    // To avoid frozen UI we need to maximize the number of items to render
    // We can render around 200 items without problem, but this settings comes from properties
    const MAX_ITEMS_TO_DISPLAY =  100 //We use 100 as default for performance reasons. TODO: Make this configurable
    const isTooLong = itemsCount > MAX_ITEMS_TO_DISPLAY
    const itemsToDisplay = isTooLong ? items.slice(0, MAX_ITEMS_TO_DISPLAY) : items
    if (isTooLong) {
      D(`[RouteList.js] Too many items to render! Renderable items have been trucated to ${itemsToDisplay.length}`)  
    }
  
    const routes = itemsToDisplay.map( (r, i) => {
      return <Row route={r} key={`route-${i}`} /> //We use useMemo to avoid re-rendering
    })

    return (
      <div className={classes.root}>
      {
        [
          <Header
            key="header"
            isMultipleSelected={isMultipleSelected}
            items={items}
            itemsForSummary={itemsForSummary} 
            itemsToDisplay={itemsToDisplay} />,
            ...routes
        ]
      }
      </div>
    )
  }

  export default RouteList