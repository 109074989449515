import axios from 'axios'
import { createPlutoLogger } from '../../utilities/Common'

const BASE_URL = process.env.REACT_APP_API_URL

const D = createPlutoLogger("[REPO] [auth.js]")

/**
 * Poll the messages
 * @param {object} params - Complex object, mandatory to contain the following properties:
 * @param {string} params.acceptLanguage  - Mandatory parameter for the API, use the current language
 * @param {object} params.credentials - The username and password
 * @param {boolean} params.fromTime - The time from which the messages should be fetched
 */
 export const pollMessages = (params) => {
    D('pollMessages', params)

    //NTOE: The server-side of the API also has these hardcoded values but for clarity we sign these values with XXX here too!
    return axios.get(
        `${BASE_URL}/messages/tracking`, {
            auth: params.credentials,
            headers: {
                'Accept-Language': params.acceptLanguage
            },
            params: {
                fromTime: params.fromTime,
                asynchronous: true, //XXX: hardcoded value! Long-polling is enabled, call duration is 30 seconds
                threadLimit: 10, //XXX: hardcoded value!
                messagesByThreadLimit: 10, //XXX: hardcoded value!
            }
        }
    )
}

/**
 * Get all the messages for a thread
 * @param {object} params - Complex object, mandatory to contain the following properties:
 * @param {string} params.threadId - The ID of the thread
 * @param {string} params.lastTimeMicros - The last time the messages were fetched
 * @param {string} params.credentials - The username and password
 * @param {string} params.acceptLanguage  - Mandatory parameter for the API, use the current language
 */
export const getMessages = (params) => {
    return axios.get(
        `${BASE_URL}/messages`, {
            auth: params.credentials,
            headers: {
                'Accept-Language': params.acceptLanguage
            },
            params: {
                threadId: params.threadId,
                lastTimeMicros: params.lastTimeMicros,
                limit: 10, 
            }
        }
    )
}

/**
 * Get the message threads for the credentials
 * @param {object} credentials - The username and password
 * @param {string} acceptLanguage  - Mandatory parameter for the API, use the current language
 */
export const getThreads = (credentials, acceptLanguage) => {
    return axios.get(
        `${BASE_URL}/messageThreads/recent`, {
            auth: credentials,
            headers: {
                'Accept-Language': acceptLanguage
            },
            params: {
                threadLimit: 10, //TODO: this value should be configurable
                messagesByThreadLimit: 10, //TODO: this value should be configurable
            }
        }
    )
}

/**
 * Get a message thread by the user name
 * @param {object} params - Complex object, mandatory to contain the following properties:
 * @param {string} params.name - The name of the user
 * @param {string} params.credentials - The username and password
 * @param {string} params.acceptLanguage  - Mandatory parameter for the API, use the current language
 */
export const getThreadsByUser = (params) => {
    return axios.get(
        `${BASE_URL}/messageThreads/threadUser`, {
            auth: params.credentials,
            headers: {
                'Accept-Language': params.acceptLanguage
            },
            params: {
                name: params.name,
                messagesByThreadLimit: 10, //TODO: this value should be configurable
            }
        }
    )
}


/**
 * Send a chat message
 * @param {object} params - Complex object, mandatory to contain the following properties:
 * @param {string} params.threadId - The ID of the thread
 * @param {string} params.body - The message body
 * @param {string} params.credentials - The username and password
 * @param {string} params.acceptLanguage  - Mandatory parameter for the API, use the current language
 */
export const sendMessage = (params) => {
    return axios.post(
        `${BASE_URL}/messages/send/${params.threadId}`,
        {
            mimeType: "UTF8_STRING",
            body: params.body,
        },
        {
            headers: {
                'Accept-Language': params.acceptLanguage,
                'Content-Type': 'application/json',
            },
            auth: params.credentials
        }
    )
}