
import { Alert, Grid } from "@mui/material"
import { makeStyles } from "@mui/styles"
import { useTranslation } from "react-i18next"

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        "& *": {
            fontSize: ".75rem",
        }
    },
}))

const Truncated = ({totalCount, displayedCount}) => {

    const classes = useStyles()
    const { t } = useTranslation()

    ////console.debug("[Truncated.js] classes: ", classes)

    return (
        <Grid item xs={12} className={classes.root}>
            <Alert severity="info">
                {t("routes.truncated",{
                    totalCount: totalCount,
                    displayedCount: displayedCount,
                })}
            </Alert>
        </Grid>
    )
}

export default Truncated