// react
import React from 'react'

// redux
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'

// own components
import App from './App'
import { AppControlProvider } from './AppControlProvider'
import { BrowserRouter } from 'react-router-dom'
import { createPlutoLogger } from '../../utilities/Common'

const D = createPlutoLogger("💉 [StateInjector.js]")

const StateInjector = (props) => {
    const languageCode = props.languageCode
    D("Language code:", languageCode)

    const {store, persistor, saga } = props.redux
    D("Params received: Store: ", store, " Persitor: ", persistor, " Saga: ", saga)
    
    return (
        <Provider store={store}>
            <PersistGate loading={"Loading..."} persistor={persistor}>
                <BrowserRouter>
                    <AppControlProvider>
                        <App languageCode={languageCode} />
                    </AppControlProvider>
                </BrowserRouter>
            </PersistGate>
        </Provider>
    )
}

export default StateInjector