import { storeCreated } from './store/actions/root'
import rootSaga from './store/sagas/root'
import initStoreAndSaga from './store/init'    

export const initRedux = async () => {
    const { store, saga, persistor } = initStoreAndSaga()
    try {
        saga.run(rootSaga)
        store.dispatch(storeCreated())
    } catch (e) {
        console.error("Error during initializing the store!", e)
    }

    return { store: store, persistor: persistor, saga: saga }
}