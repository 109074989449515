import { makeStyles } from "@mui/styles";
import { BOTTOM_MENU_HEIGHT, clsx, createPlutoLogger } from "../../utilities/Common";
import useScreenRatio from "./useScreenRatio";

const useStyles = makeStyles((theme) => ({
    resultContainer: {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backdropFilter: "blur(5px)",
        [`-webkit-backdrop-filter`]: "blur(5px)", /* For Safari */ //Under the eclipse (20)
        zIndex: 10,  //This div goes over the map container. Map container is at z-index 10
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    spacer: {
        width: "100%",
        height: "100%",
        position: "relative",
        padding: theme.spacing(5),
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    customSpacer: {
        padding: 0,
    },
    ['@media (max-width: 768px)']: {
        spacer: {
            padding: 0,
        },
    },
    wrapper: {
        width: "100%",
        height: "100%",
        maxWidth: "1024px",
        minWidth: "320px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
}));

const D = createPlutoLogger("[ResultContainer.js]")

const ResultContainer = ((props) => {

    const classes = useStyles()
    const screenRatio = useScreenRatio()

    const spacerClasses = clsx(classes.spacer, {
        [classes.customSpacer]: screenRatio < 0.52, // Apply custom styles when ratio is greater than 0.48
    });

    D("Screen ratio: ", screenRatio)

    return (
        <div className={classes.resultContainer}>
            <div className={spacerClasses}>
                <div className={classes.wrapper}>
                    {props.children}
                </div>
            </div>
        </div>
    )
})

export default ResultContainer