import { TableCell, TableRow, Typography } from '@mui/material';
import React from 'react'


// i18n imports
import { useTranslation } from 'react-i18next';

const Environment = () => {

    const { t } = useTranslation();    
    
    return (
        <>
            <TableRow>
                <TableCell component="th" scope="row" colSpan={2}>
                    <Typography variant="h6" component="h6">
                        {t("systemInfo.environment")}
                    </Typography>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell>{t("systemInfo.NodeEnvironment")}</TableCell>
                <TableCell>{process.env.NODE_ENV}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell>{t("systemInfo.apiUrl")}</TableCell>
                <TableCell>{process.env.REACT_APP_API_URL}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell>{t("systemInfo.osmTileLayerDomain")}</TableCell>
                <TableCell>{process.env.REACT_APP_OSM_TILE_LAYER_DOMAIN}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell>{t("systemInfo.osmTileLayerSuffix")}</TableCell>
                <TableCell>{process.env.REACT_APP_OSM_TILE_LAYER_SUFFIX}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell>{t("systemInfo.googleAnaliticsId")}</TableCell>
                <TableCell>{process.env.REACT_APP_GOOGLE_ANALYTICS_ID}</TableCell>
            </TableRow>
        </>
    )
}

export default Environment