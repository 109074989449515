import * as actionTypes from './actionTypes'

// LIFECYCLE
export const setMounted = (value) => {
    return {
        type: actionTypes.CHAT_SET_MOUNTED,
        payload: value
    }
}

// RECEIVE THREADS & MESSAGES
export const getMessages = (payload) => {
    return {
        type: actionTypes.CHAT_GET_MESSAGES,
        payload: payload,
    }
}

export const getMessagesSuccess = (data) => {
    return {
        type: actionTypes.CHAT_GET_MESSAGES_SUCCESS,
        payload: data
    }
}

export const getThreads = () => {
    return {
        type: actionTypes.CHAT_GET_THREADS,
    }
}

export const getThreadsByUser = (name) => {
    return {
        type: actionTypes.CHAT_GET_THREADS_BY_USER,
        payload: name,
    }
}

export const getThreadsSuccess = (data) => {
    return {
        type: actionTypes.CHAT_GET_THREADS_SUCCESS,
        payload: data
    }
}

// SEND MESSAGE
export const sendMessage = (body) => {
    return {
        type: actionTypes.CHAT_SEND_MESSAGE,
        payload: body
    }
}

export const sendMessageSuccess = () => {
    return {
        type: actionTypes.CHAT_SEND_MESSAGE_SUCCESS,
    }
}


// POLLING
export const pollStart = () => {
    return {
        type: actionTypes.CHAT_POLL_START
    }
}

export const pollStop = () => {
    return {
        type: actionTypes.CHAT_POLL_STOP
    }
}

export const pollSuccess = (data) => {
    return {
        type: actionTypes.CHAT_POLL_SUCCESS,
        payload: data
    }
}


// SETTERS
export const setThreads = (newItems) => {
    return {
        type: actionTypes.CHAT_SET_THREADS,
        payload: newItems
    }
}

export const setServerTimeMicros = (serverTimeMicros) => {
    return {
        type: actionTypes.CHAT_SET_SERVER_TIME_MICROS,
        payload: serverTimeMicros
    }
}

export const setSelectedThreadId = (threadId) => {
    return {
        type: actionTypes.CHAT_SET_SELECTED_THREAD_ID,
        payload: threadId
    }
}

/*export const threadIdSelectedByUser = (threadId) => {
    return {
        type: actionTypes.CHAT_THREAD_ID_SELECTED_BY_USER,
        payload: threadId
    }
}*/

export const setCounters = (counters) => {
    return {
        type: actionTypes.CHAT_SET_COUNTERS,
        payload: counters
    }
}

export const changeFilter = (filter) => {
    return {
        type: actionTypes.CHAT_CHANGE_FILTER,
        payload: filter
    }
}

export const setFilter = (filter) => {
    return {
        type: actionTypes.CHAT_SET_FILTER,
        payload: filter
    }
}

// COUNT
export const countMessages = () => {
    return {
        type: actionTypes.CHAT_COUNT_MESSAGES
    }
}

// PROGRESS
export const setFilteringInProgress = (value) => {
    return {
        type: actionTypes.CHAT_SET_FILTERING_IN_PROGRESS,
        payload: value,
    }
}