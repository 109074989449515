import React, { useRef, useState } from 'react'
import { connect } from 'react-redux'

//actions
import * as authActions from '../../store/actions/auth'

//routes
import * as navigation from './NavigationRoutes.js'

// i18n imports
import { useTranslation } from 'react-i18next'


import {
  ContactPhoneRounded,
  ExitToAppRounded,
  GpsFixedOutlined,
  LocalGasStationRounded,
  MessageRounded,
  MoreHorizRounded,
  PermDeviceInformationRounded,
  PersonRounded,
  SyncAltRounded
} from '@mui/icons-material'


import EnhancedIcon from './EnhancedIcon'
import PopupMenu from './PopupMenu'
import { useLocation, useNavigate } from 'react-router-dom'

import { DIALOG_CONTACTS, DIALOG_PROFILE, useAppControl } from '../App/AppControlProvider.js'
import { BottomNavigation, BottomNavigationAction, Divider, useMediaQuery, useTheme } from '@mui/material'
import { createPlutoLogger, isTokenValid } from '../../utilities/Common.js'

const D = createPlutoLogger("[BottomMenu.js]")

const FILTER_PANE_DEFAULT_CLOSED = false

const BottomMenu = (props) => {

  const { state } = props
  const { auth } = state
  const credentials = auth.credentials
  D("auth: ", auth)

  const isTokenLogin = isTokenValid(credentials.token)
  D("isTokenLogin: ", isTokenLogin)

  const itemsRef = useRef([]);
  const [anchorRef, setAnchorRef] = useState(null);

  const { t } = useTranslation()
  const theme = useTheme();
  const navigate = useNavigate()
  const location = useLocation()

  const { openDialog, toggleFilterPane, closeFilterPane, openFilterPane  } = useAppControl()
  const currentModule = location.pathname

  const openContacts = () => openDialog(DIALOG_CONTACTS)
  const openProfile = () => openDialog(DIALOG_PROFILE)
  const handleLogout = () => props.signOutStart()
  const handleClose = () => setAnchorRef(null)

  // !!! I18N ISSUE !!!
  // The i18n keys are collected from the source using a collector script. This script searches for matches like this: t("foobar")
  // Do not optimize the following code by calling the t() function elsewhere only once. The collector script will not find it!
  const allMenuItems = [
    {
      availableForToken: true,
      icon: <GpsFixedOutlined />,
      label: t("mainMenu.tracking"),
      to: navigation.NAVIGATION_ROUTE_TRACKING,
    },
    {
      icon: <SyncAltRounded />,
      label: t("mainMenu.routes"),
      to: navigation.NAVIGATION_ROUTE_ROUTES
    },
    {
      icon: <MessageRounded />,
      label: t("mainMenu.messages"),
      to: navigation.NAVIGATION_ROUTE_CHAT
    },
    {
      icon: <LocalGasStationRounded />,
      label: t("mainMenu.refuels"),
      to: navigation.NAVIGATION_ROUTE_REFUELS
    },
    {
      availableForToken: true,
      icon: <MoreHorizRounded />,
      label: t("mainMenu.more"),
      popupMenuItems:
      [
        {icon: <ContactPhoneRounded />, label: t("mainMenu.contacts"), to: openContacts },
        {icon: <PersonRounded />, label: t("mainMenu.profile"), to: openProfile },
        {icon: <PermDeviceInformationRounded />, label: t("mainMenu.systemInformation"), to: navigation.NAVIGATION_ROUTE_SYS_INFO },
        <Divider sx= {{
          margin: ".5rem 0",
        }}/>,
        {icon: <ExitToAppRounded />, label: t("mainMenu.logout"), to: handleLogout },
      ]
    },
  ]

  const availableMenuItems = allMenuItems.filter(item => {
    return isTokenLogin ? item.availableForToken : true
  })

  const navigateTo = (to) => {
    const toIsFunction = typeof to === 'function'
    if (toIsFunction) {
      to()
      console.debug("[BottomMenu.js] Handle function click: ", to)
    } else {
      console.debug("[BottomMenu.js] Handle action click: ", to)
      navigate(to)
    }
  }

  const handleClick = (item, ref) => {
    const to = item.to

    if (item.popupMenuItems) {
      D("[handleClick] Main menu item with popup clicked. Setting anchor ref.")
      setAnchorRef(ref)
    } else {
      D("[handleClick] Main menu item clicked. Toggling filter pane and closing popup.")
      handleClose()
      if (location.pathname === to) {
        D("[handleClick] Module will not change. Toggling filter pane.")
        toggleFilterPane()
      } else {
        D("[handleClick] Module will change. Filter plane closed by default settings: ", FILTER_PANE_DEFAULT_CLOSED)
        if (FILTER_PANE_DEFAULT_CLOSED) {
          D("[handleClick] Module will change. Closing filter pane.")
          closeFilterPane()
        } else {
          D("[handleClick] Module will change. Opening filter pane.")
          openFilterPane()
        }
      }
    }
  
    navigateTo(to)
  }

  const handlePopupClick = (item) => {
    const to = item.to

    D("[handlePopupClick] Popup menu item clicked. Setting anchor ref to null and closing filter pane.")
    handleClose()
    closeFilterPane()

    navigateTo(to)
  }

  const isMobile = useMediaQuery('(max-width:480px)')
  const fewMenuItems = availableMenuItems.length < 3

  D("[render] currentModule: ", currentModule, "isMobile: ", isMobile, "fewMenuItems: ", fewMenuItems)
  D("[render] availableMenuItems: ", availableMenuItems)

  return (
      <>
       <BottomNavigation
          showLabels={!isMobile}
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: isMobile || fewMenuItems ? 'center' : 'space-between',
            padding: isMobile ? '0 10px' : '0',
            backgroundColor: "primary.main",
            "& *": {
              color: "white",
            }
          }}
          value={currentModule}>
            {availableMenuItems.map((item, index) =>
                <BottomNavigationAction
                  style={{ minWidth: 0, paddingLeft: 8, paddingRight: 8 }} 
                  value={item.to}
                  key={index}
                  ref={el => itemsRef.current[index] = el}
                  label={isMobile ? null : item.label}
                  icon={<EnhancedIcon icon={item.icon} isSelected={item.id !== currentModule } />}
                  onClick={(event) => handleClick(item, itemsRef.current[index])}
                  sx={{
                    '&.Mui-selected': {
                      '& *': {
                        color: theme.palette.secondary.main,
                      },
                    }
                  }} />
            )}
        </BottomNavigation>
        {availableMenuItems.map((item, index) => {
          if (!item.popupMenuItems) return null
          return (
            <PopupMenu
              onClose={handleClose}
              anchorEl={anchorRef}
              onClick={(event, popupItem) => handlePopupClick(popupItem, null)}
              key={index}
              items={item.popupMenuItems} />
          )
        })}

      </>
)
}


const mapDispatchToProps = dispatch => {
  return {
      signOutStart: () => dispatch(authActions.authSignOutStart()),
  }
}

function mapStateToProps(state) {
  return {
      state: state,
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(BottomMenu)