import { takeEvery, call, select, putResolve } from 'redux-saga/effects'

import * as actions from './../actions/shareTarget'
import * as errorActions from './../actions/error'
import * as actionTypes from '../actions/actionTypes'
import * as shareTargetRepo from '../repository/shareTarget'
import { ApiError } from '../../other/ApiError'
import { createPlutoLogger } from '../../utilities/Common'

const D = createPlutoLogger("🔗 [SAGA] [shareTarget.js]")

function* generateSharableLink(action) {

    // The two timestamps are in milliseconds and pre-handled by UI layer.
    // The date and time have been selected by the user and the start of the day and end of the day are already calculated if necessary.
    const { fromTimeMillis, toTimeMillis, targetId, email, variant } = action

    // The JS Date object is in milliseconds, we need to convert it to microseconds
    // because the server expects the time in microseconds.
    const fromTimeMicros = fromTimeMillis * 1000
    const toTimeMicros = toTimeMillis * 1000

    try {
        const state = yield select()

        const params = {
            targetId: targetId,
            fromTimeMicros: fromTimeMicros,
            toTimeMicros: toTimeMicros,
            emailAddress: email,
            //NODE: type field is necessary otherwise the backend throws exception! 
            type: variant || "tracking" //We use 'variant' instead of 'type' because 'type' is a field of the action object
        }

        const acceptLanguage = state.settings.languageCode
        D('Accept language: ', acceptLanguage)

        const { data } = yield call(shareTargetRepo.generateSharableLink.bind(this, state.auth.credentials, params, acceptLanguage))

        //NOTE: data is an object, we have to use loginToken FROM the object
        yield putResolve(actions.setLoginToken(data.loginToken))
        yield putResolve(actions.generateSuccess())
    
    } catch (e) {
        yield putResolve(actions.generateFail())
        yield putResolve(errorActions.addError(new ApiError(e, ApiError.MODULE_SHARE_TARGET)))

    }
}

export function* saga() {
    yield takeEvery(actionTypes.SHARE_TARGET_GENERATE_START, generateSharableLink)
}