import { useEffect, useState } from "react";
import { Check, FileCopy } from "@mui/icons-material";

import { useTranslation } from 'react-i18next'

import copy from 'copy-to-clipboard'; 

const MESSAGE_TIMEOUT = 1500;
const GOOGLE_MAPS_URL = 'https://www.google.com/maps/search/?api=1&query='

const CopyPosition = ({ position }) => {
    const [copied, setCopied] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        if (copied) {
            const timeout = setTimeout(() => {
                setCopied(false);
            }, MESSAGE_TIMEOUT);
            return () => clearTimeout(timeout);
        }
    })
    
    const copyToClipboard = (evt) => {
        evt.stopPropagation(); // We don't want to trigger the parent's onClick event (and close the popup)
        const googleMapPositionLink = `${GOOGLE_MAPS_URL}${position.latitude},${position.longitude}`
        copy(googleMapPositionLink);
        setCopied(true);
    };

    const isPositionValid = position && position.latitude && position.longitude;

    if (!isPositionValid) {
        return null
    }

    if (!copied) {
        return (
            <FileCopy onClick={copyToClipboard} />
        );
    }
    
    return (
        <>
            <Check />{t("tracking.positionCopied")}
        </>
    )
}

export default CopyPosition