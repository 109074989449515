
import { makeStyles } from "@mui/styles"
import * as COM from "../../../../../utilities/Common"

const useStyles = makeStyles((theme) => ({
    root: {
        padding: ".25rem",
        borderRadius: ".25rem",
        color: "white",
        fontWeight: "bold",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        // add stroke to text
        border: `1px solid ${theme.palette.grey[600]}`,
    },
    primary: {
        backgroundColor: theme.palette.primary.light,
    },
    secondary: {
        backgroundColor: theme.palette.secondary.light,
        color: theme.palette.secondary.contrastText,
    },
}))

const TimeBox = (props) => {
    const { timeText, color } = props
    const classes = useStyles()
    const c = color.toLowerCase()

    const combinedClasses = COM.clsx({
        [classes.root]: true,
        [classes[c]]: true
    })

    return (
        <div className={combinedClasses}>
            {timeText}
        </div>
    )
}

export default TimeBox
