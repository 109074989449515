import React from 'react'

import Target from './Target';
import TargetMenu from '../../TargetMenu/TargetMenu';

import * as COM from '../../../utilities/Common.js'
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    wrapper: {
    },
    
    /*
        TODO element.scrollHeight > element.clientHeight;
        Make the wrapper's paddingRight conditional. If the scrollbar is not visible
        we do not have to use padding, to keep the edges of the elements in column
        Use something like this: element.scrollHeight > element.clientHeight;
        scrollBarIsVisibe: {

        },
    */
    root: {
      width: "100%",
      padding: ".75rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      gap: "0.5rem",
    },
    odd: {
        backgroundColor: theme.palette.grey[200],
    },
    even: {
        backgroundColor: theme.palette.common.white
    },
}))

const Targets = (props) => {

    const classes = useStyles();
    const { items, selectedItems, onClick } = props 
    const showCheckbox = props.showCheckbox
    const showTargetMenu = props.showTargetMenu

    //console.debug("[Targets.js] selectedItems: ", selectedItems, "items: ", items)

    const getSelectedById = (id) => {
        if (selectedItems == null || selectedItems.length < 1) return null 
        for (var i = 0; i < selectedItems.length; i++) {
            const si = selectedItems[i]
            //We have to check the id and the target.id too to work generally for all of the modules.
            //The structure of 'target' and 'targets' are not the same for refuels, routes and tracking.
            if (si.id === id) return si;
        }
        return null
    }

    //console.debug("[Targets.js], Show checkbox: ", showCheckbox)

    return items.map((item, index) => {
        
        const selected = getSelectedById(item.id)
        const isSelected = !(selected == null)
        const isEven = index % 2 === 0

        const innerDivClasses = COM.clsx({
            [classes.root]: true,
            [classes.odd]: !isEven,
            [classes.even]: isEven,
          })

        
        //console.debug("[Targets.js] item: ", item, "selected: ", selected, "isSelected: ", isSelected)
 
        const isTargetMenuNecessary = 
        showTargetMenu &&
        selected &&
        selected.status === COM.TRACKING_STATUS_LIVE

        return (
            <div key={index} className={classes.wrapper}>
                <div className={innerDivClasses}>
                    <Target
                        item={item}
                        selected={isSelected}
                        onClick={() => onClick(item)}
                        showCheckbox={showCheckbox} />
                    {isTargetMenuNecessary ? <TargetMenu item={selected} /> : null}
                </div>
            </div>
        )

    })
}

export default Targets