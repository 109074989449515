import React from 'react'


// i18n imports
import { useTranslation } from 'react-i18next'

import BuildId from '../BuildId/BuildId'
import { TableCell, TableRow, Typography } from '@mui/material';

const Application = () => {

    const { t } = useTranslation();    
    
    return (
        <>
            <TableRow>
                <TableCell component="th" scope="row" colSpan={2}>
                    <Typography variant="h6" component="h6">
                        {t("systemInfo.application")}
                    </Typography>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell>{t("systemInfo.packageName")}</TableCell>
                <TableCell>{process.env.REACT_APP_NPM_PACKAGE_NAME}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell>{t("systemInfo.packageVersion")}</TableCell>
                <TableCell>{process.env.REACT_APP_NPM_PACKAGE_VERSION}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell>{t("systemInfo.buildId")}</TableCell>
                <TableCell><strong><BuildId /></strong> (<BuildId long />)</TableCell>
            </TableRow>
        </>
    )
}

export default Application