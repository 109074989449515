// React imports
import React from 'react'

import { makeStyles } from '@mui/styles'
import { Link } from '@mui/material'

const useStyles = makeStyles( theme => (
    {
        root: {
            fontSize: ".75rem",
            color: theme.palette.grey[500],
            paddingLeft: ".5rem",
        }
    }
))

const Copyright = ( props => {
    
    const classes = useStyles()

    return (
        <div className={classes.root}>
            &copy;&nbsp;<Link color="inherit" href="https://itrack.hu/">iData Kft.</Link>&nbsp;{new Date().getFullYear()}
        </div>
    )
})

export default Copyright