import { connect } from 'react-redux'
import { ANDROID_EXOSKELETON, IOS_EXOSKELETON } from '../../utilities/Common'
import useOrientationChange from './useOrientationChange'

const ExoskeletonOrientation = (props) => {

    const { showOnPortrait } = props
    const { showOnLandscape } = props
    
    // Redux
    const { device } = props.state
    const { presenter } = device
    const isExoskeleton = (presenter === IOS_EXOSKELETON || presenter === ANDROID_EXOSKELETON)
    const isBrowser = !isExoskeleton
    
    // orientation
    const isPortrait = useOrientationChange() 
    const showOnCurrentOrientation = isPortrait ? showOnPortrait : showOnLandscape

    if (isBrowser) return props.children

    if (!showOnCurrentOrientation) return null

    return props.children
}

const mapStateToProps = (state) => {
    return {
        state: state
    }
}

export default connect(mapStateToProps)(ExoskeletonOrientation)
