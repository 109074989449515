import { makeStyles } from '@mui/styles'
import * as COM from '../../../utilities/Common.js'

const useStyles = makeStyles((theme) => ({
    root: {
        //use a gray color as a border
        borderColor: theme.palette.grey[600],
        borderRadius: ".1rem",
        border: "1px solid",
        width: "1rem",
        height: "1rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
    },
    selected: {
        color: theme.palette.secondary.main,
        borderColor: theme.palette.secondary.main,
        "& div": {
            backgroundColor: theme.palette.secondary.main,
            width: ".5rem",
            height: ".5rem",
        }
    }
}))

const PlutoCheckBox = (props) => {
    const { selected } = props
    const classes = useStyles()

    const conditionalClasses = COM.clsx({
        [classes.root]: true,
        [classes.selected]: selected
    })

    return (
        <div className={conditionalClasses}>
            {selected ? <div></div> : null}
        </div>
    )
}

export default PlutoCheckBox

//showCheckbox === true ? selected ? <CheckBox /> : <CheckBoxOutlineBlank /> : null