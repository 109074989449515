// React imports
import React from 'react'
import { connect } from 'react-redux'
import * as systemInfoActions from '../../store/actions/SystemInfo.js'

// i18n imports
import { useTranslation } from 'react-i18next';

import Application from './Application'
import Environment from './Environment'
import DeviceInfo from './DeviceInfo'
import Language from './Language'
import Packages from './Packages.js';

import { makeStyles } from '@mui/styles';
import { Paper, Table, TableBody, TableContainer, Typography } from '@mui/material';
import ErrorList from './ErrorList.js';
import { Close } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        height: `100%`,
        display: "flex",
        flexDirection: "column",
        backgroundColor: theme.palette.background.paper,
        borderRadius: theme.shape.borderRadius,
        boxShadow: theme.shadows[5],
    },
    header: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        flexDirection: "row",
        padding: theme.spacing(2),
    },
}));

const SystemInfo = (props) => {

    const { t } = useTranslation()
    const classes = useStyles()
    const navigate = useNavigate()

    const handleClose = () => {
        navigate("/")
    }

    return(
        <div className={classes.root}>
            <div className={classes.header}>
                <Typography variant="h4" component="h4">
                    {t("systemInfo.title")}
                </Typography>
                <Close onClick={handleClose} />
            </div>
            <TableContainer component={Paper}>
                <Table aria-label="System information">
                    <TableBody>
                        <ErrorList />
                        <Application />
                        <Environment />
                        <DeviceInfo />
                        <Language />
                        <Packages />
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
      state: state
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setMounted: (payload) => dispatch(systemInfoActions.setMounted(payload)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SystemInfo)