
import { makeStyles } from "@mui/styles"
import Event from "./Event"

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    }
}))

const Events = ({events}) => {
    
    const classes = useStyles()
   
    return (
        <div className={classes.root}>
            {events.map( (e, i) => <Event event={e} index={i} />)}
        </div>
    )
}

export default Events