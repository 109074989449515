import { spawn } from 'redux-saga/effects'

import { saga as auth } from './auth'
import { saga as authToken } from './authToken'
import { saga as refuels } from './refuels'
import { saga as route } from './routes'
import { saga as settings } from './settings'
import { saga as device } from './device'
import { saga as error } from './error'

// chat
import { saga as chatMessages } from './chat/chatMessages'
import { saga as chatThreads } from './chat/chatThreads'
import { saga as chatMessagePolling } from './chat/chatMessagePolling'
import { saga as chatFilter } from './chat/chatFilter'

// targets
import { saga as targets } from './targets/targets'
import { saga as shareTarget } from './shareTarget'

//tracking
import { saga as tracking } from './tracking/tracking'
import { saga as trackingPolling } from './tracking/trackingPolling'
import { saga as trackingAuth } from './tracking/trackingAuth'
import { saga as trackingReload } from './tracking/trackingReload'

// debugging & testing
//import { saga as dummy } from './dummy'

const modules = [
    // auth
    auth,
    authToken,

    refuels,
    route,
    settings,
    device,
    error,

    // chat
    chatMessages,
    chatThreads,
    chatMessagePolling,
    chatFilter,

    // targets
    shareTarget,
    targets,
    //targetsAuth,

    // tracking
    tracking,
    trackingPolling,
    trackingAuth,
    trackingReload,
    
    // testing
    //dummy,    //just for debugging
]

function* rootSaga() {
    for(let i = 0; i < modules.length; i++) {
        yield spawn(modules[i])
    }
}

export default rootSaga