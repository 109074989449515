import { buffers } from 'redux-saga'
import { actionChannel, take, takeEvery, putResolve, call, select, fork } from 'redux-saga/effects'

import * as actionTypes from '../../actions/actionTypes'
import * as errorActions from '../../actions/error'
import * as targetActions from '../../actions/targets'
import * as targetRepo from '../../repository/targets'

import * as COM from '../../../utilities/Common'
import { ApiError } from '../../../other/ApiError'

const TARGETS_LIMIT = process.env.REACT_APP_TARGETS_LIMIT || 30

const D = COM.createPlutoLogger("🎯 [SAGA] [Targets.js]")

function* fetchCount(action) {

    try {

        const { auth, settings } = yield select()
        const { filter } = action
        const { credentials } = auth
        const token = credentials.token
        const isTokenValid = COM.isTokenValid(token)

        //Getting the count of the items for the specified filter
        const { data } = yield call(targetRepo.getTargetCount,
        {
            credentials: credentials,
            filter: filter,
            acceptLanguage: settings.languageCode,
            useToken: isTokenValid,
        })
        //console.debug(`Get target count finished: ${data}`)

        return data

    } catch (e) {
        yield putResolve(errorActions.addError(new ApiError(e, ApiError.MODULE_TARGETS)))
    }
}

function* fetchItems(action) {

    try {
        
        const { auth, settings } = yield select()
        const { offset, filter } = action
        const { credentials } = auth
        const token = credentials?.token
        const isTokenValid = COM.isTokenValid(token)
        const limit = TARGETS_LIMIT
       
        //Getting the items by using the specified filter
        const result = yield call(targetRepo.getTargets,
        {
            credentials: credentials,
            offset: offset,
            filter: filter,
            limit: limit,
            acceptLanguage: settings.languageCode,
            useToken: isTokenValid,
        })
        const items = result.data

        //console.debug(`Get targets finished: ${items.length}`, items)
        
        return {items: items, offset: offset, filter: filter}

    } catch (e) {
        yield putResolve(errorActions.addError(new ApiError(e, ApiError.MODULE_TARGETS)))
    }
}


function* authSuccess() {
    
    const { targets } = yield select()

    const initialOffset = targets.offset
    const initialFilter = targets.filter
    
    yield putResolve(targetActions.setInProgress(true))
    const count = yield call(fetchCount, {filter: initialFilter})
    const fetched = yield call(fetchItems, {offset: initialOffset, filter: initialFilter})
    
    try {
        const {items, offset, filter} = fetched
        //console.debug("[SAGA] [Targets.js] [authSuccess] Items: ", items.length, " Offset: ", offset, " Filter: ", filter)
        yield putResolve(targetActions.setCount(count))
        yield putResolve(targetActions.set(items, offset, filter))
    } catch (e) {
        yield putResolve(errorActions.addError(new ApiError(e, ApiError.MODULE_TARGETS)))
        console.warn("[SAGA] [Targets.js] [authSuccess] Error during fetching items!", e)
    }

    yield putResolve(targetActions.setInProgress(false))
}

function* next() {

    const { targets } = yield select()

    const currentOffset = targets.offset
    const currentFilter = targets.filter
    const currentItems = targets.items

    const newOffset = currentOffset + TARGETS_LIMIT

    // In case of an exception inside the fetchItems function, the return value is undefined
    // and this will cause an exception when this return value will be destructured.
    try {
        const { items, offset, filter } = yield fetchItems({offset: newOffset, filter: currentFilter})
        const newItems = [...currentItems, ...items]     
        yield putResolve(targetActions.set(newItems, offset, filter))
    } catch (e) {
        yield putResolve(errorActions.addError(new ApiError(e, ApiError.MODULE_TARGETS)))
        console.warn("[SAGA] [Targets.js] [next] Error during fetching items!", e)
    }
}

function* setInputFilter() {

    const { targets } = yield select()
    const { inputFilter } = targets

    const newOffset = 0

    // We need a try here because the fechCount and fetchItems could throw an exception but these
    // are handled inside the functions. In case of exception inside the fetchCount function, the return value is undefined
    // and this will cause an exception when this retaurn value will be destructured.
    try {
        const count = yield call(fetchCount, {filter: inputFilter})
        const { items, offset, filter } = yield call(fetchItems, {offset: newOffset, filter: inputFilter})
        
        yield putResolve(targetActions.setCount(count))
        yield putResolve(targetActions.set(items, offset, filter))

    } catch (e) {
        console.error("[SAGA] [Targets.js] [setInputFilter] Error during handling fetch calls.", e)
    }
}

function* clearInputFilter() {
    yield putResolve(targetActions.setInputFilter(""))
}

function* watchSetInputFilter() {

    //We are useing a sliding buffer with capacity 1. This means, in case of new event the older is dropped and
    //will be replaced with the newer. This means we could call the server always by useing the curent filter
    const channel = yield actionChannel(actionTypes.TARGETS_SET_INPUT_FILTER, buffers.sliding(1))
    
    while (true) {
      yield take(channel)
      yield call(setInputFilter)
    }
}

function* autocompleteInProgressOn() {
    yield putResolve(targetActions.setAutocompleteInProgress(true)) 
}

function* autocompleteInProgressOff() {
    yield putResolve(targetActions.setAutocompleteInProgress(false)) 
}

export function* saga() {
    // we are waiting for the rehydration to be finished
    D("Waiting for rehydration...")
    yield take("persist/REHYDRATE")
    D("Store rehydrated event received.")

    yield fork(watchSetInputFilter)

    yield takeEvery(actionTypes.AUTH_SUCCESS, authSuccess)
    yield takeEvery(actionTypes.TARGETS_CLEAR_INPUT_FILTER, clearInputFilter)
    yield takeEvery(actionTypes.TARGETS_NEXT, next)

    yield takeEvery(actionTypes.TARGETS_SET_INPUT_FILTER, autocompleteInProgressOn)
    yield takeEvery(actionTypes.TARGETS_SET, autocompleteInProgressOff)
}
