import React from 'react'

// i18n imports
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@mui/styles'
import { TableCell, TableRow } from '@mui/material'


const useStyles = makeStyles(theme => ({
    keyValueHeaderRow: {
       backgroundColor: `${theme.palette.grey[200]}`,
       textDecoration: "underline",
    },
    keyCell: {
        fontStyle: 'italic',
    },
    valueCell: {
        fontStyle: 'italic',
    }
}))


const KeyValueTable = ({obj}) => {

    const { t } = useTranslation()
    const classes = useStyles();
    
    if (!obj) return null // If not object presented nothing to show
    const objectKeys = Object.keys(obj)
    
    if (!objectKeys && objectKeys.length === 0) return null

    return (
        <>
            <TableRow className={classes.keyValueHeaderRow}>
                <TableCell>{t("systemInfo.key")}:</TableCell>
                <TableCell>{t("syetemInfo.value")}:</TableCell>
            </TableRow>
            {objectKeys.map( (aKey, index) => (
                <TableRow key={index}>
                    <TableCell className={classes.keyCell}>{aKey}</TableCell>
                    <TableCell className={classes.valueCell}>{obj[aKey]}</TableCell>
                </TableRow>
            ))}
        </>
    )
}

export default KeyValueTable