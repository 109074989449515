const FlagSvg = (props) => {

    const bodyColor = props.bodyColor || "black"
    const rectColor = props.rectColor || "white"
    
    return (
            <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" version="1.1">
            <g fill={bodyColor}> 
                <rect x="4" y="3" width="15" height="11"></rect>
                <rect x="4" y="14" width="2" height="7"></rect>
            </g>
            <g fill={rectColor}>
                <rect x="9" y="4" width="3" height="3"></rect>
                <rect x="15" y="4" width="3" height="3"></rect>
                <rect x="6" y="7" width="3" height="3"></rect>
                <rect x="12" y="7" width="3" height="3"></rect>
                <rect x="9" y="10" width="3" height="3"></rect>
                <rect x="15" y="10" width="3" height="3"></rect>
            </g>
        </svg>
    )
}

export default FlagSvg

/*
<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" version="1.1">
            <g fill={bodyColor}> 
                <rect x="4" y="3" width="15" height="11"></rect>
                <rect x="4" y="14" width="2" height="7"></rect>
            </g>
            <g fill={rectColor}>
                <rect x="9" y="4" width="3" height="3"></rect>
                <rect x="15" y="4" width="3" height="3"></rect>
                <rect x="6" y="7" width="3" height="3"></rect>
                <rect x="12" y="7" width="3" height="3"></rect>
                <rect x="9" y="10" width="3" height="3"></rect>
                <rect x="15" y="10" width="3" height="3"></rect>
            </g>
        </svg>*/