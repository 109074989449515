import { takeEvery, put, call, select, putResolve, take } from 'redux-saga/effects'

import * as routesActions from '../actions/routes'
import * as errorActions from '../actions/error'

import * as actionTypes from '../actions/actionTypes'
import * as routesRepo from '../../store/repository/routes'

import * as COM from '../../utilities/Common'

import { ApiError } from '../../other/ApiError'

import L from 'leaflet'

const D = COM.createPlutoLogger("🛣️ [REPOSITORY] [routes.js]")

function* fetchRoutes(action) {

    try {
        const state = yield select()
        
        //We are going to create the proper 'from' and 'to' time micros for the server
        const processedFromTimeMicros = COM.createStartOfDayServerTimeMicrosFromDate(action.fromTime)
        const processedToTimeMicros = COM.createEndOfDayServerTimeMicrosFromDate(action.toTime)
        D(`Fetching routes for targetId: ${action.targetId} from ${processedFromTimeMicros} to ${processedToTimeMicros}`)
        
        const acceptLanguage = state.settings.languageCode
        D('Accept language: ', acceptLanguage)
        
        const params = {
            acceptLanguage: acceptLanguage,
            credentials: state.auth.credentials,
            targetId: action.targetId,
            fromTime: processedFromTimeMicros,
            toTime: processedToTimeMicros,
        }

        const { data } = yield call(routesRepo.getRoutes.bind(this, params))
        
        //D("Fetched routes: ", data)

        for (var i = 0; i < data.length; i++) {
            const item = data[i]
            item.targetId = action.targetId
            item.positions = []
            
            
            //[HACK]
            //XXX: PLUTO-1230
            //XXX: The server returns invalid start positions for some routes.
            //XXX: https://jira.idata.hu/browse/PLUTO-1230
            //XXX:
            //XXX: After this function the route fetch success action will be dispatched.
            //XXX: We have to flag this route as invalid if the start position is not valid.
            //XXX: The bound creator will not contain this route in the calculation to avoid bouncing of the map.
            //XXX: After we have fetched the positions of this route, we could use a valid start position and remove the flag.
            //XXX: This bug definitely should be fixed on the server side!
            if (item.startPosition.longitude === 0 && item.startPosition.latitude === 0) {
                item.isInvalidStartPosition = true
                //D(`Invalid start position for route ${item}. It will be marked as invalid until the position is fetched from the server.`);
            }
            //[/HACK]

            ////D("[SAGA] [routes.js] Route item: ", item)
        }

        yield put(routesActions.routesFetchSucces(data))
    
    } catch (e) {
        yield putResolve(routesActions.routesFetchFail())
        yield putResolve(errorActions.addError(new ApiError(e, ApiError.MODULE_ROUTES)))
    }
}

function* fetchPositions(action) {

    const state = yield select()
    const items = state.routes.items
    const targetId = action.targetId
    const fromTime = action.fromTime

    try {

        const params = {
            acceptLanguage: state.settings.acceptLanguage,
            credentials: state.auth.credentials,
            targetId: targetId,
            fromTime: fromTime,
            toTime: action.toTime,
        }
        //D("[SAGA] [routes.js] Fetching positions for route with params: ", params)
        const { data } = yield call(routesRepo.getPositions.bind(this, params))

        yield put(routesActions.positionsFetchSucces(data, fromTime))
        //D("[SAGA] [routes.js] Positions fetch success! Data: ", data, "From time: ", fromTime)
    
    } catch (e) {
        updateItems(items, false)
        yield setPositionsFetchInProgress(fromTime, false)
        yield putResolve(errorActions.addError(new ApiError(e, ApiError.MODULE_ROUTES)))
    }
}

function updateItems(items, checked) {
    items.forEach(itm => {
        itm.checked = checked
        itm.markers = null
        itm.positions = []
    })
    return items
} 

const deepItemsCopy = (items) => {

    const copiedItems = [...items]
    for (var i = 0; i < items.length; i++) {
        copiedItems[i].events = [...items[i].events]
    }

    return copiedItems
}

function* handleToggleChecked(action) {
    
    const timeMicros = action.payload

    const currentState = yield select()
    const multiselect = currentState.routes.multiselect
 
    const items = deepItemsCopy(currentState.routes.items)
    const itm = COM.getRouteItemByStartPositionTimeMicros(items, timeMicros)

    D("[handleToggleChecked] items: ", items)
    D("[handleToggleChecked] Item to toggle: ", itm)
    D("[handleToggleChecked] Item 'checked' before toggle: ", itm.checked)


    // Remove item if it is allready checked
    // NOTE: itm is referenced from items, so we can modify it directly!
    if (itm.checked) {
        D("[handleToggleChecked] Removing item from the list!")
        itm.checked = false
        itm.positions = []
        itm.markers = null
    } else {
        // There is a switch between multiselect and single select mode
        // In single select mode we have to clear all selected items
        if (!multiselect) {
            D("[handleToggleChecked] Multiselect is NOT allowed! Sections have been cleared.")
            updateItems(items, false)
        }
        D("[handleToggleChecked] Adding item to the list!")
        itm.checked = true
        itm.inProgress = true
        yield putResolve(routesActions.positionsFetchStart(
            itm.targetId,
            itm.startPosition.timeMicros,
            itm.stopPosition.timeMicros))
    }

    /*

    Original handling. I keep here for reference in case of rollback.

    /*itm.checked = itm.checked ? false : true
    //D("[handleToggleChecked] Item checked after toggle: ", itm.checked)

    if (!multiselect) {    
        updateItems(items, false)
    }*/
       
    /*if (!itm.checked) {
        itm.positions = []
        itm.markers = null
        
    } else {
        itm.inProgress = true
        yield putResolve(routesActions.positionsFetchStart(
            itm.targetId,
            itm.startPosition.timeMicros,
            itm.stopPosition.timeMicros))
    }
            
    */

    yield put(routesActions.setItems(items))
}

function* handleSetMultiselect(action) {
    
    const currentState = yield select()
    const multiselect = currentState.routes.multiselect
    const items = [...currentState.routes.items]

    // we have to clear all selected if multiselect is false
    if (!multiselect) {
        updateItems(items, false)
        //D("setMultiselect: Multiselect is NOT allowed! Sections have been cleared.")
    } else {
        //D("setMultiselect: Multiselect is allowed!")
    }

    yield put(routesActions.setItems(items))
}

function* handleClearSelectedSections() {
    const currentState = yield select()
    const items = [...currentState.routes.items]
    
    updateItems(items, false)

    yield put(routesActions.setItems(items))
}

function* handleClearDateRange() {
    const d = new Date()
    yield putResolve(routesActions.setDateRangeFrom(d))
    yield putResolve(routesActions.setDateRangeTo(d))
}

function* fetchIfNecessary() {

    const currentState = yield select()
    const routes = currentState.routes
    const targetId = routes.target ? routes.target.id : null //To avoid to read propery on null!
    const fromTime = routes.dateRangeFrom
    const toTime = routes.dateRangeTo

    D("Fetch if necessary! TargetId: ", targetId, "From time: ", fromTime, "To time: ", toTime)

    if (targetId) yield putResolve(routesActions.routesFetchStart(targetId, fromTime, toTime))
}

function* setPositionsFetchInProgress(fromTime, value) {

    const { routes } = yield select()
    const newItems = JSON.parse(JSON.stringify(routes.items))
    
    const items = COM.getRouteItemByStartPositionTimeMicros(newItems, fromTime)
    items.inProgress = value

    yield putResolve(routesActions.setItems(newItems))
}

function* handlePositionsFetchStart(action) {
    yield setPositionsFetchInProgress(action.fromTime, true)
}

function* handlePositionsFetchSuccess(action) {

    try {
        const { routes, map } = yield select()
        const { items } = routes
        const { data, fromTime } = action 

        D("Positions fetch success! Data: ", data, "From time: ", fromTime)
        
        const newItems = [...items] 
        const itm = COM.getRouteItemByStartPositionTimeMicros(newItems, fromTime)
        itm.inProgress = false
        itm.markers = data.markers
        itm.positions = data.positions
    
        itm.latLngPositions = data.positions.map( p => [p.latitude, p.longitude])

        //[HACK]
        //XXX: PLUTO-1230
        //XXX: If the tracked wehicle has no GPS data the positions array can contain two zero values!
        //XXX: The 0,0 coordinates are located somewhere in the Atlantic Ocean in the Gulf of Guinea, so we have to remove these positions!
        //itm.positions = itm.positions.filter( p => p.latitude !== 0 && p.longitude !== 0)
        if (itm.isInvalidStartPosition) {
            //D("Invalid start position! Resetting start position to the first position in the positions array!")
            
            //We have to reset the start position to the first position in the positions array!
            //But we have to keep the timeMicros value, otherwise the start time of the route will be changed!
            itm.startPosition.longitude = itm.positions[0].longitude
            itm.startPosition.latitude = itm.positions[0].latitude
            itm.isInvalidStartPosition = false
            //D("New start position: ", itm.startPosition)
        }
        //[/HACK]

        const positionArray = data.positions
        //D(`Positions fetched for route ${itm}. Position array: `, positionArray)
        
        const sections = []
        let totalRouteLength = 0;

        //create route sections
        for(let i = 1; i < positionArray.length; i++) {
            
            const p1 = COM.toLatLngArray(positionArray[i - 1])
            const p2 = COM.toLatLngArray(positionArray[i])

            D("[SAGA] [routes.js] map: ", map)
            D("[SAGA] [routes.js] Calculating route section between points: ", p1, p2)
            
            // TODO: This is a quick patch to fix an issue, so a more sophisticated solution is needed.
            // If you have time the whole data structure should be refactored to avoid this kind of patch.
            const pa1 = positionArray[i - 1]
            const pa2 = positionArray[i] 

            // Leaflet map object created to calculate the distance between two points
            const latLng1 = new L.LatLng(pa1.latitude, pa1.longitude)
            const latLng2 = new L.LatLng(pa2.latitude, pa2.longitude)
            const distance = latLng1.distanceTo(latLng2)

            // We use the original p1 and p2 to calculate the angle (the midPoint helper accepts array instead of LatLng object)
            const middle = COM.midPoint(p1, p2)
            const section = {
                index: i - 1, //To start from 0
                angle: COM.calcAngle(p1, p2, -1),
                length: distance,
                distanceFromStart: totalRouteLength,
                midPointDistanceFromStart: totalRouteLength + (distance / 2),
                endPointDistanceFromStart: totalRouteLength + distance,
                midPoint: middle,
            }

            totalRouteLength += distance
            sections.push(section);
        }
        ////D(`Total count of route sections: ${sections.length}`)
        ////D(`Total route length: ${totalRouteLength}m`)

        itm.sections = sections
        itm.totalRouteLength = totalRouteLength

        //D("Route items: ", newItems)

        yield putResolve(routesActions.setItems(newItems))
    } catch (e) {
        console.error("Error in handlePositionsFetchSuccess: ", e)
        yield putResolve(errorActions.addError(new ApiError(e, ApiError.MODULE_ROUTES)))
    }
}

function* itemCheckedByUser(action) {
    const { payload } = action
    yield putResolve(routesActions.toggleChecked(payload))
}

function* handleSetItems(action) {
    yield putResolve(routesActions.createBounds())
}

export function* saga() {
    yield takeEvery(actionTypes.ROUTES_SET_ITEMS, handleSetItems)

    yield takeEvery(actionTypes.ROUTES_FETCH_START, fetchRoutes)

    yield takeEvery(actionTypes.ROUTES_POSITIONS_FETCH_START, fetchPositions)
    yield takeEvery(actionTypes.ROUTES_POSITIONS_FETCH_START, handlePositionsFetchStart)
    yield takeEvery(actionTypes.ROUTES_POSITIONS_FETCH_SUCCESS, handlePositionsFetchSuccess)

    yield takeEvery(actionTypes.ROUTES_ITEM_CHECKED_BY_USER, itemCheckedByUser)
    yield takeEvery(actionTypes.ROUTES_TOGGLE_CHECKED, handleToggleChecked)
    yield takeEvery(actionTypes.ROUTES_SET_MULTISELECT, handleSetMultiselect)

    yield takeEvery(actionTypes.ROUTES_CLEAR_SELECTED_SECTIONS, handleClearSelectedSections)

    yield takeEvery(actionTypes.ROUTES_CLEAR_DATE_RANGE, handleClearDateRange)
   

    yield takeEvery([
        actionTypes.ROUTES_SET_DATE_RANGE,
        actionTypes.ROUTES_ADD_TARGET,
    ], fetchIfNecessary)
}