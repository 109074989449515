// Redux
import { combineReducers } from 'redux'

// Reducer imports
import errorReducer from './error'
import mapEventsReducer from './mapEvents'
import authReducer from './auth'
import settingsReducer from './settings'
import refuelsReducer from './refuels'
import targetsReducer from './targets'
import deviceReducer from './device'
import trackingReducer from './tracking'
import routesReducer from './routes'
import shareTargetReduced from './shareTarget'
import mapReducer from './map'
import chatReducer from './chat'
import navigationReducer from './navigation'
import debugReducer from './debug'
import systemInfoReduces from './SystemInfo'

// Combine reducers
const rootReducer = combineReducers({
    error: errorReducer,
    auth: authReducer,
    settings: settingsReducer,
    refuels: refuelsReducer,
    targets: targetsReducer,
    device: deviceReducer,
    tracking: trackingReducer,
    routes: routesReducer,
    shareTarget: shareTargetReduced,
    map: mapReducer,
    mapEvents: mapEventsReducer,
    chat: chatReducer,
    navigation: navigationReducer,
    debug: debugReducer,
    systemInfo: systemInfoReduces,
})

export default rootReducer