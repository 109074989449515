// Basic imports
import React from 'react'
import { connect } from 'react-redux'

// Own components
import RefuelsListHeader from './RefuelsListHeader'
import RefuelsNoResultHeader from './RefuelsNoResultHeader'
import RefuelsNoSelectedTarget from './RefuelsNoSelectedTarget'
import { makeStyles } from '@mui/styles'
import { Grid } from '@mui/material'

const useStyles = makeStyles((theme) => ({
  grid: {
    borderBottom: `solid 1px ${theme.palette.grey[500]}`,
  },
}))

const RefuelsHeader = (props) => {

  const classes = useStyles()

  //XXX: !!! MISLEADING NAMES !!!
  //XXX: Nameing is misleading, be carefull!
  //XXX: The state contains a 'refuels' object.
  //XXX: The refuels object contains a 'refuels' list.
  //XXX: We use the refuels list INSIDE the refuels object to iterate
  const refuelsState = props.state.refuels
  const refuelsList = refuelsState.refuels
  const { target } = refuelsState

  const noSelectedTarget = target ? false : true
  //console.debug("RefuelsHeader: noSelectedTarget", noSelectedTarget, target)
  if (noSelectedTarget) return <RefuelsNoSelectedTarget />;

  const noResult = (!refuelsList || refuelsList.length < 1)
  //console.debug("[RefuelsHeader.js]: noResult: ", noResult, "refuelsList: ", refuelsList)
  
  return (
      <Grid container className={classes.grid}>
          {noResult 
            ? <RefuelsNoResultHeader />
            : <RefuelsListHeader />
          }
      </Grid>
  );

}
const mapDispatchToProps = dispatch => {
    return {}
}
  
const mapStateToProps = state => {
    return {
        state: state,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RefuelsHeader)