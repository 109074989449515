/*export const authenticateFromUrl = (onAuth) => {
    const { search } = window.location
    const params = new URLSearchParams(search)
    const token = params.get('t')

    console.log('[authenticateFromUrl.js] Token param:', token)

    if (token) {
        console.log('[authenticateFromUrl.js] Token found in the URL, trying to authenticate...', token)
        onAuth({ 
            token: token
        })
    }
  }*/

import { createPlutoLogger } from "../../utilities/Common"
import * as actionTypes from "../actions/actionTypes"
import * as authActions from "../actions/auth"
import { putResolve, select, take } from "redux-saga/effects"

const D = createPlutoLogger("🔐 [SAGA] [tokenAuth.js]")

export function* checkToken() {
    const { search } = window.location
    const params = new URLSearchParams(search)
    const token = params.get('t')

    if (token) {
        D("Token found in the URL: ", token)
        //Are we authenticated already?
        const { auth } = yield select()
        D("Current auth state: ", auth)

        const { credentials } = auth
        D("Current credentials: ", credentials)

        const userId = credentials && credentials.id
        if (userId) {
            D("Already authenticated. User ID: ", userId)
            return
        }
        
        D("Not authenticated yet. Authenticating with token...", token)
        yield putResolve(authActions.authStart({ token: token }))
    }
}

export function* saga () {
    D("Forked.")
    yield take("persist/REHYDRATE")
    D("Rehydrated.")
    
    D("Waiting for auth check or sign in...")
    yield take([
        actionTypes.AUTH_SIGN_IN_NECESSARY,
        actionTypes.AUTH_SUCCESS,
    ]);

    D("Checking token...")
    yield checkToken()
}