// Action Types import
import * as actionTypes from '../actions/actionTypes'

import { update } from '../../utilities/Common.js'

// Initial state
const initialState = {
    mounted: false,
}

const reducer = (state = initialState, action) => {
   
    switch (action.type) {
        
        // Lifecycle
        case actionTypes.SYSTEM_INFO_SET_MOUNTED: return update(state, {mounted: action.payload})

        // Auth
        case actionTypes.AUTH_SIGN_OUT_SUCCESS: return update(state, initialState) //Return to initial state

        default: return state
    }
}

export default reducer