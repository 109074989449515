import { takeEvery, call, select, putResolve, put } from 'redux-saga/effects'

import * as actionTypes from '../actions/actionTypes'
import * as refuelActions from './../actions/refuels'
import * as settingsActions from './../actions/settings'
import * as errorActions from '../actions/error'
import * as refuelsRepo from '../../store/repository/refuels'
import * as COM from '../../utilities/Common'
import { ApiError } from '../../other/ApiError'

function* fetchRefuels(action) {

    try {
        const state = yield select()

        //console.debug("Incoming action for fetch refuels: ", action)

        // Limit
        let limit = action.payload.limit || 25 //TODO: Move to settings
        if (limit > 100 || limit < 1) { limit = 25 } //TODO: Move to settings

        // Offset
        let offset = action.payload.offset || 0
        if (offset < 0) { offset = 0 }

        const fromDate = state.refuels.dateRangeFrom
        const toDate = state.refuels.dateRangeTo

        //We are going to create the proper 'from' and 'to' time micros for the server
        const processedFromTimeMicros = COM.createStartOfDayServerTimeMicrosFromDate(fromDate)
        const processedToTimeMicros = COM.createEndOfDayServerTimeMicrosFromDate(toDate)

        const params = {
            fromTime: processedFromTimeMicros,
            toTime: processedToTimeMicros,
            offset: offset,
            limit: limit,
        }

        ////console.debug("Refuels fetch params: ", params)
        const apiCall = refuelsRepo.getRefuels.bind(this, state.auth.credentials, state.refuels.target.id, params)
        const { data } = yield call(apiCall)

        ////console.debug("Fetched refuels: ", data)
        const payload = {
            refuels: data,
            offset: offset,
            limit: limit,
        }

        yield putResolve(refuelActions.fetchSucces(payload))
        
    } catch (e) {
        yield putResolve(refuelActions.fetchFail())
        yield put(errorActions.addError(new ApiError(e, ApiError.MODULE_REFUELS)))
    }
}

function* fetchIfNecessary(action) {

    const { refuels } = yield select()

    // There is no target selected, so we could simply return
    if (!refuels.target || !refuels.target.id) return

    yield putResolve(refuelActions.fetchStart())
}

function* setFilterPaneVisible(action) {
    const refuels = action.payload.refuels
    //If we have results, we are going to hide the filter pane
    yield (refuels && refuels.length > 0) && putResolve(settingsActions.setFilterPaneVisible(false))
}

function* clearResultIfNecessary(action) {
    const { refuels } = yield select()
    const { target } = refuels
    yield !target && putResolve(refuelActions.setRefuels([]))
}

export function* saga() {

    yield takeEvery(actionTypes.REFUELS_FETCH_START, fetchRefuels)
    yield takeEvery([
        actionTypes.REFUELS_FETCH_SUCCESS,
    ], setFilterPaneVisible)

    yield takeEvery([
        actionTypes.REFUELS_ADD_TARGET,
        actionTypes.REFUELS_SET_DATE_RANGE,
    ], fetchIfNecessary)

    yield takeEvery([
        actionTypes.REFUELS_ADD_TARGET,
        actionTypes.REFUELS_DELETE_TARGET,
    ], clearResultIfNecessary)
}