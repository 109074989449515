import { useState, useEffect, useRef } from 'react';
import { useMediaQuery } from '@mui/material'; // or another media query hook

const useMediaQueryTracker = () => {
  // Media query states
  const is320 = useMediaQuery('(max-width: 320px)')
  const is400 = useMediaQuery('(min-width: 400px)')
  const is480 = useMediaQuery('(max-width: 480px)')
  const is890 = useMediaQuery('(max-width: 890px)')

  // State to store current media query results
  const [mediaQueryStates, setMediaQueryStates] = useState({
    is320,
    is400,
    is480,
    is890
  });

  // Ref to store previous media query states
  const prevMediaQueryStatesRef = useRef(mediaQueryStates);

  // Effect to update states and detect changes
  useEffect(() => {
    const prevMediaQueryStates = prevMediaQueryStatesRef.current;

    // Check if any media query result has changed
    const hasChanged =
      prevMediaQueryStates.is320 !== is320 ||
      prevMediaQueryStates.is400 !== is400 ||
      prevMediaQueryStates.is480 !== is480 ||
      prevMediaQueryStates.is890 !== is890;

    if (hasChanged) {
      console.log("[mediaQuery] has changed:", {
        is320,
        is400,
        is480,
        is890
      });

      // Update the current states
      setMediaQueryStates({ is320, is400, is480, is890 });

      // Update the previous states
      prevMediaQueryStatesRef.current = { is320, is400, is480, is890 }
    }
  }, [is320, is400, is480, is890])

  return mediaQueryStates
};

export default useMediaQueryTracker