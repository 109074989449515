// Build Info import
import buildInfo from '../components/SystemInfo/buildInfo.json'

import { 
    compareAsc,
    format,
    endOfDay,
    startOfDay,
    differenceInCalendarDays,
    /*differenceInHours,
    differenceInMinutes,
    differenceInSeconds,*/
    intervalToDuration,
} from 'date-fns'

import clsx from 'clsx'

import { latLngBounds } from 'leaflet'
import L from 'leaflet'

// MENU ITEMS
const MENU_ITEM_TRACKING = "tracking"
const MENU_ITEM_ROUTES = "routes"
const MENU_ITEM_CHAT = "chat"
const MENU_ITEM_SETTINGS = "settings"

// Chat
const CHAT_UNREAD_MESSAGE = "unread"
const CHAT_TYPE_SENT = "sent"

// Device info (presenters)
const ANDROID_EXOSKELETON = "android"
const IOS_EXOSKELETON = "ios"
const BROWSER = "browser"
//const IOS_COORDINATOR_NAME = "device" //!!! This name have to match exactly to the name used in the XCODE project at ContentView.swift file !!!
//const IOS_NAMESPACE = "iTrackPluto" //!!! This name have to match exactly to the name used in the XCODE project at ContentView.swift file !!!

// Tracking
const DEFAULT_FROM_TIME_MILIS = -1
const TRACKING_STATUS_PENDING = "TRACKING_STATE_PENDING"
const TRACKING_STATUS_NO_DATA = "TRACKING_STATE_NO_DATA"
const TRACKING_STATUS_LIVE = "TRACKING_STATE_LIVE"

// Date & Time
const TODAY = new Date()
const TODAY_START_OF_DAY = startOfDay(TODAY)
const TODAY_END_OF_DAY = endOfDay(TODAY)
const FIRST_DAY_OF_CURRENT_MONTH = startOfDay(new Date(TODAY.getFullYear(), TODAY.getMonth(), 1))
const BEFORE_CMPASC = -1
const SAME_CMPASC = 0
const AFTER_CMPASC = 1

// Date & Time formatters
const TIME_FORMAT = "HH:mm:ss"
const DATE_FORMAT = "yyyy-MM-dd"
const DATE_TIME_FORMAT = "yyyy-MM-dd HH:mm:ss"
const SHORT_DATE_TIME_FORMAT = "MM-dd"

const formatted = (d, convertToMilis, formatString) => {
    let ts = d instanceof Date ? d.getTime() : d
    if (convertToMilis) { ts /= 1000 } 
    return format(ts, formatString)
}

const formattedDate = (d, convertToMilis) => formatted(d, convertToMilis, DATE_FORMAT)
const formattedTime = (d, convertToMilis) => formatted(d, convertToMilis, TIME_FORMAT)
const formattedDateTime = (d, convertToMilis) => formatted(d, convertToMilis, DATE_TIME_FORMAT)
const formattedShortDateTime = (d, convertToMilis) => formatted(d, convertToMilis, SHORT_DATE_TIME_FORMAT)

// Logger
const createPlutoLogger = (prefix) => (...args) => console.debug(`${prefix}`, ...args);

// State
const update = (state, updatedValues) => {
    const newState = {
        ...state,
        ...updatedValues
      };
      //console.debug("[SAGA] State been has changed:", { previousState: state, newState: newState });
      return newState;
}

// Labels
const getTargetLabelText = t => {
    if (t.name && !t.description) return t.name 
    if (t.description && !t.name) return t.description
    if (t.name !== t.description) return `${t.name} (${t.description})`
    return t.name
}

// Google Maps
const googleMapsURLFromLatLng = latlng => `https://www.google.com/maps?q=${latlng}&t=k`


// Calendars and date pickers
const shoudDisableDayBefore = (d1, d2) => {
    return isBeforeOrSameDay(d1, d2)
}
  
const shoudDisableDayAfter = (d1, d2) => {
    return isAfterOrSameDay(d1, d2)
}
  
const isBeforeOrSameDay = (d1, d2) => {
    return compareAsc(d1, d2) === BEFORE_CMPASC || SAME_CMPASC
}
  
const isAfterOrSameDay = (d1, d2) => {
    return compareAsc(d1, d2) === AFTER_CMPASC || SAME_CMPASC
}

const isBefore = (d1, d2) => {
    return compareAsc(d1, d2) === BEFORE_CMPASC
}

/*const createNewDateWithTimeSetToEndOfDay = (d) => {
    return endOfDay(d)
}  

const createNewDateWithTimeSetToStartOfDay = (d) => {
    return startOfDay(d)
}*/

//This return a given timestamp with MICROseconds (time used by the server)
const createServerTimeMicros = (tm) => {
    return tm * 1000
}

//This return a given timestamp (assums the timestamp is in microseconds) with MILIseconds (time used by the frontend)
const createFrontendTimeMilis = (tm) => {
    return tm / 1000
}

const createStartOfDayServerTimeMicrosFromDate = (d) => {
    const startOfDayDate = startOfDay(d)
    return createServerTimeMicros(startOfDayDate)
}

const createEndOfDayServerTimeMicrosFromDate = (d) => {
    const endOfDayDate = endOfDay(d)
    return createServerTimeMicros(endOfDayDate)
}

// Calculate the difference between days
const calculateDayDifference = (from, to) => {
    //NOTE: TO and FROM are in different order!
    return differenceInCalendarDays(to, from)
}

const dateDifference = (from, to) => {
    //NOTE: TO and FROM are in different order!
    //const hours = differenceInHours(to, from);
    return {
        days: differenceInCalendarDays(to, from),
        /*hours: hours,
        plusHours: differenceInHours(to, from) % 24,
        plusMinutes: differenceInMinutes(to, from) % 60,
        plusSeconds: differenceInSeconds(to, from) % 60,*/
    }
}

//const zeroPad = (num) => String(num).padStart(2, '0')

const formattedPhoneNumber = (phoneNumber) => {
    return phoneNumber.match(/[^/].{1,3}/g).join('-')
}

const formattedDuration = (durationTime, durationTimeIsMicros, signs = {yearSign: "y", monthSign: "mt", daySign: "d", hourSign: "h", minuteSign: "m", secondSign: "s"}) => { 

    const { yearSign, monthSign, daySign, hourSign, minuteSign, secondSign } = signs

    const durationInMillis = durationTimeIsMicros ? durationTime / 1000 : durationTime;

    const {
        years,
        months,
        days, 
        hours,
        minutes,
        seconds
    } = intervalToDuration({ start: 0, end: durationInMillis })
    
    const yearStr = years > 0 ? `${years}${yearSign} ` : ``
    const monthStr = months > 0 ? `${months}${monthSign} ` : ``
    const dayStr = days > 0 ? `${days}${daySign} ` : ``
    const hoursStr = hours > 0 ? `${hours}${hourSign} ` : ``
    const minutesStr = minutes > 0 ? `${minutes}${minuteSign} ` : ``

    return `${yearStr}${monthStr}${dayStr}${hoursStr}${minutesStr}${seconds}${secondSign}`
}

// Helper function for routes
const getRouteItemByStartPositionTimeMicros = (routeItems, tm) => {
    for (let i = 0; i < routeItems.length; i++) {
        if (routeItems[i].startPosition.timeMicros === tm) return routeItems[i]
    }
}

// Helper functions for route drawing
const calcAngle = (latLng1, latlng2, coef) => {
    var dy = latlng2[0] - latLng1[0];
    var dx = Math.cos(Math.PI / 180 * latLng1[0]) * (latlng2[1] - latLng1[1]);
    var ang = ((Math.atan2(dy, dx) / Math.PI) * 180 * coef);
    return (ang).toFixed(2);
}

const midPoint = (p1, p2) => {
    const lat = (p1[0] + p2[0]) / 2
    const lng = (p1[1] + p2[1]) / 2
    return [lat, lng]
}

const toLatLngArray = (item) => {
    return [item.latitude, item.longitude]
}

// Helper functions for handling chat threads & messages
const getItemFromAnArrayById = (arr, id) => {
    for (let i = 0; i < arr.length; i++) {
        if (arr[i].id === id) return arr[i]
    }
    return null
}

// Helper function for creating bounds from coordinates
const createBounds = coordinates => {
    const bounds = latLngBounds()
    coordinates.forEach((c) => { bounds.extend(L.latLng(c.lat, c.lng)) })
    return bounds
}

// Helper function for class handling
const cls = (...args) => args.reduce( (a, c) => a += ` ${c}`, "")
const buildIdString = (long) => long ? `${buildInfo.id}@${buildInfo.platform}` : `b${buildInfo.id}`


// XOR encryption
// Of course it could be a library, but I did not want to use any external library for this
const xorString = (str, secret) => {
    const k = secret.split('').map((c) => c.charCodeAt(0))
    let encrypted = '';
    let j = 0;
    for (let i = 0; i < str.length; i++) {
        j = i % k.length
        encrypted += String.fromCharCode(str[i].charCodeAt(0) ^ k[j])
    }
    return encrypted
}

const encryptString = (clearText, secret) => {
    // to get rid of the "atob" deprecated warning
    // https://stackoverflow.com/questions/18279141/javascript-string-encryption-and-decryption
    const bt = window.btoa
    return bt(xorString(clearText, secret))
}

const descryptString = (encryptedText, secret) => {
    // to get rid of the "atob" deprecated warning
    // https://stackoverflow.com/questions/18279141/javascript-string-encryption-and-decryption
    const at = window.atob
    return xorString(at(encryptedText), secret)
}
const removeUrlParameters = () => {
    window.history.replaceState(null, '', window.location.pathname);
}

/*
...FromTimeMilis
const humanReadableDuration = (tm) => {

    let seconds = Math.floor(tm / (1000 * 1000))
    let hours = Math.floor(seconds / 3600)
    seconds -= hours * 3600
    let minutes = Math.floor(seconds / 60)
    seconds -= minutes * 60

    let hoursStr   = `${hours}`
    let minutesStr = `${minutes}`
    let secondsStr = `${seconds}`

    if (hours   < 10) {hoursStr   =  `0${hours}`}
    if (minutes < 10) {minutesStr = `0${minutes}`}
    if (seconds < 10) {secondsStr = `0${seconds}`}

    const minsec = `${minutesStr}:${secondsStr}`

    return hours > 0 ? `${hoursStr}:${minsec}` : minsec
}*/

const isTokenValid = (token) => {
    return (token && token.length > 0) ? true : false
}
export const LANGUAGE_FILES_URL = process.env.REACT_APP_LANGUAGE_FILES_URL || "./i18n"

export const BOTTOM_MENU_HEIGHT = process.env.REACT_APP_BOTTOM_MENU_HEIGHT || "60px"

export {
    // MENU ITEMS
    MENU_ITEM_TRACKING,
    MENU_ITEM_ROUTES,
    MENU_ITEM_CHAT,
    MENU_ITEM_SETTINGS,

    // Dates
    TODAY,
    TODAY_START_OF_DAY,
    TODAY_END_OF_DAY,
    FIRST_DAY_OF_CURRENT_MONTH,
    
    // chat
    CHAT_UNREAD_MESSAGE,
    CHAT_TYPE_SENT,

    // device info (presenters)
    ANDROID_EXOSKELETON,
    IOS_EXOSKELETON,
    BROWSER,
    //IOS_COORDINATOR_NAME,
    //IOS_NAMESPACE,

    // tracking
    DEFAULT_FROM_TIME_MILIS,
    TRACKING_STATUS_PENDING,
    TRACKING_STATUS_NO_DATA,
    TRACKING_STATUS_LIVE,

    // general helpers, etc.
	update,
    format,
    cls,
    clsx,
	buildIdString,
    isTokenValid,

    // map helper functions
    createBounds,

    // formatters
    formattedPhoneNumber,
    formattedDate,
    formattedTime,
    formattedDateTime,
    formattedShortDateTime,
    formattedDuration,

    // Logger
    createPlutoLogger,
    
    // string manipulators and helpers
    googleMapsURLFromLatLng,
	getTargetLabelText,
	
    // helper functions for date pickers
    shoudDisableDayBefore,
	shoudDisableDayAfter,
	isBeforeOrSameDay,
	isAfterOrSameDay,
	isBefore,

    // server time & frontend time manipulators
    createServerTimeMicros,
    createFrontendTimeMilis,
	
    // date maipulation
    // createNewDateWithTimeSetToStartOfDay,
	// createNewDateWithTimeSetToEndOfDay,
    createStartOfDayServerTimeMicrosFromDate,
    createEndOfDayServerTimeMicrosFromDate,
	calculateDayDifference,
    dateDifference,

    // getters and array handling
	getRouteItemByStartPositionTimeMicros,
	getItemFromAnArrayById,

    // functions for route drawing
    calcAngle,
    midPoint,
    toLatLngArray,

    // encryption
    encryptString,
    descryptString,

    // URL handling
    removeUrlParameters,
}