import React from 'react'
import { Routes, Route } from 'react-router-dom'
import { connect } from 'react-redux'

// Own components
import Eclipse from '../App/Eclipse.js'
import TrackingFilter from '../Tracking/TrackingFilter.js'
import RoutesFilter from '../Routes/RoutesFilter.js'
import Refuels from '../Refuels/Refuels.js'
import Chat from '../Chat/Chat.js'

// common
import * as COM from '../../utilities/Common.js'

// other
import * as navigationRoutes from '../BottomMenu/NavigationRoutes.js'
import * as settingsActions from '../../store/actions/settings.js'
import { useAppControl } from '../App/AppControlProvider.js'

const FilterPane = (props) => {

    const { state } = props
    const { getFilterPaneOpen, closeFilterPane  } = useAppControl()
    const isFilterPaneOpen = getFilterPaneOpen()
    const { auth, tracking, routes } = state
    const isTokenLogin = COM.isTokenValid(auth?.credentials?.token)

    console.debug("[FilterPane.js] isFilterPaneOpen: ", isFilterPaneOpen)
    if (!isFilterPaneOpen) return null

    const allRoutes = [
        {
          availableForToken: true,
          path: navigationRoutes.NAVIGATION_ROUTE_TRACKING,
          element: <TrackingFilter />,
          progressIndicator: tracking.inProgress,
        },
        {
          path: navigationRoutes.NAVIGATION_ROUTE_ROUTES,
          element: <RoutesFilter />,
          progressIndicator: routes.inProgress,
        },
        {
          path: navigationRoutes.NAVIGATION_ROUTE_REFUELS,
          element: <Refuels />,
        },
        {
          path: navigationRoutes.NAVIGATION_ROUTE_CHAT,
          element: <Chat />,
        },
      ]

    const availableRoutes = allRoutes.filter(route => {
        if (isTokenLogin) {
           return route.availableForToken
        }
        return true
    })

    console.debug("[FilterPane.js] availableRoutes: ", availableRoutes)

    return (
          <Eclipse onClick={closeFilterPane}>
            <Routes>
              {availableRoutes.map((r, index) => {
                  return ( 
                      console.debug("[FilterPane.js] route: ", r),
                      <Route
                          key={index}
                          path={r.path}
                          exact={r.exact}
                          element={r.element}
                      />
                    )
              })}
              <Route component={() => null} /> {/* This will catch all unmatched routes and render nothing, no warning on console */}
            </Routes>
          </Eclipse>
    )
}


const mapStateToProps = (state) => {
    return {
        state: state,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        userDidAcknowledgeUserMessage: () => dispatch(settingsActions.userDidAcknowledgeUserMessage()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FilterPane)

