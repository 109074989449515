// React imports
import React from 'react'
import * as COM from '../../utilities/Common'

import { clsx } from '../../utilities/Common'
import { makeStyles } from '@mui/styles'

const INVISIBLE_PLACEHOLDER_CHAR = String.fromCharCode(8205)

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: "0.5rem",
        maxWidth: "480px",
        paddingTop: "0.25rem",
        paddingBottom: "0.25rem",
        paddingLeft: "1rem",
        paddingRight: "1rem",
        "& div:nth-child(2)": {
            borderRadius: ".5rem",
            padding: "0.5rem",
            wordBreak: "break-word",
        },
        display: "flex",
        flexDirection: "column",
        gap: "0.25rem",
    },
    dateTime: {
        color: theme.palette.grey[600],
        fontSize: "85%",
    },
    received: {
        color: theme.palette.grey[900],
        "& div:nth-child(2)": {
            border: `solid 1px ${theme.palette.primary.main}`,
            alignSelf: "flex-start",
        }
    },
    sent: {
        marginLeft: "auto",
        "& div:nth-child(1)": {
            alignSelf: "flex-end",
        },
        "& div:nth-child(2)": {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
            alignSelf: "flex-end",
            textAlign: "right",
        }
    }
}))

const TextRow = (props) => {

    const classes = useStyles()
    
    const { message } = props
    const { content, type, sentStatus } = message

    const messageSentStatusDate = new Date(COM.createFrontendTimeMilis(sentStatus.timeMicros))

    //XXX: in case of empty string, place an invisible char (UTF 8205) instead to get a same row height
    const text = (content.body === "" || content.body === null) ? INVISIBLE_PLACEHOLDER_CHAR : content.body
    
    const conditionalClasses = clsx({
        [classes.root]: true,
        [classes.received]: type !== COM.CHAT_TYPE_SENT,
        [classes.sent]: type === COM.CHAT_TYPE_SENT,
    })

    return (
        <div className={conditionalClasses}>
            <div>
                <span className={classes.dateTime}>{COM.formattedDateTime(messageSentStatusDate)}</span>
            </div>
            <div>
                {text}
            </div>
        </div>
    )
}

export default TextRow