// React imports
import React from 'react'

// i18n
import { useTranslation } from 'react-i18next'


// Icons
import { FileCopyRounded, RefreshRounded, ShareRounded } from '@mui/icons-material'

import copy from 'copy-to-clipboard'

import { makeStyles } from '@mui/styles'
import { Button, CircularProgress, FormControlLabel, Switch } from '@mui/material'
import { createPlutoLogger } from '../../utilities/Common'

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        display: 'flex',
        flexDirection: 'column',
        gap: ".5rem",
    },
    spacer: {
        marginTop: theme.spacing(1),
    }
}));

const D = createPlutoLogger("[Actions.js]")

const Actions = (props) => {

    const { t } = useTranslation()
    const classes = useStyles()
    const { 
        link,
        loginToken,
        isEmbedded,
        inProgress,
        handleGenerate,
        handleSwitchChange,
    } = props
  
    const canShare = (loginToken) && ((navigator && navigator.share) || (window.PlatformShare)) //navigator.canShare()

    D("[Actions.js] Can we share? ", canShare ? "YES" : "NO")
    D("[Actions.js] Navigator: ", navigator, " Navigator.share: ", navigator.share)
    D("[Actions.js] link: ", link)
    D("[Actions.js] loginToken: ", loginToken)
    D("[Actions.js] isEmbedded: ", isEmbedded)
    D("[Actions.js] inProgress: ", inProgress)

    const handleCopy = () => {
        copy(link);
        D(`[Actions.js] '${link}' has been copied!`)
    }

    const handleShare = () => {
        D(`[Actions.js] Link to share: '${link}'`)
        if (canShare) {
            const EMPTY = ""
            const SEPARATOR = " "
            const title = `iTrack ${isEmbedded ? t("share.embedded") : EMPTY}${isEmbedded ? SEPARATOR : EMPTY}${t("share.link")}`

            const shareData = {
                title: title,
                text: link,
                url: link,
            }

            // This object is injected by the Flutter app to the webview
            // If it is present, we use it to share the link
            if (window.PlatformShare) {
                D("[Actions.js] Using PlatformShare to share link")
                window.PlatformShare.postMessage(JSON.stringify(shareData))
            } else {
                // Otherwise, we use the native share API
                navigator.share(shareData).then(() => {
                    D(`[Actions.js] ${link} shared successfully.`);
                })
                .catch(console.error);
            }
        }
    }

    const generateIcon = inProgress ? <CircularProgress size="1rem" /> : <RefreshRounded />
    const generateText = inProgress ? t("share.generating") : t("share.generate")
    const shouldUseSpacer = !loginToken
    
    const generateButton = 
        <Button
            className={shouldUseSpacer && classes.spacer}
            variant='contained'
            disabled={inProgress}
            onClick={handleGenerate}
            color="primary"
            startIcon={generateIcon}
        >
            {generateText}
        </Button>

    const copyButton =
        <Button
            variant='contained'
            onClick={handleCopy} 
            color="primary"
            startIcon={<FileCopyRounded />}
        >
            {t("general.copy")}
        </Button>


    const shareButton = canShare &&
        <Button
            variant='contained'
            onClick={handleShare}
            color="primary"
        >
            <ShareRounded />
        </Button>

    const switchComp = 
        <Switch
            checked={isEmbedded}
            onChange={handleSwitchChange}
            color="primary" 
        />
    
    const isTokenPresented = loginToken !== null
    const selector = 
        <FormControlLabel
            control={switchComp}
            label={t("targetMenu.embedded")}
        />

    return (
        <div className={classes.root}>
            {isTokenPresented && selector}
            {generateButton}
            {loginToken && copyButton}
            {shareButton}
        </div>
    )
}

export default Actions