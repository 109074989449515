// React imports
import React, {createRef, useState } from 'react'
import { connect } from 'react-redux'
import * as chatActions from '../../store/actions/chat'

// Icons
import { SendOutlined } from '@mui/icons-material'

// Common
import { clsx, createPlutoLogger } from '../../utilities/Common'
import { makeStyles } from '@mui/styles'
import useKeyboardWithBindings from './useKeyboardWithBindings'

const useStyles = makeStyles((theme) => ({
    root: {
      flex: 1,
      display: "flex",
      alignItems: "center",
      backgroundColor: theme.palette.grey[200],
      border: `solid 1px ${theme.palette.grey[300]}`,
      borderRadius: ".5rem",
      padding: ".25rem .25rem .25rem 1rem", //TRBL
    },
    input: {
      flexGrow: 1,
    },
    textInput: {
      width: "100%",
      border: "none",
      backgroundColor: "transparent",
      '&:focus': {
        outline: "none",
      },
    },
    button: {
      backgroundColor: "transparent",
      display: "flex",
      alignItems: "flex-end",
    },
    buttonEnabled: {
      "& *": {
        color: theme.palette.grey[600],
      }
    },
    buttonDisabled: {
      "& *": {
        color: theme.palette.grey[400],
      }
    }
  }));
  
const D = createPlutoLogger("[ChatInput.js]")

const ChatInput = (props) => {

    const classes = useStyles()
    const [text, setText] = useState("")
    const { sendMessage, show } = props

    const handleOnChange = (ev) => {
      //D("[ChatInput.js] Chat input changed: ", ev.target.value)
      setText(ev.target.value)
    }
  
    // It's important to scroll to the bottom of the chat window when the input is clicked
    // to act like a normal chat window, like a desktop chat app
    const handleInputClick = (ev) => {
      D("Chat input clicked or enter pressed.", ev)

      // XXX: This is a hack to scroll to the bottom of the chat window
      // The good and proper way to do this is to use a ref, but accessing the ref here is a bit tricky
      // so we'll just do it the easy way for now
      const scrollContainer = document.getElementById('chat-scroll-container')
      scrollContainer.scrollTop = scrollContainer.scrollHeight
    }
  
    const doSendMessage = (message) => {
      //D(`[ChatInput.js] Sending message: ${message}`)

      if (!message || message.length < 1) {
        D("[ChatInput.js] Message is empty. Not sending.")
        return
      } 

      message.trim()
      D("[ChatInput.js] Message is not empty. Trimmed. It is: ", message)
      
      // Call the SAGA action to send the message
      sendMessage(message)
      
      // Set the text to empty after sending the message
      setText("") //NOTE: this is an async call, so the text will be empty after the next render

      // We need to focus on the input again after sending the message
      // to make it easier for the user to send another message
      // without having to click on the input again
      const input = inputRef.current
      if (!input) {
        console.warn("[ChatInput.js] Input ref is null. Can't focus on input")
        return
      }
      input.focus()
      //D("[ChatInput.js] Focused on input")
    }

    const keyBindings = [
      {
        key: 'Enter',
        action: (ev) => {
          ev.preventDefault(); // Prevent the default behavior
          if (text.trim()) {
            doSendMessage(text);
            handleInputClick(ev);
          }
        },
      }
      /*
      // If you need to bind a key with a modifier, you can use this
      {
        key: 's',
        action: () => console.log('Key "d" pressed with Ctrl'),
        modifiers: { ctrl: true },
      },*/
    ];

    useKeyboardWithBindings(keyBindings);

    const inputRef = createRef()  

    const isDisabled = !(text.length > 0)

    if (!show) return null

    const buttonClasses = clsx({
      [classes.button]: true,
      [classes.buttonDisabled]: isDisabled,
      [classes.buttonEnabled]: !isDisabled,
    })

    return (
      <div className={classes.root}>
  
        <div className={classes.input}>
          <input
            // Getting the ref for auto focus
            ref={inputRef}
            className={classes.textInput}
            type="text"
            onClick={handleInputClick}
            onChange={handleOnChange}
            value={text} />
        </div>
  
        <div className={buttonClasses} onClick={(evt) => doSendMessage(text)}>
           <SendOutlined />
        </div>
  
      </div>
    )
  }

const mapStateToProps = (state) => {
    return {
        state: state
    }
}

const mapDispatchToProps = dispatch => {
    return {
        sendMessage: (payload) => dispatch(chatActions.sendMessage(payload)),
    }
}
  
  export default connect(mapStateToProps, mapDispatchToProps)(ChatInput)
