import { LocalizationProvider } from '@mui/x-date-pickers'

// There is a bug in DateFns adapter, so we use Moment adapter.
// Moment libray labeled "do-not-use" by DG@iData, so we use Luxon instead. Moment is still available as an option in MUI5 documentation.
// import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3"
// import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'

// i18n
import { useTranslation } from 'react-i18next'

// DateFns locales. We use Moment adapter, so we don't need these now
//import { hu, enUS, ro, sr, sk, hr, cs } from 'date-fns/locale'

/*import moment from 'moment';
import 'moment/locale/hu';
import 'moment/locale/ro';
import 'moment/locale/sr';
import 'moment/locale/sk';
import 'moment/locale/hr';
import 'moment/locale/cs';
//US english is the default locale, so we don't need to import it*/

const PlutoLocalizationProvider = ({ children }) => {

  const { t, i18n } = useTranslation()

  const currentLanguage = i18n.language;
  //console.debug("[PlutoLocalizationProvider.js] currentLanguage:", currentLanguage)

  const localeText = {
    cancelButtonLabel: t("general.cancel"),
    okButtonLabel: t("general.ok"),
    start: t("general.dateTime.startDate"),
    end: t("general.dateTime.endDate"),
    previousMonth: t("picker.dateRange.previousMonth"),
    nextMonth: t("picker.dateRange.nextMonth"),
    dateRangePickerToolbarTitle: t("picker.dateRange.toolbarTitle"),
  }

  /*
  // For DateFns adapter
  const localesForLanguage = {
      "hu": hu,
      "en": enUS,
      "ro": ro,
      "sr": sr,
      "sk": sk,
      "hr": hr,
      "cs": cs,
  }
  // For DateFns adapter
  const adapterLocale = localesForLanguage[currentLanguage] || i18n.options.fallbackLng[0] || enUS*/
  
  // We keep here to know when we should change back to fallbackLng
  const localesForLanguage = {
    "hu": "hu",
    "en": "en",
    "ro": "ro",
    "sr": "sr",
    "sk": "sk",
    "hr": "hr",
    "cz": "cs",
  }
  
  console.debug("[PlutoLocalizationProvider.js] current language:", currentLanguage)

  // For moment adapter
  const adapterLocale = localesForLanguage[currentLanguage] || i18n.options.fallbackLng[0]
  console.debug("[PlutoLocalizationProvider.js] Adapter locale:", adapterLocale)
  
  /*
    // AdapterDateFns is not working properly, so we use AdapterMoment
    // update: AdapterMoment is labeled as "do-not-use" by DG@iData, so we use AdapterLuxon instead
    // Use this code if the bug is fixed in the future
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
        localeText={localeText}
        adapterLocale={adapterLocale}>

      {children}
    
    </LocalizationProvider>*/

  return (
    <LocalizationProvider
      dateAdapter={AdapterLuxon}
        localeText={localeText}
        adapterLocale={adapterLocale}>

      {children}
    
    </LocalizationProvider>
  );
}

export default PlutoLocalizationProvider;