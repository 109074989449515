
// React imports
import React, { useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { makeStyles } from '@mui/styles'
import { Fab, Tooltip } from '@mui/material'
import { AspectRatioRounded } from '@mui/icons-material'
import { useMap } from 'react-leaflet'

import { clsx, createPlutoLogger } from '../../utilities/Common'
import useCoordinateChange from './useCoordinateChange'

import L from 'leaflet'
import { useTheme } from '@emotion/react'

const useStyles = makeStyles((theme) => ({
    root: {
      position: "absolute",
      bottom: "6rem",
      right: ".4rem",
      zIndex: 1000,
    },
    wrapper: {
      display: "flex",
      alignContent: "center",
      justifyContent: "center",
    },
    '@keyframes rotator': {
      from: {transform: 'scale(0.75)'},
      to: {transform: 'scale(1.1)'},
    },
    animated: {
      animation: '$rotator 2s infinite linear',
    }
  
}));

const D = createPlutoLogger("🗺️ [FitButton.js]")

const FitButton = (props) => {

    const zoomLogicEnabled = props.zoomLogicEnabled
    const coordinates = props.coordinates
    const singleCoordinate = coordinates.length === 1
    const multipleCoordinates = coordinates.length > 1
    const classes = useStyles()
    const theme = useTheme()
    const { t } = useTranslation()
    const map = useMap()
    const [autoFit, setAutoFit] = useState(true)

    // changes
    const didCoordinatesChange = useCoordinateChange(coordinates);

    // Conditionally fit the map to the bounds of the selected targets
    useEffect(() => {

        if (!autoFit) {
            D("[useEffect] Autofit is turned off. Skippng re-fitting the map.")
            return
        }

        // It should be but just in case
        if (!map) {
            console.warn("[useEffect] Map is not ready.")
            return
        }

        if (!didCoordinatesChange) {
            D("[useEffect] Coordinates did not change. No need to fit the map.")
            return
        }

        if (!coordinates || coordinates.length === 0) {
            D("[useEffect] Coordinates cannot be checked. Coordinates are empty or undefined. Coordinates: ", coordinates)
            return
        }

        D("[useEffect] Fitting the map to the coordinates.")
        fitMap()
    // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [coordinates, didCoordinatesChange, map, autoFit]);

    useEffect(() => {

      const dragStartEventName = 'dragend'
      const dragEndEventName = 'dragend'
      const zoomStartEventName = 'zoomstart'
      const zoomEndEventName = 'zoomend'
      
      // Event handlers
      const handleDragStart = (e) => {
        D('Map drag started.')
        if (autoFit) {
          setAutoFit(false)
          D("Autofit turned off.")
        }
      }
  
      const handleDragEnd = (e) => {
        // TODO: Handle the autofit here with some threshold to avoid the autofit state correction
        // on minor map movements (This would improve the UX)
        D('Map drag ended.')
      }

      const handleZoomStart = (e) => {
        D('Map zoom started.')
      }

      const handleZoomEnd = (e) => {
        D('[handleZoomEnd] Map zoom ended.')
        if (zoomLogicEnabled) {
          D("[handleZoomEnd] Zoom logic is enabled.")
          // PLUTO-12
          try {
            if (multipleCoordinates && autoFit) {
              D("[handleZoomEnd] Multiple coordinates are available. Autofit is turned on.")
              const bounds = map.getBounds();
              const visible = coordinates.filter(coord => bounds.contains(coord));
              const visibleCount = visible.length;
              const allVisible = visibleCount === coordinates.length;
              if (!allVisible) {
                setAutoFit(false)
                D("[handleZoomEnd] There is one ore more non-visible coordinates. Autofit turned off.")
              }
            }
          } catch (e) {
            console.warn("[handleZoomEnd] Error while checking the visibility of the coordinates.")
          }
        } else {
          D("[handleZoomEnd] Zoom logic is disabled.")
        }
      }

  
      // Attach event listeners
      map.on(dragStartEventName, handleDragStart)
      map.on(dragEndEventName, handleDragEnd)
      map.on(zoomStartEventName, handleZoomStart)
      map.on(zoomEndEventName, handleZoomEnd)
  
      // Cleanup listeners on unmount
      return () => {
        map.off(dragStartEventName, handleDragStart)
        map.off(dragEndEventName, handleDragEnd)
        map.off(zoomStartEventName, handleZoomStart)
        map.off(zoomEndEventName, handleZoomEnd)
      };
    }, [map, autoFit, coordinates, multipleCoordinates, zoomLogicEnabled]);


    const wrapperClasses = clsx({
      [classes.animated]: autoFit,
      [classes.wrapper]: true,
    })

    //D("Coordinates: ", coordinates)

    const fitMap = () => {
      try {
        const bounds = L.latLngBounds(coordinates);

        if (bounds.isValid()) {
            const settings = {
              padding: [5, 5]
            }

            // PLUTO-12: In case of one coordinate, we keep the current zoom level
            if (singleCoordinate) {
              settings.maxZoom = map.getZoom()
              D("[useEffect] Only one coordinate is available. Set the maxZoom for the current zoom: ", settings.maxZoom)
            }

            if (singleCoordinate) {
              const targetCoordinate = bounds.getCenter(); // Get the center of the bounds as the target coordinate
              const currentZoom = map.getZoom(); // Get the current zoom level
              map.setView(targetCoordinate, currentZoom); // Set the view to the target coordinate with the current zoom level
              D("[useEffect] Only one coordinate is available. Set the view to the target coordinate with the current zoom: ", targetCoordinate, currentZoom);
            } else {
              D("[useEffect] Multiple coordinates are presented. Fitting the map to the bounds.")
              map.fitBounds(bounds, settings);
            }
          } else {
            D("[useEffect] Invalid bounds.", bounds)
          }
        } catch (e) {
          console.warn("[useEffect] Error while fitting the map to the coordinates.")
      }
    }

    const handleOnClick = (event) => {
      event.stopPropagation()
      D("[handleOnClick] Fit button clicked.")
      if (!autoFit) {
        D("[handleOnClick] Autofit turned on.")
        setAutoFit(true)
      } else {
        D("[handleOnClick] Autofit turned off")  
        setAutoFit(false)
      }
      
      fitMap()
    }

    const bgColor = autoFit ? theme.palette.secondary.main : undefined
    const color = autoFit ? theme.palette.primary.contrastText : undefined


    return (
      <div className={classes.root}>
        <Fab
            sx={{
              bgcolor: bgColor, // Conditional background color
              color: color, // Icon color
              "&:hover": {
                bgcolor: bgColor, // Conditional hover background color
                color: color, // Icon color
              }
            }}
            size="small"
            onClick={handleOnClick}>
            <div className={wrapperClasses}>
              <Tooltip title={t("map.controllButtons.fitMap")}>
                <AspectRatioRounded />
              </Tooltip>
            </div>
        </Fab>
      </div>
    )
}

export default FitButton