import * as L from 'leaflet'

import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import Items from "./Map/Items"
import { createPlutoLogger } from '../../utilities/Common'
import FitButton from '../Map/FitButton'

const D = createPlutoLogger("[RoutesMapObjects.js]")

const RoutesMapObjects = (props) => {
    // Old redux state
    const { state } = props
    const { routes } = state
    const { items } = routes
   
    // coordinates
    const checkedItems = items.filter( itm => itm.checked )
    const itemsWithLatLng = checkedItems.filter( itm => itm.latLngPositions && itm.latLngPositions.length > 0)
    const coordinateArrays = itemsWithLatLng.map((ci) => ci.latLngPositions)
    const flattedCoordinates = coordinateArrays.flat(1)

    return (
        <>
            <FitButton coordinates={flattedCoordinates} />
            <Items items={checkedItems} />
        </>
    )
}

const mapStateToProps = (state) => {
    return {
      state: state
    }
}

const mapDispatchToProps = dispatch => {
    return {
      // no actions
    }
  }
  

  export default connect(mapStateToProps, mapDispatchToProps)(RoutesMapObjects)