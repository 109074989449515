// Redux
import { connect } from 'react-redux'

import React, { useEffect, useState } from 'react'


// i18n
import { useTranslation } from 'react-i18next'

import MapIcon from '@mui/icons-material/Map'
import CenterIcon from '@mui/icons-material/CenterFocusWeak'
import ShareIcon from '@mui/icons-material/Share'
import ShareDialog from './ShareDialog'
import { ExpandMoreRounded } from '@mui/icons-material'
import { ListItemIcon, Menu, MenuItem, Typography } from '@mui/material'
import { useAppControl } from '../App/AppControlProvider'
import { createPlutoLogger, googleMapsURLFromLatLng, isTokenValid } from '../../utilities/Common'

const D = createPlutoLogger("[TargetMenu.js]")

const TargetMenu = (props) => {


    const { item, state } = props
    // old redux state
    const { auth } = state

    const token = auth?.credentials?.token
    D("Token: ", token)

    const isTokenLogin = isTokenValid(token)
    D("isTokenLogin: ", isTokenLogin)

    // new hook based state
    const { getMap } = useAppControl()
    const map = getMap()

    const { t } = useTranslation()

    const [isShareDialogOpen, setShareDialogOpen] = useState(false)
    const [anchorEl, setAnchorEl] = useState(false)
    const [openGoogleMapsLink, setOpenGooleMapsLink] = useState(null)
    const [selectedItem, setSelectedItem] = useState(null)

    D("[TargetMenu.js] item: ", item)

    /* 
        *** BUG FIX PLUTO-1224 ***

        This logic is implemented to address the PLUTO-1224 ticket. The issue was as follows: when a user clicked on
        the 'Open with Google Maps' menu item in the target sharing screen, the popup menu did not close in Firefox.
        This problem was caused by the asynchronous behavior of the 'setState' call.
        The new windows were being opened before the 'setState' call had finished.
        The process of the opener window was terminated, and the process of the newly opened window was activated.
        To resolve this issue, we will first set the link and then close the popup window.
        We use the 'useEffect' hook to check the necessary conditions and open the new window from within the hook.
        
    */
    useEffect(() => {
        if (!anchorEl && openGoogleMapsLink) {
            window.open(openGoogleMapsLink)
            D(`New window (tab) has been opened with Google Maps.`, selectedItem)
        } 
    },[openGoogleMapsLink, anchorEl, selectedItem])

    const handleMenuClose = () => {
        setAnchorEl(null)
    }
 
    const handleShareDialogClose = () => {
        setShareDialogOpen(false)
    }

    const handleMenuOpen = (event, item) => {
        setOpenGooleMapsLink(null)
        setSelectedItem(item)
        setAnchorEl(event.currentTarget)
    }

    const handleOpenInGoogleMaps = (event) => { 
    const latlng = selectedItem.currentPosition ? `${selectedItem.currentPosition.latitude},${selectedItem.currentPosition.longitude}` : null
    if (latlng) {
        setOpenGooleMapsLink(googleMapsURLFromLatLng(latlng));
        D(`New window (or tab) will be open Google Maps.`, selectedItem)
    }
    else {
        //D("No currentPosition for target! ", selectedItem)
    }
        handleMenuClose(event)
    }

    const handleGetSharableLink = (event) => {
        handleMenuClose(event) //close the popup
        setShareDialogOpen(true) //open the dialog
    }

    const handleCenterTarget = (event) => {

        handleMenuClose(event)

        selectedItem && selectedItem.currentPosition && map.setView([
            selectedItem.currentPosition.latitude,
            selectedItem.currentPosition.longitude
        ], 16)
    }

    // We only show the sharable link if the user is not logged in with a token
    const showSharableLink = !isTokenLogin
    D("[TargetMenu.js] Token login: ", isTokenLogin)
    D("[TargetMenu.js] Show sharable link: ", showSharableLink)

    const menuItems = [
        {
            show: true,
            id: "centerTarget",
            icon: <CenterIcon fontSize="small" />,
            text: t("targetMenu.fitMap"),
            onClick: handleCenterTarget
        },
        {
            show: true,
            id: "openInGoogleMaps",
            icon: <MapIcon fontSize="small" />,
            text: t("targetMenu.googleMaps"),
            onClick: handleOpenInGoogleMaps
        },
        {
            show: showSharableLink,
            availableForToken: false,
            id: "getSharableLink",
            icon: <ShareIcon fontSize="small" />,
            text: t("targetMenu.sharableLink"),
            onClick: handleGetSharableLink
        }
    ]

    return (
        <>
            <ExpandMoreRounded onClick={(event) => handleMenuOpen(event, item)} />
            <ShareDialog
                item={selectedItem}
                onClose={handleShareDialogClose}
                open={isShareDialogOpen} />

            <Menu
                id={`share-menu`}
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose} >
            
                {menuItems.map((menuItem) => {
                    D("[TargetMenu.js] menuItem: ", menuItem.id, " show: ", menuItem.show)
                    return menuItem.show && (
                        <MenuItem key={menuItem.id} onClick={menuItem.onClick}>
                            <ListItemIcon>
                                {menuItem.icon}
                            </ListItemIcon>
                            <Typography variant="inherit">
                                {menuItem.text}
                            </Typography>
                        </MenuItem>
                    )
                })}
            </Menu>
        </>
    )
}

const mapDispatchToProps = dispatch => {
    return {  
        // no actions needed
    }
}

const mapStateToProps = (state) => {
    return {
        state: state,
    }
}
  
export default connect(mapStateToProps, mapDispatchToProps)(TargetMenu)
