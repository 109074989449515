import * as actionTypes from './actionTypes'

export const addError = (error) => {
    return {
        type: actionTypes.ERROR_ADD_ERROR,
        error: error
    }
}

export const setItems = (items) => {
    return {
        type: actionTypes.ERROR_SET_ITEMS,
        payload: items  
    }
}

export const setAcknowledged = (uuid) => {
    return {
        type: actionTypes.ERROR_SET_ACKNOWLEDGED,
        payload: uuid
    }
}