import { takeEvery, put, putResolve, call, select, delay } from 'redux-saga/effects'

import * as actionTypes from '../../actions/actionTypes'
import * as chatActions from '../../actions/chat'
import * as errorActions from '../../actions/error'

import * as chatRepo from '../../repository/chat'

import {createPlutoLogger, getItemFromAnArrayById as getById} from '../../../utilities/Common'
import { ApiError } from '../../../other/ApiError'

// CONSTANTS
const STATUS_UNREAD = "unread"

// HELPERS
const MESSAGE_SORTER = (a,b) => b.id - a.id
const THREAD_FIRST_SENT_STATUS_TIME_MICROS = t => t.messages.length >= 1 ? t.messages[t.messages.length - 1].sentStatus.timeMicros : null
const THREAD_LAST_SENT_STATUS_TIME_MICROS = t => t.messages.length >= 1 ? t.messages[0].sentStatus.timeMicros : null
//const THREAD_UNREAD_COUNTER = t => t.messages.reduce(a, c => a + c.)

const D = createPlutoLogger("💬 [SAGA] [chatMessages.js]")

const mergeNewMessagesIntoCurrentThreads = (threads, messages) => {
    
    threads.forEach(t => {
        const newMessages = messages.filter( m => m.threadId === t.id)
        t.messages.forEach( tm => {
            if (!getById(newMessages, tm.id)) { newMessages.push(tm) }
        })
        t.messages = newMessages.sort(MESSAGE_SORTER)
        t.firstMessageSentTime = THREAD_FIRST_SENT_STATUS_TIME_MICROS(t)
        t.lastMessageSentTime = THREAD_LAST_SENT_STATUS_TIME_MICROS(t)
    })
}

// MESSAGES
function* getMessages(action) {
    try {

        const {threadId, lastTimeMicros} = action.payload
        const currentState = yield select()

        const { data } = yield call(chatRepo.getMessages, {
            acceptLanguage: currentState.settings.languageCode,
            credentials: currentState.auth.credentials,
            threadId: threadId,
            lastTimeMicros: lastTimeMicros,
        })

        yield putResolve(chatActions.getMessagesSuccess(data))
    } catch (e) {
        yield putResolve(errorActions.addError(new ApiError(e, ApiError.MODULE_CHAT)))

    }
}

function* getMessagesSuccess(action) {

    const currentState = yield select()
    const threads = currentState.chat.threads
    const messages = action.payload
    
    mergeNewMessagesIntoCurrentThreads(threads, messages)

    yield putResolve(chatActions.setThreads(threads))
    yield put(chatActions.countMessages())
}

function* sendMessage(action) {

    const body = action.payload
    const currentState = yield select()
    const credentials = currentState.auth.credentials
    const threadId = currentState.chat.selectedThreadId

    const sendMessageParameters = {
        acceptLanguage: currentState.settings.languageCode,
        credentials: credentials,
        threadId: threadId,
        body: body
    }

    const getMessagesPayload = {
        threadId: threadId,
        
        //XXX: just for sure, we jump forward 3 seconds to the future, to be sure to get the new messages
        //XXX: consider to change to a more robust solution
        lastTimeMicros: (new Date().getTime() + 3000) * 1000
    }

    try {
        //1. stop the long-polling
        yield putResolve(chatActions.pollStop())
        
        //2. send the message
        yield call(chatRepo.sendMessage, sendMessageParameters)

        //3. do the 'cheap' update
        yield putResolve(chatActions.getMessages(getMessagesPayload))

        //4. restart the long-polling
        //We moved this to the finally section
        //yield putResolve(chatActions.pollStart())
        
        //5. send the success mnessage
        yield putResolve(chatActions.sendMessageSuccess())

        //6. count messages
        yield putResolve(chatActions.countMessages())
    } catch (e) {
        yield putResolve(errorActions.addError(new ApiError(e, ApiError.MODULE_CHAT)))
        yield delay(5000)
    } finally {
        //7. restart the long-polling
        yield putResolve(chatActions.pollStart())
    }
}

function* countMessages(action) {

    const currentState = yield select()
    const threads = currentState.chat.threads
    
    const counters = {
        totalUnreadMessageCount: 0,
        threads: {}
    }

    threads.forEach( t => {
        let threadCounters = counters.threads[`${t.id}`]
        threadCounters = {
            unreadMessageCount: 0
        }
        t.messages.forEach( m => {
            if (m.readStatus.name === STATUS_UNREAD) {
                counters.totalUnreadMessageCount++
                threadCounters.unreadMessagesCount++
            }
        })
    })

    yield putResolve(chatActions.setCounters(counters))
}

export function* saga() {
    D("Forked.")
    yield takeEvery(actionTypes.CHAT_GET_MESSAGES, getMessages)
    yield takeEvery(actionTypes.CHAT_GET_MESSAGES_SUCCESS, getMessagesSuccess)

    yield takeEvery(actionTypes.CHAT_SEND_MESSAGE, sendMessage)
    yield takeEvery(actionTypes.CHAT_COUNT_MESSAGES, countMessages)
}