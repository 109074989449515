// i18n
import { Alert } from '@mui/material'
import { useTranslation } from 'react-i18next'

const NoResult = (props) => {

    const { t } = useTranslation()

    let { message } = props

    if (!message) message = t("general.noResult")

    return <Alert severity="warning">{message}</Alert>
}

export default NoResult