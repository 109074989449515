import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next';

// Other
import InfiniteScroll from 'react-infinite-scroller'
import InfiniteScrollMessage from '../../InfiniteScroll/InfiniteScrollMessage'

// Own
import NoResult from '../../Filter/NoResult';
import RouteList from './RouteList';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  
  root: {
   flex: 1,
   display: "flex",
   alignItems: "stretch",
   paddingLeft: ".5rem",

   //just for debugging
   //backgroundColor: "green",
 },

 // TODO: is there a better way to do this?
 // Could it be done easier? Is it a good place for this?
 flexWrapper: {
   flex: 1,
   alignItems: "stretch",
   width: `100%`,
   
   //just for debugging
   //backgroundColor: "pink",
 },

 /* This wrapper is necessary to fit & scroll perfectly */
 relativeWrapper: {
   position: "relative",
   top: 0,
   left: 0,
   width: "100%",
   height: "100%",
 },

 scroller: {
   position: "absolute",
   top: 0,
   left: 0,
   width: "100%",
   height: "100%",
   overflow: "scroll",
   
   //Firefox
   scrollbarWidth: "thin",
   scrollbarColor: `${theme.palette.grey[400]} transparent`,

   //Chrome
   "&::-webkit-scrollbar": {
     width: ".5rem",
     background: "transparent",
   },
   "&::-webkit-scrollbar-thumb": {
     background: theme.palette.grey[400],
     borderRadius: "1rem",
   },
   //for debug:
   //backgroundColor: "yellow",
 },

})); 

const ScrollContainer = ({items, selectedItems, next, hasMore}) => {
  
    const classes = useStyles()
    const scrollParentRef = useRef(null)
    const { t } = useTranslation()
  
    if (items.length === 0) return <NoResult />
  
    return (
  
      <div className={classes.root}>
         <div className={classes.flexWrapper}>
            <div className={classes.relativeWrapper}>
              <div className={classes.scroller} ref={scrollParentRef} >
                <InfiniteScroll
                  pageStart={0}
                  initialLoad={false}
                  loadMore={next || (() => {})} // if no next function is provided, use an empty function to prevent console errors messages
                  hasMore={hasMore}
                  useWindow={false}
                  getScrollParent={() => scrollParentRef.current}
                  loader={<InfiniteScrollMessage message={t("routes.loadingTargets")} />}>
                    
                  <RouteList items={items} selectedItems={selectedItems} />
  
                </InfiniteScroll>
            </div>
          </div>
        </div>
      </div>
    )
  } 

  export default ScrollContainer