import React, { Fragment } from 'react'


// i18n imports
import i18next from "i18next";
import { useTranslation } from 'react-i18next';
import { LANGUAGE_FILES_URL } from '../../utilities/Common';
import { TableCell, TableRow, Typography } from '@mui/material';

const Language = () => {

    const { t } = useTranslation();    
    
    return (
        <>
            <TableRow>
                <TableCell component="th" scope="row" colSpan={2}>
                    <Typography variant="h6" component="h6">
                        {t("systemInfo.language")}
                    </Typography>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell>{t("systemInfo.selectedAndFallback")}</TableCell>
                <TableCell>{i18next.languages.map((e, i) => i === i18next.languages.length - 1 ? `${e}` : `${e}, `)}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell>{t("systemInfo.languageFilesURL")}</TableCell>
                <TableCell>{LANGUAGE_FILES_URL}</TableCell>
            </TableRow>
        </>
    )
}

export default Language