import React, { useState } from 'react'
import { connect } from 'react-redux'

import * as errorActions from '../../store/actions/error'

import { useTranslation } from 'react-i18next'

import AllErrors from './AllErrors'
import DisplayableError from '../../other/DisplayableError'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'

const ErrorDialog = (props) => {

    const { t } = useTranslation()
    const [open, setOpen] = useState(false)
    const [showAllErrors, setShowAllErrors] = useState(false)
    const allItems = props.errorState.items
    const items = allItems.filter((i) => i.severity > DisplayableError.SEVERITY_WARNING)
    const notAcknowledgeds = items.filter( i => !i.acknowledged)
    const multipleItems = items.length > 1
    const currentItem = notAcknowledgeds.length > 0 ? notAcknowledgeds[0] : null

    console.debug("[ErrorDialog.js] Items: ", items)

    const toggleShowAllErrors = () => {
        setShowAllErrors(!showAllErrors)
    }

    const handleOk = (uuid) => {
        props.setAcknowledged(uuid)
        setOpen(false)
    };

    // We are going to open if we have errors
    if (!open && currentItem) { setOpen(true) }


    const showOrHideAllErrors = showAllErrors ? t("error.dialog.hideAll") : t("error.dialog.showAll")
    const showAction = multipleItems ? <Button onClick={toggleShowAllErrors}>{showOrHideAllErrors}</Button> : null

    if (!currentItem) { return null }

    const content = showAllErrors
    ?
        <AllErrors show={showAllErrors} items={items} />
    :
        <DialogContentText id="error-dialog-description">
            {t(currentItem.messageKey)}
        </DialogContentText>

    const title = showAllErrors ? t("error.dialog.allErrors") : t(currentItem.titleKey)

    return (
        <Dialog open={open} fullWidth maxWidth="sm">
            
            <DialogTitle id="error-dialog-title">{title}</DialogTitle>
            
            <DialogContent>
                {content}
            </DialogContent>

            <DialogActions>
                {showAction}
                <Button onClick={() => {handleOk(currentItem.uuid)}} color="primary">{t("error.dialog.ok")}</Button>
            </DialogActions>

        </Dialog>
    )
}

function mapStateToProps(state) {
    return {
      errorState: state.error
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setAcknowledged: (uuid) => dispatch(errorActions.setAcknowledged(uuid)),
    }
  }

export default connect(mapStateToProps, mapDispatchToProps)(ErrorDialog)