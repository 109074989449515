// Rreact imports
import React, { useEffect } from 'react'

// Redux imports
import { connect } from 'react-redux'
import * as settingsActions from './../../store/actions/settings'


// Own components
import ProfilePane from '../Dialogs/ProfileDialog.js'
import BottomMenu from '../BottomMenu/BottomMenu.js'
import MapView from '../Map/MapView.js'
import SignIn from '../SignIn/SignIn.js'
import FilterPane from '../Filter/FilterPane.js'
import UserMessage from '../UserSettings/UserMessage.js'

import RefuelsResults from '../Refuels/RefuelsResult.js'
import SystemInfo from '../SystemInfo/SystemInfo.js'

import * as navigationRoutes from '../BottomMenu/NavigationRoutes.js'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import ChatResult from '../Chat/ChatResult.js'
import Authenticated from './Authenticated.js'
import ContactsPane from '../Dialogs/ContactsDialog.js'
import TrackingMapObjects from '../Tracking/TrackingMapObjects.js'
import RoutesMapObjects from '../Routes/RoutesMapObjects.js'

import { makeStyles } from '@mui/styles'
import TargetLoadingDialog from '../Tracking/TargetLoadingDialog.js'
import ErrorDialog from '../ErrorDialog/ErrorDialog.js'
import WarningMessage from '../ErrorDialog/WarningMessage.js'
import { BOTTOM_MENU_HEIGHT, createPlutoLogger, isTokenValid } from '../../utilities/Common.js'
import DialogContainer from './DialogContainer.js'
import ResultContainer from './ResultContainer.js'
import { useTranslation } from 'react-i18next'

const D = createPlutoLogger("🔲 [Layout.js]")

const useStyles = makeStyles((theme) => {

  /*// XXX: For robustness, we can use a fallback color
  //
  // There was a rare strange case where the theme was not available.
  // TODO: Investigate why the theme is not available in this rare case
  // 
  // My first impression is that the theme is somehow not available when the component is being rendered.
  // If this is the case, we can wait for the theme is being available at the App.js file by using a useEffect hook.
  // The theme is comming from AppControlProvider.js so it could be changed there during the rendering of the App.js. (edi)
  //
  // This was I one time bug and I could not reproduce it. The cause was a cache error during starting, so it is not related to the theme or the normal
  // operation of the application. I leave the fallback color here for future reference.
  let mainColor = '#00375c'
  if (theme?.palette?.primary?.main != null) {
    mainColor = theme.palette.primary.main
    console.warn("[Layout.js] Theme or propery of theme is not available! Using fallback color. mainColor: ", mainColor, ". Theme object: ", theme)
  }*/

  return {
    '@global': {    
      html: {
        overscrollBehavior: "none", //Prevent the page from scrolling when the user tries to scroll past the top or bottom
      },
      body: {
        padding: 0,
        margin: 0,
      },
      button: {
        cursor: "pointer",
      },
      svg: {
        cursor: "pointer",
      },
      a: {
        cursor: "pointer",
      },
      /*
      // Does not work with this way. I leave it here for future reference.
      // TODO: Find a way to apply pointer cursor to all clickable elements.
      // https://stackoverflow.com/questions/34680463/apply-style-cursor-pointer-to-all-react-components-with-onclick-function
      [`role='button'`]: {
        cursor: "pointer",
      },
      [`tabindex`]: {
        cursor: "pointer",
      },
      [`onclick`]: {
        cursor: "pointer",
      }*/
    },
    root: {
      height: "100vh", // For browsers that do not support 100dvh (100% of the viewport height)
      display: "flex",
      flexDirection: "column",
      backgroundColor: theme.palette.primary.main,
      position: 'relative', // Ensure that child elements are positioned relative to this container
      overflow: 'hidden',   // Prevent overflow issues
    },
    [`@supports (height: 100dvh)`]: {
      root: {
        height: "100dvh",
      },
    },
    contentContainer: {
      position: "relative",
      width: "100%",
      height: "100%",
    },
    mapContainer: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      zIndex: 0,
    },
    //
    // The Eclipse z-index is 20.
    //
    // containers over the Eclipse has to be as small as the content to let the Eclipse to get the click event and the Map get the mouse events.
    // so components goes into these containers has to be a size defined component wrapped around!
    pageContainerOverEclipse: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "fit-content",
      height: "fit-content",
      zIndex: 20,
    },
    menuContainer: {
      height: BOTTOM_MENU_HEIGHT,
      backgroundColor: theme.palette.primary.main,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    bottomMenu: {
      width: "100%",
      maxWidth: "768px",
    },
  }
})

const Layout = (props) => {
  
  const classes = useStyles()
  const location = useLocation()
  const navigate = useNavigate()

  const { languageChange } = props
  const propsLanguageCode = props.languageCode
  D("Language code from props: ", propsLanguageCode)
  
  const settingState = props.settingsState
  const settingsLanguageCode = settingState.languageCode
  D("Language code from settings state: ", settingsLanguageCode)

  const { i18n } = useTranslation()

  useEffect(() => {
    D("[useEffect] [propsLanguageCode] propsLanguageCode: ", propsLanguageCode, " i18n.language: ", i18n.language)
    if (i18n.language !== propsLanguageCode) {
      D("[useEffect] [propsLanguageCode] Changing language to: ", propsLanguageCode)
      languageChange(propsLanguageCode)
      //i18n.changeLanguage(propsLanguageCode)
    }
  }, [propsLanguageCode, i18n])

  useEffect(() => {
    D("[useEffect] settingsLanguageCode: ", settingsLanguageCode)
    if (i18n.language !== settingsLanguageCode) {
      D("Changing language to: ", settingsLanguageCode)
      i18n.changeLanguage(settingsLanguageCode)
    }
  }, [settingsLanguageCode, i18n])

  // location callback
  useEffect(() => {
    try {

      // It could be a rewrite rule in the server side, but we can handle it here as well.
      if (location.pathname === '/') {
        navigate(`${navigationRoutes.NAVIGATION_ROUTE_TRACKING}${location.search}`) // Redirect to the tracking page
        return
      }

      const authState = props.authState
      const credentials = authState.credentials
      if (!credentials) {
        D("No credentials found in the auth state.")
        return
      }
      const token = credentials.token
      const validToken = isTokenValid(token)

      const allowedRoutesForToken = [
        navigationRoutes.NAVIGATION_ROUTE_TRACKING,
        navigationRoutes.NAVIGATION_ROUTE_SYS_INFO,
      ]
      const isRouteAllowedForToken = allowedRoutesForToken.includes(location.pathname)
      D(`Token is valid: ${validToken}, Is pathname allowed: ${isRouteAllowedForToken}`)

      if (validToken && !isRouteAllowedForToken) {
        D("[Layout.js] Token is valid but pathname is not allowed. Redirecting to the tracking page.")
        navigate(navigationRoutes.NAVIGATION_ROUTE_TRACKING)
      }
    } catch (e) {
      console.warn("[Layout.js] [useEffect] Error in location callback:", e)
    }
      
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  D('Render');

  return (
      <>
        <ErrorDialog />
        <WarningMessage />
        <div className={classes.root}>
            <div className={classes.contentContainer}>
              <div className={classes.mapContainer}>
                <MapView>
                  <Authenticated>
                    <Routes>
                      <Route path={navigationRoutes.NAVIGATION_ROUTE_TRACKING} element={<TrackingMapObjects />} />
                      <Route path={navigationRoutes.NAVIGATION_ROUTE_ROUTES} element={<RoutesMapObjects />} />
                      <Route path="*" element={null} /> {/* This will catch all unmatched routes and render nothing, no warning on console */}
                    </Routes>
                  </Authenticated>
                </MapView>
              </div>
              <Authenticated
                nonAuthenticated={<SignIn />}
                >
                {/* FilterPane wrapper around by Eclipse at z-Index 2 */}
                <FilterPane />
              </Authenticated>
              <Authenticated>
                  <Routes>
                      <Route path={navigationRoutes.NAVIGATION_ROUTE_TRACKING} element={<TargetLoadingDialog />}/>
                      <Route path={navigationRoutes.NAVIGATION_ROUTE_SYS_INFO} element={
                        <ResultContainer>
                          <SystemInfo />
                        </ResultContainer>} />
                      <Route path={navigationRoutes.NAVIGATION_ROUTE_REFUELS} element={
                        <ResultContainer>
                          <RefuelsResults />
                        </ResultContainer>} />
                      <Route path={navigationRoutes.NAVIGATION_ROUTE_CHAT} element={
                        <ResultContainer>
                          <ChatResult />
                        </ResultContainer>} />
                      <Route path="*" element={null} /> {/* This will catch all unmatched routes and render nothing, no warning on console */}
                  </Routes>
              </Authenticated>
              {/* Eclipse goes here */}
              {/* Page container over the Eclipse */}
              <div className={classes.pageContainerOverEclipse}>
              </div>
              <Authenticated>
                <DialogContainer>
                  <ContactsPane />
                </DialogContainer>
                <DialogContainer>
                  <ProfilePane />
                </DialogContainer>
                <div className={classes.toastContainer}>
                  <UserMessage />
                </div>
              </Authenticated>
          </div>
          <Authenticated>
            <div className={classes.menuContainer}>
                <div className={classes.bottomMenu}>
                  <BottomMenu />
                </div>
            </div>
          </Authenticated>
      </div>
    </>
  )
}
const mapStateToProps = (state) => {
  return {
      settingsState: state.settings,
      authState: state.auth,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    languageChange: (code) => dispatch( settingsActions.languageChange(code) ),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Layout)