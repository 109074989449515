import { useTranslation } from "react-i18next"
import { clsx, formattedDateTime } from "../../utilities/Common"
import DisplayableError from "../../other/DisplayableError"
import { ModuleInfo } from "./ModuleInfo"
import { makeStyles } from "@mui/styles"
import { Grid } from "@mui/material"

const useStyles = makeStyles( (theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        padding: theme.spacing(1),
    },
    header: {
        fontSize: "0.65rem",
    },
    message: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        gap: "0.5rem",
    },
    date: {
        textAlign: "left",
    },
    [theme.breakpoints.up("sm")]: {
        date: {
            textAlign: "right",
        },
    },
    odd: {
        backgroundColor: theme.palette.grey[200],
    },
    even: {
        // no need to set background color
    },
    notAck: {
        fontWeight: "bold",
    },
    info: {
        color: theme.palette.common.black,
    }, 
    warning: {
        color: theme.palette.warning.main,
    },
    error: {
        color: theme.palette.warning.main,
    },
    fatal: {
        color: theme.palette.error.main,
    },
    unknown: {
        color: theme.palette.error.main,
    }, 
}))

const ErrorRow = ({ error, index }) => {
    
    console.error("[ErrorRow.js] ErrorRow: ", error, error.severity)

    const classes = useStyles()
    const { t } = useTranslation()
    const message = t(error.messageKey)
    const isAck = error.acknowledged
    
    const sevirityClasses = {
        [DisplayableError.SEVERITY_INFO]: "info",
        [DisplayableError.SEVERITY_WARNING]: "warning",
        [DisplayableError.SEVERITY_ERROR]: "error",
        [DisplayableError.SEVERITY_FATAL]: "fatal",
        [DisplayableError.SEVERITY_UNKNOWN]: "unknown"
    }
    const severityClass = sevirityClasses[error.severity]

    console.debug("[ErrorRow.js] classes: ", severityClass)

    const isIndexEven = index % 2 === 0
    const rootClasses = clsx({
        [classes.root]: true,
        [classes.even]: isIndexEven,
        [classes.odd]: !isIndexEven,
    })
    const messageClasses = clsx({
        [classes.message]: true,
        [classes.notAck]: !isAck,
        [classes[severityClass]]: true
    })
    const date = error.date
    return (
        <div className={rootClasses} key={index}>
            <Grid container className={classes.header}>
                <Grid item xs={12} sm={6}><ModuleInfo module={error.module} /></Grid>
                <Grid item xs={12} sm={6} className={classes.date}>{formattedDateTime(date)}</Grid>
            </Grid>
            <div className={messageClasses}>{message}</div>
        </div>
    )
}

export default ErrorRow