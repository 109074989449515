// React imports
import React, {useEffect, useRef, useState } from 'react'

// Redux imports
import { connect } from 'react-redux'
import MessageList from './MessageList'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
    root: {
        flex: 1,
        overflowY: "scroll",
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },

    /*
    // This class is used for debugging purposes.
    // It displays a debug info box at the top left corner of the window,
    // above all other HTML elements.
    debug: {
        position: "fixed",
        top: 0,
        left: 0,
        zIndex: 10000,
        padding: 10,
        color: "green",
        backgroundColor: "rgba(177,177,177,77)"
    }*/
}))

// This component is responsible for scrolling to the bottom of the chat window.
// We handle the full logic here because it is part of the presentation layer.
// There is no other scroll handling logic in the saga layer anymore.
const ScrollToBottomManager = (props) => {

    const classes = useStyles()
    const scrollRef = useRef(null)
    const { messages, firstMessageSentTime, handlePreviousClick, selectedThreadId } = props
    const messageLength = messages ? messages.length : 0

    // Local scroll state
    const [scrolled, setScrolled] = useState(false)

    //console.debug("[ScrollToBottomManager] scrolled", scrolled, messageLength)

    // When the previous button is visible without scrolling, the scrolled state is
    // false. Due to this, in case of clicking the button, the scroller logic will be activated. 
    // This behavior is unintended, and we want to prevent it, so we set the scrolled flag to true when clicking the button.
    const preHandlePreviousClick = () => {
        //console.debug("[ScrollToBottomManager] Previous button has been clicked. Prehandling click...")
        
        // XXX: setScrolled is asynchronous, but for simplicity, we do not account for that,
        // and we call the handler immediately after setScrolled has been invoked.
        setScrolled(true)
        handlePreviousClick()
    }

    // When the selected thread changes, we need to set the scrolled flag to false
    // in order to scroll the window to the very bottom.
    useEffect(() => {
        //console.debug("[ScrollToBottomManager.js] Selected thread ID has been changed. Setting scrolled to 'false'")
        setScrolled(false)
    }, [selectedThreadId])

    // This is the main logic: we add and remove the scroll listener,
    // compare the heights, and perform scrolling if necessary.
    useEffect(() => {
        const scrollEvent = "scroll"
        const scrollableElement = scrollRef.current

        const scrollListener = scrollableElement.addEventListener(scrollEvent, (event) => {
            //console.debug("[ScrollToBottomManager.js] Scrolled event happened")
            
            if (!scrolled) {
               //console.debug("[[ScrollToBottomManager.js] Scrolled flag is 'false', setting to 'true'")            
               setScrolled(true)
            }

            const scrollTop = scrollableElement.scrollTop
            const offsetHeight = scrollableElement.offsetHeight
            const scrollHeight = scrollableElement.scrollHeight
            const totalHeight = scrollTop + offsetHeight

            // This value could be slightly smaller than the integer scrollHeight,
            // so we use `ceil` here to account for any case.
            const ceiledTotalHeight = Math.ceil(totalHeight)
            
            //console.debug("[ScrollToBottomManager.js] ceiledTotalHeight: ", ceiledTotalHeight)
            //console.debug("[ScrollToBottomManager.js] scrollHeight: ", scrollHeight)

            if (ceiledTotalHeight >= scrollHeight) {
                //console.debug("[ScrollToBottomManager.js] Scrolled to bottom. Setting scrolled...")
                setScrolled(false)
            }
        
        })
        //console.debug("[ScrollToBottomManager.js] Scroll event listener has been added")

        if (!scrolled) {
            scrollableElement.scrollTop = scrollableElement.scrollHeight
            //console.debug("[ScrollToBottomManager.js] Scroll to bottom... ")
        } else {
            //console.debug("[ScrollToBottomManager.js] Scrolled! No scroll to bottom!")
        }

        // This runs when the component is being removed.
        // In this case, we remove the listener.
        return () => {
            scrollableElement.removeEventListener(scrollEvent, scrollListener)
            //console.debug("[ScrollToBottomManager.js] scrollEventListener removed.")
        }

    }, [messageLength, scrolled, selectedThreadId])
    
    return (
        <>
            {/* 

                // Uncomment this element for debugging scrolling
                // You should uncomment the related style class also
                <div className={classes.debug}>
                    {selectedThreadId}&nbsp;{scrolled ? "^^^ scrolled ^^^" : "___ bottom ___"}
                </div>

            */}

            <div ref={scrollRef} className={classes.root} id="chat-scroll-container">
                <MessageList
                    messages={messages}
                    firstMessageSentTime={firstMessageSentTime}
                    handlePreviousClick={preHandlePreviousClick} />
           </div>
        </>
    )
}

const mapDispatchToProps = dispatch => {
    return {
        // No dispatch
    }
}

const mapStateToProps = (state) => {
    return {
        state: state
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ScrollToBottomManager)