// React imports
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import * as authActions from '../../store/actions/auth'
import * as settingsActions from '../../store/actions/settings'

// import i18next
import { useTranslation } from 'react-i18next'

import {
  ComputerRounded,
  Apple,
  Android,
} from '@mui/icons-material'

import logo from '../../assets/itrack-logo.svg'
import LanguageSelector from '../LanguageSelector/LanguageSelector'
import Registration from './Registration'
import Form from './Form'
import Copyright from './Copyright'
import Eclipse from '../App/Eclipse'
import ModalProgressIndicator from '../Progress/ModalProgressIndicator'
import { makeStyles } from '@mui/styles'
import { Box, Grid, Tab, Tabs, Tooltip } from '@mui/material'
import { useAppControl } from '../App/AppControlProvider'
import { ANDROID_EXOSKELETON, buildIdString, createPlutoLogger, IOS_EXOSKELETON } from '../../utilities/Common'

const positions = require('../../config/language-positions.json');

const useSyles = makeStyles( theme => {

  return ({
    container: {
      minWidth: "320px",
      boxShadow: theme.shadows[3],
      backgroundColor: theme.palette.background.paper,
      borderRadius: theme.shape.borderRadius,
      padding: theme.spacing(1),
    },
    paper: {
      display: 'flex',
      flexDirection: 'column',
    },
    logoContainer: {
      marginTop: "1.5rem",
      marginLeft: "1rem",
      marginRight: "1rem",
      marginBottom: ".5rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      //backgroundColor: "rgba(34,56,72,.5)", //just for debugging!
      "& img": {
        width: "65%",
      }
    },
    form: {
      width: '100%',
      marginTop: theme.spacing(1),
    },
    signUp: {
        textAlign: "right",
    },
    footer: {
      borderWidth: "1px",
      borderTopStyle: "solid",
      borderTopColor: theme.palette.grey['400']
    },
    languageWrapper: {
      //
    },
    buildId: {
      color: theme.palette.grey['400'],
      fontSize: ".75rem",
      flexDirection: "row",
    },
    packageVersion: {
      paddingRight: ".5rem",
    }
  })
})

const D = createPlutoLogger("[SignIn.js]")

const PresenterIcon = ({presenter}) => {
  if (presenter === IOS_EXOSKELETON) return <Apple color="disabled" fontSize="small"/>
  if (presenter === ANDROID_EXOSKELETON) return <Android color="disabled" fontSize="small"/>
  return <ComputerRounded color="disabled" fontSize="small"/>
}

const SignIn = (props) => {

  const { t } = useTranslation()

  const { state, onAuth } = props
  const { auth, settings, device } =  state

  const { getMap } = useAppControl()
  const map = getMap()

  const classes = useSyles()
  const [tabValue, setTabValue] = useState(0)
  const { i18n } = useTranslation()
  const lng = i18n.language

  const { inProgress } = auth
  const { forcedAutoLogin } = settings

  const moveMap = (map, code) => {
    try {
      const mapPosition = positions[code]
      if (mapPosition && map) {
        console.debug("[SignIn.js] Changing map position to: ", mapPosition)
        map.flyTo(mapPosition.center, mapPosition.zoom)
      } else {
        console.warn("[SignIn.js] Oops! Something went wrong with the map! Map object or map position is missing! Map: ", map, "map position: ", mapPosition)
      }
    } catch (error) {
      console.error("[SignIn.js] Error while changing map position: ", error)
    }
  }

  // There could be a situation when the map isn't ready yet, but the language has been changed.
  // especially when we start with language parameter in the URL. In this case, we need to wait for the map to be ready.
  // This effect will be triggered when the map or the language has been changed.
  useEffect(() => {
    D("Map is: ", map, "Language is: ", lng)
    if (map && lng) {
      moveMap(map, lng)
    }
  }, [map, lng])

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  }

  const handleSubmit = (credentials) => {
    onAuth(credentials)
  }

  const handleForgotPassword = () => { 
    const to = process.env.REACT_APP_PASSWORD_RESET_URL
    window.location.href = to
  }

  const tabContent = [
    <Form
      handleSubmit={handleSubmit}
      handleForgotPassword={handleForgotPassword}
      setForcedAutoLogin={props.setForcedAutoLogin}
      presenter={device.presenter}
      forcedAutoLogin={forcedAutoLogin} />,
    <Registration />,
  ]
 
  return (
    <Eclipse>
      <div className={classes.container}>

          <div className={classes.logoContainer}>
             <img src={logo} alt='iTrack logo' />
          </div>          

          <div className={classes.paper}>

            <Box mt={2}>
              <Tabs
              value={tabValue}
              variant="fullWidth"
              indicatorColor="primary"
              textColor="primary"
              onChange={handleTabChange}
              aria-label="SignIn tabs">
                <Tab label={t("login.signIn")} />
                <Tab label={t("login.registration")} />
              </Tabs>
            </Box>

            {tabContent[tabValue]}

            <Box mb={2} mt={2} className={classes.footer}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="stretch">

                <Grid item xs={12} className={classes.languageWrapper}>
                    <LanguageSelector /> 
                </Grid>

                <Grid item xs={2} container justifyContent="flex-start" alignContent="center">
                  <PresenterIcon presenter={device.presenter} />
                </Grid>
                
                <Grid item xs={10} container justifyContent="flex-end" alignContent="center" className={classes.buildId}>
                  <Tooltip title="build ID">
                    <span>
                      <span className={classes.packageVersion}>{process.env.REACT_APP_NPM_PACKAGE_VERSION}</span>
                      {buildIdString()}&nbsp;
                      ({process.env.REACT_APP_ENV_ID || "???"})
                    </span>
                  </Tooltip>
                  <Copyright />
                </Grid>
            
              </Grid>
            </Box>
          </div>

      </div>

      <ModalProgressIndicator
        id="login-progress"
        message={t("login.inProgress")}
        flag={inProgress}
        showPaper />
    </Eclipse>
  )
}

const mapDispatchToProps = dispatch => {
  return {
    onAuth: (credentials) => dispatch(authActions.authStart(credentials)),
    setForcedAutoLogin: (value) => dispatch(settingsActions.setForcedAutoLogin(value)),
  }
}

function mapStateToProps(state) {
  return {
    state: state,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignIn)