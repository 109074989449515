import { take, takeEvery, putResolve } from 'redux-saga/effects'
import { eventChannel } from 'redux-saga'

import * as actionTypes from '../actions/actionTypes'
import * as actions from '../actions/device'
import * as COM from '../../utilities/Common' 

// Channel to communicate with the Flutter app
function* createReceiverChannel() {

    if (!window.Exoskeleton) {
        console.debug("[SAGA] [device.js] Exoskeleton is not available!")
        yield putResolve(actions.set({
            info: {
                userAgent: navigator.userAgent,
            },
            presenter: COM.BROWSER,
        }))
        yield putResolve(actions.infoReady())

        // We do not have to wait for the device information, because we are not in exoskeleton
        return
    }

    const channel = eventChannel( emitter => {

        //1. Create a receiver function
        window.iTrackPluto = {
            exoskeletonPresented: (platform) => {
                console.debug("[SAGA] [device.js] exoskeletonPresented function called with paramter: ", platform)
                emitter(platform)
                //emitter(END)
            }
        }

       /* 
        // We do not use this, but I keep it here for future reference
        // 4. Sending the END signal if the timeout exceeeded.
        const timeoutInterval = setTimeout(() => {
            emitter(END)
        }, WAIT_FOR_IOS_DEVICE_INFO_TIMEOUT)

        return () => clearInterval(timeoutInterval)
        */
        return () => console.log("[SAGA] [device.js] Channel closed!")
    })

    try {    
        //Send the first message to the exoskeleton
        window?.Exoskeleton?.postMessage("Welcome from the web app!")
        while (true) {

            // Wainting until we get an event on channel
            const platform = yield take(channel)
            console.debug("[SAGA] [device.js] Message received on channel: ", platform)
 
            // 5. Create the object and store into the store            
            const deviceInfo = {
                info: {},  // We do not handle any system information at the moment but the Fullter app could send it (Device name, OS version, etc.)
                presenter: platform,
            }
            yield putResolve(actions.set(deviceInfo))
            console.debug("[SAGA] [device.js] Device info has been set. Value: ", deviceInfo)

            // This action signs the device information has been collected, the auth saga could take this event
            yield putResolve(actions.infoReady())
        }

    } finally {
        // END jumps here (we do not use it now)
        // 6. Close the channel
        channel.close()
        console.log('[SAGA] [device.js] Device info channel has been closed!')
    }
}

export function* saga() {
    // Store created event is the first event in the application. It runs only once.
    // But in the future, we can use fork() logic here.
    yield take(actionTypes.STORE_CREATED)
    yield createReceiverChannel()
}