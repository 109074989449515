
import { makeStyles } from "@mui/styles";
import TargetMeasuresKeyValueTable from "./TargetMeasuresKeyValueTable";

const useStyles = makeStyles(theme => ({
  root: {
    padding: ".5rem",
    borderRadius: ".5rem",
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    opacity: 1,
    fontSize: "12px !important",
  },
}))

const Measures = (props) => {
  const { measures } = props
  const classes = useStyles()

  // For some reason I forgot, there was a case where we had to ensure the given measures are an array.
  // Investigate this and remove the check if it is not needed.
  const showMeasures = measures && Array.isArray(measures) && measures.length > 0

  if (!showMeasures) return null;

  return (
    <div className={classes.root}>
      <TargetMeasuresKeyValueTable array={measures} />
    </div>
  )
}

export default Measures
  