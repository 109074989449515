// Basic imports
import React from 'react'
import { clsx } from '../../utilities/Common'
import { connect } from 'react-redux'


// Own components
import NoResult from '../Filter/NoResult'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@mui/styles'
import { useAppControl } from '../App/AppControlProvider'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  fixedContainer: {
    // BUGFIX
    // The container is fixed to the top of all other elements
    // In this case we have to turn off pointer events to let the underlying elements to be clickable
    pointerEvents: "none",

    position: "fixed",
    right: "0px",
    top: "0px",
    margin: ".25rem",
    zIndex: 10000,
    display: "flex",
    justifyContent: "flex-end",
  },
  fullWith: {
    display: "flex",
    flexDirection: "column",
    padding: ".5rem",
  },
  withBorder: {
    border: "1px solid #ff9800",
    boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.25)",
    borderRadius: ".25rem",
    opacity: .9,
  },
}))

const RefuelsNoResultHeader = (props) => {

    const classes = useStyles()
    const { t } = useTranslation()
    
    // old Redux
    const state = props.state
    const { refuels} = state

    // new hooks
    const { getFilterPaneOpen } = useAppControl()
    const isFilterPaneVisible = getFilterPaneOpen()

    const containerClasses = clsx({
      [classes.root]: true,
      [classes.fixedContainer]: isFilterPaneVisible,
      [classes.fullWith]: !isFilterPaneVisible,
    })

    const noResultClasses = clsx({
      [classes.withBorder]: isFilterPaneVisible,
    })

    const defaultMessage = t("refuels.noResult")
    const targetDetails = `(${refuels.target.name}, ${refuels.target.description})`
    const message = !isFilterPaneVisible ? `${defaultMessage} ${targetDetails}` : defaultMessage

    return (
          <div className={containerClasses}>
              <div className={noResultClasses}>
                <NoResult message={message} />
              </div>
          </div>
    )
}

const mapDispatchToProps = dispatch => {
  return {
    // no methods to dispatch
  }
}

function mapStateToProps(state) {
  return {
      state: state,
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(RefuelsNoResultHeader)