import { ApiError } from './ApiError'
import { isTokenValid } from '../utilities/Common'

export class AuthError extends ApiError {
    
    static MESSAGE_KEY_ROOT = "error.auth"

    constructor(exp, credentials, messageKey) {      
      
        super(exp)

        const status = this.exception?.response?.status

        // We assume that the status code may be a string or a number, so we use == instead of ===
        // eslint-disable-next-line
        if (status == 401) {
            const token = credentials.token
            const validToken = isTokenValid(token)
            const messageKey = validToken ? "invalidToken" : "invalidCredentials"
            this.messageKey = `${AuthError.MESSAGE_KEY_ROOT}.${messageKey}`
        }
        this.titleKey = `${AuthError.MESSAGE_KEY_ROOT}.title`
        this.severity = ApiError.SEVERITY_FATAL
        this.module = ApiError.MODULE_AUTH
        if (messageKey) {
            this.messageKey = messageKey
        }
    }

}