// React
import React from 'react'

// Redux
import { connect } from 'react-redux'
import * as trackingActions from '../../store/actions/tracking'

// Others
import TrackingSelectedTargets from './TrackingSelectedTargets'
import TargetsFilter from '../Filter/Targets/TargetsFilter'
import PlutoDrawer from '../Drawer/PlutoDrawer'
import LoadingIndicator from './TargetLoadingDialog'

const TrackingFilter = (props) => {

    const { state } = props
    const { tracking, targets } = state

    // TODO: Model should be used here
    const selectedTargets = tracking.targets.map((e) => {
      return {id: e.target.id, ...e}
    })

    const handleTargetClick = (item) => props.addTargets([item])
    const handleAllTargetClick = (items) => props.replaceAllTargets(items)   
    const handleClearSelectedClick = () => props.replaceAllTargets([])   
    const handleClickDelete = (item) => props.removeTargetById(item.id) 
    
    return(
        <>
          <LoadingIndicator targets={selectedTargets} />
          <PlutoDrawer inProgress={tracking.inProgress || targets.inProgress} >
            <TargetsFilter
                selectedTargets={selectedTargets}
                onAddAllClick={handleAllTargetClick}
                onTargetClick={handleTargetClick}
                onClearSelectedClick={handleClearSelectedClick}
                showTargetMenu={true} >
              <TrackingSelectedTargets items={selectedTargets} onDelete={handleClickDelete} />
            </TargetsFilter>
          </PlutoDrawer>         
      </>
    )
}

const mapDispatchToProps = dispatch => {
  return {  
    //targets add & remove
    addTargets: (newItems) => dispatch(trackingActions.addTargets(newItems)),
    removeTargetById: (id) => dispatch(trackingActions.removeTargetById(id)),
    replaceAllTargets: (newItems) => dispatch(trackingActions.replaceAllTargets(newItems)), 
  }
}

const mapStateToProps = (state) => {
  return {
    state: state,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TrackingFilter)
