import { makeStyles } from "@mui/styles"

const useStyles = makeStyles((theme) => ({
    root: {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 24, 63, .75)", //!!! polished library necessary >>> rgba(theme.palette.primary.main, .75),
        zIndex: 20,  //This div goes over the map container. Map container is at z-index 10
    },
    wrapper: {
        position: "relative",
        width: "100%",
        height: "100%",
    },
    clicker: {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: 1,
    },
        
    // The content div is over the clicker div.
    // We have to keep the content div as small as possible the be able to get the click event on clicker which is the 'click outside' area.
    content: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: 2,  // Ensures this div is above the map container which is at z-index 1
        width: "fit-content", // Keeps the width as small as possible while allowing inner components to stretch with 100vw if needed
        height: "fit-content",
        display: "flex",
        flexDirection: "column",
    },
}))

const Eclipse = (props) => {
    const classes = useStyles()
    const { children } = props
    const onClick = props.onClick
    
    const handleClick = () => {
        console.debug("[Eclipse] Clicker div clicked")
        if (onClick) {
            onClick()
        }
    }
    
    return (
        <div className={classes.root}>
            <div className={classes.wrapper}>
                <div className={classes.clicker} onClick={handleClick}></div>
                <div className={classes.content}>
                    {children}
                </div>
            </div>
        </div>
    )
}

export default Eclipse