// React imports
import React from 'react'

// React Leaflet
import { MapContainer, TileLayer, ZoomControl } from "react-leaflet"

// Own components
import { ErrorBoundary } from './ErrorBoundary'
import { makeStyles } from '@mui/styles'
import { useAppControl } from '../App/AppControlProvider'
import { createPlutoLogger } from '../../utilities/Common'

const positions = require('../../config/language-positions.json');

const useStyles = makeStyles(theme => ({
  eclipse: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "rgba(0, 24, 63, .75)",
    zIndex: 1,
  },
  mapContainer: {
    width: "100%",
    height: "100%",
  },
}))

const D = createPlutoLogger("🗺️ [MapView.js]")

const MapView = (props) => {
    const classes = useStyles()
    const { setMap } = useAppControl()
    const { children } = props
    const tileLayerURL = process.env.REACT_APP_OSM_TILE_LAYER_DOMAIN + process.env.REACT_APP_OSM_TILE_LAYER_SUFFIX

    // Getting the map reference
    const handleWhenReady = (event) => {
      const map = event.target
      
      D('[handleWhenReady] Map is ready', map);
      // Provide the map reference to useAppControl hook
      if (map) {
        D("[handleWhenReady] Map will be set to the useAppControl hook")
        setMap(map) // For the new hook based state management
      } else {
        console.warn("[handleWhenReady] Oops! Something went wrong with the map! Map is missing! Map: ", map)
      }
    }

    D('[MapView] Rendering MapView component')

    return (     
      <ErrorBoundary>
        <>
          <MapContainer
              id='map-container'
              doubleClickZoom={false}
              center={positions.en.center}
              zoom={positions.en.zoom}
              scrollWheelZoom={true}
              zoomControl={false}
              maxZoom={17}
              minZoom={3}
              whenReady={handleWhenReady}
              className={classes.mapContainer}>

              <TileLayer
                url={tileLayerURL}
                attribution=""/>   

              {children}

              <ZoomControl position="bottomright" />
           
            </MapContainer>
        </>
    </ErrorBoundary>
    )
}

export default MapView