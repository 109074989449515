// Rreact imports
import { connect } from 'react-redux'

// common
import * as COM from '../../utilities/Common.js'

const D = COM.createPlutoLogger("[Authenticated.js]")

const Authenticated = (props) => {
   
    const { auth } = props.state
    const nonAuthenticated = props.nonAuthenticated
    const isPasswordLogin = (auth?.credentials?.username && auth?.credentials?.password) ? true : false
    const isTokenLogin = COM.isTokenValid(auth?.credentials?.token)
    const isAuthenticated = isPasswordLogin || isTokenLogin

    //D("[Authenticated.js] isAuthenticated: ", isAuthenticated, " isPasswordLogin: ", isPasswordLogin, " isTokenLogin: ", isTokenLogin) 
    //D(`[Authenticated.js] nonAuthenticated component is ${nonAuthenticated ? "defined" : "not defined"}`)
    //D(`[Authenticated.js] Children component count: ${props.children ? props.children.length : 0}`)

  if (!isAuthenticated) {
    return nonAuthenticated || null
  }

  return props.children
}

const mapDispatchToProps = dispatch => {
  return {
      // There is no need to dispatch any actions here
  }
}

function mapStateToProps(state) {
  return {
      state: state,
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Authenticated)