import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { MobileDateRangePicker } from '@mui/x-date-pickers-pro/MobileDateRangePicker'

// i18n
import { useTranslation } from 'react-i18next'

// other
import CustomMobileDateRangePickerLayout from './CustomMobileDateRangePickerLayout'
import { makeStyles } from '@mui/styles'
import { DateTime } from 'luxon'
import { createPlutoLogger } from '../../../utilities/Common'

const D = createPlutoLogger("📅 [DateRangeFilterWithLuxon.js]")

const previousMonday = (luxonDate) => luxonDate.minus({ days: luxonDate.weekday + 6})
const previousSunday = (luxonDate) => luxonDate.minus({ days: luxonDate.weekday })
const thisWeeksMonday = (luxonDate) => luxonDate.minus({ days: luxonDate.weekday - 1 })
const startOfDay = (luxonDate) => luxonDate.startOf('day')
const endOfDay = (luxonDate) => luxonDate.endOf('day')
const lastDayOfMonth = (luxonDate) => luxonDate.endOf('month')

const TODAY = DateTime.now()
const CURRENT_YEAR = TODAY.year;
const CURRENT_MONTH = TODAY.month;
const PREVIOUS_MONTH_FIRST_DAY = DateTime.local(CURRENT_YEAR, CURRENT_MONTH - 1, 1);
const PREVIOUS_MONTH_LAST_DAY = DateTime.local(CURRENT_YEAR, CURRENT_MONTH, 1).minus({ days: 1 });
const FIRST_DAY_OF_CURRENT_MONTH = DateTime.local(CURRENT_YEAR, CURRENT_MONTH, 1);
const PREVIOUS_MONDAY = previousMonday(TODAY)
const PREVIOUS_SUNDAY = previousSunday(TODAY)

const useStyles = makeStyles((theme) => ({
  root: {
    boxSizing: "border-box",
    padding: theme.spacing(2),
    // The outer container has padding, so we don't need to add extra padding to the picker.
    // Adding extra padding would cause the picker to be misaligned with other elements.
    backgroundColor: theme.palette.grey[100],
    borderRadius: theme.shape.borderRadius,
  },
}))

const DateRangeFilterWithLuxon = (props) => {

  // Incoming dateRangeFrom and dateRangeTo are expected to be JavaScript Date objects
  const { dateRangeFrom, dateRangeTo, handleDateRangeChange } = props
  D("Date parameters, from: ", dateRangeFrom, " to: ", dateRangeTo)

  const fromMillisRef = useRef(dateRangeFrom.getTime())
  const toMillisRef = useRef(dateRangeTo.getTime())
  const didFromChange = fromMillisRef.current !== dateRangeFrom.getTime()
  const didToChange = toMillisRef.current !== dateRangeTo.getTime()

  // update the refs
  fromMillisRef.current = dateRangeFrom.getTime()
  toMillisRef.current = dateRangeTo.getTime()
 
  // We create the matching date object for the Adapter
  const luxonDateFrom = DateTime.fromJSDate(dateRangeFrom)
  const luxonDateTo = DateTime.fromJSDate(dateRangeTo)
  const dateRange = [luxonDateFrom, luxonDateTo]
  D("Date range object with Luxon objects:", dateRange)

  const [dateRangeCache, setDateRangeCache] = useState(dateRange)
  D("Date range cache with Luxon objects:", dateRangeCache)

  const [readyToSet, setReadyToSet] = useState(false)

  const classes = useStyles()

  // This effect handles updates when 'readyToSet' changes,
  // ensuring the 'dateRangeCache' reflects the correct value.
  // It triggers after the 'onClose' handler is called, confirming
  // that 'storeDateRange()' has been executed, and 'dateRangeCache' is updated.
  // This helps resolve issues when rapidly selecting a custom date range from 'customItems'.
  useEffect(() => {
    //D("[DateRangeFilter.js] useEffect() dateRange:", dateRange)
    //D("[DateRangeFilter.js] useEffect() dateRangeCache:", dateRangeCache)
    if (readyToSet) {
      handleChange(dateRangeCache)
      setReadyToSet(false)
    }
  // eslint-disable-next-line
  }, [readyToSet])
  // ^^^ eslint-disable-next-line is needed because we consciously want to ignore other dependencies

  const { t } = useTranslation()
  const shortcutItems = [
    {
      label: t("general.dateTime.today"),
      getValue: () => [startOfDay(TODAY), endOfDay(TODAY)],
    },
    {
      label: t("general.dateTime.thisWeek"),
      getValue: () => [thisWeeksMonday(TODAY), endOfDay(TODAY)]
    },
    {
      label: t("general.dateTime.previousWeek"),
      getValue: () => [startOfDay(PREVIOUS_MONDAY), endOfDay(PREVIOUS_SUNDAY)]
    },
    {
      label: t("general.dateTime.thisMonth"),
      getValue: () => [startOfDay(FIRST_DAY_OF_CURRENT_MONTH), endOfDay(lastDayOfMonth(TODAY))]
    },
    {
      label: t("general.dateTime.previousMonth"),
      getValue: () => [startOfDay(PREVIOUS_MONTH_FIRST_DAY), endOfDay(PREVIOUS_MONTH_LAST_DAY)]
    },
  ]

  const storeDateRange = (value) => {
    D("storeDateRange() dateRange:", value)
    setDateRangeCache(value)
  }


  const handleChange = (newValue) => {
    try { 
      
      D("handleChange() newValue:", newValue)
      const from = newValue[0]
      const to = newValue[1]
      
      D("handleChange() from:", from, " to:", to)

      // In Luxon, the 'isValid' property indicates whether the DateTime object is valid.
      // DateTime implements #valueOf to return the epoch timestamp, so you can compare DateTimes with <, >, <=, and >=. That lets you find out if one DateTime is after or before another DateTime.
      // https://moment.github.io/luxon/#/math?id=comparing-datetimes
      if (from.isValid && to.isValid &&  from < to) {
            const dateArray = [from.toJSDate(), to.toJSDate()]
            D("Calling change handler callback function with array contains JavaScript Date objects:", dateArray)

            // We have to call the parent's handler function with a date array in JavaScript Date format
            // the first element is the start date, the second is the end date
            handleDateRangeChange(dateArray)
      } else {  
        console.warn("[DateRangeFilter.js] handleChange() Invalid date range:", newValue)
      }

    } catch (error) {
      console.warn("[DateRangeFilter.js] handleChange() There was an error during checking dates:", error)
      console.warn("[DateRangeFilter.js] handleChange() Given array:", newValue)
      return
    }
  }

  const handleClose = () => {
    //D("[DateRangeFilter.js] handleClose() dateRangeCache:", dateRangeCache)
    setReadyToSet(true)
  }

  const handlePrevYearClick = useCallback(() => {
    try {
      const startDate = dateRangeCache[0];
      const endDate = dateRangeCache[1];
      if (startDate && endDate) {
        const newDateRange = [
          dateRangeCache[0].minus({ years: 1 }),
          dateRangeCache[1].minus({ years: 1 }),
        ];
        storeDateRange(newDateRange);
        D('handleOnPrevYearClick() New date range:', newDateRange);
      }
    } catch (error) {
      console.warn(
        '[DateRangeFilter.js] handlePrevYearClick() There was an error during checking dates:',
        error
      );
    }
  }, [dateRangeCache[0]?.toMillis(), dateRangeCache[1]?.toMillis(), D])

  const handleNextYearClick = useCallback(() => {
    try {
      const startDate = dateRangeCache[0];
      const endDate = dateRangeCache[1];
      if (startDate && endDate) {
        const newDateRange = [
          startDate.plus({ years: 1 }),
          endDate.plus({ years: 1 }),
        ];
        storeDateRange(newDateRange);
        D('handleOnNextYearClick() New date range:', newDateRange);
      }
    } catch (error) {
      console.warn(
        '[DateRangeFilter.js] handleNextYearClick() There was an error during checking dates:',
        error
      );
    }
  }, [dateRangeCache[0]?.toMillis(),  dateRangeCache[1]?.toMillis(), D])

  // Memoizing the layout part of the MobileDateRangePicker
  const memoizedLayout = useMemo(
    () => (props) => (
      <CustomMobileDateRangePickerLayout
        {...props}
        showYearArrows={!!(dateRangeCache[0] && dateRangeCache[1])}
        onPrevYearClick={handlePrevYearClick}
        onNextYearClick={handleNextYearClick}
      />
    ),
    [
      dateRangeCache[0]?.toMillis(),
      dateRangeCache[1]?.toMillis(),
      handlePrevYearClick,
      handleNextYearClick,
    ]
  )

  return (
    <div className={classes.root}>
        <MobileDateRangePicker
          id="date-range-picker"
          closeOnSelect={true}
          value={dateRangeCache}
          onClose={handleClose}
          onChange={storeDateRange}
          className={classes.picker}
          slots={{
            layout: memoizedLayout,
          }}
          slotProps={{
            shortcuts: {
              items: shortcutItems,
            },
          }}
        />
    </div>
  )
}

export default DateRangeFilterWithLuxon
