// setnry
import * as Sentry from "@sentry/react";

// logrocket
import LogRocket from 'logrocket';

const TELEMETRY_ENABLED = process.env.REACT_APP_TELEMETRY_ENABLED === 'true'
const SENTRY_TRACES_SAMPLE_RATE = parseFloat(process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE) || 0.3 //be careful parseFloat can return NaN

export const initMetrics = async () => {
    
    if (!TELEMETRY_ENABLED) {
        console.info("📊 Telemetry is disabled!")
        return
    }

    console.debug("📊 Sentry traces sample rate: ", SENTRY_TRACES_SAMPLE_RATE)
    Sentry.init({
        //dsn: "https://543ac7259c5a452dbc3893abf1f9ba90@o4504259711991808.ingest.sentry.io/4504265284255744",
        /// ^^^ This is the normal Pluto Sentry DSN ^^^

        dsn: "https://58efe31c3a99c584d6e3fc9309cc1fc3@o4504259711991808.ingest.us.sentry.io/4507606745481216",
        // ^^^ This is just for testing 2.4.1 ^^^
        
        integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
        ],
        // Performance Monitoring
        tracesSampleRate: SENTRY_TRACES_SAMPLE_RATE, //  Capture 100% of the transactions
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    })
    console.info("📊 Sentry initialized.")

    console.debug("📊 Initializing LogRocket...")
    LogRocket.init('whfqgl/pluto-uo0y4')
    console.info("📊 LogRocket initialized.")
}