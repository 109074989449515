import { OpenInBrowser } from "@mui/icons-material"
import { useAppControl } from "../App/AppControlProvider"

const OpenFilterPaneButton = () => {
    const { openFilterPane } = useAppControl()
    const handleIconClick = () => openFilterPane(true)

    return <OpenInBrowser onClick={handleIconClick}/>
}

export default OpenFilterPaneButton