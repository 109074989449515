
import { PlaylistAddRounded } from '@mui/icons-material'
import { Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';

const MAX_ADDABLE_ITEMS = 50

const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: ".75rem",
  },
}));

const AddAll = (props) => {

    const classes = useStyles()
    const { t } = useTranslation()

    const targetItems = props.targetItems
    const onClick = props.onClick
    
    if (!onClick || !targetItems || targetItems.length === 0) return null;
    // we want to filter the first MAX_ADDABLE_ITEMS items
    const needToFilter = targetItems.length > MAX_ADDABLE_ITEMS;
    const addableItems = needToFilter ? targetItems.slice(0, MAX_ADDABLE_ITEMS) : targetItems; 
  
    return (
      <div className={classes.root}>
        <Tooltip title={t("general.addAll")}>               
          <PlaylistAddRounded onClick={() => onClick(addableItems)}/>
        </Tooltip>
      </div>
    )
}

export default AddAll