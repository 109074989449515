// Action Types import
import * as actionTypes from '../actions/actionTypes'

import { update } from '../../utilities/Common.js'

// Initial state
const initialState = {
    mounted: false,
    selectedThreadId: null,   
    threads: [],
    serverTimeMicros: -1, //for the first call we need the messesges of the last 30 seconds only (-1 means "last 30 seconds" for the backend)
    counters: {},
    filter: "",
    filteringInProgress: false,
}

const reducer = (state = initialState, action) => {
   
    switch (action.type) {
        
        // Lifecycle
        case actionTypes.CHAT_SET_MOUNTED: return update(state, {mounted: action.payload})

        // Data
        case actionTypes.CHAT_SET_THREADS: return update(state, {threads: action.payload})
        case actionTypes.CHAT_SET_SERVER_TIME_MICROS: return update(state, {serverTimeMicros: action.payload})
        case actionTypes.CHAT_SET_SELECTED_THREAD_ID: return update(state, {selectedThreadId: action.payload})
        case actionTypes.CHAT_SET_COUNTERS: return update(state, {counters: action.payload})
        case actionTypes.CHAT_SET_FILTER: return update(state, {filter: action.payload})

        // Progress
        case actionTypes.CHAT_SET_FILTERING_IN_PROGRESS: return update(state, {filteringInProgress: action.payload})

        // Auth
        case actionTypes.AUTH_SIGN_OUT_SUCCESS: return update(state, initialState) //Return to initial state

        default: return state
    }
}

export default reducer