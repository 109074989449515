import React from 'react'
import { connect } from 'react-redux'

// i18n imports
import { useTranslation } from 'react-i18next'
import KeyValueTable from './KeyValueTable'
import { TableCell, TableRow, Typography } from '@mui/material'

const DeviceInfo = (props) => {

    const { t } = useTranslation()
    const { deviceState } = props
    const { info, presenter } = deviceState

    return (
        <>
            <TableRow>
                <TableCell component="th" scope="row" colSpan={2}>
                    <Typography variant="h6" component="h6">
                        {t("systemInfo.deviceInfo")}
                    </Typography>
                </TableCell>
            </TableRow>
            <TableRow>
                    <TableCell>{t("systemInfo.presenter")}</TableCell>
                    <TableCell>{presenter}</TableCell>
            </TableRow>
            <KeyValueTable obj={info} />
        </>
    )
}

function mapStateToProps(state) {
    return {
        deviceState: state.device
    };
}

export default connect(mapStateToProps)(DeviceInfo)