// React imports
import React, { useRef } from 'react'

// Other
import InfiniteScroll from 'react-infinite-scroller'

// I18n
import { useTranslation } from 'react-i18next'


import InfiniteScrollMessage from '../../InfiniteScroll/InfiniteScrollMessage.js'
import NoResult from '../NoResult.js'
import Targets from './Targets.js'
import { makeStyles } from '@mui/styles'
import { CircularProgress } from '@mui/material'
import { clsx } from '../../../utilities/Common.js'

const useStyles = makeStyles((theme) => ({
  
   
    /* total height is 4.5rem */
    selectedTargetsContainer: {
      boxSizing: "border-box",
      marginTop: ".5rem",
      marginBottom: ".5rem",
  
      overflowX: "scroll", 
      overflowY: "hidden", 
  
      display: "flex",
      alignItems: "center",
      justifyContent: 'flex-start',
      gap: "1rem",
  
      //We have to use much larger height here to make place for the scrollbar in Firefox and Chromium on Linux
      height: "3.5rem", //Use fixed height to avoid 'jumping' of the target list when the first element being selected.
    
      //for debug:
      //backgroundColor: "rgba(11,23,233,.5)"

    },
  
    progressContainer: {
        visibility: "hidden",
        position: "absolute",
        width: "100%",
        height: "100%",
        top: 0,
        left: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(255, 255, 255, .3)", // Just for debug
        zIndex: 1,
    },

    visible: {
        visibility: "visible",
    },

    wrapper: {
      flex: 1,
      alignItems: "stretch",
      width: `100%`,
      //backgroundColor: "green", // for debug
    },

    /* This wrapper is necessary to fit & scroll perfectly */
    scrollerWrapper: {
      position: "relative",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
    },
  
    scroller: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      overflowY: "scroll",
      
      //Firefox
      scrollbarWidth: "thin",
      scrollbarColor: `${theme.palette.grey[400]} transparent`,

      //Chrome
      "&::-webkit-scrollbar": {
        width: ".5rem",
        background: "transparent",
      },
      "&::-webkit-scrollbar-thumb": {
        background: theme.palette.grey[400],
        borderRadius: "1rem",
      },
    },
  }
));

const TargetsContainer = (props) => {
  
    const { items, selectedItems, next, hasMore, onTargetClick, inProgress } = props
    const showCheckbox = props.showCheckbox
    const showTargetMenu = props.showTargetMenu
    const classes = useStyles()
    const scrollParentRef = useRef(null)
    const { t } = useTranslation()
    
    if (items.length === 0) return <NoResult />
    
    const progressContainerClasses = clsx({
        [classes.progressContainer]: true,
        [classes.visible]: inProgress
    })

    // Loadmore is mandatory for InfiniteScroll component. There could be a case when it's not defined, so we have to define a dummy function here,
    // to avoid an error message to console.
    const loadMore = next || (() => {})

    return (
      <div className={classes.wrapper}>
       
       {/*
          Maybe it seems overkiller but it's not!
          To scroll and fit preciselly this dual div solution looks the easiest way!  
        */}
        <div className={classes.scrollerWrapper}>
          <div className={progressContainerClasses}>
            <CircularProgress />
          </div>
          <div className={classes.scroller} ref={scrollParentRef}>
          <InfiniteScroll
              pageStart={0}
              initialLoad={false}
              loadMore={loadMore || (() => {})} // if no next function is provided, use an empty function to prevent console errors messages
              hasMore={hasMore} //TODO: Check, sometimes it prints a warning about missing 'hasMore' prop on console
              useWindow={false}
              getScrollParent={() => scrollParentRef.current}
              
              //XXX: We use a random number for key to avoid a strange 'unique key missing' error
              //TODO: investigate futher this problem!
              loader={<InfiniteScrollMessage message={t("routes.loadingTargets")} key={`${Math.random()}`} />}>

              <Targets
                items={items}
                onClick={onTargetClick}
                selectedItems={selectedItems} 
                showCheckbox={showCheckbox}
                showTargetMenu={showTargetMenu} />

            </InfiniteScroll>
          </div>
        </div>

      </div>
    )
  }

  export default TargetsContainer