import { KeyboardDoubleArrowLeftRounded, KeyboardDoubleArrowRightRounded } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import {
    usePickerLayout,
    PickersLayoutRoot,
    pickersLayoutClasses,
    PickersLayoutContentWrapper,
} from '@mui/x-date-pickers/PickersLayout';
  
  // There is an shortcuts customization example at
  // https://mui.com/x/react-date-pickers/shortcuts/ but it seems more complicated than this.
  const useStyles = makeStyles((theme) => ({
    header: {
      //backgroundColor: "red", // just for debugging
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      "& .MuiPickersToolbar-root": {
        //backgroundColor: "blue", // just for debugging
        alignItems: "center",
      },
      "& .MuiDialogActions-root": {
        dispaly: "flex",
        flexDirection: "column-reverse",
        justifyContent: "center",
        //backgroundColor: "yellow", // just for debugging

        // Keep in mind that the buttons are in reverse order!
        // The first button is the second one in the dialog !
        "& button:nth-child(1)": {
          scale: 0.75,
        }
      },
    },
    yearHandlersContainer: {
      width: "fit-content",
      height: "fit-content",
      display: "flex",
      justifyContent: "space-between",
      marginLeft: theme.spacing(2),
      backgroundColor: theme.palette.grey[200],
      borderRadius: theme.shape.borderRadius,
      padding: ".25rem",
      marginBottom: theme.spacing(1),
    },
    shortcutsWrapper: {
      //backgroundColor: "pink", // just for debugging
      display: "flex",
      flexDirection: "column",
      "& ul": {
        display: "flex",
        listSyle: "none",
        margin: 0,
        padding: 0,
        maxWidth: "initial",
      },
      "& ul > li": {
        display: "inline-block",
        //backgroundColor: "green", // just for debugging
      }
    },
    contentWrapper: {
      "& .MuiDateRangeCalendar-root": {
        display: "flex",
        justifyContent: "center",
        //backgroundColor: "yellow", // just for debugging
      }
    }
  }));

  export default function CustomMobileDateRangePickerLayout(props) {
    const { toolbar, shortcuts, content, actionBar } = usePickerLayout(props);
    const { onPrevYearClick, onNextYearClick, showYearArrows } = props
    const classes = useStyles()
    return (
      <PickersLayoutRoot  className={pickersLayoutClasses.root} style={{
          maxWidth: "400px",
          display: "flex",
          flexDirection: "column",
        }} ownerState={props}>
        <div className={classes.header}>
          {toolbar}
          {showYearArrows 
            ?
            <div className={classes.yearHandlersContainer}>
                <KeyboardDoubleArrowLeftRounded onClick={onPrevYearClick} />&nbsp;<KeyboardDoubleArrowRightRounded onClick={onNextYearClick}/>
            </div>
            : null
          }
          {actionBar}
        </div>
        <div className={classes.shortcutsWrapper}>
          {shortcuts}
        </div>
        
        <PickersLayoutContentWrapper className={classes.contentWrapper}>
          {content}
        </PickersLayoutContentWrapper>
      </PickersLayoutRoot>
    );
  }
