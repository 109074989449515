import * as actionTypes from './actionTypes'

export const set = (value) => {
    return {
        type: actionTypes.SETTINGS_SET,
        payload: value
    }
}

export const themeChange = (uiThemeName) => {
    return {
        type: actionTypes.SETTINGS_UI_THEME_CHANGE,
        uiThemeName: uiThemeName
    }
}

export const autoLoginDataChange = (autoLoginData) => {
    return {
        type: actionTypes.SETTINGS_AUTO_LOGIN_DATA_CHANGE,
        autoLoginData: autoLoginData
    }
}

export const setFilterPaneVisible = (value) => {
    return {
        type: actionTypes.SETTINGS_SET_FILTER_PANE_VISIBLE,
        payload: value
    }
}

export const storeTrackingTargets = (targets) => {
    return {
        type: actionTypes.SETTINGS_STORE_TRACKING_TARGETS,
        payload: targets
    }
}

export const setTrackingTargets = (targets) => {
    return {
        type: actionTypes.SETTINGS_SET_TRACKING_TARGETS,
        payload: targets
    }
}

export const setUserId = (userId) => {
    return {
        type: actionTypes.SETTINGS_SET_USER_ID,
        payload: userId
    }
}

export const setForcedAutoLogin = (value) => {
    return {
        type: actionTypes.SETTINGS_SET_FORCED_AUTO_LOGIN,
        payload: value
    }
}

export const userIdStored = () => {
    return {
        type: actionTypes.SETTINGS_USER_ID_STORED,
    }
}

export const languageChange = (languageCode) => {
    return {
        type: actionTypes.SETTINGS_LANGUAGE_CHANGE,
        payload: languageCode
    }
}

export const setSelectedBottomMenuItem = (item) => {
    return {
        type: actionTypes.SETTINGS_SET_SELECTED_BOTTOM_MENU_ITEM,
        payload: item,
    }
}

export const userSettingsChange = (settings) => {
    return {
        type: actionTypes.SETTINGS_USER_SETTINGS_CHANGE,
        payload: settings
    }
}

export const setUserSettings = (settings) => {
    return {
        type: actionTypes.SETTINGS_SET_USER_SETTINGS,
        payload: settings
    }
}

export const userDidAcknowledgeUserMessage = () => {
    return {
        type: actionTypes.SETTINGS_USER_DID_ACKNOWLEDGE_USER_MESSAGE,
    }
}

export const initState = () => {
    return {
        type: actionTypes.SETTINGS_INIT_STATE,
    }
}