import { useTranslation } from "react-i18next"
import getSign from "./GetSign"
import { PauseCircleOutline } from "@mui/icons-material"

import * as COM from "../../../../utilities/Common"
import { makeStyles } from "@mui/styles"


const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        justifyContent: 'felx-start',
        alignItems: 'center',
        gap: '.25rem',
    },
    icon: {
        color: theme.palette.grey[600],
    }
}))

const StandTime = (props) => {

    const { timeMicros } = props
    const classes = useStyles()
    const { t } = useTranslation()
    
    const signs = getSign(t)
    const standText = COM.formattedDuration(timeMicros, true, signs)
    
    if (!standText) return null

    return (
        <div className={classes.root}>
            <PauseCircleOutline fontSize="small" className={classes.icon}/>
            <span>{standText}</span>
        </div>
    )
}

export default StandTime