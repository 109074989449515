import React from 'react'
import { connect } from 'react-redux'

import * as settingsActions from '../../store/actions/settings'
import { useTranslation } from 'react-i18next'
import { IconButton, Snackbar, SnackbarContent } from '@mui/material'
import { Close } from '@mui/icons-material'
import { useTheme } from '@emotion/react'

const UserMessage = (props) => {

    const { state } = props
    const { t } = useTranslation()
    const theme = useTheme()

    const { settings } = state
    const userSettings = settings.userSettings // user settings could be null!
    if (! userSettings) return null
    
    const shouldDisplayUserMessage = userSettings.shouldDisplayUserMessage
    if (!shouldDisplayUserMessage) return null

    const userMessage = userSettings.userMessage
    //const isProbablyKey = userSettings.isUserMessageProbablyKey // we don't care about this at the moment
    //console.debug("[Message.js] [userMessage] User message: ", userMessage)
    //console.debug("[Message.js] [userMessage] User settings: ", userSettings)
    //console.debug("[Message.js] [userMessage] Should display user message: ", shouldDisplayUserMessage)
    ////console.debug("[Message.js] [userMessage] User message probably key: ", isProbablyKey) // we don't care about this at the moment

    // We assume that the user message is a key. If it is not, we will display it as is due to the fallback behavior of the t function.
    const processedUserMessage = t(`${userMessage}`)
    //console.debug("[Message.js] [userMessage] Processed user message: ", processedUserMessage)

    const handleClose = () => props.userDidAcknowledgeUserMessage()

    return (
        <Snackbar
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            open={true}
            onClose={handleClose} >
            <SnackbarContent
                sx={{
                    backgroundColor: theme.palette.error.dark,
                }}
                message={processedUserMessage}
                action={[
                    <IconButton key="close" aria-label="close" color="inherit" onClick={handleClose}>
                        <Close />
                    </IconButton>,
                ]}
            />
        </Snackbar>
    )
}


const mapStateToProps = (state) => {
    return {
        state: state,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        userDidAcknowledgeUserMessage: () => dispatch(settingsActions.userDidAcknowledgeUserMessage()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserMessage)

