import L from 'leaflet'

import React from 'react'
import { Marker } from "react-leaflet"
import { makeStyles } from '@mui/styles'
import { renderToString } from 'react-dom/server'
import ArrowSvgIcon from '../IconsAndMarkers/ArrowSvgIcon'

import * as COM from '../../../utilities/Common'

const useStyles = makeStyles((theme) => ({
    root: {
        color: theme.palette.common.white,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    '@keyframes sizePulse': {
        '0%': {
            transform: `scale(1)`,
        },
        '50%': {
            transform: `scale(.85)`,
        },
        '100%': {
            transform: `scale(1)`,
        },
    },
    arrowContainer: {
        display: 'flex',
    },
    doAnimation: {
        animation: "$sizePulse 1.5s infinite"
    },
}));

const RotableMarker = (props) => {

    const { position, angle }  = props

    ////console.debug("Rotable marker position: ", position, "angle: ", angle, "strokeColor: ", strokeColor, "strokeWidth: ", strokeWidth, "fill: ", fill)

    //https://stackoverflow.com/questions/263965/how-can-i-convert-a-string-to-boolean-in-javascript
    const doAnimation = (process.env.REACT_APP_ANIMATE_ROUTE_ARROW_ICONS === 'true')
    //console.debug(`Are route arrows animated: ${doAnimation}`)

    const classes = useStyles()   
    const classNames = COM.clsx({
        [classes.arrowContainer]: true,
        [classes.doAnimation]: doAnimation,
    })

    const iconStyle = {
        transform: `rotate(${angle}deg)`,
        display: "flex", // we need flex to center the arrow
    }

    const htmlString = renderToString(
        <div className={classNames}>
            <div style={iconStyle}>
                <ArrowSvgIcon />
            </div>
        </div>
    )

    const htmlIcon = new L.divIcon({
        html: htmlString,
        popupAnchor: [12, 12], //NOTE: Very important to set, cannot be null, otherwise the leaflet library will crash! I did spend hours to find this bug ;)
        className: classes.root,
    })
    
    return <Marker icon={htmlIcon} position={position} />
}

export default RotableMarker