// ROOT
export const STORE_CREATED = "STORE_CREATED" //This is the first action after the store has been created.
export const STORE_REHYDRATED = "persist/REHYDRATE"


// Auth
export const AUTH_START = "AUTH_START"
export const AUTH_SUCCESS = "AUTH_SUCCESS"
export const AUTH_FAIL = "AUTH_FAIL"
export const AUTH_SIGN_OUT_START = "AUTH_SIGN_OUT_START"
export const AUTH_SIGN_OUT_SUCCESS = "AUTH_SIGN_OUT_SUCCESS"
export const AUTH_SIGN_IN_NECESSARY = "AUTH_SIGN_IN_NECESSARY"

// Settings
export const SETTINGS_SET = "SETTINGS_SET"

export const SETTINGS_UI_THEME_CHANGE = "SETTINGS_UI_THEME_CHANGE"
export const SETTINGS_FLOATING_MENU_LOCATION_CHANGE = "SETTINGS_FLOATING_MENU_LOCATION_CHANGE"
export const SETTINGS_AUTO_LOGIN_DATA_CHANGE = "SETTINGS_AUTO_LOGIN_DATA_CHANGE"

export const SETTINGS_SET_FILTER_PANE_VISIBLE = "SETTINGS_SET_FILTER_PANE_VISIBLE"

export const SETTINGS_PERSIST_REHYDRATE = "persist/REHYDRATE"

export const SETTINGS_SET_TRACKING_TARGETS = "SETTINGS_SET_TRACKING_TARGETS"
export const SETTINGS_STORE_TRACKING_TARGETS = "SETTINGS_STORE_TRACKING_TARGETS"

export const SETTINGS_SET_USER_ID = "SETTINGS_SET_USER_ID"
export const SETTINGS_USER_ID_STORED = "SETTINGS_USER_ID_STORED"

export const SETTINGS_SET_SELECTED_BOTTOM_MENU_ITEM = "SETTINGS_SET_SELECTED_BOTTOM_MENU_ITEM"

export const SETTINGS_LANGUAGE_CHANGE = "SETTINGS_LANGUAGE_CHANGE"
export const SETTINGS_INIT_STATE = "SETTINGS_INIT_STATE"

export const SETTINGS_USER_SETTINGS_CHANGE = "SETTINGS_USER_SETTINGS_CHANGE"
export const SETTINGS_SET_USER_SETTINGS = "SETTINGS_SET_USER_SETTINGS"
export const SETTINGS_USER_DID_ACKNOWLEDGE_USER_MESSAGE = "USER_DID_ACKNOWLEDGE_USER_MESSAGE"

export const SETTINGS_SET_FORCED_AUTO_LOGIN = "SETTINGS_SET_FORCED_AUTO_LOGIN"


// Refuel
export const REFUELS_FETCH_START = "REFUELS_FETCH_START"
export const REFUELS_FETCH_SUCCESS = "REFUELS_FETCH_SUCCESS"
export const REFUELS_FETCH_FAIL = "REFUELS_FETCH_FAIL"

export const REFUELS_SET_REFUELS = "REFUELS_SET_REFUELS"

export const REFUELS_ADD_TARGET = "REFUELS_ADD_TARGET"
export const REFUELS_DELETE_TARGET = "REFUELS_DELETE_TARGET"

export const REFUELS_SET_DATE_RANGE = "REFUELS_SET_DATE_RANGE"

export const REFUELS_SET_SELECTED_TAB = "REFUELS_SET_SELECTED_TAB"

export const REFUELS_SET_MOUNTED = "REFUELS_SET_MOUNTED"


// Targets
export const TARGETS_FETCH_ITEMS_START = "TARGETS_FETCH_ITEMS_START"
export const TARGETS_FETCH_ITEMS_SUCCESS = "TARGETS_FETCH_ITEMS_SUCCESS"

export const TARGETS_FETCH_COUNT_START = " TARGETS_FETCH_COUNT_START"
export const TARGETS_FETCH_COUNT_SUCCESS = "TARGETS_FETCH_COUNT_SUCCESS"

export const TARGETS_SET = "TARGETS_SET"
export const TARGETS_SET_ITEMS = "TARGETS_SET_ITEMS"
export const TARGETS_SET_COUNT = "TARGETS_SET_COUNT"
export const TARGETS_SET_IN_PROGRESS = "TARGETS_SET_IN_PROGRESS"
export const TARGETS_SET_AUTOCOMPLETE_IN_PROGRESS = "TARGETS_SET_AUTOCOMPLETE_IN_PROGRESS"
export const TARGETS_SET_INPUT_FILTER = "TARGETS_SET_INPUT_FILTER"

export const TARGETS_NEXT = "TARGETS_NEXT"
export const TARGETS_CLEAR_INPUT_FILTER = "TARGETS_CLEAR_INPUT_FILTER"


// Error
export const ERROR_ADD_ERROR = "ERROR_ADD_ERROR"
export const ERROR_SET_ITEMS = "ERROR_SET_ITEMS"
export const ERROR_SET_ACKNOWLEDGED = "ERROR_SET_ACKNOWLEDGED"


// Device
export const DEVICE_SET = "DEVICE_SET"
export const DEVICE_INFO_READY = "DEVICE_INFO_READY"


// Tracking
export const TRACKING_POLL_START = "TRACKING_POLL_START"
export const TRACKING_POLL_STOP = "TRACKING_POLL_STOP"
export const TRACKING_POLL_RESTART = "TRACKING_POLL_RESTART"

export const TRACKING_UPDATE_TARGETS_START = "TRACKING_UPDATE_TARGETS_START"
export const TRACKING_UPDATE_TARGETS_SUCCESS = "TRACKING_UPDATE_TARGETS_SUCCESS"
export const TRACKING_UPDATE_TARGETS_FAIL = "TRACKING_UPDATE_TARGETS_FAIL"

export const TRACKING_NO_PENDING_TARGET = "TRACKING_NO_PENDING_TARGET"

export const TRACKING_ADD_TARGETS_USER_REQUEST = "TRACKING_ADD_TARGETS_USER_REQUEST"
export const TRACKING_ADD_TARGETS = "TRACKING_ADD_TARGETS"

export const TRACKING_REMOVE_TARGET_BY_ID_USER_REQUEST = "TRACKING_REMOVE_TARGET_BY_ID_USER_REQUEST"
export const TRACKING_REMOVE_TARGET_BY_ID = "TRACKING_REMOVE_TARGET_BY_ID"

export const TRACKING_REPLACE_ALL_TARGETS_USER_REQUEST = "RACKING_REPLACE_ALL_TARGETS_USER_REQUEST"
export const TRACKING_REPLACE_ALL_TARGETS = "TRACKING_REPLACE_ALL_TARGETS"

export const TRACKING_SET_TARGETS = "TRACKING_SET_TARGETS"

export const TRACKING_UPDATE_TARGET = "TRACKING_UPDATE_TARGET"
export const TRACKING_SET_TARGET_STATUS = "TRACKING_SET_TARGET_STATUS"

export const TRACKING_SET_IN_PROGRESS = "TRACKING_SET_IN_PROGRESS"

export const TRACKING_SET_BOUNDS = "TRACKING_SET_BOUNDS"
export const TRACKING_CREATE_BOUNDS = "TRACKING_CREATE_BOUNDS"
export const TRACKING_BOUNDS_ARE_VALID = "TRACKING_BOUNDS_ARE_VALID"
export const TRACKING_FIT_TO_BOUNDS_USER_REQUEST = "TRACKING_FIT_TO_BOUNDS_USER_REQUEST"
export const TRACKING_FIT_TO_BOUNDS = "TRACKING_FIT_TO_BOUNDS"
export const TRACKING_SET_AUTO_FIT_TO_BOUNDS = "TRACKING_SET_AUTO_FIT_TO_BOUNDS"

export const TRACKING_SET_POPUP_TARGET_ID = "TRACKING_SET_POPUP_TARGET_ID"

export const TRACKING_SET_MOUNTED = "TRACKING_SET_MOUNTED"

// Routes
export const ROUTES_ADD_TARGET = "ROUTES_ADD_TARGET"
export const ROUTES_DELETE_TARGET = "ROUTES_DELETE_TARGET"

export const ROUTES_FETCH_START = "ROUTES_FETCH_START"
export const ROUTES_FETCH_SUCCESS = "ROUTES_FETCH_SUCCESS"
export const ROUTES_FETCH_FAIL = "ROUTES_FETCH_FAIL"

export const ROUTES_POSITIONS_FETCH_START = "POSITIONS_FETCH_START"
export const ROUTES_POSITIONS_FETCH_SUCCESS = "POSITIONS_FETCH_SUCCESS"

export const ROUTES_ITEM_CHECKED_BY_USER = "ROUTES_ITEM_CHECKED_BY_USER"
export const ROUTES_TOGGLE_CHECKED = "ROUTES_TOGGLE_CHECKED"
export const ROUTES_SET_MULTISELECT = "ROUTES_SET_MULTISELECT"
export const ROUTES_SET_CHECKED_COUNT = "ROUTES_SET_CHECKED_COUNT"

export const ROUTES_SET_ITEMS = "ROUTES_SET_ITEMS"
export const ROUTES_CLEAR_SELECTED_SECTIONS = "ROUTES_CLEAR_SELECTED_SECTIONS"

export const ROUTES_SET_DATE_RANGE = "ROUTES_SET_DATE_RANGE"
export const ROUTES_SET_DATE_RANGE_FROM = "ROUTES_SET_DATE_RANGE_FROM"
export const ROUTES_SET_DATE_RANGE_TO = "ROUTES_SET_DATE_RANGE_TO"
export const ROUTES_CLEAR_DATE_RANGE = "ROUTES_CLEAR_DATE_RANGE"

export const ROUTES_SET_SELECTED_TAB = "ROUTES_SET_SELECTED_TAB"

export const ROUTES_CREATE_BOUNDS = "ROUTES_CREATE_BOUNDS"
export const ROUTES_SET_BOUNDS = "ROUTES_SET_BOUNDS"
export const ROUTES_FIT_TO_BOUNDS_USER_REQUEST = "ROUTES_FIT_TO_BOUNDS_USER_REQUEST"
export const ROUTES_FIT_TO_BOUNDS = "ROUTES_FIT_TO_BOUNDS"

export const ROUTES_SET_MOUNTED = "ROUTES_SET_MOUNTED"

// Share Target
export const SHARE_TARGET_GENERATE_START = "SHARE_TARGET_GENERATE_START"
export const SHARE_TARGET_GENERATE_FAIL = "SHARE_TARGET_GENERATE_FAIL"
export const SHARE_TARGET_GENERATE_SUCCESS = "SHARE_TARGET_GENERATE_SUCCESS"
export const SHARE_TARGET_SET_LOGIN_TOKEN = "SHARE_TARGET_SET_LOGIN_TOKEN"

// Map
export const MAP_SET = "MAP_SET"
export const MAP_SET_CENTER = "MAP_SET_CENTER"
export const MAP_SET_ZOOM = "MAP_SET_ZOOM"
export const MAP_ZOOM_USER_REQUEST = "MAP_ZOOM_USER_REQUEST"
export const MAP_SET_ZOOM_CONTROL = "MAP_SET_ZOOM_CONTROL"

export const MAP_SET_REF = "MAP_SET_REF"
export const MAP_FIT_TO_BOUNDS = "MAP_FIT_TO_BOUNDS"
export const MAP_FIT_TO_BOUNDS_USER_REQUEST = "MAP_AUTO_FIT_TO_BOUNDS_USER_REQUEST"
export const MAP_PAN_TO = "MAP_PAN_TO"
export const MAP_FIT_TO = "MAP_FIT_TO"
export const MAP_SHOULD_REFRESH = "MAP_SHOULD_REFRESH"

// Map events
export const MAP_EVENTS_START_CHANNEL = "MAP_EVENTS_START_CHANNEL"
export const MAP_EVENTS_STOP_CHANNEL = "MAP_EVENTS_STOP_CHANNEL" 
export const MAP_EVENTS_MAP_MOVED_BY_USER = " MAP_EVENTS_MAP_MOVED_BY_USER"
export const MAP_EVENTS_MAP_DID_ZOOM = " MAP_EVENTS_MAP_DID_ZOOM"


// Chat
export const CHAT_SET_MOUNTED = "CHAT_SET_MOUNTED"

export const CHAT_POLL_START = "CHAT_POLL_START"
export const CHAT_POLL_STOP = "CHAT_POLL_STOP"
export const CHAT_POLL_RESTART = "CHAT_POLL_RESTART"
export const CHAT_POLL_SUCCESS = "CHAT_POLL_SUCCESS"

export const CHAT_GET_MESSAGES = "CHAT_GET_MESSAGES"
export const CHAT_GET_MESSAGES_SUCCESS = "CHAT_GET_MESSAGES_SUCCESS"

export const CHAT_GET_THREADS = "CHAT_GET_THREADS"
export const CHAT_GET_THREADS_BY_USER = "CHAT_GET_THREADS_BY_USER"
export const CHAT_GET_THREADS_SUCCESS = "CHAT_GET_THREADS_SUCCESS"

export const CHAT_SEND_MESSAGE = "CHAT_SEND_MESSAGE"
export const CHAT_SEND_MESSAGE_SUCCESS = "CHAT_SEND_MESSAGE_SUCCESS"

export const CHAT_SET_THREADS = "CHAT_SET_THREADS"
export const CHAT_THREAD_ID_SELECTED_BY_USER = "CHAT_THREAD_ID_SELECTED_BY_USER"
export const CHAT_SET_SERVER_TIME_MICROS = "CHAT_SET_SERVER_TIME_MICROS"
export const CHAT_SET_SELECTED_THREAD_ID = "CHAT_SET_SELECTED_THREAD_ID"
export const CHAT_SET_COUNTERS = "CHAT_SET_COUNTERS"

export const CHAT_COUNT_MESSAGES = "CHAT_COUNT_MESSAGES"

export const CHAT_CHANGE_FILTER = "CHAT_CHANGE_FILTER"
export const CHAT_SET_FILTER = "CHAT_SET_FILTER"

export const CHAT_SET_FILTERING_IN_PROGRESS = "SET_FILTERING_IN_PROGRESS"

// Keyboard
export const KEYBOARD_KEY_DOWN = "KEYBOARD_KEY_DOWN"


// Navigation
export const NAVIGATION_SET_HISTORY = "NAVIGATION_SET_HISTORY"
export const NAVIGATION_PUSH_TO_HISTORY = "NAVIGATION_PUSH_TO_HISTORY"
export const NAVIGATION_BOTTOM_MENU_ITEM_CLICK = "NAVIGATION_BOTTOM_MENU_ITEM_CLICK"

export const NAVIGATION_TRACKING_PAGE_IS_READY = "NAVIGATION_TRACKING_PAGE_IS_READY"
export const NAVIGATION_ROUTES_PAGE_IS_READY = "NAVIGATION_ROUTES_PAGE_IS_READY"


// Debug
export const DEBUG_ADD_EVENT = "DEBUG_ADD_EVENT"
export const DEBUG_SET_EVENTS = "DEBUG_SET_EVENT"

// System Info
export const SYSTEM_INFO_SET_MOUNTED = "SYSTEM_INFO_SET_MOUNTED"