import * as actionTypes from './actionTypes'

export const setHistory = (history) => {
    return {
        type: actionTypes.NAVIGATION_SET_HISTORY,
        payload: history,
    }
}

export const pushToHistory = (to) => {
    return {
        type: actionTypes.NAVIGATION_PUSH_TO_HISTORY,
        payload: to,
    }
}

export const bottomMenuItemClick = (item) => {
    return {
        type: actionTypes.NAVIGATION_BOTTOM_MENU_ITEM_CLICK,
        payload: item,
    }
}

export const trackingPageIsReady = () => {
    return {
        type: actionTypes.NAVIGATION_TRACKING_PAGE_IS_READY,
    }
}

export const routesPageIsReady = () => {
    return {
        type: actionTypes.NAVIGATION_ROUTES_PAGE_IS_READY,
    }
}