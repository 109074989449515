// Action Types import
import * as actionTypes from '../actions/actionTypes'
import { BROWSER, update } from '../../utilities/Common.js'

// Initial state
const initialState = {
    info: null,
    presenter: BROWSER, // We assume that the presenter is the browser until we did not get any information from the device (if it is available)
}

const reducer = (state = initialState, action) => {
   
    switch (action.type) {
        case actionTypes.DEVICE_SET: return update(state, action.payload)
        default: return state
    }
}

export default reducer
