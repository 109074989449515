import { Grid, useTheme } from "@mui/material"
import { useTranslation } from "react-i18next"
import TailwindSvgIcon from "../../../IconsAndMarkers/TailwindSvgIcon"
import { Error, LocalShippingRounded, HourglassEmptyRounded } from "@mui/icons-material"

import { makeStyles } from "@mui/styles"
import { createPlutoLogger, formattedDate } from "../../../../../utilities/Common"

const D = createPlutoLogger("[Event.js]")

const useStyles = makeStyles((theme) => ({
    root: {
        borderTop: `1px dotted ${theme.palette.grey[600]}`,
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        justifyContent: "flex-start",
        alignItems: "center",
    },
    statusContainer: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        gap: ".25rem",
    },
    standing: {
        //
    },
    running: {
        //
    },
    text: {
        overflowWrap: "break-word",
    }
}))

const Event = ({event, index}) => {

    const { t } = useTranslation()
    const classes = useStyles()
    const theme = useTheme()

    //console.debug("[Event.js] theme: ", theme)
    
    //Example data structure:
    //code: 10007
    //description: "Idle"
    //eventTimeMicros: 1607665069000000
    //marker: "standing"
    const { description, eventTimeMicros, marker } = event


    const icons = {
        "running": <><TailwindSvgIcon fill={theme.palette.grey[700]} /><LocalShippingRounded /></>,
        "standing": <><HourglassEmptyRounded /><LocalShippingRounded /></>,
    }

    const icon = icons[marker] || <Error />
    const iconClass = classes[marker]
    const iconContent = <div className={iconClass}>{icon}</div>

    D("event: ", event)

    return (
        <Grid
            item
            xs={12}
            container
            key={`event-row-${index}`}
            className={classes.root}>
            
            <Grid
                item
                xs={3}
                container
                justifyContent="flex-start"
                alignItems="center"
                className={classes.statusContainer}>
                    {iconContent}
                    <span>{formattedDate(eventTimeMicros, true)}</span>
            </Grid>     
            <Grid item xs={9} className={classes.text}>{t(description)}, {t(marker)}</Grid>   
        </Grid>
    )
}

export default Event