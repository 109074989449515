import { takeEvery, putResolve, select } from 'redux-saga/effects'

import * as actions from './../actions/settings'
import * as actionTypes from '../actions/actionTypes'
import { ANDROID_EXOSKELETON, createPlutoLogger, IOS_EXOSKELETON, TRACKING_STATUS_PENDING } from '../../utilities/Common'

const D = createPlutoLogger("🔧 [SAGA] [settings.js]")

function* handleTrackingSetTargets(action) {

    const targets = action.payload
    const storableArray = targets.map( i => { return {
        target: i.target,
        frontendTimeMicros: i.frontendTimeMicros,
        status: TRACKING_STATUS_PENDING,
    }})
    
    yield putResolve(actions.setTrackingTargets(storableArray))

}   

function* handleAuthSucces(action) {
    const { auth } = yield select()
    const { credentials } = auth 
    const userId = credentials.id
    yield putResolve(actions.setUserId(userId))
    yield putResolve(actions.userIdStored())  
}

function* handleUserSettingsChange(action) {
    const { settings, device } = yield select()
    const isExoskeleton = device.presenter == IOS_EXOSKELETON || device.presenter == ANDROID_EXOSKELETON
    D("[handleUserSettingsChange] device state: ", device)

    const received = action.payload
    const current = settings.userSettings
    D("[handleUserSettingsChange] User settings has changed! Received: ", received, " Current: ", current)
    
    // current is null at first time when the local storage is empty
    // and the user has not logged in yet. In this case we have to store the received settings without any modification
    const update = !current ? {...received} : {...current, ...received}
    D("[handleUserSettingsChange] User settings handling logic has started. The initial update is ", update)

    const message = update.userMessage
    const isMessageEmpty = message === null || message === undefined || message.length === 0
    
    if (isMessageEmpty) {
        D("[handleUserSettingsChange] [userMessage] Received message is empty. We do not have to display the user message!", message)
        update.shouldDisplayUserMessage = false
    } else {

        //const isExoskeleton = settings.presenter == IOS_EXOSKELETON || settings.presenter == ANDROID_EXOSKELETON
        const isForcedAutoLogin = settings.forcedAutoLogin
        const isAutoLogin = isExoskeleton || isForcedAutoLogin
        D("[handleUserSettingsChange] [userMessage] Login type related variables: ", {
            isExoskeleton,
            isAutoLogin,
            isForcedAutoLogin,
        })

        if (isAutoLogin) {

            D("[handleUserSettingsChange] [userMessage] Auto login flag is true, calculating time elapsed since the message was displayed...")
            
            const currentDisplayTime = current.userMessageLastDisplayedTimestamp
            D("[handleUserSettingsChange] [userMessage] currentDisplayTime: ", currentDisplayTime)

            const areStoredAndReceivedDifferent = received.message !== current.message
            D("[handleUserSettingsChange] [userMessage] areStoredAndReceivedDifferent: ", areStoredAndReceivedDifferent)

            const now = Date.now()
            // TODO: should timeframeMillis be from properties?
            const oneHourMillis = 60 * 60 * 1000
            const timeframeMillis = 24 * oneHourMillis // 24 hours
            const isDisplayedWithinTimeframe = currentDisplayTime ? currentDisplayTime > now - timeframeMillis : false
            D("[handleUserSettingsChange] [userMessage] isDisplayedWithinTimeframe: ", isDisplayedWithinTimeframe)

            update.shouldDisplayUserMessage = !isDisplayedWithinTimeframe || areStoredAndReceivedDifferent

        } else {
            D("[handleUserSettingsChange] [userMessage] We did normal login. We have to display the userMessage!")
            update.shouldDisplayUserMessage = true
        }
    }

    yield putResolve(actions.setUserSettings(update))
    D(" [handleUserSettingsChange] User settings has been updated! Update: ", update)
}

function* handleUserDidAcknowledgeUserMessage() {
    const { settings } = yield select()
    const userSettings = settings.userSettings
    const updated = {
        ...userSettings,
        shouldDisplayUserMessage: false,
        userMessageLastDisplayedTimestamp: Date.now()
    }

    yield putResolve(actions.setUserSettings(updated))
}

export function* saga() {

    yield takeEvery(actionTypes.TRACKING_SET_TARGETS, handleTrackingSetTargets)

    yield takeEvery(actionTypes.SETTINGS_USER_SETTINGS_CHANGE, handleUserSettingsChange)
    yield takeEvery(actionTypes.SETTINGS_USER_DID_ACKNOWLEDGE_USER_MESSAGE, handleUserDidAcknowledgeUserMessage)

    yield takeEvery(actionTypes.AUTH_SUCCESS, handleAuthSucces)
}