import React, { useEffect } from "react";
import { createPlutoLogger, formattedDate } from "../../../utilities/Common";

// i18n
import { useTranslation } from 'react-i18next'


import { DateRangeRounded } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { Tooltip } from "@mui/material";
import useOrientationChange from "../../App/useOrientationChange";
import { useInView } from "react-intersection-observer";

const useStyles = makeStyles((theme) => ({
    root: {
        fontSize: ".8rem",
        padding: "1rem",
        color: theme.palette.text.primary,
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        "& svg:first-child": {
            marginRight: ".5rem",
            opacity: .5,
        },
        // We use this simple selector to make the icon and the text to be on the same line
        // in the second div. It's also ensures that the content is vertically centered in any div.
        "& div": {
            display: "flex",
            alignItems: "center",
            fontWeight: "bold",
            
        },
        //backgroundColor: "orange", //for debug
    },
    icon: {
        height: 30,
        alignSelf: "center",
        color: "#bbbbbb"
    }
}));

const D = createPlutoLogger("[TargetInfo.js]")

const TargetInfo = (props) => {
    
    
    const { targetCount, selectedTargets } = props
    const dateRangeFrom = props.dateRangeFrom || null
    const dateRangeTo = props.dateRangeTo || null
    const showSelected = props.showSelected || false
    const showDate = props.showDate || false
    const selectedTargetCount = selectedTargets.length

    const classes = useStyles()
    const { t } = useTranslation()

    // TODO: consider to use a a common i18n key for this type of
    // default values (error values)
    let from = "?"
    let to = "?"

    // In case of error, we don't want to crash the app, so we just log the error and continue
    // by using the default values.
    try {
        from = dateRangeFrom != null ? formattedDate(dateRangeFrom) : null
        to = dateRangeTo != null ? formattedDate(dateRangeTo) : null
    } catch (error) {
        console.error(error)
    }

    const isOneDay = from === to
    const separator = isOneDay ? null : " - "
    const fromStr = from ? from : "..."
    const toStr = isOneDay ? null : to

    const isPortrait = useOrientationChange()
    const inView = useInView()
    
    useEffect(() => {
        D("[isPortrait: ", isPortrait, "inView: ", inView)
    })

    if (!isPortrait && inView) {
        D("We are in landscape mode and in view. No rendering (component hidden).")
        return null
    }

    return (
        <div className={classes.root}>
            { showSelected ? <div>{t("filter.selected")}:&nbsp;{selectedTargetCount}&nbsp;/&nbsp;{targetCount}</div> : null}
            { showDate 
                ?
                <div>
                    <Tooltip title={t("filter.selectedDateRange")}>
                        <DateRangeRounded />
                    </Tooltip>
                    {fromStr}{separator}{toStr}
                </div> 
                : 
                null
            }
        </div>
    )
}

export default TargetInfo;