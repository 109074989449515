import React from 'react'

export class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false }
    }

    static getDerivedStateFromError(error) {
        return { hasError: true }
    }

    componentDidCatch(error, errorInfo) {
        console.error('Error caught by ErrorBoundary:', error, errorInfo)
    }

    resetErrorBoundary = () => {
        this.setState({ hasError: false, errorInfo: null })
      }

    render() {
        if (this.state.hasError) {
            return (
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100dvh',
                    flexDirection: 'column',
                    color: "white",
                }}>
                    <h1>Error during loading the Map. Please click reload!</h1>
                    <button
                        onClick={this.resetErrorBoundary}
                        style={{
                            borderRadius: '1rem',
                            fontSize: '2rem',
                        }}>
                        Reload
                    </button>
                </div>
            )
        }

        return this.props.children; 
    }
}
