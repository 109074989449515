// React imports
import React, { useState } from 'react'

import * as COM from '../../utilities/Common'

import DOMPurify from 'dompurify';
import { Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';

// for more options: https://github.com/cure53/DOMPurify#can-i-configure-dompurify
const domPurifyOptions = {
    USE_PROFILES: { html: true }
}

const useStyles = makeStyles(theme => ({
    container: {
        fontSize: "12px !important",
        "& *": {
            fontSize: "12px !important",
        },
        maxHeight: "25vh",
        overflowY: 'scroll',

        //Firefox
        scrollbarWidth: "thin",
        scrollbarColor: `${theme.palette.scrollbar.background} transparent`,

        //Chrome
        "&::-webkit-scrollbar": {
            width: ".5rem",
            background: "transparent",
        },
        "&::-webkit-scrollbar-thumb": {
            background: theme.palette.scrollbar.background,
            borderRadius: "1rem",
        },
    },
    table: {
        "& tr": {
        },
        "& td": {
            borderBottom: `solid 1px ${theme.palette.primary.main}`,
            paddingTop: ".25rem",
            paddingBottom: ".25rem",
        },
        "& *": {
            color: "white",
        }
    },
    timestampRow: {
        "& td": {
            padding: ".25rem",
            margin: 0,
            opacity: "0.9",
        }
    },
    noBottomBorder: {
        "& td": {
            borderBottom: "none"
        }
    },
    even: {
        backgroundColor: theme.palette.primary.main,
    },
    odd: {
        backgroundColor: theme.palette.primary.light,
        "& *": {
            backgroundColor: theme.palette.primary.light,
        }
    },
    left: {
        overflowWrap: "anywhere",
        textAlign: "left",
    },
    right: {
        textAlign: "right",
        overflowWrap: "anywhere",
    }
}))

const Timestamp = (props) => {

    const { index } = props
    const classes = useStyles()
    const timestampMicros = props.timestampMicros

    if (!props.show) return null

    if (!timestampMicros) {
        //console.debug("[Timestamp] No timestampMicros provided for <Timestamp> component!", props)
        return
    }

    let formattedTime = "-" // In case of invalid timestampMicros we show this

    try {
        const time = COM.createFrontendTimeMilis(timestampMicros)
        formattedTime = COM.formattedDateTime(time)
    } catch (e) {
        //console.debug("[Timestamp] Invalid timestampMicros: ", timestampMicros, e)
    }

    const cellClasses = COM.clsx({
        [classes.even]: index % 2 === 0,
        [classes.odd]: index % 2 === 1,
    })

    return <TableCell colSpan={2} className={cellClasses} sx={{color: "white"}}>{formattedTime}</TableCell>
}

const Row = (props) => {

    const classes = useStyles()
    const theme = useTheme()

    const { row, index } = props
    const [timestampVisible, setTimestampVisible] = useState(false)

    const toggleVisible = () => setTimestampVisible(!timestampVisible)

    const rowClasses = COM.clsx({
        [classes.noBottomBorder]: timestampVisible,    
        [classes.even]: index % 2 === 0,
        [classes.odd]: index % 2 === 1,
    })

    //console.debug("Row.value:", row.name, row.value)

    return (
        <>
            <TableRow className={rowClasses} onClick={toggleVisible} sx={{
                "& .MuiTableCell-root": {
                    color: theme.palette.primary.contrastText,
                }
            }}>
                <TableCell className={classes.left} sx={{color: "white"}}>{row.name}</TableCell>
                <TableCell className={classes.right} sx={{color: "white"}} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(row.value, domPurifyOptions) }}></TableCell>
            </TableRow>
            <TableRow className={classes.timestampRow}>
                <Timestamp show={timestampVisible} timestampMicros={row.timestampMicros} index={index} />
            </TableRow>
            {
            /*
            
            // This is just here for testing purposes
            // It creates 20 rows with the same content
            // I leave it here for future debugging purposes

            Array.from({ length: 20 }, (_, index) => {
              return `This is row ${index}`
            }).map((item, index) => (
              <p key={index}>{item}</p>
            ))
            */}
        </>
    )
}

const TargetMeasuresKeyValueTable = (props) => {

    const classes = useStyles()
    const { array } = props

    return(
        <div className={classes.container}>
            <TableContainer>
                <Table className={classes.table} aria-label="keyvalue-table">
                    <TableBody>
                        {array.map((row, index) => <Row row={row} key={`${row.name}-${index}`} index={index} />)}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}

export default TargetMeasuresKeyValueTable