// React imports
import React, {useEffect } from 'react'
import * as refuelActions from '../../store/actions/refuels'
import * as COM from "../../utilities/Common"

// Redux imports
import { connect } from 'react-redux'

// i18n imports
import { useTranslation } from 'react-i18next'

 // Icons
import LocalShipping from "@mui/icons-material/LocalShipping"
import DateRange from "@mui/icons-material/DateRange"

// Own components
import TargetsFilter from "../Filter/Targets/TargetsFilter"
import PlutoDrawer from "../Drawer/PlutoDrawer"
import SelectedLabel from "../Filter/SelectedLabel"
import FilterTabs from '../Filter/Tabs/FilterTabs'
import TargetInfo from '../Filter/Targets/TargetInfo'
import DateRangeFilterWithLuxon from '../Filter/DateRangeFilter/DateRangeFilterWithLuxon'

const TARGET_TAB_INDEX = 0 
const DATE_TAB_INDEX = 1

const Refuels = (props) => {

    const { t } = useTranslation()

    const { state, setDateRange } = props
    const { refuels, targets } = state
    const { dateRangeFrom, dateRangeTo } = refuels
    const targetCount = targets.items.length
    //const selectedTargetCount = refuels.target ? 1 : 0

    // Lifecycle
    useEffect(() => {
        props.setMounted(true)
        return () => {
            props.setMounted(false)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    // XXX:
    // TODO: If we will have more time this logic should be refactored to avoid spaghetti code.
    const handleTargetClick = (item) => {
        // It's a UI logic, so we do not complicate the SAGA layer with this.
        // We call the addTarget first and after that we simoultanously set the selected tab the date range selector
        // The addTarget will not block, but we do not care about the order of the two actions.
        props.addTarget(item)
        props.setSelectedTab(DATE_TAB_INDEX)
    }
       
    const handleClickDelete = () => {
        //console.debug("[Refuels.js] handleClickDelete() called")
        props.deleteTarget()
    }

    const handleTabChange = (value) => {
        props.setSelectedTab(value)
    }

    const { selectedTab } = refuels

    //NOTE: To be human-friendy we add plus one to the real day difference, so in case of same days the difference is 1 intead of 0
    const dateDifferenceString = `${COM.dateDifference(refuels.dateRangeFrom, refuels.dateRangeTo).days + 1 }${t('d')}`
    const selectedCount = refuels.target ? 1 : 0

    //We have to create a common target format
    const selectedTargets =[]
    const selectedTarget = state.refuels?.target
    if (selectedTarget != null) selectedTargets.push(
      {
        id: state.refuels?.target?.id,
        ...state.refuels?.target
      }
    )

    // This function looks unnecessary, but it is not!
    // Adding the setRange function directly to the DateRangeFilter component
    // causes the component to re-render on every change, which leads to an infinite loop!
    // TODO: We have to investigate this issue later.
    const handleDateRangeChange = (newValue) => {
        //console.debug("[Refuels.js] handleDateRangeChange() called with newValue: ", newValue)
        setDateRange(newValue[0], newValue[1])
    }

    const tabContent = [

        <TargetsFilter
            showCheckbox={false}
            items={refuels.target}
            selectedTargets={selectedTargets}
            selectedCount={selectedCount}
            onTargetClick={handleTargetClick}
            onClearSelectedClick={handleClickDelete}>
            {
                // In alfa version I leave this code here, but we have to remove it later
                // <SingleSelectedTarget item={refuels.target} onDelete={handleClickDelete} />
            }
        </TargetsFilter>, 
        
        <DateRangeFilterWithLuxon
            dateRangeFrom={dateRangeFrom}
            dateRangeTo={dateRangeTo}
            handleDateRangeChange={handleDateRangeChange} />,
    ]

    const tabs = [
        {
            icon: <LocalShipping />,
            label: <SelectedLabel selectedCount={selectedCount} name={state.refuels.target?.name} />,
            index: TARGET_TAB_INDEX,
        },
        {
            icon: <DateRange />,
            label: dateDifferenceString,
            index: DATE_TAB_INDEX,
        },
    ]

    console.debug("[Refuels.js] selectedTab: ", tabContent[selectedTab])

    const aggregatedInProgess = refuels.inProgress || targets.inProgress

    return (
        <>
            <PlutoDrawer inProgress={aggregatedInProgess} >
                <FilterTabs            
                    tabs={tabs}
                    selectedTab={selectedTab}
                    onChange={handleTabChange}
                    targetCount={targetCount}
                 />
                <TargetInfo
                    dateRangeFrom={dateRangeFrom}
                    dateRangeTo={dateRangeTo}
                    selectedTargets={selectedTargets}
                    targetCount={targetCount}
                    showSelected={false}
                    showDate={true} />
                
                {tabContent[selectedTab]}
            </PlutoDrawer>
        </>
    )
} 

const mapDispatchToProps = dispatch => {
    return {
        fetchStart: (offset, limit) => dispatch(refuelActions.fetchStart(offset, limit)),
        addTarget: target => dispatch(refuelActions.addTarget(target)),
        deleteTarget: () => dispatch(refuelActions.deleteTarget()),
        setDateRange: (from, to) => dispatch(refuelActions.setDateRange(from, to)),
        setSelectedTab: value => dispatch(refuelActions.setSelectedTab(value)),
        setMounted: value => dispatch(refuelActions.setMounted(value)),
    }
}

function mapStateToProps(state) {
    return {
        state: state,
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Refuels)