import { makeStyles } from "@mui/styles"
import ErrorRow from "./ErrorRow"

const useStyles = makeStyles( (theme) => ({
    root: {
        height: "50vh",
    },
    header: {
        borderBottom: `solid 1px ${theme.palette.grey[300]}`,
    },
    rows: {

    },
}))

const AllErrors = ({show, items}) => {

    const classes = useStyles()

    if (!show) return null 

    return (    
        <div className={classes.root}>
            <div className={classes.rows}>
                {items.map((item, index) => <ErrorRow error={item} key={index} index={index} />)}
            </div>
        </div>
    )
}

export default AllErrors