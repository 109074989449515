import axios from 'axios'
import { createPlutoLogger } from '../../utilities/Common'

const BASE_URL = process.env.REACT_APP_API_URL

const D = createPlutoLogger("[REPO] [shareTarget.js]")

/**
 * Generate a sharable link for the target
 * @param {object} credentials - Complex object, mandatory to contain the following properties:
 * @param {string} credentials.username - The username
 * @param {string} credentials.password - The password
 * @param {string} acceptLanguage  - Mandatory parameter for the API, use the current language
 * @param {object} payload - The query parameters for the API
 */
export const generateSharableLink = (credentials, payload, acceptLanguage) => {
   
    D('generateSharableLink', credentials, payload, acceptLanguage)

    const { fromTimeMicros, toTimeMicros } = payload
    const payloadAsJSON = JSON.stringify(payload)

    // print the payload in the console
    //console.debug("[REPOSITORY] [shareTarget.js] Generating sharable link with payload: ", payloadAsJSON)
    //console.debug("[REPOSITORY] [shareTarget.js] type of payloadAsJSON: ", typeof payloadAsJSON)
    //console.debug("[REPOSITORY] [shareTarget.js] [sharable_link_time] fromTimeMillis: ", fromTimeMicros)
    //console.debug("[REPOSITORY] [shareTarget.js] [sharable_link_time] toTimeMillis: ", toTimeMicros)
    
    // print the times in the console formatted as date
    const fromMilis = fromTimeMicros / 1000
    const toMilis = toTimeMicros / 1000
    //console.debug("[REPOSITORY] [shareTarget.js] [sharable_link_time] fromTimeMillis as date: ", new Date(fromMilis))
    //console.debug("[REPOSITORY] [shareTarget.js] [sharable_link_time] toTimeMillis as date: ", new Date(toMilis))

    return axios.post(
        `${BASE_URL}/urls/generate`,
        payloadAsJSON,
        {
            headers: {
                'Content-Type': 'application/json',
                'Accept-Language': acceptLanguage,
            },
            auth: credentials
        }
    )
}