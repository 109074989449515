// React imports
import React from 'react'

// UI themes
import MuiXLicense from './MuiXLicense'
import PlutoLocalizationProvider from './PlutoLocalizationProvider'
import Layout from './Layout'
import { CssBaseline, ThemeProvider } from '@mui/material'
import { useAppControl } from './AppControlProvider'
import { AVAILABLE_THEMES } from '../Theme/themes'
import { useKeyboard } from './useKeyboard'
import { createPlutoLogger } from '../../utilities/Common'

export const AUTH_NOT_AUTHENTICATED = "not_authenticated"
export const AUTH_PASSWORD = "password"
export const AUTH_TOKEN = "token"

//const DEFAULT_LANGUAGE_CODE = process.env.REACT_APP_DEFAULT_LANGUAGE_CODE

const D = createPlutoLogger("[App.js]")

const App = (props) => {

  // keyboard
  useKeyboard()

  // theme
  const { getThemeName } = useAppControl()
  const currentThemeName = getThemeName()

  D("Current theme name: ", currentThemeName)  
  const currentTheme = AVAILABLE_THEMES[currentThemeName].theme

  const languageCode = props.languageCode
  D("Start language parameter is: ", languageCode)

  return (
      <>
        <MuiXLicense />
        <ThemeProvider theme={currentTheme}>
          <PlutoLocalizationProvider>
              <CssBaseline />
              <Layout languageCode={languageCode} />
          </PlutoLocalizationProvider>
        </ThemeProvider>
    </>
  )
}

export default App