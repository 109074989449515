import * as COM from '../../../utilities/Common'

import { take, takeEvery, putResolve, call, select, put, fork } from 'redux-saga/effects'
import * as trackingRepo from '../../repository/tracking'
import * as actionTypes from '../../actions/actionTypes'

import * as errorActions from '../../actions/error'
import * as trackingActions from '../../actions/tracking'

import { ApiError } from '../../../other/ApiError'

const D = COM.createPlutoLogger("📍 [SAGA] [tracking.js]")

function* resetPopupTargetIdIfRemoved(items) {
    const state = yield select()
    const { tracking } = state
    const { popupTargetId } = tracking

    D("[resetPopupTargetIdIfRemoved] items: ", items)

    const targetForPopup = COM.getItemFromAnArrayById(items, popupTargetId)
    D("[resetPopupTargetIdIfRemoved] targetForPopup: ", targetForPopup)
    if (!targetForPopup) {
        D("[resetPopupTargetIdIfRemoved] Popup target is not in the list. Reseting popupTargetId (to null).")
        yield putResolve(trackingActions.setPopupTargetId(null))
    }
}

function* updateTargets() {       
    const state = yield select();
    const { tracking, settings } = state
    const { auth } = state
    const { credentials } = auth
    const token = credentials.token
    const useToken = token ? true : false
    //D("[SAGA tracking.js] updateTargets called with the following parameters: ", state.auth.credentials, state.tracking.targets)
    //D(`Using ${useToken ? 'token' : 'credentials'} for the API call. credentials: ${credentials}`)

    // filtering by status
    const filteredByStatus = []
    filteredByStatus[`${COM.TRACKING_STATUS_PENDING}`] = []
    filteredByStatus[`${COM.TRACKING_STATUS_NO_DATA}`] = []
    filteredByStatus[`${COM.TRACKING_STATUS_LIVE}`] = []

    state.tracking.targets.forEach((i) => filteredByStatus[`${i.status}`].push(i))

    //we have to ignore items with NO_DATA status if we have any items with PENDING status
    const merged = []
    merged.push(...filteredByStatus[`${COM.TRACKING_STATUS_PENDING}`])
    merged.push(...filteredByStatus[`${COM.TRACKING_STATUS_LIVE}`])
    if (filteredByStatus[`${COM.TRACKING_STATUS_PENDING}`].length === 0) merged.push(...filteredByStatus[`${COM.TRACKING_STATUS_NO_DATA}`])

    // create datastructure for the API call
    const targetsArray = merged.map((t) => {             

        const ret = {
            targetId: t.target.id,
            
            fromTimeMicros: t.status === COM.TRACKING_STATUS_PENDING 
            ? COM.DEFAULT_FROM_TIME_MILIS : t.currentPosition
            ? t.currentPosition.timeMicros : COM.DEFAULT_FROM_TIME_MILIS,
            
            asynchronous: t.status !== COM.TRACKING_STATUS_PENDING
        }

        return ret
    })

    //We have to pass the popupTargetId as a query param to the API call
    const { popupTargetId } = tracking
    const languageCode = settings.languageCode
    const JSONTargetsArray = JSON.stringify(targetsArray)
    //D("[SAGA tracking.js] Calling getTargetsPosition with the following parameters: ", credentials, JSONTargetsArray, popupTargetId, languageCode, useToken)

    try {
        const { data } = yield call(trackingRepo.getTargetsPosition, credentials, JSONTargetsArray, popupTargetId, languageCode, useToken)
        yield putResolve(trackingActions.updateTargetsSuccess(data))  
    } catch (e) {
        yield putResolve(errorActions.addError(new ApiError(e, ApiError.MODULE_TRACKING)))
    }
}

function* updateTarget(action) {

    const targetId = action.payload
    const { auth, tracking, settings } = yield select()   
    const { credentials } = auth
    const token = credentials.token
    const useToken = token ? true : false
    const currentTarget = tracking.targets.find(t => t.target.id === targetId)
    const currentTargetStatus = currentTarget.status
    //D("[SAGA] [tracking.js] updateTargets called with the following parameters: ", credentials, targets)
    //D(`[SAGA] [tracking.js] Using ${useToken ? 'token' : 'credentials'} for the API call. credentials: `, credentials)

    // We have to set the status to pending to show a progess indicator
    yield putResolve(trackingActions.setTargetStatus(targetId, COM.TRACKING_STATUS_PENDING))

    D("[updateTarget] targetId: ", targetId)

    const targetsArray = [
        {
            targetId: targetId,
            fromTimeMicros: + new Date(),
            asynchronous: false,
        },    
    ]

    //D("[SAGA] [tracking.js] [updateTarget] targetsArray: ", targetsArray)
    
    //We have to pass the popupTargetId as a query param to the API call if the popup is open
    try {
        const { popupTargetId } = tracking
        const languageCode = settings.languageCode
        const JSONTargetsArray = JSON.stringify(targetsArray)
        const { data } = yield call(trackingRepo.getTargetsPosition, credentials, JSONTargetsArray, popupTargetId, languageCode, useToken)
        yield putResolve(trackingActions.updateTargetsSuccess(data))
    } catch (e) {
        yield putResolve(trackingActions.setTargetStatus(targetId, currentTargetStatus))
        yield putResolve(errorActions.addError(new ApiError(e, ApiError.MODULE_TRACKING)))
    }
}

function* handleUpdateTargetsSuccess(action) {

    const newTargets = []                                   
    const receivedTargets = action.payload                  // These are the received items from the server
    const currentState = yield select()                     // The current state contains the actual array of targets
    const currentItems = currentState.tracking.targets      

    const getNewTargetById = (id) => {
        let f = receivedTargets.filter((i) => i.target.id === id)
        return f && f.length > 0 ? f[0] : null
    }

    // Merge newly received data with the currentItems array
    currentItems.forEach((i) => {
        const item = getNewTargetById(i.target.id)
        let newItem = item ? {
            ...item,
            frontendTimeMicros: i.frontendTimeMicros
        } : {...i}
        newItem.status = newItem.currentPosition ? COM.TRACKING_STATUS_LIVE : COM.TRACKING_STATUS_NO_DATA
        newTargets.push(newItem)
    })

    //1. Push an action and set the new targets
    yield putResolve(trackingActions.setTargets(newTargets))  
}

//XXX: this function is little bit messy. Rethinking & redesigning would be great!
const enhanceItemsIfNecessary = (items) => {
    const enhancedItems = []
    const currentTime = + new Date() //shorthand for get time
    for (var ti of items) {
        let ei = ti;

        //Note: the rehydration process also uses this function, so the incoming structure could be enhanced already!
        //We have to check the object here, because we do not want to cascade the addition of the target object!
        if (!ti.target) {
            ei = {
                target: {...ti},
                frontendTimeMicros: currentTime,
                status: COM.TRACKING_STATUS_PENDING
            }
        }
        enhancedItems.push(ei)
    }

    return enhancedItems;
}

function* addTargets(action) {

    D("[addTargets] Action:", action)
    
    const newItems = action.payload

    const currentState = yield select()
    const currentItems = currentState.tracking.targets
    const popupTargetId = currentState.tracking.popupTargetId
   
    // If the popup target is removed from the list, we have to reset the popupTargetId to null
    D("[addTargets] Checking if the popup target is removed from the list.",newItems)
    if (newItems.length == 1 && newItems[0].id == popupTargetId) {
        D("[addTargets] Reset popup target ID ")
        yield putResolve(trackingActions.setPopupTargetId(null))
    }
    const newEnhancedItems = enhanceItemsIfNecessary(newItems)

    // The current items array is empty, we have to create the default
    if (currentItems.length === 0) {
        yield putResolve(trackingActions.setTargets(newEnhancedItems))
        yield putResolve(trackingActions.pollRestart())
    } else {

        /*
        
        SYMMETRIC DIFFERENCE OF THE ARRAYS

        We are going to create the symmetric difference of the current items and the new items.
        It means all of items which are represented in both the 'current items' and the 'new enhanced items'
        arrays will be removed. If a target is in the list and the user clicks on it will be removed and all
        of the items which aren't represented in the current list will be added to. This behaviour allows the
        user to use only the targets list to 'toggle select' items.
        
        */
        
        //TODO: move to COM
        const arraySubstract = (a, b) => {
            const res = []
            for (let x of a) {
                let contains = false;
                for (let y of b) {
                    if (x.target.id === y.target.id) {
                        contains = true;
                        continue;
                    }
                }
                if (!contains) res.push(x)
            }
            return res;
        }

        //Combining the two arrays
        const filtered = [
            ...arraySubstract(currentItems, newEnhancedItems),
            ...arraySubstract(newEnhancedItems, currentItems),
        ]

        yield putResolve(trackingActions.setTargets(filtered))
        yield putResolve(trackingActions.pollRestart())
    }
}

function* replaceAllTargets(action) {

    D("Replace all targets action received.")

    const newItems = action.payload
    //D("New items: ", newItems)


    yield resetPopupTargetIdIfRemoved(newItems)

    const newEnhancedItems = enhanceItemsIfNecessary(newItems)

    yield putResolve(trackingActions.setTargets(newEnhancedItems))
    yield putResolve(trackingActions.pollRestart())
}

function* removeTargetById(action) {
    
    const tid = action.payload
    const currentState = yield select()
    const currentTargets = currentState.tracking.targets
    const newTargets = currentTargets.filter(e => e.target.id !== tid)

    yield resetPopupTargetIdIfRemoved(newTargets)

    yield putResolve(trackingActions.setTargets(newTargets))
    yield putResolve(trackingActions.pollRestart())
}

function* setTargetStatusById(action) {

    const tid = action.targetId
    const status = action.status
    const state = yield select()
    const targets = state.tracking.targets

    // Get by ID and set the status
    const filtered = targets.filter(e => e.target.id === tid)
    if (filtered[0]) filtered[0].status = status

    yield putResolve(trackingActions.setTargets(targets))
}

function* inProgressOn() {
    yield putResolve(trackingActions.setInProgress(true))
}

function* inProgressOff() {
    yield putResolve(trackingActions.setInProgress(false))
}

/*function* reloadStoredTargets() {


    try {
        const { settings, auth } = yield select()
        D("[reloadStoredTargets] Reloaded settings: ", settings)
        D("[reloadStoredTargets] Authentication details: ", auth)

        const storedTrackingTargets = settings.trackingTargets //We rename the variable to make it more readable and to avoid confusion
        
        const storedUserId = settings.userId
        D("[reloadStoredTargets] Reloaded, stored user ID: ", storedUserId)

        const credentials = auth.credentials
        const authenticatedUserId = credentials.id


        // Using the same browser for both password login and token login can cause all stored targets to be imported again.
        // This should be avoided because if a valid token is presented, the API calls will return data only for the single target defined by the token.
        // This can lead to a situation where all previously stored targets are rehydrated but cannot be updated.
        const token = credentials.token
        const isTokenValid = COM.isTokenValid(token)
        if (isTokenValid) {
            D("[reloadStoredTargets] Token is valid. Skipping reload stored targets.")
            return
        }

        const currentAndStoredUserIdsAreEqual = storedUserId && storedUserId === authenticatedUserId

        if (!currentAndStoredUserIdsAreEqual) {
            D("[reloadStoredTargets] Stored user ID is not equal to the authenticated user ID. Skipping reload stored targets.")
            return
        } else {
            D("[reloadStoredTargets] Stored user ID is equal to the authenticated user ID. Reloading stored targets. Stored user ID: ", storedUserId, " Authenticated user ID: ", authenticatedUserId)
        }
 
        const haveStoredTargets = storedTrackingTargets && storedTrackingTargets.length > 0
        if (!haveStoredTargets) {
            D("[reloadStoredTargets] No stored targets found. Skipping reload.")
            return
        }

        D("[reloadStoredTargets] Reload last tracked targets. We have ", storedTrackingTargets.length, " targets stored for user ID: ", storedUserId)

        yield put(trackingActions.replaceAllTargets(storedTrackingTargets))

    } catch (e) {
        console.warn("[reloadStoredTargets] An error occurred during reloading stored targets: ", e)
    }   
}*/

function* checkPendingTargetsCount(action) {
    const { tracking } = yield select()
    const { targets } = tracking
    if ( targets.filter(t => t.status === COM.TRACKING_STATUS_PENDING).length === 0 ) {
        //There is no pending target
        yield putResolve(trackingActions.noPendingTarget())
    }
}

function* setPopupTargetId(action) {
    const popupTargetId = action.payload
    
    // If the popup of the target is closed, we set the popupTargetId to null.
    if (popupTargetId == null) return

    //D(`[SAGA] [tracking.js] Popup target is has been changed to: ${popupTargetId}. Updating the target.`)
    yield putResolve(trackingActions.updateTarget(popupTargetId))
}

export function* saga() {
    yield takeEvery(actionTypes.TRACKING_SET_TARGETS, checkPendingTargetsCount)

    yield takeEvery(actionTypes.TRACKING_UPDATE_TARGETS_START, updateTargets)
    yield takeEvery(actionTypes.TRACKING_UPDATE_TARGETS_SUCCESS, handleUpdateTargetsSuccess)
    
    yield takeEvery(actionTypes.TRACKING_ADD_TARGETS, addTargets)

    yield takeEvery(actionTypes.TRACKING_REPLACE_ALL_TARGETS, replaceAllTargets)
    yield takeEvery(actionTypes.TRACKING_REMOVE_TARGET_BY_ID, removeTargetById)

    yield takeEvery(actionTypes.TRACKING_UPDATE_TARGET, updateTarget)
    yield takeEvery(actionTypes.TRACKING_SET_TARGET_STATUS, setTargetStatusById)


    yield takeEvery(actionTypes.TRACKING_UPDATE_TARGETS_START, inProgressOn )

    yield takeEvery([
        actionTypes.TRACKING_UPDATE_TARGETS_SUCCESS,
        actionTypes.TRACKING_SET_TARGETS,
        actionTypes.TRACKING_UPDATE_TARGETS_FAIL
    ], inProgressOff )

    yield takeEvery(actionTypes.TRACKING_SET_POPUP_TARGET_ID, setPopupTargetId)
}

