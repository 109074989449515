
import PlaceIcon from '@mui/icons-material/Place';
import LanguageIcon from '@mui/icons-material/Language';
import ListItemButton from '@mui/material/ListItemButton';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';

import { Close } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { formattedPhoneNumber } from "../../utilities/Common";

import { BOTTOM_MENU_HEIGHT } from "../../utilities/Common";
import { DIALOG_CONTACTS, useAppControl } from "../App/AppControlProvider";
import { makeStyles } from '@mui/styles';
import { Divider, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';

const useStyles = makeStyles( theme => ({
    root: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        width: "320px",
        height: "100%",
        minHeight: "300px",
        maxHeight: `calc(92.5vh - ${BOTTOM_MENU_HEIGHT})`,  // Not a totally accurate calculation, but it is close enough
        backgroundColor: theme.palette.primary.main,
        borderRadius: theme.spacing(2), //theme.shape.borderRadius would be the same as the other dialogs but i did not want to change the existing ones
        boxShadow: theme.shadows[5],
    },
    [`@supports (height: 100dvh)`]: {
        root: {
            height: "100dvh",
            maxHeight: `calc(92.5dvh - ${BOTTOM_MENU_HEIGHT})`,  // Not a totally accurate calculation, but it is close enough
        },
    },
    curveContainer: {
        backgroundColor: theme.palette.common.white,
        paddingTop: theme.spacing(6),
        borderRadius: theme.spacing(2), //theme.shape.borderRadius would be the same as the other dialogs but i did not want to change the existing ones
        display: "flex",
    },
    curveLeftWrapperContainer: {
        flex: 25,
        backgroundColor: theme.palette.common.white,
    },
    curveRightWrapperContainer: {
        flex: 75,
        backgroundColor: theme.palette.primary.main,
    },
    curveLeftContainer: {
        flex: 1,
        height: "3rem",
        borderTopRightRadius: "1.5rem",
        backgroundColor: theme.palette.primary.main,
    },
    curveRightContainer: {
        flex: 1,
        height: "3rem",
        borderBottomLeftRadius: "1.5rem",
        backgroundColor: theme.palette.common.white,
        color: theme.palette.primary.main,
    },
    closeContainer: {
        position: "absolute",
        top: ".5rem",
        right: ".5rem",
        backgroundColor: theme.palette.secondary.main,
        borderRadius: "50%",
        width: "1.5rem",
        height: "1.5rem",
        color: theme.palette.common.white,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
    },
    logoContainer: {
        position: "relative",
        top: `${theme.spacing(2) * -1}px`,
        right: theme.spacing(1),
    },
    logo: {
        width: "8rem",
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginBottom: "70",
    },
    contactsContainer: {
        flex: 1,
        overflowY: "auto",
        overflowWrap: "break-word",
        margin: theme.spacing(1),
        color: theme.palette.primary.contrastText,
        "& *": {
            color: theme.palette.primary.contrastText,
        },
        
        //Firefox
        scrollbarWidth: "thin",
        scrollbarColor: `${theme.palette.scrollbar.background} transparent`,

        //Chrome
        "&::-webkit-scrollbar": {
            width: ".5rem",
            background: "transparent",
        },
        "&::-webkit-scrollbar-thumb": {
            background: theme.palette.scrollbar.background,
            borderRadius: "1rem",
        },
    },
    companyNameContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        paddingTop: theme.spacing(2),
        paddingLeft: theme.spacing(6),
        paddingRight: theme.spacing(6),
        color: theme.palette.primary.contrastText,
    },
    companyName: {
        fontSize: "20px !important",
        fontWeight: "bold",
    },
    companyNameCalling: {
        fontSize: "12px !important",
    },
    [`@media (min-width: 480px)`]: {
        root: {
            width: "480px",
        },
        curveLeftWrapperContainer: {
            flex: 55,
        },
        curveRightWrapperContainer: {
            flex: 45,
        },   
    },
}))

const ContactsDialog = (props) => {
    const classes = useStyles()

    const { t } = useTranslation() 
    const { getDialogOpen, closeDialog } = useAppControl()

    const isOpen = getDialogOpen(DIALOG_CONTACTS)
    const close = () => closeDialog(DIALOG_CONTACTS)
    
    if (!isOpen) return null

    const listItemFont = {fontSize: "13.6px !important"}

    return (

            <div className={classes.root}>

                <div className={classes.closeContainer}> 
                    <Close onClick={close}/>
                </div>

                <div className={classes.curveContainer}>
                    <div className={classes.curveLeftWrapperContainer}>
                        <div className={classes.curveLeftContainer}></div>
                    </div>
                    <div className={classes.curveRightWrapperContainer}>
                        <div className={classes.curveRightContainer}>
                            <div className={classes.logoContainer}>
                                <img src="./itrack-logo.png" alt="iTrack logo" className={classes.logo} />
                            </div>
                        </div>
                    </div>
                </div>    


                <div className={classes.companyNameContainer}>
                    <span className={classes.companyNameCalling}>{t("contacts.ourCompanyName")}:</span>
                    <span className={classes.companyName}>{t("contacts.companyName")}</span>
                </div>

                <div className={classes.contactsContainer}>
                    <List>
                        <ListItem>
                            <ListItemButton component="a" href="https://www.google.com/maps/place/iData+Kft./@47.4702,19.1536171,17z/data=!3m1!4b1!4m6!3m5!1s0x4741c319f6724737:0x9d4790cc078925ba!8m2!3d47.4702!4d19.1536171!16s%2Fg%2F1td6q_vq?entry=ttu" target="_black">
                                <ListItemIcon>
                                    <PlaceIcon />
                                </ListItemIcon>
                                <ListItemText primary="H-1103 Budapest, Gergely utca 112/C."  primaryTypographyProps={listItemFont}/>
                            </ListItemButton>
                        </ListItem>
                        <ListItem>
                            <ListItemButton component="a" href="https://www.itrack.hu"  primaryTypographyProps={listItemFont} target="_blank">
                                <ListItemIcon>
                                    <LanguageIcon />
                                </ListItemIcon>
                                <ListItemText primary="https://www.itrack.hu" primaryTypographyProps={listItemFont}/>
                            </ListItemButton>
                        </ListItem>
                        <ListItem>
                            <ListItemButton component="a" href="https://help.itrack.hu/"  primaryTypographyProps={listItemFont} target="_blank">
                                <ListItemIcon>
                                    <img src="small-itrack-logo.png" alt="iTrack logo" style={{ width:"26px"}} />
                                </ListItemIcon>
                                <ListItemText primary="https://help.itrack.hu" primaryTypographyProps={listItemFont}/>
                            </ListItemButton>
                        </ListItem>
                        <ListItem>
                            <ListItemButton>
                                <ListItemIcon>
                                    <LocalPhoneIcon />
                                </ListItemIcon>
                                <ListItemText primary={formattedPhoneNumber(process.env.REACT_APP_CALL_CENTER_PHONE_NUMBER)} primaryTypographyProps={listItemFont} />
                            </ListItemButton>
                        </ListItem>
                        <ListItem>
                            <ListItemButton component="a" href="mailto:info@idata.hu">
                                <ListItemIcon>
                                    <MailOutlineIcon />
                                </ListItemIcon>
                                <ListItemText primary="info@idata.hu" primaryTypographyProps={listItemFont} />
                            </ListItemButton>
                        </ListItem>
                        <Divider />
                        <ListItem>
                            <ListItemButton component="a" href="https://www.facebook.com/jarmukovetes/"  primaryTypographyProps={listItemFont} target="_blank">
                                <ListItemIcon>
                                    <FacebookIcon />
                                </ListItemIcon>
                                <ListItemText primary="https://www.facebook.com/jarmukovetes"  primaryTypographyProps={listItemFont} />
                            </ListItemButton>
                        </ListItem>
                        <ListItem>
                            <ListItemButton component="a" href="https://www.youtube.com/user/iTrackNyomkovetes"  primaryTypographyProps={listItemFont} target="_blank">
                                <ListItemIcon>
                                    <YouTubeIcon />
                                </ListItemIcon>
                                <ListItemText primary="https://www.youtube.com/user/iTrackNyomkovetes" primaryTypographyProps={listItemFont} />
                            </ListItemButton>
                        </ListItem>
                        <ListItem>
                            <ListItemButton component="a" href="https://www.linkedin.com/company/idata-kft/"  primaryTypographyProps={listItemFont} target="_blank">
                                <ListItemIcon>
                                    <LinkedInIcon />
                                </ListItemIcon>
                                <ListItemText primary="https://www.linkedin.com/company/idata-kft" primaryTypographyProps={listItemFont} />
                            </ListItemButton>
                        </ListItem>
                        <ListItem>
                            <ListItemButton component="a" href="https://www.instagram.com/idata.insta"  primaryTypographyProps={listItemFont} target="_blank">
                                <ListItemIcon>
                                    <InstagramIcon />
                                </ListItemIcon>
                                <ListItemText primary="https://www.instagram.com/idata.insta" primaryTypographyProps={listItemFont} />
                            </ListItemButton>
                        </ListItem>
                    </List>
                </div>
            </div>
    );
}

export default ContactsDialog