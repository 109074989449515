import { takeEvery, put, putResolve, call, select, fork, take, all } from 'redux-saga/effects'

import LogRocket from 'logrocket'

import * as actionTypes from '../actions/actionTypes'
import * as authActions from '../actions/auth'
import * as errorActions from '../actions/error'
import * as settingsActions from '../actions/settings'
import * as authRepo from '../../store/repository/auth'

import { AuthError } from '../../other/AuthError'
import { ANDROID_EXOSKELETON, buildIdString, createPlutoLogger, IOS_EXOSKELETON, isTokenValid } from '../../utilities/Common'

const D = createPlutoLogger("🔐 [SAGA] [auth.js]")

const _isExoskeleton = (presenter) => {
    return (presenter === IOS_EXOSKELETON || presenter === ANDROID_EXOSKELETON)
}

function* authStart(action) {

    const credentials = action.credentials

    const { settings } = yield select()
    const language = settings.languageCode

    try {
        D("[authStart] credentials: ", credentials)
        const token = credentials.token
        if (
            !isTokenValid(token) &&
            (
                !credentials.username ||
                !credentials.password ||
                credentials.username.length < 1 ||
                credentials.password.length < 1
            )
        ) {
            throw new AuthError(null, null, "error.auth.emptyCredentials")
        }

        const authWithPassword = () => call(authRepo.getCurrentUserWithPassword, action.credentials, language)
        const authWithToken = () => call(authRepo.getCurrentUserWithToken, token, language)
        const authCall = isTokenValid(token) ? authWithToken : authWithPassword
        const { data } = yield authCall()

        // data contains unnecessary fields, we have to filter them out
        // We need only username, password, id, token
        const filteredCredentials = {
            password: credentials.password,
            username: credentials.username,
            id: data.id,
            token: credentials.token,
        }
        
        D("Authenticated user's filtered credentials: ", filteredCredentials)
        yield putResolve(authActions.authSuccess(filteredCredentials))

        const remoteUserSettings = {
            uid: data.id,
            userMessage: data.userMessage,
        }
        
        D("[userMessage] Authenticated user's remote settings: ", data)
        yield putResolve(settingsActions.userSettingsChange(remoteUserSettings))

    } catch (e) {
        yield putResolve(authActions.authFail(e))
        if (e instanceof AuthError) {
            yield put(errorActions.addError(e))
        } else {
            yield put(errorActions.addError(new AuthError(e, credentials)))
        }
    }
}

function* authSignOutStart() {
    // We don't have to do anything here, but I left this function here for future use in case of any additional requirements.
    yield putResolve(authActions.authSignOutSuccess())
}

function* checkAutoLogin() {

    D("[checkAutoLogin] Checking auto login...")
    
    const { device, settings } = yield select()
    const { autoLoginData, forcedAutoLogin } = settings  
    const { info, presenter } = device
    const isExoskeleton = _isExoskeleton(presenter)
    
    try {
        //Just for debugging
        D("[checkAutoLogin] Checking auto login... device: ", device)
        D("[checkAutoLogin] Checking auto login... info: ", info)
        D("[checkAutoLogin] Checking auto login... isExoskeleton: ", isExoskeleton, " forcedAutoLogin: ", forcedAutoLogin, "autoLogin username:", autoLoginData?.username, "autlogin password: ", autoLoginData?.password)

        if ((isExoskeleton || forcedAutoLogin) && autoLoginData && autoLoginData.username && autoLoginData.password) {
            
            //Just for debugging
            D("[checkAutoLogin] Do auto login: ", autoLoginData, presenter)

            const credentials = {
                username: autoLoginData.username,
                password: autoLoginData.password,
            }
            
            yield putResolve(authActions.authStart(credentials))

        } else {
            // We have to call this action to because the authToken.js saga is waiting for this action or authSuccess action
            // to start handling the URL check process. 
            yield putResolve(authActions.signInNecessary())
            console.log("[checkAutoLogin] Auto login isn't necessary. Do normal login.")
        }
    } catch (e) {
        console.warn("[checkAutoLogin] Auto login failed: ", e)
    }
}

function* authSuccess() {

    const { auth, device, settings } = yield select()

    const { autoLoginData, forcedAutoLogin } = settings
    const { credentials } = auth
    const { presenter } = device //We do not use info at the moment but you can get it like { info, presenter } = device

    const logRocketIdentifier = credentials.username
    const appVersion = `${process.env.REACT_APP_NPM_PACKAGE_VERSION}-${buildIdString(false)}`

    D("LogRocket identifier: ", logRocketIdentifier)
    D("LogRocket app version: ", appVersion)
    LogRocket.identify(logRocketIdentifier, {
        name: logRocketIdentifier,
        appVersion: appVersion,
    });
    D("LogRocket has been initialized.")

    const isExoskeleton = _isExoskeleton(presenter)

    if (autoLoginData && autoLoginData.id !== credentials.id) {
        D("The stored user id and the id used for the login don't mach! Using the initial state for 'settings'.")
        yield putResolve(settingsActions.initState())
    }
    
    //XXX: ❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗
    //XXX:
    //XXX: ❗❗❗ VERY BAD CODE & DESIGN                ❗❗❗
    //XXX: ❗❗❗ CHANGE THIS CODE AS SOON AS POSSIBLE! ❗❗❗ 
    //XXX:  
    //XXX:
    //XXX:  It's a VERY BAD part of the current application!
    //XXX:  Is saves the username and password into the local storage.
    //XXX:  This 'feature' should be replaced with a token-based solution as soon as possible!
    //XXX:  The customer had been informed multiple times before this code has been implemented.
    //XXX:  They understand, acknowledge and accept the riskk due to their higher priority of business needs.
    //XXX:
    //XXX: ❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗

    const persistObject = {...credentials} //We have to make a copy here, to not to modify the credentials of the state!
    if (!forcedAutoLogin && !isExoskeleton) {
        //D("Remove password from auto login data. isExoskeleton: ", isExoskeleton, " forcedAutoLogin: ", forcedAutoLogin, " autoLoginData: ", autoLoginData)
        persistObject.password = null 
    }
    
    yield putResolve(settingsActions.autoLoginDataChange(persistObject))   
}

function* waitForDevice() {
    
    D("[waitForDevice] ⏳ Waiting for device info ready event...")

    yield all([
        take("persist/REHYDRATE"),
        take(actionTypes.DEVICE_INFO_READY),
    ])
    D("[waitForDevice] Device info ready event has been received!")

    yield checkAutoLogin()
}

function* waitForRehydrate() {
    D("[waitForRehydrate] ⏳ Waiting for rehydrate event...")
    yield take("persist/REHYDRATE")
    yield checkAutoLogin()
}

function* handleSignOutSuccess() {
    D("[handleSignOutSuccess] Sign out success event has been received!")
    try {
        const { device } = yield select()
        const { presenter } = device
        const isExoskeleton = _isExoskeleton(presenter)
        if (isExoskeleton) {
            D("[handleSignOutSuccess] 🦾 We are in an exoskeleton environment. We have to reset the settings.")
            yield putResolve(settingsActions.initState())
        } else {
            D("[handleSignOutSuccess] 🖥️ We are in a normal environment. We do not have to reset the settings.")
        }
    } catch (e) {
        console.warn("[handleSignOutSuccess] Error during sign out: ", e)
    } 
}

export function* saga() {
    
    yield fork(waitForDevice)
    yield fork(waitForRehydrate)

    yield takeEvery(actionTypes.AUTH_START, authStart)
    yield takeEvery(actionTypes.AUTH_SUCCESS, authSuccess)
    yield takeEvery(actionTypes.AUTH_SIGN_OUT_START, authSignOutStart)
    yield takeEvery(actionTypes.AUTH_SIGN_OUT_SUCCESS, handleSignOutSuccess)
}
