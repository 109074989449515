import { connect } from 'react-redux'


const EnhancedIcon = (props) => {
    const { state, icon, isSelected  } = props;
    const { settings } = state;
    const { filterPaneVisible } = settings;

    if (!filterPaneVisible || (isSelected)) return icon
    
    return icon
}

const mapDispatchToProps = dispatch => {
    return {
        //
    }
}

const mapStateToProps = (state) => {
    return {
        state: state,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EnhancedIcon)