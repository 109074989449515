import { take, putResolve, call, race, select, delay, takeEvery, fork} from 'redux-saga/effects'
import { createPlutoLogger, isTokenValid } from '../../../utilities/Common'

import * as actionTypes from '../../actions/actionTypes'
import * as chatActions from '../../actions/chat'
import * as errorActions from '../../actions/error'

import * as chatRepo from '../../repository/chat'
import { ApiError } from '../../../other/ApiError'
import { mergeCurrentMessagesIntoNewThreads } from './chatUtils'

const D = createPlutoLogger("💬 [SAGA] [chatMessagePolling.js]")
const WAIT_BETWEEN_POLLS_MS = 5000 //The server call is enough to wait. It waits some time before returning the result.
const WAIT_BETWEEN_POLL_ERROR_MS = 5000 //In case of an error it waits some time before retrying.

function* pollSuccess(action) {
 
    const payload = action.payload
    const serverTimeMicros = payload.serverTimeMicros
    const messages = payload.messages

    const currentState = yield select()
    const currentThreads = currentState.chat.threads

    const newThreads = currentThreads
    mergeCurrentMessagesIntoNewThreads(newThreads, messages)
    
    yield putResolve(chatActions.setThreads(newThreads))
    yield putResolve(chatActions.setServerTimeMicros(serverTimeMicros))
}

// POLLING
function* worker() {
    try {
        D("[worker] Working....")
        const currentState = yield select()

        const { data } = yield call(chatRepo.pollMessages, {
            credentials: currentState.auth.credentials,
            fromTime: currentState.chat.serverTimeMicros,
            acceptLanguage: currentState.settings.languageCode,
        })
        D("[worker] Finished.")
        yield putResolve(chatActions.pollSuccess(data))
        D("[worker] Poll success.")
        yield delay(WAIT_BETWEEN_POLLS_MS)
        D(`[worker] Waiting ${WAIT_BETWEEN_POLLS_MS}ms...`)
    } catch (e) {
        D("[worker] Error: ", e)
        yield putResolve(errorActions.addError(new ApiError(e, ApiError.MODULE_CHAT)))
        D(`[worker] Waiting ${WAIT_BETWEEN_POLL_ERROR_MS}ms...`)
        yield delay(WAIT_BETWEEN_POLL_ERROR_MS)
    } finally {
        D(`[worker] Waiting finished.`)
    }
}

export function* manager() {
    D("[manager] Forked.")

    while (true) {
        D("[manage] [managerLoop] ⏳ Waiting for start or auth success...")
        yield take([
            actionTypes.CHAT_POLL_START,
            actionTypes.AUTH_SUCCESS,
        ])
        D("[manage] [managerLoop] Auth success or start received.")

        try {
            const state = yield select()
            const { credentials } = state.auth
            const { token } = credentials
            const needToStop = token && isTokenValid(token)
            D("[manage] [managerLoop] needToStop: ", needToStop)

            if (needToStop) {
                D("[manage] [managerLoop] Token is valid. Jumping back to wait start or auth. Polling is not needed.")
                continue; // Continue the managerLoop
            }
        } catch (e) {
            D("[manage] [managerLoop] Error: ", e)
            yield putResolve(errorActions.addError(new ApiError(e, ApiError.MODULE_CHAT)))
        }

        D("[manage] [managerLoop] 🟢 Starting the polling loop.")
        // polling loop
        while (true) {
            D("[manager] [pollingLoop] ⏳ Polling loop is waiting for race to be finished...")
            const { stop, restart } = yield race({
                success: call(worker),
                stop: take([
                    actionTypes.CHAT_POLL_STOP,
                    actionTypes.AUTH_SIGN_OUT_START,
                ]),
                restart: take(actionTypes.CHAT_POLL_RESTART),
            });
            D("[manager] [pollingLoop] Race finished.")

            if (stop) {
                D("[manager] [pollingLoop] 🔴 Stopped. ", stop);
                break; // Exit the inner loop to restart the manager
            }

            if (restart) {
                D("[manager] [pollingLoop] 🔄 Restarted. ", restart);
                continue; // Continue the polling loop
            }

        }
    }
}
export function* saga() {
    D("Forked.")
    yield fork(manager)
    yield takeEvery(actionTypes.CHAT_POLL_SUCCESS, pollSuccess) 
}